<template>
  <div class="page-padding flex flex-col gap-5">
    <div class="flex items-center justify-between">
      <h1>
        Ordonnances
      </h1>
      <OngoingRenewalPlanButton :count="data?.meta.total" :consumer-key="'c_' + route.params.customerId" />
    </div>
    <PatientFilters v-if="state.tags" v-model="state.selectedTags" :tags="state.tags" />
    <base-flex-spinner v-if="state.isLoading" class="mt-4" />
    <div v-else>
      <prescription-list
        :prescriptions="state.prescriptions"
        @on-press="onPress"
      />
      <div class="flex justify-center py-4">
        <base-button
          v-if="!!state.afterKey && !state.endReached"
          class="font-medium"
          :disabled="state.isLoadingMore"
          @click="loadMore"
        >
          <base-spinner
            v-if="state.isLoadingMore"
            size="button"
          />
          <span v-else>Voir plus</span>
        </base-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {onMounted, reactive, watch} from "vue";
import {prescriptionApi} from '@/container';
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import PrescriptionList from '@/prescription/components/PrescriptionList.vue';
import BaseButton from '@/core/components/base/BaseButton.vue';
import BaseFlexSpinner from '@/core/components/base/spinner/BaseFlexSpinner.vue';
import {Prescription, Tag} from "@/prescription/interfaces/Prescription";
import PatientFilters, {PatientFiltersProps} from "@/prescription/components/PatientFilters.vue";
import {PatientSearchParams} from "@/customer/interfaces/Patient";
import OngoingRenewalPlanButton from "@/prescription/components/OngoingRenewalPlanButton.vue";
import {useRoute, useRouter} from "vue-router";
import useRenewalPlanIndex from "@/prescription/hooks/useRenewalPlanIndex";

const route = useRoute();
const router = useRouter();
const {data} = useRenewalPlanIndex({consumer_key: 'c_' + route.params.customerId});

const state = reactive({
    isLoading: false,
    isLoadingMore: false,
    prescriptions: [] as Prescription[],
    afterKey: null,
    endReached: false,
    selectedTags: {
        tags: [],
        certified: null,
        children: null,
        tags_operator: 'AND',
        purchase_type: null,
        purchase_id: null,
        gist: null,
    } as PatientFiltersProps,
    tags: null as Tag[] | null,
});

/**
 * Refresh the data.
 */
const refresh = async () => {
    state.isLoading = true;
    state.prescriptions = [];
    state.isLoadingMore = false;
    state.endReached = false;
    state.afterKey = null;

    await index().finally(() => state.isLoading = false);
};

/**
 * List prescriptions of a customer.
 *
 * @param [after] Optional after key, to get prescriptions beyond that key.
 *
 * @return {Promise<void>}
 */
const index = async (after = null): Promise<void> => {
    const params: Partial<PatientSearchParams & { after: string }> = {
        tags: state.selectedTags.tags,
        tags_operator: 'AND',
        limit: 10,
        after: after ? JSON.stringify(after) : '',
        ...state.selectedTags.children && {age_to: '19 years'},
        ...state.selectedTags.certified && {certified: 1},
        ...state.selectedTags.purchase_type && {purchase_type: state.selectedTags.purchase_type},
        ...state.selectedTags.purchase_id && {purchase_id: state.selectedTags.purchase_id},
        ...state.selectedTags.gist && {gist: 1},
    };

    return prescriptionApi()
        .customerIndex((parseInt(route.params.customerId as string)), params)
        .then(response => {
            state.prescriptions = [...state.prescriptions, ...response.data];
            if (state.afterKey !== response.meta.after_key) {
                state.afterKey = response.meta.after_key;
            } else {
                state.endReached = true;
            }

            state.tags = response.meta.tags;
        });
};

/**
 * Load more prescriptions with the after key.
 */
const loadMore = async () => {
    state.isLoadingMore = true;

    await index(state.afterKey).finally(() => {
        state.isLoadingMore = false;
    });
};

/**
 * On press handle action with the prescription.
 *
 * @param prescription
 */
const onPress = (prescription: Prescription) => {
    router.push({name: 'customer.prescriptions.renewal', params: {prescriptionId: prescription.id}});
};

watch(() => state.selectedTags, refresh, {deep: true});

onMounted(async () => {
    state.isLoading = true;

    await index().finally(() => {
        state.isLoading = false;
    });
});
</script>
