import {AxiosInstance} from "axios";
import PrescriptionRenewalPlan, {
    PrescriptionRenewalPlanIndexParams
} from "@/prescription/interfaces/prescription-renewal-plan/PrescriptionRenewalPlan";

export interface PrescriptionRenewalPlanResourceInterface {
    index: (params: PrescriptionRenewalPlanIndexParams | undefined) => Promise<IndexResponse>;
    create: (plan: Partial<PrescriptionRenewalPlan>) => Promise<PrescriptionRenewalPlan>;
    update: (plan: PrescriptionRenewalPlan, data: Partial<PrescriptionRenewalPlan>) => Promise<PrescriptionRenewalPlan>;
    read: (plan: PrescriptionRenewalPlan) => Promise<PrescriptionRenewalPlan>;
    delete: (plan: Partial<PrescriptionRenewalPlan>) => Promise<{ is_deleted: boolean }>;
}

interface IndexResponse {
    data: PrescriptionRenewalPlan[],
    meta: { total: number, consumer_count: number }
}

export default function (hdsClient: AxiosInstance): PrescriptionRenewalPlanResourceInterface {
    return {
        /**
         * Index prescription renewal plans.
         */
        async index(params = {}): Promise<IndexResponse> {
            return await hdsClient.get('renewal-plans', {params})
                .then(response => response.data);
        },
        /**
         * Create a prescription renewal plan.
         */
        async create(plan: Partial<PrescriptionRenewalPlan>): Promise<PrescriptionRenewalPlan> {
            return await hdsClient.post('renewal-plans', {data: plan})
                .then(response => response.data.data);
        },
        /**
         * Read a prescription renewal plan.
         */
        async read(plan: PrescriptionRenewalPlan): Promise<PrescriptionRenewalPlan> {
            return await hdsClient.get(`renewal-plans/${plan.id}`)
                .then(response => response.data.data);
        },
        /**
         * Update a prescription renewal plan.
         */
        async update(plan: PrescriptionRenewalPlan, data: Partial<PrescriptionRenewalPlan>): Promise<PrescriptionRenewalPlan> {
            return await hdsClient.patch(`renewal-plans/${plan.id}`, {data})
                .then(response => response.data.data);
        },
        /**
         * Delete a prescription renewal plan.
         */
        async delete(plan: Partial<PrescriptionRenewalPlan>): Promise<{ is_deleted: boolean }> {
            return await hdsClient.delete(`renewal-plans/${plan.id}`)
                .then(response => response.data.data);
        },
    };
}
