<script setup lang="ts">
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogFooter, DialogClose
} from "@/core/components/ui/dialog";
import {computed, ref} from "vue";
import {LoyaltyCardMembership} from "@/loyalty-card/interfaces/hds/LoyaltyCard";
import {loyaltyCardMembershipRewardApi} from "@/container";
import BaseInput from "@/core/components/base/BaseInput.vue";
import BaseProductSearchBar from "@/product/components/BaseProductSearchBar.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {useToastStore} from "@/stores/toast.store";

const {title = "Attribuer un produit", membership, customerId} = defineProps<{
    title?: string;
    membership: LoyaltyCardMembership;
    customerId: number;
}>();

const emits = defineEmits(["attributed"]);

const amount = ref(0);
const selectedProduct = ref();
const open = ref(false);
const loading = ref(false);
const toast = useToastStore();

const attributable = computed(() => {
    return membership.unit_balance > 0;
});

const objectives = computed(() => {
    return membership.loyalty_card.objectives.filter(
        (objective: any) => objective.reward.type.slug === 'product'
    );
});

/**
 * Submit the reward.
 */
const submit = () => {
    loading.value = true;
    loyaltyCardMembershipRewardApi()
        .create(customerId, membership.id, {
            type: 'product',
            amount: amount.value,
            product_id: selectedProduct.value.id
        })
        .then(() => {
            toast.showSuccess({
                content: 'Le produit a été attribué avec succès'
            });
            emits('attributed');
            amount.value = 0;
            selectedProduct.value = undefined;
            open.value = false;
        })
        .catch(() => {
            toast.showError({
                content: 'Une erreur est survenue lors de l\'attribution du produit'
            });
        })
        .finally(() => {
            loading.value = false;
        });
};

const canSubmit = computed(() => {
    return !!amount.value
        && amount.value > 0
        && selectedProduct.value
        && amount.value <= membership.unit_balance;
});
</script>

<template>
  <div>
    <div>
      <button
        class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        @click="open = true"
      >
        {{ title }}
      </button>
    </div>
    <Dialog :open="open" @update:open="(value) => open = value">
      <DialogContent @interact-outside="(e) => e.preventDefault()">
        <DialogHeader class="text-start">
          <DialogTitle>
            {{ title }}
          </DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <div v-if="attributable" class="space-y-5">
            <BaseInput
              :value="amount"
              type="number"
              label="Nombre de produits"
              @input="(value) => amount = value"
            />

            <BaseProductSearchBar :density="'compact'" variant="solo" @select="(product) => selectedProduct = product" />
          </div>

          <div v-else>
            <div class="bg-yellow-50 border-l-4 border-yellow-400 p-4">
              <div class="flex">
                <div class="flex-shrink-0">
                  <svg
                    class="h-5 w-5 text-yellow-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div class="ml-3">
                  <p class="text-sm text-yellow-700">
                    La carte du membre ne permet pas d’obtenir la récompense.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="mt-2 border-b border-gray-200">
              <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt class="text-sm font-medium text-gray-500">
                    Objectifs
                  </dt>

                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <ul
                      v-for="objective in objectives"
                      :key="objective.id"
                    >
                      <li>
                        {{ objective.reward.teaser }}
                      </li>
                    </ul>
                  </dd>
                </div>

                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt class="text-sm font-medium text-gray-500">
                    Progression
                  </dt>

                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ membership.formatted.unit_balance }}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </DialogDescription>
        <DialogFooter>
          <DialogClose as-child>
            <BaseButton>
              Fermer
            </BaseButton>
          </DialogClose>
          <BaseButton
            primary
            :loading="loading"
            :disabled="!canSubmit"
            @click="submit"
          >
            Valider
          </BaseButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  </div>
  <!--  <div>-->


  <!--      <div-->
  <!--        v-show="attributed"-->
  <!--        class="flex mt-4"-->
  <!--      >-->
  <!--        <div class="flex-shrink-0">-->
  <!--          <svg-->
  <!--            aria-hidden="true"-->
  <!--            viewBox="0 0 20 20"-->
  <!--            fill="currentColor"-->
  <!--            class="h-5 w-5 text-green-400"-->
  <!--            xmlns="http://www.w3.org/2000/svg"-->
  <!--          >-->
  <!--            <path-->
  <!--              fill-rule="evenodd"-->
  <!--              clip-rule="evenodd"-->
  <!--              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"-->
  <!--            />-->
  <!--          </svg>-->
  <!--        </div>-->

  <!--        <div class="ml-1">-->
  <!--          <h3 class="text-sm font-medium text-green-800">-->
  <!--            Récompense attribuée-->
  <!--          </h3>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--      <template #footer>-->
  <!--        <BaseButton @click="close">-->
  <!--          Fermer-->
  <!--        </BaseButton>-->

  <!--        <BaseButton-->
  <!--          primary-->
  <!--          :disabled="!attributable"-->
  <!--          @click="submit"-->
  <!--        >-->
  <!--          Valider-->
  <!--        </BaseButton>-->
  <!--      </template>-->
  <!--    </BaseModal>-->
  <!--  </div>-->
</template>

<style>
:deep(.product-search-bar) {
    @apply border-2
}
</style>

<!--<script lang="ts">-->
<!--import {defineComponent} from "vue";-->
<!--import {-->
<!--    loyaltyCardProductApi,-->
<!--    loyaltyCardMembershipRewardApi-->
<!--} from '@/container';-->

<!--import BaseModal from '@/core/components/base/BaseModal.vue';-->
<!--import BaseInput from '@/core/components/base/BaseInput.vue';-->
<!--import BaseButton from '@/core/components/base/BaseButton.vue';-->
<!--import BaseInputSearch from '@/core/components/base/BaseInputSearch.vue';-->

<!--export default defineComponent({-->
<!--    components: {-->
<!--        BaseModal,-->
<!--        BaseInput,-->
<!--        BaseButton,-->
<!--        BaseInputSearch-->
<!--    },-->

<!--    props: {-->
<!--        membership: {-->
<!--            type: Object,-->
<!--            required: true-->
<!--        }-->
<!--    },-->
<!--    emits: ['attributed'],-->

<!--    data() {-->
<!--        return {-->
<!--            error: {} as any,-->
<!--            products: [],-->
<!--            amount: 0,-->
<!--            opened: false,-->
<!--            product: null,-->
<!--            attributed: false,-->
<!--            selectedProduct: {} as any,-->
<!--            title: 'Attribuer un produit'-->
<!--        };-->
<!--    },-->

<!--    computed: {-->

<!--        attributable() {-->
<!--            return this.membership.unit_balance > 0;-->
<!--        }-->
<!--    },-->

<!--    methods: {-->
<!--        open() {-->
<!--            this.opened = true;-->
<!--        },-->

<!--        close() {-->
<!--            this.opened = false;-->
<!--        },-->
<!--        searchProduct(query: any) {-->
<!--            loyaltyCardProductApi()-->
<!--                .get(this.membership.loyalty_card, query)-->
<!--                .then(products => {-->
<!--                    this.products = products.map((item: any) => {-->
<!--                        return {-->
<!--                            key: item.product.id,-->
<!--                            value: item.product.name-->
<!--                        };-->
<!--                    });-->
<!--                });-->
<!--        },-->

<!--        selectProduct(product: any) {-->
<!--            this.selectedProduct = product;-->
<!--        },-->

<!--        submit() {-->
<!--            this.error = {};-->
<!--            this.attributed = false;-->

<!--            loyaltyCardMembershipRewardApi()-->
<!--                .create(-->
<!--                    (this.$route.params.customerId as any),-->
<!--                    (this.$route.params.membershipId as any),-->
<!--                    {-->
<!--                        type: 'product',-->
<!--                        amount: this.amount,-->
<!--                        product_id: this.selectedProduct.key-->
<!--                    }-->
<!--                )-->
<!--                .then(() => {-->
<!--                    this.amount = 0;-->
<!--                    this.product = null;-->
<!--                    this.selectedProduct = {};-->

<!--                    this.attributed = true;-->

<!--                    this.$emit('attributed');-->
<!--                })-->
<!--                .catch(error => {-->
<!--                    this.error = error.response.data;-->
<!--                });-->
<!--        }-->
<!--    }-->
<!--});-->
<!--</script>-->
