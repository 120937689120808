<script setup lang="ts">
import useKnowledgeables from "@/knowledge-box/composables/useKnowledgeables";
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import {computed} from "vue";
import KnowledgeableInputButton from "@/knowledge-box/components/KnowledgeableInputButton.vue";
import KnowledgeableChatCard from "@/knowledge-box/components/KnowledgeableChatCard.vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";

const {data, isLoading} = useKnowledgeables();

const globalKnowledgeable = computed(() => {
    if (!data.value) {
        return null;
    }

    return data.value?.find(knowledgeable => knowledgeable.knowledgeable_type === null);
});

/**
 * Get the knowledgeables attached to laboratories.
 */
const laboratories = computed(() => {
    if (!data.value) {
        return [];
    }

    return data.value.filter(knowledgeable => knowledgeable.knowledgeable_type === 'laboratory');
});
</script>

<template>
  <BaseContainer class="p-6 gap-6">
    <BaseFlexSpinner v-if="isLoading" />
    <template v-else-if="data">
      <div>
        <h1>
          L'IA vous aide au quotidien
        </h1>
        <p>
          Notre IA est entrainée pour répondre à vos questions de tous les jours. <br>
          <span v-if="globalKnowledgeable">
            Posez une question générale ou choisissez une spécialité. Allez-y, essayer 😉
          </span>
        </p>
      </div>
      <KnowledgeableInputButton
        v-if="globalKnowledgeable"
        :knowledgeable="globalKnowledgeable"
      />
      <div class="flex flex-col gap-2">
        <h2 class="text-black">
          Nos marques
        </h2>
        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-6">
          <KnowledgeableChatCard
            v-for="laboratory in laboratories"
            :key="laboratory.id"
            :knowledgeable="laboratory"
          />
        </div>
      </div>
    </template>
  </BaseContainer>
</template>

<style scoped>

</style>
