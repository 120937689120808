<script setup lang="ts">
import {computed} from "vue";

const props = defineProps<{
    pointBalance: number;
    formattedPointBalance?: string;
    rate?: number;
}>();

const rewardInEuro = computed(() => {
    if (!props.rate) {
        return null;
    }

    return (props.pointBalance * props.rate).toFixed(2);
});
</script>

<template>
  <p>
    {{ formattedPointBalance }} <span v-if="rewardInEuro">({{ rewardInEuro }} €)</span>
  </p>
</template>
