<script setup lang="ts">
import {pluralize} from "@/core/filters/Pluralize";
import ChevronRightIcon from "@/core/components/icons/ChevronRightIcon.vue";
import {useRouter} from "vue-router";
import BaseButton from "@/core/components/base/BaseButton.vue";

const router = useRouter();

const props = defineProps<{
    count: number | undefined
    consumerKey?: string
    uniqueConsumers?: boolean
}>();

/**
 * Navigate to index page.
 */
const navigateToPlanIndex = () => {
    router.push({
        name: 'patient-administration.renewal-plans.index',
        ...props.consumerKey ? {
            query: {
                consumer_key: props.consumerKey,
            }
        } : {},
    });
};

</script>

<template>
  <BaseButton v-if="count" class="text-primary text-xs min-h-9" rounded="lg" density="compact" @click="navigateToPlanIndex">
    <span v-if="uniqueConsumers">{{ count }} {{ pluralize('patient', count) }} avec un renouvellement à venir</span>
    <span v-else>{{ count }} {{ pluralize('renouvellement', count) }} à venir</span>
    <ChevronRightIcon class="inline-block h-4" />
  </BaseButton>
</template>
