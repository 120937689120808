<script setup lang="ts">
import BaseLogo from "@/core/components/logo/BaseLogo.vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import useLaboratory from "@/laboratory/composables/useLaboratory";
import {computed, nextTick, onMounted, onUnmounted, ref} from "vue";
import LaboratoryWelcome from "@/laboratory/components/LaboratoryWelcome.vue";
import useLaboratoryStatistics from "@/laboratory/composables/useLaboratoryStatistics";
import {animate} from "motion";
import {useLaboratoriesStore} from "@/laboratory/stores/laboratories.store";
import {storeToRefs} from "pinia";
import LaboratoryNavigationMenu from "@/laboratory/components/LaboratoryNavigationMenu.vue";
import useLaboratoryRoutines from "@/laboratory/composables/useLaboratoryRoutines";
import useLaboratoryOffers from "@/laboratory/composables/useLaboratoryOffers";
import {useSystemStore} from "@/stores/system.store";
import Icon from "@/core/components/icon/Icon.vue";
import {laboratoryApi, shopApi} from "@/container";
import {useRouter} from "vue-router";
import {useCartStore} from "@/laboratory/stores/cart.store";
import ColorHelper from "@/core/helpers/colorHelper";

const props = defineProps<{
    id: string;
}>();
const displayContent = ref(false);
const isGradientShown = ref(false);

const {laboratories} = storeToRefs(useLaboratoriesStore());
const {data} = useLaboratory(props.id);
const {environment} = useSystemStore();
const healthcenterId = environment?.entityId;
const {prefetch: prefetchStatistics} = useLaboratoryStatistics(props.id);
const {prefetch: prefetchRoutines} = useLaboratoryRoutines(props.id);
const {prefetch: prefetchOffers} = useLaboratoryOffers(props.id);
const {cart, franco} = storeToRefs(useCartStore());
const router = useRouter();

/**
 * On mount, fetch data.
 */
onMounted(() => {
    prefetchStatistics();
    prefetchRoutines();
    prefetchOffers();

    if (healthcenterId) {
        shopApi().laboratoryCart(Number(props.id), healthcenterId)
            .then(response => {
                cart.value = response;
            });
        laboratoryApi().franco(Number(props.id), healthcenterId)
            .then(response => {
                franco.value = response;
            });
    }
});

/**
 * Handle when welcome animation is finished.
 */
const handleWelcomeAnimationFinished = async () => {
    displayContent.value = true;
    await nextTick();

    animate(".content-container", {'--gradient-opacity': [0, 1]}, {duration: 0.4})
        .then(() => {
            isGradientShown.value = true;
        });

    animate([
        [".laboratory-header", {y: ["-100%", "0%"]}, {duration: 1, ease: "easeInOut"}],
        [".laboratory-content", {opacity: [0, 1], y: ["20px", "0px"]}, {duration: 0.4, at: "-0.2"}]
    ]);
};

/**
 * On unmounted.
 */
onUnmounted(() => {
    isGradientShown.value = false;
});

const laboratory = computed(() => data.value ?? laboratories.value[props.id] ?? null);

const cartQuantity = computed(() => cart.value?.lines.reduce((agg, line) => agg + line.quantity, 0));

/**
 * Display cart.
 */
const displayCart = () => {
    router.push({
        name: 'laboratory.cart',
        params: {laboratoryId: props.id}
    });
};

/**
 * Total cart value.
 */
const cartTotal = computed(() => {
    if (cart.value && franco.value) {
        return cart.value?.lines.reduce((acc, line) => {
            return acc + (franco.value?.laboratoryFrancoTypeId === 1 ? line.quantity : line.totalDiscountedWithoutVat);
        }, 0);
    }

    return null;
});

/**
 * Progress value in percentage.
 */
const progress = computed(() => {
    if (cartTotal.value && franco.value) {
        return (cartTotal.value / franco.value.francoValue > 1 ? 1 : cartTotal.value / franco.value.francoValue) * 100;
    }

    return 0;
});
</script>

<template>
  <BaseFlexSpinner v-if="!laboratory" />
  <div
    v-if="laboratory"
    class="h-full overflow-auto content-container"
    :style="{
      '--gradient-opacity': isGradientShown ? 1 : 0,
      backgroundImage: laboratory?.branding?.background
        ? `linear-gradient(to top, rgb(255, 255, 255, var(--gradient-opacity)) 0%, rgba(255, 255, 255, 0) 80%), url(${laboratory.branding.background.url})`
        : '',
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }"
  >
    <template v-if="displayContent">
      <div
        class="relative flex bg-white pr-6 items-center laboratory-header"
        style="transform: translateY(-100%);"
      >
        <div class="bg-white pt-4 px-2 space-y-6">
          <div class="space-y-2">
            <h1 class="text-black">
              Gestion de la relation patient {{ laboratory.name }}
            </h1>
            <div class="border-l-2 border-black pl-3">
              Bonjour, bienvenue dans l'espace de gestion de la relation votre pharmacie avec vos patients {{
                laboratory.name
              }}.
            </div>
          </div>
          <LaboratoryNavigationMenu
            :active-color="laboratory?.branding?.primary_color"
            :active-text-color="laboratory?.branding?.secondary_color"
          />
        </div>
        <div class="flex-1 flex-col basis-1/2">
          <div class="flex justify-end pr-8">
            <BaseLogo alt="logo" :logo="laboratory?.logo" img-class="object-contain" class="bg-white !h-24" />
          </div>
          <div v-if="franco" class="flex justify-end items-center gap-4 mt-4">
            <div
              class="relative flex flex-row items-center border-2 h-8 rounded-full w-72"
              :style="{'border-color': ColorHelper.opacity(laboratory?.branding?.primary_color, 0.1)}"
            >
              <div
                class="rounded-l-full h-full"
                :class="{'rounded-full': progress === 100}"
                :style="{'width': progress+'%', 'background-color': ColorHelper.opacity(laboratory?.branding?.primary_color, 0.1)}"
              />
              <span
                class="absolute inset-0 flex items-center justify-center text-sm font-semibold"
                :style="{'color': laboratory?.branding?.primary_color}"
              >
                Franco atteint à {{ progress }}% <span v-if="franco" class="pl-1">(Besoin {{ franco.francoValue }} €)</span>
              </span>
              <div class="bg-white" />
            </div>
            <v-badge
              v-if="cartQuantity && cartQuantity > 0"
              :content="cartQuantity"
              color="error"
              class="cursor-pointer hover:opacity-50"
              @click="displayCart"
            >
              <Icon name="mdi-cart" :size="28" :color="laboratory?.branding?.primary_color" />
            </v-badge>
            <Icon
              v-else
              name="mdi-cart"
              :size="28"
              class="cursor-pointer hover:opacity-50"
              :color="laboratory?.branding?.primary_color"
              @click="displayCart"
            />
          </div>
        </div>
      </div>
      <div class="laboratory-content" style="opacity: 0; transform: translateY(20px)">
        <router-view :laboratory-id="props.id" :laboratory="laboratory" />
      </div>
    </template>
    <div v-else class="w-full overflow-hidden">
      <LaboratoryWelcome class="mt-32" :laboratory="laboratory" @animation-finished="handleWelcomeAnimationFinished" />
    </div>
  </div>
</template>
