import {defineStore} from "pinia";
import {LaboratoryCart} from "@/laboratory/interfaces";
import {LaboratoryFranco} from "@/core/interfaces/laboratory/LaboratoryFranco";

/**
 * Cart store.
 */
export const useCartStore = defineStore('cart', {
    state: () => ({
        cart: undefined as LaboratoryCart | undefined,
        franco: undefined as LaboratoryFranco | undefined
    })
});
