<script setup lang="ts">
import categories from "@/core/factories/search-bar/CustomerQuickFilters";
import QuickFilters from "@/core/components/filters/QuickFilters.vue";
import {QuickFilter, SearchItem} from "@/core/interfaces/search-bar";
import SearchBarService from "@/core/services/SearchBarService";
import {ref, watch} from 'vue';
import {CUSTOMER_SEARCH_QUERY_PARAM_KEYS} from "@/core/interfaces/search-bar/ICustomerSearchBar";

const {selected} = defineProps<{ selected: SearchItem[] }>();
const emits = defineEmits(['onFilterChange']);

watch(() => selected, () => {
    const filters = selected.filter(isCustomerQuickFilter);
    if (selectedFilters.value !== filters) {
        selectedFilters.value = filters;
    }
});

/**
 * Check if the item is a customer quick filter.
 *
 * @param {SearchItem} item - The search item.
 *
 * @return {item is QuickFilter<any>}
 */
const isCustomerQuickFilter = (item: SearchItem): item is QuickFilter<any> => {
    return SearchBarService.isQuickFilter(item, categories);
};

const selectedFilters = ref<QuickFilter<any>[]>(selected.filter(isCustomerQuickFilter));

/**
 * Handle the quick filter press.
 *
 * @param {SearchItem} item - The pressed item.
 */
const handleItemPress = (item: QuickFilter<any>) => {
    const elementIndex = SearchBarService.getItemIndex(item, selectedFilters.value);
    const copy = [...selectedFilters.value];

    if (elementIndex === -1) {
        const lastVisitRangeItemIndex = copy.findIndex(item => item.queryParam === CUSTOMER_SEARCH_QUERY_PARAM_KEYS.LAST_VISIT_RANGE);

        if (lastVisitRangeItemIndex !== -1 && item.queryParam === CUSTOMER_SEARCH_QUERY_PARAM_KEYS.LAST_VISIT_RANGE) {
            copy.splice(lastVisitRangeItemIndex, 1);
        }

        copy.push(item);
    } else {
        copy.splice(elementIndex, 1);
    }

    selectedFilters.value = copy;
    emits('onFilterChange', copy);
};
</script>

<template>
  <div>
    <QuickFilters
      :categories="categories"
      :selected-filters="selectedFilters"
      @on-filter-press="handleItemPress"
    />
  </div>
</template>
