import useSwr from "@/core/composables/swr/useSwr";
import {alertApi} from "@/container";

/**
 * Composable to fetch alerts.
 */
const useAlerts = () => {
    const {data, isLoading} = useSwr(
        "alerts",
        () => alertApi().index()
    );

    return {
        data,
        isLoading
    };
};

export default useAlerts;
