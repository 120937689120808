<script setup lang="ts">
import {NotificationSequence} from "@/notification-sequences/interfaces/NotificationSequence";
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardHeader from "@/core/components/base/BaseCardHeader.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import NotificationSequenceCardMessages
    from "@/notification-sequences/components/notification-sequence-card/NotificationSequenceCardMessages.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {ref, watch} from "vue";
import {notificationSequenceApi} from "@/container";

const props = defineProps<{
    sequence: NotificationSequence
    color?: string
}>();

const emits = defineEmits<{
    navigate: [sequence: NotificationSequence]
}>();

const enabled = ref<boolean | undefined>(props.sequence.is_enabled ?? false);

watch(() => props.sequence, (newValue) => {
    enabled.value = newValue.is_enabled;
});

/**
 * Toggle the enabled state of the notification sequence
 *
 * @param value
 */
const toggleEnabled = async (value: boolean | null) => {
    if (value === null) {
        return;
    }

    enabled.value = value;
    try {
        await notificationSequenceApi()
            .toggleEnabled(props.sequence.id, value)
            .then((response) => {
                enabled.value = response.enabled;
            });
    } catch (error) {
        enabled.value = props.sequence.is_enabled;
    }
};
</script>

<template>
  <BaseCard>
    <BaseCardHeader>
      <div class="flex items-start flex-wrap justify-between gap-x-8 gap-y-3">
        <div>
          {{ sequence.name }}
          <p class="text-base font-content">
            {{ sequence.description }}
          </p>
        </div>
        <div>
          <v-switch
            hide-details
            label="Séquence activée"
            :model-value="enabled"
            :color="color"
            @update:model-value="toggleEnabled"
          />
        </div>
      </div>
    </BaseCardHeader>
    <BaseCardBody class="flex flex-col gap-4">
      <NotificationSequenceCardMessages :messages="sequence.messages" :color="color" class="max-w-4xl" />
      <div class="flex justify-end">
        <BaseButton :color="color" append-icon="mdi-send" @click="emits('navigate', sequence)">
          Voir la séquence
        </BaseButton>
      </div>
    </BaseCardBody>
  </BaseCard>
</template>

<style scoped>

</style>
