<script setup lang="ts">
import {Video} from "@/video/interfaces";
import Icon from "@/core/components/icon/Icon.vue";
import {Dialog, DialogContent, DialogTitle, DialogTrigger} from "@/core/components/ui/dialog";

defineProps<{
    video: Video;
}>();
</script>

<template>
  <Dialog>
    <DialogTrigger as-child>
      <div class="bg-white flex items-center gap-2 pr-2 py-1 rounded-lg">
        <Icon
          color="red"
          name="mdi-play"
          :size="35"
        />
        <p class="text-gray-600">
          {{ video.title }}
        </p>
      </div>
    </DialogTrigger>
    <DialogContent class="aspect-video max-w-4xl">
      <DialogTitle>
        {{ video.title }}
      </DialogTitle>
      <div class="flex flex-col gap-4">
        <iframe
          class="w-full h-[400px]"
          :src="video.url"
          frameborder="0"
          allowfullscreen
        />
      </div>
    </DialogContent>
  </Dialog>
</template>

<style scoped>

</style>
