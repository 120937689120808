import {LGO} from "@/onboarding/interfaces";

/**
 * Maps LGO numbers to their corresponding names
 */
export const LGO_NAMES: Record<LGO, string> = {
    1: 'Actipharm (MSI 2000)',
    2: 'Alliadis / SmartRX',
    3: 'Axiopharm',
    4: 'Caduciel',
    5: 'CIP Primoris',
    6: 'CIP-GS (Pharmagest)',
    7: 'Crystal (Infosoft)',
    8: 'Data conseil (Alliadis)',
    9: 'Esculape',
    10: 'LEO Isipharm',
    11: 'LGPI (Pharmagest)',
    12: 'Logipharm',
    13: 'Magic Vente',
    14: 'Opus (PG Informatique)',
    15: 'Pharmaland',
    16: 'Pharmavitale Cepi',
    17: 'Primoris (Pharmagest)',
    18: 'Périphar (Aspline)',
    19: 'Vindilis',
    20: 'Visiosoft (Visiopharm)',
    21: 'Winpharma (Everys)'
};
