import {AxiosInstance} from "axios";
import {Prescription, PrescriptionIndexMeta} from "@/prescription/interfaces/Prescription";

export interface IndexParams {
    /** Whether to add renewal plan count. */
    with_renewal_plan_count?: boolean,
    /** The maximum number of returned prescriptions. */
    limit?: number,
    /** The prescription tag slugs to scope the query. */
    tags?: string[],
    /** The prescription tag operator : AND for an exclusive query and OR for an inclusive one. */
    tags_operator?: 'AND' | 'OR',
    /** The after key used by elastic search for aggregations pagination */
    after?: string,
}

export default function (hdsClient: AxiosInstance) {
    return {
        /**
         * List of prescriptions
         *
         * @param {Object} params Params of the search, the available params are listed below.
         *     limit?: number;
         *     certified?: boolean;
         *     recently_renewed?: boolean;
         *     no_dosage_plan?: boolean;
         *     no_renewal_plan?: boolean;
         *     after?: any;
         *     from?: string;
         *     to?: string
         *
         * @returns {Promise}
         */
        async index(params: IndexParams = {}): Promise<{ data: Prescription[], meta: PrescriptionIndexMeta }> {
            return await hdsClient.get('prescriptions', {params})
                .then(response => response.data);
        },

        /**
         * Read a prescription.
         *
         * @param customerId Customer id.
         * @param prescriptionKey Prescripiton key.
         *
         * @return {Promise}
         */
        read(customerId: number, prescriptionKey: string) {
            return hdsClient.get(`customers/${customerId}/prescriptions/${prescriptionKey}`)
                .then(response => response.data.data);
        },

        /**
         * Overwrite a prescription.
         *
         * @param {Prescription} prescription - The prescription.
         * @param {boolean} renewable - The renewable status.
         *
         * @returns {Promise}
         */
        async toggleRenewable(prescription: Prescription, renewable: boolean): Promise<Prescription> {
            return await hdsClient.post(
                `customers/${prescription.customer.id}/prescriptions/${prescription.key}`,
                {data: {renewable}}
            )
                .then(response => response.data.data);
        },

        /**
         * List customer prescriptions.
         *
         * @param {number} customerId Customer id of the list of prescriptions we want to get.
         * @param {Object} [params] Params of the request are the following :
         *   limit?: number;
         *   after?: string;
         * @param {number} [beneficiaryId] Beneficiary id, default is 0 to only show customer prescriptions.
         *
         * @return {Promise}
         */
        customerIndex(customerId: number, params = {}, beneficiaryId = null) {
            params = {
                ...beneficiaryId ? {beneficiary_id: beneficiaryId} : {},
                ...params
            };

            return hdsClient.get(`customers/${customerId}/prescriptions`, {params})
                .then(response => response.data);
        }
    };
}
