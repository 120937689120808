<script setup lang="ts">
import PatientAdministrationHomeCard from "@/patient-administration/components/PatientAdministrationHomeCard.vue";
import {useRouter} from "vue-router";
import Icon from "@/core/components/icon/Icon.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import BaseVideo from "@/core/components/base/BaseVideo.vue";
import {ref} from "vue";
import {Dialog, DialogContent, DialogTrigger} from "@/core/components/ui/dialog";
import TheCalendar from "@/core/components/navigation-bar/TheCalendar.vue";

const router = useRouter();

const isModalVisible = ref<boolean>(false);
</script>

<template>
  <div class="flex flex-col justify-between">
    <div class="flex flex-col flex-1 p-6 space-y-6 pb-12">
      <h2>Tous les outils pour gérer la relation patient</h2>
      <div class="grid grid-cols-2 gap-6">
        <PatientAdministrationHomeCard
          title="Patientèle"
          subtitle="Analyser vos patients sous tous les angles"
          icon-name="mdi-account-search"
          @click="router.push({name: 'patient-administration.dashboard'})"
        />
        <PatientAdministrationHomeCard
          title="Renouvellement de l'ordonnance"
          subtitle="Simplifier la gestion des renouvellements"
          icon-name="mdi-autorenew"
          @click="router.push({name: 'patient-administration.renewal.index'})"
        />
        <PatientAdministrationHomeCard
          title="Communication"
          subtitle="Animez vos patients par SMS/notif"
          icon-name="mdi-send-circle"
          @click="router.push({name: 'patient-administration.communication'})"
        />
        <PatientAdministrationHomeCard
          title="Application"
          subtitle="Découvrez l'application pour vos patients"
          icon-name="mdi-cellphone"
          @click="router.push({name: 'patient-administration.certified-patients'})"
        />
        <PatientAdministrationHomeCard
          title="Click & collect"
          subtitle="Personnalisez votre vitrine digitale !"
          icon-name="mdi-shopping"
          @click="router.push({name: 'patient-administration.click-collect'})"
        />
        <TheCalendar>
          <PatientAdministrationHomeCard
            title="Évènements et RDV"
            subtitle="Gérez vos évènements dans le calendrier"
            icon-name="mdi-calendar"
          />
        </TheCalendar>
        <PatientAdministrationHomeCard
          title="Questionnaires"
          subtitle="Accédez aux résultats des questionnaires patients"
          icon-name="mdi-list-box-outline"
          @click="router.push({name: 'patient-administration.surveys'})"
        />
      </div>
    </div>
    <div class="relative bg-secondary-lightest py-4 px-8">
      <h2>Proposez plus à vos patients</h2>
      <div class="flex flex-col p-4 gap-2">
        <div class="flex flex-row gap-3">
          <icon name="mdi-clipboard-text" color="primary" />
          <p>Ordonnances</p>
        </div>
        <div class="flex flex-row gap-3">
          <icon name="mdi-shopping" color="primary" />
          <p>Click & collect personnalisable</p>
        </div>
        <div class="flex flex-row gap-3">
          <icon name="mdi-clipboard-text-clock" color="primary" />
          <p>Rappels renouvellement d'ordonnance</p>
        </div>
        <div class="flex flex-row gap-3">
          <icon name="mdi-pill" color="primary" />
          <p>Rappels pilullier</p>
        </div>
        <div class="flex flex-row gap-3">
          <icon name="mdi-needle" color="primary" />
          <p>Suivi vaccinal</p>
        </div>
        <div class="flex flex-row gap-3">
          <icon name="mdi-plus-box-multiple" color="primary" />
          <p>Et plus encore !</p>
        </div>
      </div>
      <div class="flex flex-1 justify-center">
        <Dialog v-model:open="isModalVisible">
          <DialogTrigger>
            <base-button primary>
              Découvrir l'app
            </base-button>
          </DialogTrigger>
          <DialogContent class="min-w-[90%] max-h-auto p-10">
            <BaseVideo reference="A3MCtVC67Lw" />
          </DialogContent>
        </Dialog>
      </div>
      <div class="absolute bottom-0 right-10">
        <img src="@/assets/images/iphone-mockup.png" class="h-96 rotate-6">
      </div>
    </div>
  </div>
</template>
