<script setup lang="ts">
import {computed} from 'vue';
import Extension from "@/core/interfaces/extension/Extension";
import ExtensionLogo from "@/extension/components/extension-logo/ExtensionLogo.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import StripePriceService from "@/stripe/services/stripePriceService";
import ExtensionNavigationService from "@/extension/services/ExtensionNavigationService";
const props = defineProps<{
    extension: Extension;
}>();

defineEmits(["buttonClick"]);

/**
 * Get the price to display to the user.
 */
const price = computed(() => {
    const _price = StripePriceService.featuredPrice(props.extension.prices);

    if(_price) {
        return StripePriceService.formatPrice(_price);
    }

    return null;
});
</script>

<template>
  <div class="flex bg-white p-6 gap-3">
    <ExtensionLogo :extension="extension" />
    <div class="self-end flex-1">
      <h1>{{ extension.name }}</h1>
      <p>{{ extension.short_description }}</p>
    </div>
    <div class="flex flex-col items-end justify-end gap-1">
      <p v-if="extension.subscription_plan && !extension.subscribed">
        Inclut dans le
        <router-link
          :to="{name: 'subscription-plan', params: {planId: extension.subscription_plan.id}}"
          class="text-primary"
        >
          Pack {{ extension.subscription_plan.name }}
        </router-link>
      </p>
      <BaseButton
        v-else-if="extension.subscribed"
        class="self-end"
        primary
        @click="$emit('buttonClick')"
      >
        {{ extension.enabled ? 'Désactiver' : 'Activer' }}
      </BaseButton>
      <BaseButton v-else-if="extension.stripe_id && price" primary @click="$emit('buttonClick')">
        Acheter pour {{ price }}
      </BaseButton>
      <BaseButton
        v-if="extension.enabled"
        class="mt-2"
        @click="ExtensionNavigationService.navigate(extension, $router)"
      >
        Ouvrir
      </BaseButton>
    </div>
  </div>
</template>

<style scoped>

</style>
