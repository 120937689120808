<script lang="ts" setup>
import useSwr from "@/core/composables/swr/useSwr";
import {computed, reactive} from "vue";
import {filterEmptyKeys} from "@/lib/utils";
import dateHelper from "@/core/helpers/dateHelper";
import {useRouter} from "vue-router";
import {loyaltyCardApi} from "@/container";

const router = useRouter();
const props = defineProps<{
    resourceId: number;
}>();

let searchParams = reactive<{ page: number; itemsPerPage: number }>({
    page: 1,
    itemsPerPage: 20
});

/**
 * The computed SWR key.
 */
const swrKey = computed(() => {
    const query = new URLSearchParams(filterEmptyKeys(searchParams));

    return `loyalty-card/${props.resourceId}/memberships?${query.toString()}`;
});

const {data: consumers, isLoading, isValidating} = useSwr(
    swrKey,
    () => loyaltyCardApi().getMemberships(props.resourceId, searchParams.page, searchParams.itemsPerPage)
);

const dataTableConsumers = computed(() => {
    if (!consumers.value) {
        return [];
    }

    return consumers.value.data.map((consumer) => ({
        customerId: consumer.customer?.id,
        membershipId: consumer.customer?.membership_id,
        name: consumer.customer?.name,
        lastPurchase: consumer.statistics.last_purchase ? dateHelper.shortFormat(consumer.statistics.last_purchase) : '-',
        averageBasket: consumer.total.quantity
            ? String((consumer.total.revenue / consumer.total.quantity).toFixed(2)) + '€'
            : '-',
        balance: `${consumer.total.point_balance ||consumer.total.unit_balance || 0}`,
        purchases: `${consumer.total.quantity} (${consumer.total.revenue.toFixed(2)}€)`,
        points: `${consumer.total.value}/${consumer.total.value + consumer.total.point_balance}`,
        rewards: `${consumer.statistics.reward.discounts.toFixed(2)}€`
    }));
});

/**
 * Handle item per page change.
 */
const handleItemPerPageChange = (itemsPerPage: number) => {
    searchParams = {
        ...searchParams,
        page: 1,
        itemsPerPage
    };
};

/**
 * Handle page change.
 *
 * @param page
 */
const handlePageChange = (page: number) => {
    searchParams.page = page;
};

/**
 * Handle row click.
 */
const handleRowClick = (_: unknown, row: { item: { customerId: number, membershipId: number } }) => {
    router.push({
        name: 'customer.loyalty.membership',
        params: {
            customerId: row.item.customerId,
            membershipId: row.item.membershipId
        }
    });
};

const headers = [
    {
        title: 'Nom',
        key: 'name'
    },
    {
        title: 'Dernière visite',
        key: 'lastPurchase'
    },
    {
        title: 'Panier moyen',
        key: 'averageBasket'
    },
    {
        title: 'Achats',
        key: 'purchases'
    },
    {
        title: 'Débités / Crédités',
        key: 'points'
    },
    {
        title: 'Solde',
        key: 'balance'
    },
    {
        title: 'Récompenses',
        key: 'rewards'
    }
];
</script>

<template>
  <v-data-table-server
    :headers="headers"
    :items="dataTableConsumers"
    :items-per-page="Number(searchParams.itemsPerPage)"
    :loading="isLoading || isValidating"
    :disable-sort="true"
    hover
    item-value="customerId"
    :items-length="consumers?.meta.total ?? 0"
    :loading-text="'Chargement en cours...'"
    no-data-text="Aucun patients n'a activé cette carte de fidélité"
    :footer-props="{
      'items-per-page-text': 'Éléments par page',
      'page-text': '{0}-{1} sur {2}',
      'total-items': 'Total des éléments'
    }"
    @update:page="handlePageChange"
    @update:items-per-page="handleItemPerPageChange"
    @click:row="handleRowClick"
  />
</template>
