<script setup lang="ts">
import useHealthcenterAdvantages from "@/advantage/composables/useHealthcenterAdvantages";
import MarkdownView from "@/core/components/markdown/Markdown.vue";
import {Skeleton} from "@/core/components/ui/skeleton";
import BaseButton from "@/core/components/base/BaseButton.vue";
import AdvantageDialog from "@/advantage/components/AdvantageDialog.vue";

const {data, isLoading} = useHealthcenterAdvantages();
</script>

<template>
  <div class="flex flex-col gap-2">
    <div v-if="isLoading" class="space-y-4">
      <Skeleton class="w-48 h-4" />
      <Skeleton class="w-80 h-4" />
    </div>
    <div v-else>
      <div v-if="data?.length">
        <div v-for="advantage in data" :key="advantage.id">
          <MarkdownView :content="advantage.sales_pitch" />
        </div>
      </div>
      <div v-else class="flex items-center justify-between gap-4">
        <p>Vous n'avez pas de code de réduction.</p>
        <AdvantageDialog />
      </div>
    </div>
    <div class="flex flex-col md:flex-row text-primary text-sm items-center justify-between">
      <p>Invitez vos amis pharmacien.nes sur Apodis et gagnez 1 mois offert (chacun !)</p>
      <BaseButton variant="text" density="comfortable" :to="{name: 'sponsorship'}">
        Parrainer une pharmacie
      </BaseButton>
    </div>
  </div>
</template>

<style scoped>

</style>
