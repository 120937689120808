import { computed, Ref, ref, watch } from 'vue';
import { useElementSize } from '@vueuse/core';

/**
 * Timing configuration for scrolling animations (in milliseconds)
 */
export const TIMING = {
    PRE_SCROLL_DELAY: 1000,
    POST_SCROLL_DELAY: 2000,
    PIXELS_PER_SECOND: 40,
    SINGLE_ALERT_LOOP_DELAY: 10000,
    DEFAULT_ROTATION_DELAY: 10000
};

/**
 * Alert states for state machine approach
 */
export enum AlertState {
    IDLE = 'idle',
    READY = 'ready',
    SCROLLING = 'scrolling',
    COMPLETED = 'completed'
}

/**
 * Composable for managing text scrolling behavior for overflowing text
 *
 * @param textWrapper - Reference to the wrapper element containing the text
 * @param textContent - Reference to the actual text content element
 */
export function useScrolling(textWrapper: Ref<HTMLElement | null>, textContent: Ref<HTMLElement | null>) {
    const { width: wrapperWidth } = useElementSize(textWrapper);
    const { width: contentWidth } = useElementSize(textContent);

    const scrollState = ref<AlertState>(AlertState.IDLE);
    const scrollDuration = ref(8000);

    /**
     * Determines if text content is overflowing its container
     */
    const isOverflowing = computed(() => {
        return (contentWidth.value > 0 && wrapperWidth.value > 0) &&
            (contentWidth.value > wrapperWidth.value);
    });

    /**
     * Calculates the exact translation amount needed for scrolling
     */
    const scrollAmount = computed(() => {
        if (contentWidth.value && wrapperWidth.value && isOverflowing.value) {
            return `${-(contentWidth.value - wrapperWidth.value + 20)}px`;
        }
        return '0px';
    });

    /**
     * Calculates the appropriate scroll duration based on content width
     * @returns Duration in milliseconds
     */
    const calculateScrollDuration = () => {
        if (contentWidth.value && wrapperWidth.value && isOverflowing.value) {
            const overflowAmount = contentWidth.value - wrapperWidth.value;
            scrollDuration.value = Math.max(5000, (overflowAmount / TIMING.PIXELS_PER_SECOND) * 1000);
            return scrollDuration.value;
        }

        return 8000;
    };

    /**
     * Resets the scroll state to idle
     */
    const resetScrollState = () => {
        scrollState.value = AlertState.IDLE;
    };

    /**
     * Computed properties for different scroll states
     */
    const isScrolling = computed(() => scrollState.value === AlertState.SCROLLING);
    const isReadyToScroll = computed(() => scrollState.value === AlertState.READY);
    const isScrollComplete = computed(() => scrollState.value === AlertState.COMPLETED);

    /**
     * Recalculates the scroll duration when the content or wrapper width changes
     */
    watch([contentWidth, wrapperWidth], () => {
        if (isOverflowing.value) {
            calculateScrollDuration();
        }
    });

    return {
        isOverflowing,
        scrollState,
        isScrolling,
        isReadyToScroll,
        isScrollComplete,
        scrollDuration,
        scrollAmount,
        calculateScrollDuration,
        resetScrollState
    };
}
