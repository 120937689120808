import {AxiosInstance} from "axios";
import {
    NotificationSequence,
    NotificationSequenceDetails
} from "@/notification-sequences/interfaces/NotificationSequence";

export interface NotificationSequenceIndexQueryParams {
    organization_id?: number;
}

const NotificationSequenceResource = (apiClient: AxiosInstance) => {
    return {
        /**
         * Get all notification sequences.
         *
         * @param query
         */
        async index(query: NotificationSequenceIndexQueryParams = {}): Promise<NotificationSequence[]> {
            return apiClient.get('notification-sequences', {params: query})
                .then(response => response.data.data);
        },

        /**
         * Toggle enabled status for a notification sequence.
         *
         * @param sequenceId
         * @param enabled
         */
        async toggleEnabled(sequenceId: number, enabled: boolean): Promise<{ enabled: boolean }> {
            return apiClient.patch(`notification-sequences/${sequenceId}/toggle-enabled`, {enabled})
                .then(response => response.data.data);
        },

        /**
         * Read a notification sequence.
         * @param sequenceId
         */
        async read(sequenceId: number | string): Promise<NotificationSequenceDetails> {
            return apiClient.get(`notification-sequences/${sequenceId}`)
                .then(response => response.data.data);
        }
    };
};

export default NotificationSequenceResource;
