<script setup lang="ts">
import {Backing, BackingInterviewStep, BackingInvoiceStep, BackingStep} from "@/backing/interfaces/backing";
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import {computed, ref} from "vue";
import BackingInterview from "@/backing/components/BackingInterview.vue";
import BackingInvoiced from "@/backing/components/BackingInvoiced.vue";
import {useRouter} from "vue-router";
import Customer from "@/customer/interfaces/Customer";
import BackingInterviewInstance from "@/backing/components/BackingInterviewInstance.vue";
import MarkdownView from "@/core/components/markdown/Markdown.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {backingApi} from "@/container";
import Icon from "@/core/components/icon/Icon.vue";
import {ProgramAttachment} from "@/program/interfaces/Program";
import BackingSummary from "@/backing/components/BackingSummary.vue";
import DMPButton from "@/core/components/buttons/DMPButton.vue";
import MailizButton from "@/core/components/buttons/MailizButton.vue";

const props = defineProps<{
    backing: Backing,
    customer: Customer;
    attachments?: ProgramAttachment[];
    programId: number;
}>();

const router = useRouter();
const summary = ref<string>();
const loading = ref<boolean>(false);
const displaySummary = ref<boolean>(false);

/**
 * Compute the billing placeholder replacement.
 */
const billing = computed(() =>
    [props.backing.first_year_billing_act?.code, props.backing.next_years_billing_act?.code]
        .filter(n => n)
        .join(' ou ')
);

/**
 * Compute the billing placeholder replacement.
 */
const billingExtended = computed(() => {
    if (props.backing.first_year_billing_act?.code) {
        if (props.backing.next_years_billing_act?.code) {
            return props.backing.first_year_billing_act.code
                + ' la première année pour un montant de '
                + (props.backing.first_year_billing_act?.price || '[non-renseigné]') + ' €'
                + ' ou ' + props.backing.next_years_billing_act.code
                + ' les suivantes pour un montant de '
                + (props.backing.next_years_billing_act?.price || '[non-renseigné]') + ' €';
        }

        return props.backing.first_year_billing_act.code
            + ' pour un montant de '
            + (props.backing.first_year_billing_act?.price || '[non-renseigné]') + '€';
    }

    return '';
});

/**
 * Check if the backing has completed steps
 */
const completedSteps = computed(() => props.backing.steps.filter((step: BackingStep) => step.completed_at));

/**
 * Get the interview steps
 */
const interviewSteps = computed(() => props.backing.steps.filter((step: BackingStep): step is BackingInterviewStep => {
    return step.resource_type === 'interview';
}));

/**
 * Flatten the interview instances.
 */
const interviewInstances = computed(() => {
    return interviewSteps.value.flatMap((step: BackingInterviewStep) => {
        return step.interview_instances?.map(instance => {
            return {
                instance,
                step: {
                    ...step,
                    interview_instances: undefined
                }
            };
        }) || [];
    });
});

/**
 * Get the completed invoice steps
 */
const completedInvoiceSteps = computed(() => completedSteps.value.filter((step: BackingStep): step is BackingInvoiceStep => {
    return step.resource_type === 'invoice';
}));


const interviewIds = computed(() => new Set(interviewInstances.value.map(instance => instance.step.resource_id)));

/**
 * Start the interview
 */
const startInterview = (step: BackingStep) => {
    router.push({
        name: 'interview-instances',
        query: {
            backingId: props.backing.id,
            interviewId: step.resource_id,
            consumerKey: props.customer.consumer_key,
            interviewName: step.name
        }
    });
};

/**
 * Generate a backing summary.
 */
const generateBackingSummary = () => {
    if (!summary.value) {
        loading.value = true;
        return backingApi().summary(props.backing.id, props.customer.consumer_key)
            .then(response => {
                summary.value = response.summary;
                displaySummary.value = true;
            })
            .finally(() => loading.value = false);
    } else {
        displaySummary.value = true;
    }
};
</script>

<template>
  <div>
    <BackingSummary
      :summary="summary"
      :attachments="attachments"
      :backing-id="backing.id"
      :consumer-key="customer.consumer_key"
      :interview-ids="[...interviewIds]"
      :visible="displaySummary"
      @on-close="displaySummary = false"
    />
    <BaseCard>
      <BaseCardBody>
        <div class="flex justify-space-between items-center mb-4">
          <h2 class="flex-1">
            {{ backing.name }}
          </h2>
          <v-tooltip>
          <template #activator="{props}">
            <span v-bind="props" class="text-primary underline">Comment ça marche ? 🧐</span>
          </template>
          <markdown-view class="text-white" :content="backing.description?.replace('{billing_code}', billing)" />
        </v-tooltip>
        </div>
        <div class="p-4 rounded-lg border-primary" style="border-width: 1px">
          <div
            v-for="step in interviewSteps"
            :key="'available' + step.resource_id"
            class="pb-2"
            @click="() => startInterview(step)"
          >
            <BackingInterview :step="step" />
          </div>
        </div>
        <div
          v-if="interviewInstances?.length"
          class="p-4 mt-2"
        >
          <h3 class="mb-4">
            Entretiens réalisés
          </h3>
          <div class="flex flex-col gap-4">
            <div v-for="instance in interviewInstances" :key="instance.instance.id">
              <BackingInterviewInstance
                :data="instance"
                :backing-id="backing.id"
                :consumer-name="customer.information.name"
                :consumer-key="customer.consumer_key"
              />
            </div>
          </div>
          <div class="flex flex-row flex-wrap gap-4 mt-3">
            <div v-for="(step, index) in completedInvoiceSteps" :key="'invoiced' + index">
              <BackingInvoiced :step="step" />
            </div>
          </div>
        </div>
        <div
          v-if="backing.billable"
          class="bg-primary rounded p-4 mt-2"
        >
          <p class="mt-2">
            N'oubliez pas de facturer le code acte <strong>{{ billingExtended }}</strong>.
          </p>
        </div>
      </BaseCardBody>
    </BaseCard>
    <div
      v-if="interviewInstances?.length"
      class="bg-secondary-lightest rounded p-4 mt-2"
    >
      <h3 class="mb-1">
        Conclusion
      </h3>
      <p>Générer le document de conclusion à partir des entretiens réalisés</p>
      <div class="relative flex flex-row justify-end gap-6 pt-10">
        <DMPButton />
        <MailizButton />
        <div class="pb-6">
          <base-button primary :loading="loading" @click="generateBackingSummary">
            <icon name="mdi-creation" color="white" :size="24" class="pr-4" />
            Générer la conclusion
          </base-button>
          <p v-if="loading" class="absolute bottom-0">
            Notre ia génère votre résumé cela peut prendre du temps (~20sec)
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
