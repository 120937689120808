<script setup lang="ts">
import BaseButton from "@/core/components/base/BaseButton.vue";
import {ref, watch} from "vue";
import dayjs from "dayjs";
import {useVModel} from '@vueuse/core';

const props = withDefaults(defineProps<{
    modelValue: string | null;
    minDate?: string;
    displayTime?: boolean;
}>(), {minDate: undefined, displayTime: true});

const emit = defineEmits(['update:modelValue', 'onConfirm']);

const dateTimeValue = useVModel(props, 'modelValue', emit);

const date = ref<Date | null>(props.modelValue ? dayjs(props.modelValue).toDate() : null);
const time = ref<string | null>(props.modelValue ? dayjs(props.modelValue).format('HH:mm') : null);
const now = ref<Date>(props.minDate ? dayjs(props.minDate).toDate() : dayjs().toDate());

/**
 * Watch for external changes to the date time value.
 */
watch(() => dateTimeValue.value, (newValue) => {
    if (newValue) {
        const newDate = dayjs(newValue);
        if (newDate.isValid()) {
            date.value = newDate.toDate();
            time.value = newDate.format('HH:mm');
        }
    } else {
        date.value = null;
        time.value = null;
    }
});

watch(() => date.value, (newDate) => {
    if (newDate && time.value) {
        const [hours, minutes] = time.value.split(':').map(Number);
        dateTimeValue.value = dayjs(newDate)
            .hour(hours)
            .minute(minutes)
            .toISOString();
    }
});

/**
 * On date selection confirmed, rebuild the date time value, and emit the event.
 */
const onDateSelectionConfirm = () => {
    if (props.displayTime && date.value && time.value) {
        try {
            const [hours, minutes] = time.value.split(':').map(Number);
            const selectedDateTime = dayjs(date.value)
                .hour(hours)
                .minute(minutes)
                .toISOString();

            dateTimeValue.value = selectedDateTime;
            emit('onConfirm', selectedDateTime);
        } catch (error) {
            console.error('Error processing date:', error);
        }
    } else if (!props.displayTime && date.value) {
        try {
            const selectedDate = dayjs(date.value).toISOString();

            dateTimeValue.value = selectedDate;
            emit('onConfirm', selectedDate);
        } catch (error) {
            console.error('Error processing date:', error);
        }
    }
};
</script>

<template>
  <div class="w-auto p-0">
    <v-date-picker
      v-model="date"
      color="primary"
      :min="now"
      class="w-full"
      elevation="0"
    />
    <div class="flex flex-col justify-between px-6 pb-6">
      <v-text-field
        v-if="displayTime"
        v-model="time"
        label="Saisir l'heure"
        type="time"
        class="w-full"
      />
      <BaseButton
        primary
        :disabled="!date || !time"
        @click="onDateSelectionConfirm"
      >
        Valider
      </BaseButton>
    </div>
  </div>
</template>

<style scoped>
:deep(.v-date-picker) {
    width: 100%;
    max-width: 100%;
}

.flex-col {
    gap: 1rem;
}

:deep(.v-text-field) {
    width: 100%;
}

:deep(.v-input__details) {
    display: none;
}

:deep(.v-picker-title) {
    padding-bottom: 0;
}

:deep(.v-date-picker-header) {
    display: none;
}

:deep(.v-picker-title) {
    display: none;
}
</style>
