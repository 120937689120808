<script setup lang="ts">
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import {ref} from "vue";
import dayjs from "dayjs";
import DateTimePickerInput from "@/core/components/date-picker/DateTimePickerInput.vue";
import {useVModel} from '@vueuse/core';

const props = defineProps<{
    selectedDate: string | null;
    title?: string;
    subtitle?: string;
}>();

const emit = defineEmits(['onDateChange', 'update:selectedDate']);

const selectedDateValue = useVModel(props, 'selectedDate', emit, {
    eventName: 'update:selectedDate'
});
const now = ref(dayjs().format('YYYY-MM-DD'));

/**
 * Handle date confirmation.
 */
const handleDateConfirm = (newDate: string) => {
    try {
        emit('onDateChange', dayjs(newDate).format('YYYY-MM-DD HH:mm'));
    } catch (error) {
        console.error('Error handling date confirmation:', error);
    }
};
</script>

<template>
  <BaseCard>
    <BaseCardBody>
      <div class="flex flex-row items-center justify-between">
        <div class="flex flex-col pr-3">
          <h2 class="text-black">
            {{ title ?? 'Planifiez un rendez-vous' }}
          </h2>
          <p class="text-sm">
            {{ subtitle ?? 'Le patient recevra un rappel 2 heures avant' }}
          </p>
        </div>
        <DateTimePickerInput
          v-model="selectedDateValue"
          :min-date="now"
          :show-selected-date="!!selectedDate"
          @on-confirm="handleDateConfirm"
        />
      </div>
    </BaseCardBody>
  </BaseCard>
</template>
