<script setup lang="ts">
import {Laboratory} from "@/core/interfaces/laboratory/Laboratory";
import useNotificationSequenceIndex from "@/notification-sequences/composables/useNotificationSequenceIndex";
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardHeader from "@/core/components/base/BaseCardHeader.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import {NotificationSequenceCard} from "@/notification-sequences/components/notification-sequence-card";
import {useRouter} from "vue-router";

const router = useRouter();

const props = defineProps<{
    laboratory: Laboratory
}>();

const {data} = useNotificationSequenceIndex({
    organization_id: props.laboratory.organization.id
});
</script>

<template>
  <BaseCard v-if="data?.length">
    <BaseCardHeader>
      <h1 class="text-black">
        Animez vos patients avec des SMS
      </h1>
      <p class="font-content text-base">
        {{ laboratory.name }} vous aide à prendre soin de vos patients en fonction de <span class="underline">leur profil</span>
      </p>
    </BaseCardHeader>
    <BaseCardBody>
      <div class="flex flex-col gap-4">
        <div v-for="sequence in data" :key="sequence.id">
          <NotificationSequenceCard
            :sequence="sequence"
            :color="laboratory.branding?.primary_color"
            @navigate="router.push({
              name: 'laboratory.sequence',
              params: {
                sequenceId: sequence.id,
                laboratoryId: laboratory.id
              }
            })"
          />
        </div>
      </div>
    </BaseCardBody>
  </BaseCard>
</template>
