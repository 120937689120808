<template>
  <div>
    <div
      v-for="section in survey.sections"
      :key="`section-${section.id}`"
    >
      <h2
        v-if="section.questions.includes(question => question.type.slug === QuestionType.CHECK_INPUT)"
        class="text-lg mb-4"
      >
        {{ section.title }}
      </h2>
      <base-container class="gap-y-1">
        <div
          v-for="question in section.questions"
          :id="`question-${question.id}`"
          :key="question.id"
          :class="{'flex flex-row' :question.type.slug === QuestionType.CHECK_INPUT}"
          style="scroll-margin: 90px"
        >
          <div v-if="question.type.slug === QuestionType.CHECK_INPUT">
            <input
              type="checkbox"
              class="w-6 h-6 mr-3 text-blue-600 bg-gray-100 border-gray-300 rounded mt-1 focus:ring-0 focus:ring-primary cursor-pointer"
              :name="question.id"
              :checked="!!getAnswerValue(question.id)?.length"
              @input="(event) => onCheckUpdate(question, (event.target as HTMLInputElement)?.checked)"
            >
          </div>
          <div :class="{'flex flex-col w-full' :question.type.slug === QuestionType.CHECK_INPUT}">
            <div class="flex flex-col">
              <p>
                {{ question.title }}
                <v-tooltip
                  v-if="question.description?.length && question.type.slug === QuestionType.CHECK_INPUT"
                  location="bottom"
                  content-class="bg-grey-darken-2"
                >
                  <template #activator="{ props }">
                    <icon v-bind="props" class="h-6 w-6 cursor-pointer" color="primary" name="mdi-help-circle-outline" />
                  </template>
                  <markdown-view :content="question.description" class="text-white" />
                </v-tooltip>
              </p>
              <p
                v-if="!!errors && errors[question.id]"
                class="text-sm text-red-500"
              >
                {{ errors[question.id] }}
              </p>
            </div>
            <div v-if="question.description?.length">
              <label
                v-if="question.description?.length && question.type.slug !== QuestionType.CHECK_INPUT"
                class="text-sm mt-1"
              >
                {{ question.description }}
              </label>
            </div>
            <survey-built-answer
              :question="question"
              :survey-id="survey.id"
              :editable="true"
              :density="density"
              :value="getAnswerValue(question.id)"
              class="mt-1"
              @change="(change) => onChange(change, question.id)"
            />
            <p
              v-if="getReasonForQuestion(question.id)"
              class="mt-1 italic text-sm flex items-center"
            >
              <icon
                name="info-circle"
                class="mr-2"
              />
              {{ getReasonForQuestion(question.id) }}
            </p>
            <v-divider class="mt-4" />
          </div>
        </div>
      </base-container>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import SurveyBuiltAnswer from '@/survey/components/SurveyBuiltAnswer.vue';
import BaseContainer from '@/core/components/base/BaseContainer.vue';
import Icon from '@/core/components/icon/Icon.vue';
import {mapState} from "pinia";
import {useInterviewStore} from "@/stores/interview-store";
import {QuestionType, SurveyQuestion} from "@/core/interfaces/survey/Survey";
import MarkdownView from "@/core/components/markdown/Markdown.vue";

export default defineComponent({
    name: 'InterviewSurveyBuilt',
    components: {MarkdownView, Icon, BaseContainer, SurveyBuiltAnswer},
    props: {
        survey: {
            type: Object,
            required: true
        },
        answers: {
            type: Object,
            default: null
        },
        errors: {
            type: Object,
            default: null
        },
        density: {
            type: String,
            default: 'normal'
        }
    },
    emits: ['change'],
    data() {
        return {
            QuestionType,
        };
    },
    computed: {
        ...mapState(useInterviewStore, {
            llmAnswerReasons: state => state.prefilledSurveyAnswers?.reduce((acc, answer) => {
                acc[answer.question_id] = answer.reason ?? null;

                return acc;
            }, {})
        })
    },
    methods: {
        /**
         * Update the answer value.
         *
         * @param value
         * @param questionId
         */
        onChange(value, questionId) {
            this.$emit('change', {value, questionId});
        },

        onCheckUpdate(question: SurveyQuestion, checked: boolean) {
            if (checked && question.answers?.length) {
                this.onChange(question.answers[0].label, question.id);
            } else {
                this.onChange("", question.id);
            }
        },
        /**
         * Get the answer value for a question.
         *
         * @param {Number} questionId The question id.
         *
         * @return {Array}
         */
        getAnswerValue(questionId) {
            return this.answers[questionId] || [];
        },
        /**
         * Get the LLM answer reason for a question.
         *
         * @param {Number} questionId The question id.
         *
         * @returns {string|null}
         */
        getReasonForQuestion(questionId) {
            if (!this.llmAnswerReasons) {
                return null;
            }

            return this.llmAnswerReasons[questionId] ?? null;
        }
    }
});
</script>
