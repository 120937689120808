const ContentfulUtil = () => {
    const fullImagePath = (path: string) => {
        return `https:${path}`;
    };

    return {
        fullImagePath
    };
};

export default ContentfulUtil();
