<script setup lang="ts">

import {Dialog, DialogContent, DialogTrigger} from "@/core/components/ui/dialog";
import BaseButton from "@/core/components/base/BaseButton.vue";
import useAdvantages from "@/advantage/composables/useAdvantages";
import {Skeleton} from "@/core/components/ui/skeleton";

const {data, isLoading} = useAdvantages();
</script>

<template>
  <Dialog>
    <DialogTrigger as-child>
      <BaseButton variant="text" density="comfortable" class="text-primary" prepend-icon="mdi-tag">
        Bénéficier d'une offre partenaire
      </BaseButton>
    </DialogTrigger>
    <DialogContent class="max-w-3xl">
      <div class="space-y-4">
        <div>
          <h2>Nos partenaires vous offrent des avantages sur Apodis</h2>
          <p>Demandez votre code de réduction à votre partenaire !</p>
        </div>
        <div class="flex gap-4 flex-wrap items-center">
          <template v-if="isLoading">
            <Skeleton v-for="i in 4" :key="i" class="h-32 w-32" />
          </template>
          <template v-else>
            <div v-for="advantage in data" :key="advantage.id">
              <img v-if="advantage.logo" class="h-32 w-32 object-contain" :src="advantage.logo" :alt="advantage.title">
            </div>
          </template>
        </div>
      </div>
    </DialogContent>
  </Dialog>
</template>

<style scoped>

</style>
