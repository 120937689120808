<template>
  <div ref="menuContainer">
    <button
      id="menu-1"
      type="button"
      class="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary ml-1"
      aria-expanded="false"
      aria-haspopup="true"
      @click="isOpen = !isOpen"
    >
      <span class="sr-only">
        Ouvrir le menu utilisateur
      </span>

      <Icon name="mdi-account-circle" :size="25" class="text-primary" />
    </button>

    <div
      v-show="isOpen"
      class="origin-top-right absolute z-30 right-0 mt-2 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-1"
    >
      <div class="flex gradient justify-center">
        <img src="@/assets/images/avatar-my-profile.png" alt="Pharmacie">
      </div>
      <div
        v-if="environment"
        class="py-1"
      >
        <p class="block px-4 pt-2 text-sm text-primary font-semibold">
          {{ environment.entity.name }}
        </p>
        <p v-if="environment?.user?.email" class="block px-4 pb-2 text-xs text-primary">
          {{ environment.user.email }}
        </p>
      </div>
      <div>
        <router-link
          class="button"
          :to="{ name: 'healthcenter' }"
        >
          <a
            href="#"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:bg-gray-100"
            @click="route('healthcenter')"
          >
            Ma pharmacie
          </a>
        </router-link>
      </div>
      <div>
        <router-link
          class="button"
          :to="{ name: 'billing' }"
        >
          <a
            href="#"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:bg-gray-100"
            @click="route('billing')"
          >
            Mes factures
          </a>
        </router-link>
      </div>
      <div>
        <router-link
          class="button"
          :to="{ name: 'notification.preference' }"
        >
          <a
            href="#"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:bg-gray-100"
            @click="route('notification.preference')"
          >
            Paramètres de notification
          </a>
        </router-link>
      </div>
      <div>
        <router-link
          class="button"
          :to="{ name: 'support' }"
        >
          <a
            href="#"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:bg-gray-100"
            @click="route('support')"
          >
            Support
          </a>
        </router-link>
      </div>
      <div>
        <router-link
          class="button"
          :to="{ name: 'sponsorship' }"
        >
          <a
            href="#"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:bg-gray-100"
            @click="route('sponsorship')"
          >
            Parrainage
          </a>
        </router-link>
      </div>
      <div>
        <router-link
          class="button"
          :to="{ name: 'cgu' }"
        >
          <a
            href="#"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:bg-gray-100"
            @click="route('cgu')"
          >
            CGU
          </a>
        </router-link>
      </div>
      <div v-if="environment?.user.is_superadmin">
        <router-link
          class="button block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:bg-gray-100"
          :to="{ name: 'login.as' }"
          @click="route('login.as')"
        >
          Se connecter en tant que ...
        </router-link>
      </div>
      <div>
        <a
          href="#"
          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:bg-gray-100"
          @click="logout()"
        >
          Déconnexion
        </a>
      </div>
      <div class="px-4 py-2">
        <label
          class="block text-xs text-gray-700"
        >
          {{ `Version ${appVersion} - ${env}` }}
        </label>
        <label
          class="block pt-2 text-xs text-gray-700"
        >
          Fait avec ❤️ à Tours et Paris
        </label>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {authenticationService} from '@/container';
import {useSystemStore} from '@/stores/system.store';
import {ref, computed, watch} from 'vue';
import {useRouter} from 'vue-router';
import {storeToRefs} from 'pinia';
import {version} from '../../../../package.json';
import Icon from "@/core/components/icon/Icon.vue";

const router = useRouter();
const systemStore = useSystemStore();
const {environment} = storeToRefs(systemStore);

const menuContainer = ref<null | HTMLElement>(null);
const isOpen = ref(false);

const env = computed(() => {
    switch (process.env.NODE_ENV) {
    case 'development':
        return 'dev';
    case 'production':
        return 'prod';
    case 'staging':
        return 'stag';
    default:
        return '???';
    }
});

const appVersion = computed(() => {
    return version;
});

const handleClickOutside = (event) => {
    if (menuContainer.value && !menuContainer.value.contains(event.target)) {
        isOpen.value = false;
    }
};

const route = (name) => {
    if (name) {
        router.push({name});
    }
    isOpen.value = false;
};

const logout = () => {
    authenticationService().logout();
    route('login');

    if (window.ipcRenderer) {
        window.ipcRenderer.send('unauthenticated');
    }
};

watch(() => isOpen.value, (value) => {
    if (value) {
        document.addEventListener('click', handleClickOutside);
    } else {
        document.removeEventListener('click', handleClickOutside);
    }
});
</script>
