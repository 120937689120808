import {LoyaltyCard} from "@/loyalty-card/interfaces/hds/LoyaltyCard";

/**
 * Service for loyalty cards.
 */
const LoyaltyCardService = () => {

    /**
     * Determine if a loyalty card can have point rewards.
     */
    const hasPointRewards = (loyaltyCard: LoyaltyCard): boolean => {
        return !!loyaltyCard.point_rewards_count;
    };

    /**
     * Determine if a loyalty card can have product rewards.
     */
    const hasProductRewards = (loyaltyCard: {product_rewards_count: number}): boolean => {
        return !!loyaltyCard.product_rewards_count;
    };

    return {
        hasPointRewards,
        hasProductRewards
    };
};

export default LoyaltyCardService;
