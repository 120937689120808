<template>
  <BaseButton class="w-44" @click="goToPrescriptionControl">
    <Icon name="mdi-list-status" />
    Contrôler
  </BaseButton>
</template>

<script lang="ts" setup>
import {PropType} from "vue";
import {Prescription} from "@/prescription/interfaces/Prescription";
import BaseButton from "@/core/components/base/BaseButton.vue";
import Icon from "@/core/components/icon/Icon.vue";
import {useRouter} from "vue-router";

const router = useRouter();

const props = defineProps({
    prescription: {
        type: Object as PropType<Prescription>,
        required: false,
        default: null
    }
});

/**
 * Redirect to the new renewal plan page
 */
const goToPrescriptionControl = () => {
    router.push({
        name: 'customer.prescriptions.control',
        params: {
            customerId: props.prescription.customer.id,
            prescriptionId: props.prescription.id
        }
    });
};
</script>
