<script setup lang="ts">
import videoThumbnail from "@/assets/images/thumbnail_video_default.png";
import EmbedVideo from "@/core/components/video/EmbedVideo.vue";
import {Dialog, DialogTrigger, DialogContent, DialogTitle} from "@/core/components/ui/dialog";
import {ref} from "vue";
import BaseCard from "@/core/components/base/BaseCard.vue";
import Icon from "@/core/components/icon/Icon.vue";
import {useRouter} from "vue-router";

const showVideoModal = ref<boolean>(false);
const router = useRouter();
</script>

<template>
  <div class="flex flex-row gradient p-4">
    <div class="flex flex-col pr-12">
      <h1 class="text-white">
        Boostez vos ventes avec le click & collect
      </h1>
      <p class="text-white">
        Avec le click & collect, vos patients équipés de l'application Apodis peuvent passer commande directement dans
        votre pharmacie (voir vidéo).
        Personnalisez votre vitrine en publiant vos promotions sur l'application Apodis, et mettez vos marques en
        avant!
      </p>
    </div>
    <Dialog v-model:open="showVideoModal">
      <DialogTrigger class="min-w-40">
        <img class="w-40 cursor-pointer" alt="tutoriel" :src="videoThumbnail">
      </DialogTrigger>
      <DialogContent class="min-w-[90%] max-h-auto">
        <DialogTitle>Gestion d'une commande Click & Collect</DialogTitle>
        <embed-video class="w-full" url="https://www.youtube.com/embed/DmP2f4GxNaI" />
      </DialogContent>
    </Dialog>
  </div>
  <div class="px-4 pb-4">
    <div class="flex flex-row gap-8 py-8">
      <BaseCard
        class="flex flex-1 flex-col p-4 hover:opacity-75 cursor-pointer"
        @click="router.push({name: 'patient-administration.offers'})"
      >
        <div>
          <div class="flex flex-row gap-2">
            <h2 class="text-black">
              Vos promotions
            </h2>
            <icon name="mdi-tag" color="orange" />
          </div>
          <p>Publiez vos promotions et vos patients bénéficieront des réductions dans l'application</p>
          <div class="flex justify-end pt-4">
            <span class="text-primary hover:opacity-75 cursor-pointer">Publier des promotions</span>
          </div>
        </div>
      </BaseCard>
      <BaseCard class="flex flex-1 flex-col relative">
        <div class="opacity-50 bg-black/50 p-4">
          <div class="flex flex-row gap-2">
            <h2 class="text-black">
              Vos produits du moment
            </h2>
            <icon name="mdi-fire" color="red" />
          </div>
          <p>Les produits ajoutés sont mis en avant sur l'application</p>
          <div class="flex justify-end pt-4">
            <span class="text-primary hover:opacity-75 cursor-pointer">Ajouter des produits</span>
          </div>
        </div>
        <div class="absolute inset-0 flex items-center justify-center bg-white/50">
          <h1>Bientôt disponible</h1>
        </div>
      </BaseCard>
    </div>
    <div class="flex flex-col gap-8">
      <BaseCard class="flex flex-1 flex-col relative">
        <div class="opacity-50 bg-black/50 p-4">
          <div class="flex flex-row gap-2">
            <h2 class="text-black">
              Vos marques
            </h2>
          </div>
          <p>Choisissez les marques à mettre en avant sur l'application</p>
        </div>
        <div class="absolute inset-0 flex items-center justify-center bg-white/50">
          <h1>Bientôt disponible</h1>
        </div>
      </BaseCard>
      <BaseCard
        class="flex flex-1 flex-col p-4 hover:opacity-75 cursor-pointer"
        @click="router.push({name: 'patient-administration.orders'})"
      >
        <div class="flex flex-row gap-2">
          <h2 class="text-black">
            Commandes passées
          </h2>
        </div>
        <p>Retrouver l'ensemble des commandes effectués par vos patients au même endroit</p>
        <div class="flex justify-end pt-4">
          <span class="text-primary">Accéder aux commandes</span>
        </div>
      </BaseCard>
    </div>
  </div>
</template>
