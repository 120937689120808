import {computed} from "vue";
import dateHelper from "@/core/helpers/dateHelper";
import {storeToRefs} from "pinia";
import {useSystemStore} from "@/stores/system.store";

/**
 * Get the sante secure state.
 */
const useSanteSecureState = () => {
    const {environment} = storeToRefs(useSystemStore());

    return computed(() => {
        let lastSync: string;
        let syncState: boolean;
        const version = environment.value?.entity.santeSecureVersion;
        if (environment.value?.entity && environment.value?.entity.lastLgoImport) {
            const date = new Date(environment.value?.entity.lastLgoImport.replace(' ', 'T'));
            // Si pas de remontée depuis plus de trois jours
            const diff = new Date().getTime() - date.getTime();
            if (diff > (1000 * 60 * 60 * 24 * 3)) {
                lastSync = 'vos données ne sont plus sauvegardées depuis ' + Math.floor(diff / 86400000) + ' jours';
                syncState = false;
            } else {
                lastSync = 'dernière synchronisation des données le ' + dateHelper.longFormat(date);
                syncState = true;
            }
        } else {
            lastSync = 'date de dernière synchronisation inconnue';
            syncState = false;
        }
        return {lastSync, syncState, version};
    });
};

export default useSanteSecureState;
