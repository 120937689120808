import {SubscriptionPlan} from "@/subscription-plan/interfaces";
import {AxiosInstance} from "axios";
import {Trial} from "@/core/interfaces/trial";

/**
 * Subscription plan resource.
 */
export default function (client: AxiosInstance) {
    return {
        /**
         * Get the subscription plans.
         */
        async index(): Promise<SubscriptionPlan[]> {
            return client
                .get('/subscription-plans')
                .then(response => response.data.data);
        },

        /**
         * Read a subscription plan.
         *
         * @param id
         */
        async read(id: number): Promise<SubscriptionPlan> {
            return client
                .get(`/subscription-plans/${id}`)
                .then(response => response.data.data);
        },

        /**
         * Launch a trial for the subscription plan.
         *
         * @param planId
         */
        async launchTrial(planId: number): Promise<Trial> {
            return client
                .post(`/subscription-plans/${planId}/trials`)
                .then(response => response.data.data);
        }
    };
}
