<script setup lang="ts">
import Icon from "@/core/components/icon/Icon.vue";
import {Dialog, DialogContent, DialogTitle, DialogTrigger} from '@/core/components/ui/dialog';
import {calendarApi} from "@/container";
import {computed, reactive, ref, watch} from "vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import dayjs from "dayjs";
import {CalendarEvent} from "@/core/http/resources/CalendarResource";
import dateHelper from "../../helpers/dateHelper";
import {PrescriptionRenewalPlanDate} from "@/prescription/interfaces/prescription-renewal-plan/PrescriptionRenewalPlan";
import {Appointment} from "@/core/interfaces/Appointment";
import {useRouter} from "vue-router";

const api = calendarApi();
const router = useRouter();

interface VCalendarEvent {
    title: string;
    type: string;
    start: Date;
    end: Date;
    color: string;
    allDay: boolean;
}

const open = ref<boolean>(false);
const calendarRange =ref<Date[]>([new Date()]);
const loading = ref(true);
const events = ref<CalendarEvent[]>([]);
const requestRange = reactive<{ from: string, to: string }>({
    from: dayjs().startOf('month').toISOString(),
    to: dayjs().endOf('month').toISOString(),
});

/**
 * Load the calendar data.
 */
const loadData = (opened: boolean) => {
    if (opened) {
        loading.value = true;
        return api.calendar(requestRange.from, requestRange.to).then((response) => {
            events.value = response;
            loading.value = false;
        });
    }
};

/**
 * Handle the calendar change.
 */
watch(calendarRange, (value) => {
    requestRange.from = dayjs(value[0]).startOf('month').toISOString();
    requestRange.to = dayjs(value[0]).endOf('month').toISOString();
    loadData(open.value);
});


/**
 * Mapped events for calendar display.
 */
const mappedEvents = computed((): VCalendarEvent[] => {
    return events.value.map((event) => {
        return {
            title: event.title,
            start: dayjs(event.start).toDate(),
            end: dayjs(event.end).toDate(),
            type: event.type,
            color: event.color,
            allDay: event.allDay,
        };
    });
});

/**
 * Group events by day.
 */
const groupByDay = (events: CalendarEvent[]) => {
    return events.reduce((acc, event) => {
        const date = dayjs(event.start).format('YYYY-MM-DD');
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(event);
        return acc;
    }, {} as Record<string, CalendarEvent[]>);
};

/**
 * Handler for calendar clicks.
 * @param event
 */
const onClick = (event: CalendarEvent) => {
    const customer = event.type === 'renewal-date'
        ? (event.model as PrescriptionRenewalPlanDate).plan!.customer
        : (event.model as Appointment).customer;

    open.value = false;

    router.push({
        name: 'customer.home',
        params: {
            customerId: customer.id,
        },
    });
};


</script>

<template>
  <Dialog v-model:open="open" @update:open="loadData">
    <DialogTrigger>
      <slot/>
    </DialogTrigger>
    <DialogContent class="min-w-[90%] max-h-auto">
      <div class="hidden">
        <DialogTitle>Calendrier</DialogTitle>
      </div>
      <div v-if="!loading" class="flex flex-col gap-3">
        <v-calendar v-model="calendarRange" :events="mappedEvents">
          <template #event="{ event }">
            <Icon v-if="event.type === 'renewal-date'" :size="12" name="mdi-bell" class="text-primary" />
            <Icon v-else name="mdi-star" :size="14" class="text-secondary" />
          </template>
        </v-calendar>
        <h4>Événements à venir</h4>
        <div v-if="events.length > 0" class="flex flex-col gap-1 text-sm">
          <div
            v-for="([date, groupedEvents], index) in Object.entries(groupByDay(events))"
            :key="index" class="flex gap-2"
          >
            <h5 class="w-24">
              {{ dateHelper.abbreviatedMonthFormat(date) }}
            </h5>
            <ul>
              <li
                v-for="(event, groupIndex) in groupedEvents"
                :key="groupIndex"
                class="cursor-pointer"
                @click="() => onClick(event)"
              >
                <Icon v-if="event.type === 'renewal-date'" :size="12" name="mdi-bell" class="text-primary" />
                <Icon v-else name="mdi-star" :size="14" class="text-secondary" />
                {{ event.title }}
              </li>
            </ul>
          </div>
        </div>
        <div v-else>
          <p class="text-sm">
            Aucun événement à venir. Vous retrouverez ici vos rendez-vous patients et les rappels de renouvellements de
            vos ordonnances.
          </p>
        </div>
      </div>
      <BaseFlexSpinner v-else />
    </DialogContent>
  </Dialog>
</template>

<style scoped>
:deep(.v-calendar-month__days > .v-calendar-month__day) {
    min-height: 40px;
}

:deep(.v-calendar-header > button:first-child) {
    display: none;
}

:deep(.v-calendar-header__title) {
    @apply text-base font-semibold;
    text-transform: capitalize;
}

:deep(.v-calendar-header) {
    min-height: auto;
}

:deep(.v-calendar-weekly__day-label > button) {
    @apply text-xs;
    height: 18px;
    width: 18px;
}

:deep(.v-calendar-weekly__day-alldayevents-container) {
    @apply hidden;
}
</style>
