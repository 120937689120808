import useSwr from "@/core/composables/swr/useSwr";
import {laboratoryApi} from "@/container";
import {computed} from "vue";

const useLaboratoryBranding = (laboratoryId: string) => {
    const key = computed(() => {
        return laboratoryId && `/laboratories/${laboratoryId}/branding`;
    });

    const {data, isLoading} = useSwr(
        key,
        () => laboratoryApi().branding(Number(laboratoryId)),
        {
            revalidateOnFocus: false
        }
    );
    return {
        data,
        isLoading
    };
};

export default useLaboratoryBranding;
