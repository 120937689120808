import useSwr from "@/core/composables/swr/useSwr";
import {advantageApi} from "@/container";

/**
 * Composable to fetch the advantages.
 */
const useAdvantages = () => {
    const {data, isLoading} = useSwr(
        "advantages?type=grouping",
        () => advantageApi().index({type: "grouping"}),
    );

    return {
        data,
        isLoading
    };
};

export default useAdvantages;
