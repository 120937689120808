<template>
  <div>
    <dot-lottie-vue
      v-if="isLoading"
      autoplay
      loop
      style="margin: 0; height: 200px"
      src="animations/fetching.json"
    />
    <div class="w-full flex flex-row">
      <transition
        name="slide-fade"
        class="w-full flex flex-row"
      >
        <div
          v-if="!isLoading && tasks.length"
          class="w-full flex p-4 bg-apodisPink relative -top-1 pt-5 rounded-b-md overflow-x-auto"
        >
          <div>
            <h1>À traiter</h1>
            <div class="flex flex-row">
              <transition-group>
                <div
                  v-for="(action, index) in tasks"
                  :key="`${action.type}_${index}`"
                  class="list-action mx-4 mt-4 cursor-pointer"
                >
                  <component
                    :is="resolveComponent(action)"
                    :item="action.data"
                  />
                </div>
              </transition-group>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {onMounted, onUnmounted, ref} from "vue";
import {useLiveStore} from "@/stores/live.store";
import {DotLottieVue} from "@lottiefiles/dotlottie-vue";
import {OrderCreatedBroadcastEvent} from "@/home/interfaces";
import HomeLiveOrder from "@/home/components/HomeLiveOrder.vue";
import LiveItem, {LiveItemType} from "@/core/interfaces/live/LiveItem";
import HomeLiveInterviewInstance from "@/home/components/HomeLiveInterviewInstance.vue";
import {storeToRefs} from "pinia";
import HomeLiveSurveyAnswerInstance from "@/home/components/HomeLiveSurveyAnswerInstance.vue";

type ComponentMap = {
    [LiveItemType.ORDER_CREATED]: typeof HomeLiveOrder,
    [LiveItemType.INTERVIEW_INSTANCE]: typeof HomeLiveInterviewInstance,
    [LiveItemType.SURVEY_ANSWER_INSTANCE]: typeof HomeLiveSurveyAnswerInstance
}

const liveStore = useLiveStore();
const {tasks} = storeToRefs(liveStore);

const isLoading = ref(true);

let removeOrderEventListener: (() => void) | null = null;
let removeSurveyEventListener: (() => void) | null = null;

const components: ComponentMap = {
    [LiveItemType.ORDER_CREATED]: HomeLiveOrder,
    [LiveItemType.INTERVIEW_INSTANCE]: HomeLiveInterviewInstance,
    [LiveItemType.SURVEY_ANSWER_INSTANCE]: HomeLiveSurveyAnswerInstance
};

/**
 * Resolve the component to use based on the live item given.
 *
 * @param {LiveItem} item
 */
const resolveComponent = (item: LiveItem) => {
    return components[item.type];
};

/**
 * Load the actions.
 */
const loadActions = async () => {
    try {
        await liveStore.loadItems();
    } finally {
        isLoading.value = false;
    }
};

/**
 * On mounted, load the actions and listen for new items.
 */
onMounted(async () => {
    await loadActions();

    removeOrderEventListener = window.ipcRenderer.socketListenEvent('order-created', (event: any, {data}: {
        data: OrderCreatedBroadcastEvent
    }) => {
        liveStore.newBroadcastOrder(data);
    });

    removeSurveyEventListener = window.ipcRenderer.socketListenEvent('survey-completed', () => {
        liveStore.loadItems();
    });
});

/**
 * On unmounted, remove the event listener.
 */
onUnmounted(() => {
    if (removeOrderEventListener) {
        removeOrderEventListener();
    }

    if (removeSurveyEventListener) {
        removeSurveyEventListener();
    }
});
</script>

<style scoped>
/* Slide fade animation. */
.slide-fade-enter-active {
    transition: all 1s ease;
}

.slide-fade-leave-active {
    transition: height 1.5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(-10px);
    opacity: 0;
}

/* List animation. */
.list-action {
    transition: all 1s;
    display: inline-block;
    margin-right: 10px;
}

.list-enter, .list-leave-to {
    opacity: 0;
    transform: translateX(-30px);
}
</style>
