<template>
  <base-flex-spinner v-if="loading" class="mt-8" />
  <div v-else>
    <TheBackButton title="Contrôle de l'ordonnance" class="bg-white" />
    <div class="flex gap-4 p-4">
      <div class="flex-1">
        <prescription-visual
          v-if="prescription"
          :prescription="prescription"
        />
      </div>
      <div class="flex-1">
        <h2>Double contrôle</h2>
        <BaseCard>
          <BaseCardBody>
            <div class="flex flex-col gap-4">
              <PrescriptionControlProduct v-for="product in analysis.report" :key="product.code" :product="product" />
            </div>
          </BaseCardBody>
        </BaseCard>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import PrescriptionVisual from "@/prescription/components/PrescriptionVisual.vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {prescriptionAnalysisApi, prescriptionApi} from "@/container";
import TheBackButton from "@/core/components/navigation-bar/TheBackButton.vue";
import {Prescription} from "@/prescription/interfaces/Prescription";
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import PrescriptionControlProduct from "@/prescription/components/PrescriptionControlProduct.vue";

const prescription = ref<Prescription>();
const analysis = ref();
const loading = ref(true);
const route = useRoute();

onMounted(async () => {
    loading.value = true;

    prescription.value = await prescriptionApi()
        .read(parseInt(route.params.customerId as string), route.params.prescriptionId as string);

    if (prescription.value) {
        analysis.value = await prescriptionAnalysisApi().analyze(prescription.value.document_key);
    }

    loading.value = false;
});
</script>

<style scoped>
li {
    list-style-type: disc;
    @apply text-primary text-2xl ml-6;
}

li div {
    @apply text-gray-800 text-base relative -top-1;
}
</style>
