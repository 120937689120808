/**
 * Pluralize a string
 *
 * @param words - The string to pluralize
 * @param count - The count to determine if the string should be pluralized
 *
 * @return The pluralized string
 */
export const pluralize = (words: string, count: number) => {
    return words
        .split(' ')
        .map((word: string) => {
            if (count > 1) {
                return `${word}s`;
            }

            return word;
        })
        .join(' ');
};


