import {AxiosInstance} from "axios";
import {PaginatedData} from "@/core/interfaces/api";
import {Video} from "@/video/interfaces";

export interface VideoQueryParams {
    page?: number;
    limit?: number;
    tags?: string[];
}

/**
 * API resource for videos.
 *
 * @param client
 */
export const VideoResource = (client: AxiosInstance) => {
    return {
        /**
         * Get the video index.
         */
        async index(params: VideoQueryParams = {}): Promise<PaginatedData<Video[]>> {
            return client.get('/videos', {params})
                .then(response => response.data);
        }
    };
};

export default VideoResource;
