import useSwr from "@/core/composables/swr/useSwr";
import {computed} from "vue";
import {loyaltyCardMembershipApi} from "@/container";

/**
 * Composable to fetch customer loyalty card membership.
 *
 * @param customerId
 * @param membershipId
 */
const useCustomerLoyaltyCardMembership = (customerId: number, membershipId: number) => {
    const key = computed(() => `/customer/${customerId}/loyalty-card/membership/${membershipId}`);

    const {data, isLoading, mutate} = useSwr(key, () => {
        return loyaltyCardMembershipApi().read(customerId, membershipId);
    });

    return {
        membership: data,
        isLoading,
        mutate
    };
};

export default useCustomerLoyaltyCardMembership;
