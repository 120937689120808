<script lang="ts" setup>
import {useRoute} from "vue-router";
import useCustomerLoyaltyCardMembership
    from "@/customer/composables/loyalty-card-membership/useCustomerLoyaltyCardMembership";
import useCustomerLoyaltyCardMembershipTransactions
    from "@/customer/composables/loyalty-card-membership/useCustomerLoyaltyCardMembershipTransactions";
import {ref} from "vue";
import {
    LoyaltyCardHeader,
    LoyaltyCardMembershipHeader,
    LoyaltyCardMembershipBalance,
    LoyaltyCardTeaser,
    LoyaltyCardMembershipInformation,
    LoyaltyCardMembershipTransactions
} from "@/loyalty-card-membership/components";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";

const route = useRoute();

const customerId = Number(route.params.customerId);
const membershipId = Number(route.params.membershipId);

const selectedType = ref<null | string>(null);

const {membership, isLoading, mutate: mutateMembership} = useCustomerLoyaltyCardMembership(customerId, membershipId);
const {transactions, mutate: mutateTransaction} = useCustomerLoyaltyCardMembershipTransactions(customerId, membershipId, selectedType);

const refresh = () => {
    mutateTransaction();
    mutateMembership();
};
</script>

<template>
  <div v-if="isLoading">
    <BaseFlexSpinner />
  </div>
  <div
    v-else-if="membership"
    class="m-6"
  >
    <div class="flex flex-row justify-between flex-wrap gap-4">
      <LoyaltyCardHeader
        :loyalty-card="membership.loyalty_card"
      />
      <LoyaltyCardMembershipHeader
        :customer-id="customerId"
        :membership="membership"
        @refresh="refresh"
      />
    </div>
    <div class="grid grid-cols-2 gap-5">
      <LoyaltyCardMembershipBalance :membership="membership" />
      <LoyaltyCardTeaser :loyalty-card="membership.loyalty_card" />
    </div>

    <LoyaltyCardMembershipTransactions
      v-if="transactions"
      :transactions="transactions.data"
      :selected-type="selectedType"
      @type-selected="type => selectedType = type"
    />
    <LoyaltyCardMembershipInformation :membership="membership" />
  </div>
</template>
