<template>
  <AccordionItem :value="customer.id + '_' + (beneficiary?.id || '0')" class="bg-white rounded-lg px-4 shadow">
    <AccordionTrigger>
      <div class="flex flex-col gap-2 w-full text-left font-content">
        <div class="flex justify-between items-center mr-10">
          <div class="flex items-center gap-3">
            <div>
              <img
                class="h-14 rounded-full"
                :src="avatar"
                alt="avatar"
              >
            </div>
            <div>
              <RouterLink :to="`/customer/${customer.id}/prescriptions`">
                <div class="font-bold min-w-64">
                  {{ information?.name || 'Anonyme' }}
                  <span class="font-normal text-sm">({{ dateHelper.age(information?.birth_date) }})</span>
                </div>
                <div v-if="beneficiary?.information.name" class="min-w-64">
                  Ayant droit de {{ customer.information?.name || 'Anonyme' }}
                </div>
              </RouterLink>
            </div>
          </div>
          <div>
            <strong>{{ prescriptions.length }} {{ pluralize('plan', prescriptions.length) }} de renouvellement</strong>
            <Icon name="mdi-file-document-refresh-outline" :size="20" class="text-primary" />
          </div>
        </div>
        <div>
          <div v-if="upcomingDates" class="text-gray-dark flex gap-1">
            <Icon name="mdi-update" :size="20" class="text-primary" />
            <strong>A venir:</strong> <span class="-thin">{{ upcomingDates(aggregatedDates) }}</span>
          </div>
          <div class="text-gray-dark flex gap-1">
            <Icon name="mdi-history" :size="20" class="text-primary" />
            <strong>Passés:</strong> {{ pastDates(aggregatedDates) }}
          </div>
        </div>
      </div>
    </AccordionTrigger>
    <AccordionContent class="flex flex-col gap-3">
      <div
        v-for="prescription in prescriptions" :key="prescription.id"
        class="flex flex-col border p-4 gap-2 rounded-lg bg-white shadow-md"
      >
        <div class="flex items-center gap-4">
          <div>
            <secure-image
              :urls="prescription.files"
              :size="20"
            />
          </div>
          <div class="flex-1">
            <Icon name="mdi-update" :size="20" class="text-primary" />
            <strong>Renouvellements:</strong> {{ allDates(prescription.renewal_plan.renewal_dates) }}
          </div>
          <prescription-renewal-button :prescription="prescription" />
        </div>
        <div>
          <ul class="truncate">
            <li v-for="(product, index) in prescription.products" :key="index">
              <span class="text-sm"><Icon name="mdi-pill" :size="10" class="text-primary" /> {{ product.name }}</span>
            </li>
          </ul>
        </div>
      </div>
    </AccordionContent>
  </AccordionItem>
</template>

<script lang="ts" setup>
import {computed} from "vue";
import SecureImage from '@/core/components/secure-image/SecureImage.vue';
import PrescriptionRenewalButton from '@/prescription/components/PrescriptionRenewalButton.vue';
import {Prescription} from "@/prescription/interfaces/Prescription";
import dayjs from "dayjs";
import Icon from "@/core/components/icon/Icon.vue";
import Customer from "@/customer/interfaces/Customer";
import Beneficiary from "@/customer/interfaces/Beneficiary";
import {pluralize} from "@/core/filters/Pluralize";
import userMaleIcon from '@/assets/icons/user-male.png';
import userFemaleIcon from '@/assets/icons/user-female.png';
import dateHelper from "@/core/helpers/dateHelper";
import {AccordionContent, AccordionItem, AccordionTrigger} from "@/core/components/ui/accordion";
import {PrescriptionRenewalPlanDate} from "@/prescription/interfaces/prescription-renewal-plan/PrescriptionRenewalPlan";

const props = defineProps<{
    prescriptions: Prescription[],
    customer: Customer,
    beneficiary?: Beneficiary,
}>();

/**
 *
 */
const allDates = (renewalDates: PrescriptionRenewalPlanDate[]) => {
    return renewalDates
        .map(renewal => dateHelper.dayOnlyLongFormat(new Date(renewal.renew_at)))
        .join(', ');
};

/**
 * Concatenate the upcoming renewal date labels.
 */
const upcomingDates = (renewalDates: PrescriptionRenewalPlanDate[]) => {
    return renewalDates
        .map(renewal =>
            dayjs().isBefore(dayjs(renewal.renew_at)) ? dateHelper.dayOnlyLongFormat(new Date(renewal.renew_at)) : '')
        .filter(value => !!value)
        .join(', ');
};

/**
 * Concatenate the past renewal date labels.
 */
const pastDates = (renewalDates: PrescriptionRenewalPlanDate[]) => {
    return renewalDates
        .map(renewal =>
            dayjs().isAfter(dayjs(renewal.renew_at)) ? dateHelper.dayOnlyLongFormat(new Date(renewal.renew_at)) : '')
        .filter(value => !!value)
        .join(', ');
};

/**
 * Accessor to the consumer information.
 */
const information = computed(() => {
    return props.beneficiary?.information || props.customer.information;
});

/**
 * Compute the avatar of the patient.
 */
const avatar = computed(() => {
    return props.beneficiary?.information?.gender || props.customer.information?.gender || 1 === 1
        ? userMaleIcon
        : userFemaleIcon;
});

/**
 * Aggregate the prescription dates.
 */
const aggregatedDates = computed(() => {
    return props.prescriptions
        .map(prescription => prescription.renewal_plan.renewal_dates)
        .flat()
        .sort((a, b) => (new Date(a.renew_at)).getTime() - (new Date(b.renew_at)).getTime());
});
</script>
