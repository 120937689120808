import { computed, ComputedRef, Ref } from 'vue';
import { Alert } from '@/alert/interfaces';

export function useAlertBanner(alertRef: Ref<Alert | undefined> | ComputedRef<Alert | undefined>) {
    /**
     * Check if the alert has a link.
     */
    const hasLink = computed(() => !!alertRef.value?.link);

    /**
     * Compute banner style based on alert properties.
     */
    const bannerStyle = computed(() => {
        return {
            backgroundColor: alertRef.value?.background_color || '#000000',
            cursor: hasLink.value ? 'pointer' : 'default',
            color: alertRef.value?.text_color || '#FFFFFF',
        };
    });

    /**
     * Open new window if the alert has a link.
     */
    const handleBannerClick = () => {
        if (hasLink.value && alertRef.value?.link) {
            window.open(alertRef.value.link, '_blank');
        }
    };

    return {
        hasLink,
        bannerStyle,
        handleBannerClick
    };
}
