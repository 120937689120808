import {AxiosInstance, AxiosResponse} from "axios";
import LaboratoryProduct, {LaboratoryProductDataResponse} from "@/core/interfaces/laboratory/LaboratoryProduct";
import {CartModifiedResponse, LaboratoryOrder} from "@/core/interfaces/disruption/LaboratoryOrder";
import {LaboratoryCart, LaboratoryCartLine} from "@/laboratory/interfaces";
import Product from "@/product/interfaces/Product";

export interface LaboratoryCartLinesModified {
    available: number;
    ordered: number;
    product: Product;
}

export interface ManageCartResponse {
    data: ManageCartResponseData;
}

export interface ManageCartResponseData {
    cartLines: LaboratoryCartLine[];
    cartLinesModified: LaboratoryCartLinesModified[];
}

/**
 * Interface for the response from cart items deletion
 *
 */
export interface DeleteCartResponseData {
    /** The response */
    success: boolean;
}

export interface LaboratoryOrderResponse {
    data: LaboratoryOrder | CartModifiedResponse;
}

/**
 * The shop resource.
 *
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client: AxiosInstance) {
    return {
        /**
         * Get laboratory product prices
         *
         * @param {number} laboratoryId - The laboratory id.
         * @param {number} productId - The product id.
         * @param {number} quantity - The product quantity.
         *
         * @returns {Promise<LaboratoryProduct>}
         */
        async getLaboratoryProductPrices(laboratoryId: number, productId: number, quantity = 1): Promise<LaboratoryProduct> {
            return client.get(`/shop/laboratories/${laboratoryId}/products/${productId}/prices`, {
                params: {
                    include: "laboratoryPriceRanges.laboratoryPrice",
                    quantity
                }
            })
                .then((response: AxiosResponse<LaboratoryProductDataResponse>) => {
                    const {data} = response;
                    return data.data;
                });
        },

        /**
         * Read an order by sellin offer.
         * @param {number} laboratoryId - The laboratory id.
         * @param {number}  healthcenterId - The healthcenter id.
         * @param {number}  sellinOfferId - The sellin offer id.
         *
         * @return {Promise<LaboratoryOrder>}
         */
        readBySellinOffer(laboratoryId: number, healthcenterId: number, sellinOfferId: number): Promise<LaboratoryOrder> {
            const params = {
                include: "lines"
            };
            return client.get(`/shop/laboratories/${laboratoryId}/healthcenters/${healthcenterId}/sellinOffers/${sellinOfferId}/order`, {params})
                .then((response: AxiosResponse) => response.data.data);
        },

        /**
         * Get laboratory cart
         *
         * @param {number} laboratoryId - The laboratory id.
         * @param {number} healthcenterId - The healthcenter id.
         *
         * @returns {Promise<LaboratoryCart>}
         */
        laboratoryCart(laboratoryId: number, healthcenterId: number): Promise<LaboratoryCart> {
            return client.get(
                `/shop/laboratories/${laboratoryId}/healthcenters/${healthcenterId}/cart`,
                {params: {include: "lines.product.laboratoryPriceRanges.laboratoryPrice,lines.product.availability"}}
            )
                .then(response => response.data.data);
        },

        /**
         * Manage Cart
         *
         * @param {number} laboratoryId - The laboratory id.
         * @param {number} healthcenterId - The healthcenter id.
         * @param {{ [id: string] : number; }} products - The product ids and quantities.
         *
         * @returns {Promise<ManageCartResponseData>}
         */
        manageCart(laboratoryId: number, healthcenterId: number, products: { [Key: string]: number }): Promise<ManageCartResponseData> {
            return client.post(`/shop/laboratories/${laboratoryId}/healthcenters/${healthcenterId}/manageCart`, {products}, {
                params: {
                    include: "product.laboratoryPriceRanges.laboratoryPrice,product.availability"
                }
            })
                .then((response: AxiosResponse<ManageCartResponse>) => {
                    const {data} = response;
                    return data.data;
                });
        },

        /**
         * Get laboratory product prices
         *
         * @param {number} laboratoryId - The laboratory id.
         * @param {number} healthcenterId - The healthcenter id.
         *
         * @returns {Promise<LaboratoryOrder>}
         */
         validateCart(laboratoryId: number, healthcenterId: number): Promise<LaboratoryOrder | CartModifiedResponse> {
            return client.get(`/shop/laboratories/${laboratoryId}/healthcenters/${healthcenterId}/validateCart`)
                .then((response: AxiosResponse<LaboratoryOrderResponse>) => {
                    const {data} = response;
                    return data.data;
                });
        },

        /**
         * Delete all cart items.
         * @param {number} laboratoryId - The laboratory id.
         * @param {number} healthcenterId - The healthcenter id.
         *
         * @returns {Promise<{success: boolean}>}
         */
         deleteCart(laboratoryId: number, healthcenterId: number): Promise<DeleteCartResponseData> {
            return client.get(`/shop/laboratories/${laboratoryId}/healthcenters/${healthcenterId}/emptyCart`)
                .then((response: AxiosResponse<DeleteCartResponseData>) => {
                    return response.data;
                });
        }
    };
};
