<template>
  <v-data-table
    :headers="headers"
    :items="transformedConsumers"
    :disable-sort="true"
    :loading-text="'Chargement en cours...'"
    no-data-text="Aucun patients n'a bénéficié de cette offre"
    @click:row="handleClick"
  />
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
    name: 'InstantDiscountVoucherConsumersTable',
    props: {
        consumers: {
            type: Array<any>,
            required: true
        }
    },
    emits: ['consumerClick'],
    computed: {
    /**
     * The table header configuration.
     *
     * @return {Array}
     */
        headers () {
            return [
                {
                    text: 'Nom',
                    value: 'name'
                },
                {
                    text: 'Remises',
                    value: 'total_discount'
                },
                {
                    text: 'Marges',
                    value: 'total_margin'
                },
                {
                    text: 'Quantité',
                    value: 'total_quantity'
                },
                {
                    text: 'Recette',
                    value: 'total_revenue'
                }
            ];
        },
        /**
     * Transform the customers for the table.
     *
     * @return {Array}
     */
        transformedConsumers () {
            if (!this.consumers) {
                return undefined;
            }

            return this.consumers.map((consumer) => {
                return {
                    consumer: consumer,
                    name: consumer.customer.information.name,
                    total_quantity: consumer.total_quantity,
                    total_revenue: consumer.total_revenue,
                    total_margin: consumer.total_margin.toFixed(2),
                    total_discount: consumer.total_discount
                };
            });
        }
    },
    methods: {
    /**
     * Redirect to customer details.
     *
     * @param row
     */
        handleClick (event, row) {
            this.$emit('consumerClick', row.item.consumer);
        }
    }
});
</script>
