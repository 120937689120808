import {computed, Ref} from "vue";
import {loyaltyCardMembershipTransactionApi} from "@/container";
import useSwr from "@/core/composables/swr/useSwr";

/**
 * Composable to fetch customer loyalty card membership transactions.
 *
 * @param customerId
 * @param membershipId
 * @param type
 */
const useCustomerLoyaltyCardMembershipTransactions = (customerId: number, membershipId: number, type: Ref<string | null>) => {
    const key = computed(() => {
        return `/customer/${customerId}/loyalty-card-memberships/${membershipId}/transactions?type=${type.value}`;
    });

    const {data, isLoading, mutate} = useSwr(key, () => {
        return loyaltyCardMembershipTransactionApi().get(customerId, membershipId, type.value);
    });

    return {
        transactions: data,
        isLoading,
        mutate
    };
};

export default useCustomerLoyaltyCardMembershipTransactions;
