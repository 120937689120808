import useSwr from "@/core/composables/swr/useSwr";
import {prescriptionRenewalPlanApi} from "@/container";
import {cacheKey} from "@/lib/cache";
import {
    PrescriptionRenewalPlanIndexParams
} from "@/prescription/interfaces/prescription-renewal-plan/PrescriptionRenewalPlan";
import {computed} from "vue";

/**
 * Composable to get the analysis of customers for a laboratory.
 */
const useRenewalPlanIndex = (params?: PrescriptionRenewalPlanIndexParams) => {
    const {data, error, isLoading, isValidating} = useSwr(
        computed(() => cacheKey('renewal-plans', params)),
        () => prescriptionRenewalPlanApi().index(params),
    );

    return {
        data,
        error,
        isLoading,
        isValidating
    };
};

export default useRenewalPlanIndex;
