import {AxiosInstance} from "axios";
import {LoyaltyCard} from "@/loyalty-card/interfaces/hds/LoyaltyCard";
import {LoyaltyCardMembershipItemStatistic} from "@/loyalty-card/interfaces/hds/LoyaltyCard";
import {MetaData} from "@/core/interfaces/api";

export interface LoyaltyCardAnalysisResponse extends LoyaltyCard {
    analysis: {
        average: {
            cart_price: {
                current: number;
                previous: number;
            },
            age: number;
            cart: number;
        },
        healthcenter_average: {
            cart: number;
        },
        count: {
            membership: number;
            male: number;
            female: number;
        },
        total: {
            point_balance: number;
            debited: number;
            credited: number;
            unit_balance: number;
        }
    }
}

/**
 * The loyalty card resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @param {Object} hdsClient - The injected client.
 * @returns {Object} The public object.
 */
export default function (client: AxiosInstance, hdsClient: AxiosInstance) {
    return {
        /**
         * Get all loyalty cards.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        get() {
            return client.get('/loyalty-cards', {
                params: {
                    include: 'organization.morphable,file,objectives.type,objectives.reward.type'
                }
            })
                .then(response => response.data.data)
                .catch(() => []);
        },

        async getLoyalcardStatistics(id: number): Promise<LoyaltyCardAnalysisResponse> {
            return hdsClient.get(`loyalty-cards/${id}/healthcenter/analysis`)
                .then(response => response.data.data)
                .catch(() => []);
        },

        /**
         * Get the loyalty card membership analysis.
         */
        async getMemberships(id: number, page = 1, limit = 10): Promise<{ data: LoyaltyCardMembershipItemStatistic[]; meta: MetaData }> {
            const params = {
                page,
                limit
            };

            return hdsClient.get(`loyalty-cards/${id}/memberships/analysis`, {params})
                .then(response => response.data)
                .catch(() => []);
        },

        /**
         * List animation belonging to a loyalty card that were created by the provided healthcenter.
         *
         * @param {number} healthcenterId
         * @param {number} loyaltyCardId
         *
         * @return {Promise}
         */
        animations(healthcenterId: number, loyaltyCardId: number) {
            return client.get(`/healthcenters/${healthcenterId}/loyalty-cards/${loyaltyCardId}/animations`)
                .then(response => response.data.data);
        },

        /**
         * List animation transactions.
         *
         * @param {number} animationId
         *
         * @return {Promise}
         */
        animationTransactions(animationId: number) {
            return hdsClient.get(`loyalty-card-animations/${animationId}/transactions`)
                .then(response => response.data.data);
        }
    };
}
