<template>
  <div class="grid md:grid-cols-2 lg:grid-cols-4 justify-items-center gap-2">
    <div
      v-for="(item, itemIdx) in stats"
      :key="itemIdx"
      class="flex flex-1 flex-col gap-5 p-4 rounded-lg shadow-md w-full"
      :style="{'background-color': item.color ? item.color : 'white'}"
    >
      <ProgramStatisticsCard
        v-if="isStandardCard(item)"
        :data="item"
      />
      <ProgramPieChartStatisticsCard
        v-if="item.type === SupportProgramMarketStatisticType.PIE_CHART"
        :data="item"
      />
      <ProgramBarChartStatisticsCard
        v-if="item.type === SupportProgramMarketStatisticType.BAR_CHART"
        :data="item"
      />
      <ProgramLineChartStatisticsCard
        v-if="item.type ===SupportProgramMarketStatisticType.LINE_CHART"
        :data="item"
      />
      <ProgramBinomialPieChartStatisticsCard
        v-if="item.type === SupportProgramMarketStatisticType.BINOMIAL_PIE_CHART"
        :data="item"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import ProgramStatisticsCard from '@/program/components/program-statistics/ProgramStatisticsCard.vue';
import ProgramLineChartStatisticsCard from '@/program/components/program-statistics/ProgramLineChartStatisticsCard.vue';
import ProgramPieChartStatisticsCard from '@/program/components/program-statistics/ProgramPieChartStatisticsCard.vue';
import ProgramBinomialPieChartStatisticsCard
    from '@/program/components/program-statistics/ProgramBinomialPieChartStatisticsCard.vue';
import {SupportProgramMarketStatistic, SupportProgramMarketStatisticType} from "@/program/interfaces/Program";
import ProgramBarChartStatisticsCard from "@/program/components/program-statistics/ProgramBarChartStatisticsCard.vue";

defineProps<{
    stats: SupportProgramMarketStatistic[]
}>();

/**
 * Check if the item is a standard card
 * @param item - The item to check
 */
const isStandardCard = ((item: SupportProgramMarketStatistic) => {
    return [
        SupportProgramMarketStatisticType.SALE,
        SupportProgramMarketStatisticType.CONSUMER_SALE,
        SupportProgramMarketStatisticType.CONSUMER,
        SupportProgramMarketStatisticType.COVERAGE,
        SupportProgramMarketStatisticType.KPI,
    ].includes(item.type);
});

</script>
