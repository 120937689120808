<script setup lang="ts">
import Icon from "@/core/components/icon/Icon.vue";
import GlowingContainer from "@/core/components/glowing-container/GlowingContainer.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import MarkdownView from "@/core/components/markdown/Markdown.vue";
import {nextTick, onMounted, Ref, ref} from "vue";
import {knowledgeBoxApi} from "@/container";
import {Knowledgeable, KnowledgeableProduct} from "@/knowledge-box/interfaces";
import KnowledgeableChatProducts from "@/knowledge-box/components/KnowledgeableChatProducts.vue";

interface Message {
    sender: "user" | "system";
    text: string;
    finished: boolean;
    products?: KnowledgeableProduct[] | null;
}

const {knowledgeable} = defineProps<{
    knowledgeable: Knowledgeable;
}>();

const messages = ref<Message[]>([]);
const isLoading = ref(false);
const userInput = ref();
const abortRequest = ref<(() => void) | null>(null);
const chatWindow = ref<HTMLElement | null>(null);
const inputRef = ref<HTMLInputElement | null>(null);

onMounted(() => {
    inputRef.value?.focus();
    messages.value.push({
        sender: 'system',
        text: "Bonjour, comment puis-je vous aider ?",
        finished: true
    });
});

/**
 * Handle streaming of message chunks and update messages state.
 */
const handleMessageStream = async (chunk: string, messages: Ref<Message[]>): Promise<void> => {
    if (messages.value[messages.value.length - 1].finished) {
        messages.value.push({
            sender: 'system',
            text: chunk,
            finished: false
        });
    } else {
        messages.value[messages.value.length - 1].text = chunk;
    }

    await scrollToBottom();
};

/**
 * Initialize user message in chat.
 */
const initializeUserMessage = async (currentMessage: string, messages: Ref<Message[]>): Promise<void> => {
    messages.value.push({
        sender: 'user',
        text: currentMessage,
        finished: true
    });

    await scrollToBottom();
};

/**
 * Processes concurrent API requests for products and knowledge box responses.
 */
const processResponses = async (currentMessage: string, knowledgeable: Knowledgeable, messages: Ref<Message[]>) => {
    const [products, knowledgeBoxResponse] = await Promise.all([
        requestProducts(currentMessage).catch(() => null),
        knowledgeBoxApi().streamKnowledgeBox(
            knowledgeable.id,
            (chunk) => handleMessageStream(chunk, messages),
            currentMessage
        )
    ]);

    return {products, knowledgeBoxResponse};
};

/**
 * Finalize message with products and completion status.
 */
const finalizeMessage = async (
    messages: Ref<Message[]>,
    products: KnowledgeableProduct[] | null
): Promise<void> => {
    messages.value[messages.value.length - 1].finished = true;

    if (products) {
        messages.value[messages.value.length - 1].products = products;
    }
    await scrollToBottom();
};

const requestProducts = (prompt: string): Promise<KnowledgeableProduct[]> => {
    if (!knowledgeable.can_query_products) {
        return Promise.resolve([]);
    }

    return knowledgeBoxApi().extractProducts(knowledgeable.id, prompt);
};

/**
 * Handle sending user message and processing responses.
 */
const sendMessage = async (): Promise<void> => {
    if (abortRequest.value) {
        abortRequest.value();
    }

    if (!userInput.value || !knowledgeable || userInput.value.trim() === '') {
        return;
    }

    isLoading.value = true;
    const currentMessage = userInput.value;
    userInput.value = '';

    try {
        await initializeUserMessage(currentMessage, messages);
        const {products, knowledgeBoxResponse} = await processResponses(currentMessage, knowledgeable, messages);

        abortRequest.value = knowledgeBoxResponse.abort;

        await knowledgeBoxResponse.request;

        await finalizeMessage(messages, products);
    } catch (error) {
        //
    } finally {
        isLoading.value = false;
        abortRequest.value = null;
    }
};


const scrollToBottom = async () => {
    await nextTick();
    if (chatWindow.value) {
        chatWindow.value.scrollTop = chatWindow.value.scrollHeight;
    }
};
</script>

<template>
  <div class="message-container bg-gray-100 flex flex-col rounded-md">
    <div class="flex-1 overflow-hidden">
      <div class="h-full flex flex-col pl-6 pt-6 pb-6">
        <div ref="chatWindow" class="overflow-y-auto">
          <div v-for="(message, index) in messages" :key="index" class="mb-4">
            <div
              :class="[
                'max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl rounded-lg p-3',
                message.sender === 'user' ? 'bg-blue-500 ml-auto mr-6' : 'bg-white'
              ]"
            >
              <div v-if="message.sender === 'system'">
                <Icon name="mdi-robot-happy" class="mr-2" color="primary" />
              </div>
              <div>
                <MarkdownView
                  :class="[
                    message.sender === 'user' ? 'text-white' : 'text-gray-800'
                  ]" :inherit-styles="true" :content="message.text"
                />
                <div v-if="message.products?.length" class="w-full">
                  <KnowledgeableChatProducts :products="message.products" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="isLoading" class="flex items-center space-x-1">
            <div class="w-3 h-3 bg-gray-500 rounded-full animate-bounce" />
            <div class="w-3 h-3 bg-gray-500 rounded-full animate-bounce" style="animation-delay: 0.1s" />
            <div class="w-3 h-3 bg-gray-500 rounded-full animate-bounce" style="animation-delay: 0.2s" />
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white border-t border-gray-200 py-2">
      <form class="flex" @submit.prevent="sendMessage">
        <GlowingContainer class="w-full">
          <div class="flex items-center gap-3">
            <input
              ref="inputRef"
              v-model="userInput"
              type="text"
              :placeholder="knowledgeable?.default_prompt ?? 'Entrez votre message...'"
              class="flex-1 rounded-md px-4 py-2 focus:outline-none focus:ring-0"
            >
            <BaseButton v-if="!abortRequest" variant="flat" type="submit">
              Demander
              <Icon name="mdi-creation" color="primary" class="ml-1" />
            </BaseButton>
            <BaseButton v-if="isLoading && abortRequest" variant="flat" @click="abortRequest">
              <Icon name="mdi-stop-circle-outline" color="primary" />
            </BaseButton>
          </div>
        </GlowingContainer>
      </form>
    </div>
  </div>
</template>

<style scoped>
.message-container {
    height: 80vh;
}
</style>
