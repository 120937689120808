import {RouteRecordRaw} from "vue-router";
import KnowledgeableListView from "@/knowledge-box/views/KnowledgeableListView.vue";

const routes: RouteRecordRaw[] = [
    {
        path: '/knowledgeables',
        name: 'knowledgeables',
        component: KnowledgeableListView,
    }
];

export default routes;
