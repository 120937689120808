<script lang="ts" setup>
import logoSanteSecure from '@/assets/icons/logo_sante_secure.png';
import DownloadButton from "@/onboarding/components/download-button/DownloadButton.vue";
import InstallationStepLayout from "@/onboarding/components/installation-process/InstallationStepLayout.vue";
import useSanteSecureState from "@/core/composables/sante-secure/useSanteSecureState";

defineProps<{ lgoName: string; lgoInstall: string; santeSecurePing: boolean; santeSecureVersion?: string }>();

const supportUrl = import.meta.env.VITE_SUPPORT_URL;

const santeSecureState = useSanteSecureState();

const handleDownload = () => {
    window.open(import.meta.env.VITE_APODISSECURE_EXE_URL);
};
</script>

<template>
  <div class="flex flex-col md:flex-row gap-x-4">
    <installation-step-layout class="flex-1 basis-2/3">
      <template #title>
        1. Installer Apodis Secure
      </template>
      <template #subtitle>
        La sécurité des données avant tout !
      </template>
      <template #content>
        <div class="flex">
          <div>
            <p>
              Votre LGO est <strong>{{ lgoName }}</strong>,
            </p>
            <p v-if="['client', 'server'].includes(lgoInstall)">
              Téléchargez et installez Santé Secure sur <strong
                class="uppercase underline"
              >{{ lgoInstall === 'server' ? 'Votre serveur' : 'un seul poste comptoir' }}</strong>.
            </p>
            <p v-if="['server-special'].includes(lgoInstall)">
              Téléchargez et installez Santé Secure sur <strong class="uppercase underline">Votre serveur</strong>.<br>
              Contactez-nous ensuite afin que nous demandions les autorisations nécessaires à votre LGO <br> ->
              <a
                target="_blank"
                :href="supportUrl"
                style="color: blue!important"
              >
                Prendre rendez-vous
              </a>.
            </p>
            <p v-if="lgoInstall === 'support'">
              Contactez-nous pour prendre rendez-vous avec <strong>Marion</strong> qui vous accompagnera ->
              <a
                target="_blank"
                :href="supportUrl"
                style="color: blue!important"
              >
                Prendre rendez-vous
              </a>
            </p>
          </div>
        </div>
      </template>
      <template
        v-if="lgoInstall !== 'support'"
        #download
      >
        <div class="space-y-2">
          <DownloadButton
            title="Apodis Secure"
            :icon="logoSanteSecure"
            @click="handleDownload"
          />
        </div>
      </template>
    </installation-step-layout>
    <div
      v-if="!!santeSecureState.version"
      class="rounded-md lg:max-w-sm xl:max-w-md basis-1/3"
      :class="[
        santeSecureState.syncState ? 'bg-green-100' : 'bg-red-100',
        'p-4 flex flex-col justify-center'
      ]"
    >
      <p><strong>Installation</strong> {{ santeSecureState.syncState ? '✅' : '❌' }}</p>
      <p>Version installée : {{ santeSecureState.version }}</p>
      <p>Dernière transmission : {{ santeSecureState.lastSync }}</p>
    </div>
  </div>
</template>
