<script setup lang="ts">
import BaseInput from "@/core/components/base/BaseInput.vue";

const emits = defineEmits<{
    (e: 'update:modelValue', payload: number): void
}>();

defineProps<{
    modelValue: number;
}>();
</script>

<template>
  <BaseInput
    :value="modelValue" type="number" label="Nombre de produits"
    @input="emits('update:modelValue', $event)"
  />
</template>

<style scoped>

</style>
