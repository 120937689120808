import useSwr from "@/core/composables/swr/useSwr";
import {advantageApi} from "@/container";
import {mutate} from "swrv";

/**
 * Composable to fetch the health center advantages.
 */
const useHealthcenterAdvantages = () => {
    const {data, isLoading} = useSwr(
        "advantages",
        () => advantageApi().healthcenterAdvantages()
    );

    /**
     * Prefetch the advantages.
     */
    const prefetch = () => {
        return mutate("healthcenter/advantages", () => advantageApi().healthcenterAdvantages());
    };

    return {
        data,
        isLoading,
        prefetch
    };
};

export default useHealthcenterAdvantages;
