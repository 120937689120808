<script setup lang="ts">
import ProductImage from "@/product/components/ProductImage.vue";
import BaseCard from "@/core/components/base/BaseCard.vue";
import LaboratoryProductQuantityStepper from "@/laboratory/components/LaboratoryProductQuantityStepper.vue";
import ProductPriceLine from "@/sellin-offer/components/ProductPriceLine.vue";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../tailwind.config";
import {Config} from "tailwindcss";
import {LaboratoryCartLine} from "@/laboratory/interfaces";
import LaboratoryShopPrice from "@/core/interfaces/laboratory/LaboratoryShopPrice";
import {computed, ref} from "vue";

const props = defineProps<{ line: LaboratoryCartLine; color?: string; secondaryColor?: string; available: boolean; }>();
const emits = defineEmits(['on-delete', 'on-update']);

const {theme} = resolveConfig(tailwindConfig as Config);

const quantity = ref(props.line.quantity);

/**
 * Get the label to display depending on the availability.
 *
 * @return {string}
 */
const getAvailabilityLabel = (available?: boolean): string => {
    switch (available) {
        case true:
            return "Disponible";
        case false:
            return "Indisponible";
        default:
            return "Non renseigné";
    }
};

/**
 * Get availability chip color.
 */
const availabilityChipColor = (available?: boolean): string => {
    return available === undefined
        ? 'transparent'
        : available
            ? (theme.colors as any).secondary.DEFAULT
            : (theme.colors as any).red.default;
};

/**
 * Get availability chip border color.
 */
const availabilityBorderColor = (available?: boolean): string => {
    return available === undefined
        ? (theme.colors as any).gray.default
        : available
            ? (theme.colors as any).secondary.DEFAULT
            : (theme.colors as any).red.default;
};

/**
 * Extract the laboratory shop price from cart line object.
 */
const extractLaboratoryShopPriceFromCartLine = (cartLine: LaboratoryCartLine): LaboratoryShopPrice => {
    return {
        minimumQuantity: cartLine.product.laboratoryPriceRanges[0].laboratoryPrice.minimumQuantity || 0,
        packingQuantity: cartLine.product.laboratoryPriceRanges[0].laboratoryPrice.packingQuantity || 0,
        vatId: 0,
        discountPercentage: String(cartLine.discountPercentage),
        priceWithoutVat: String(cartLine.priceWithoutVat),
        priceWithVat: cartLine.priceWithVat,
        priceDiscountedWithoutVat: cartLine.priceDiscountedWithoutVat,
        priceDiscountedWithVat: cartLine.priceDiscountedWithVat,
        totalDiscountedWithoutVat: cartLine.totalDiscountedWithoutVat,
        totalDiscountedWithVat: cartLine.totalDiscountedWithVat,
        vatRate: parseFloat(cartLine.vatRate)
    };
};

/**
 * Add price information alongside product information.
 */
const productWithPrice = computed(() => {
    return {
        ...props.line.product,
        laboratoryShopPrice: extractLaboratoryShopPriceFromCartLine(props.line)
    };
});

/**
 * Update line quantity.
 */
const updateQuantity = (updatedQuantity: number) => {
    quantity.value = updatedQuantity;

    if (updatedQuantity === 0) {
        emits('on-delete', props.line.id);
    } else {
        emits('on-update', updatedQuantity);
    }
};

/**
 * Compute cart price HT.
 */
const priceHT = computed(() => {
    return productWithPrice.value.laboratoryShopPrice.priceDiscountedWithoutVat * quantity.value;
});

/**
 * Compute TVA.
 */
const TVA = computed(() => {
    return (productWithPrice.value.laboratoryShopPrice.priceDiscountedWithVat - productWithPrice.value.laboratoryShopPrice.priceDiscountedWithoutVat) * quantity.value;
});

/**
 * Compute cart price TTC.
 */
const priceTTC = computed(() => {
    return productWithPrice.value.laboratoryShopPrice.priceDiscountedWithVat * quantity.value;
});

</script>

<template>
  <BaseCard class="mx-5">
    <div class="flex flex-row p-4 gap-2">
      <ProductImage :product="line.product" :size="28" />
      <div class="flex flex-1 flex-col">
        <div class="flex flex-row justify-between items-end">
          <div class="flex flex-col">
            <p class="font-semibold">
              {{ line.product.name }}
            </p>
            <p class="text-gray-500">
              CIP {{ line.product.cip13 }}
            </p>
            <p v-if="!line.product?.has_order_quota" class="text-gray-700">
              Produit sous quota
            </p>
            <div class="mt-2">
              <ProductPriceLine :product="productWithPrice" :quantity="1" unit="u" color="black" />
              <div class="flex flex-row border-t pt-2 mt-2 gap-2">
                <p class="pr-2">
                  {{ $filters.currencyze(priceHT) }} HT
                </p>
                <p class="pr-2">
                  TVA {{ productWithPrice.laboratoryShopPrice.vatRate }}% : {{ TVA.toFixed(2) }}
                </p>
                <p class="pr-2 font-semibold">
                  {{ $filters.currencyze(priceTTC) }} TTC
                </p>
              </div>
            </div>
          </div>
          <LaboratoryProductQuantityStepper
            :min-quantity="productWithPrice.laboratoryShopPrice.minimumQuantity"
            :color="color"
            :secondary-color="secondaryColor"
            :packing-quantity="productWithPrice.laboratoryShopPrice.packingQuantity"
            :current="line.quantity"
            @on-update="updateQuantity"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-end bg-gray-200 p-2 px-4">
      <div class="flex flex-row items-center gap-1">
        <div
          class="w-3 h-3 rounded-full border-2 border-gray-400"
          :style="{
              'background-color': availabilityChipColor(line.product.availability?.available),
              'border-color': availabilityBorderColor(line.product.availability?.available)
            }"
        />
        <p>{{ getAvailabilityLabel(line.product.availability?.available) }}</p>
      </div>
    </div>
  </BaseCard>
</template>

<style scoped>

</style>