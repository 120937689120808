<script setup lang="ts">
import {useRoute} from 'vue-router';
import {Laboratory} from "@/core/interfaces/laboratory/Laboratory";
import useNotificationSequence from "@/notification-sequences/composables/useNotificationSequence";
import Icon from "@/core/components/icon/Icon.vue";
import useLaboratoryColor from "@/laboratory/composables/useLaboratoryColor";
import {ref, watch} from "vue";
import {notificationSequenceApi} from "@/container";
import NotificationSequenceTimeline
    from "@/notification-sequences/components/notification-sequence-timeline/NotificationSequenceTimeline.vue";

const props = defineProps<{
    laboratory: Laboratory;
}>();

const route = useRoute();
const sequenceId = route.params.sequenceId as string;

const {data: sequence} = useNotificationSequence(sequenceId);
const {primaryWithOpacity, primaryColor} = useLaboratoryColor({
    primary: props.laboratory.branding?.primary_color
});

const enabled = ref<boolean | undefined>(sequence.value?.is_enabled ?? false);

watch(() => sequence.value, (newValue) => {
    enabled.value = newValue?.is_enabled;
});

/**
 * Toggle the enabled state of the notification sequence
 *
 * @param value
 */
const toggleEnabled = async (value: boolean | null) => {
    if (value === null) {
        return;
    }

    enabled.value = value;
    try {
        await notificationSequenceApi()
            .toggleEnabled(Number(sequenceId), value)
            .then((response) => {
                enabled.value = response.enabled;
            });
    } catch (error) {
        enabled.value = sequence.value?.is_enabled;
    }
};
</script>

<template>
  <div class="p-6">
    <div class="bg-white shadow rounded-lg">
      <div class="flex p-4">
        <div>
          <div class="flex items-center">
            <button class="p-2" @click="$router.back()">
              <Icon name="mdi-arrow-left" :size="20" color="primary" />
            </button>
            <h1>Animez vos patients {{ !!sequence ? `> ${sequence.name}` : '' }}</h1>
          </div>
          <p>{{ sequence?.description }}</p>
        </div>
      </div>
      <div
        class="flex items-center justify-center py-3"
        :style="{
          backgroundColor: primaryWithOpacity
        }"
      >
        <v-switch
          :model-value="enabled"
          :color="primaryColor"
          hide-details
          @update:model-value="toggleEnabled"
        >
          <template #prepend>
            <p class="text-lg">
              Activer la séquence SMS automatique
            </p>
          </template>
        </v-switch>
      </div>
      <div>
        <NotificationSequenceTimeline class="p-4" :sequence="sequence" :color="laboratory?.branding?.primary_color" />
      </div>
    </div>
  </div>
</template>
