<script setup lang="ts">
import {computed, ref, watch} from "vue";
import PatientFilterChip from "@/prescription/components/PatientFilterChip.vue";
import ChildrenRange from "@/core/interfaces/laboratory/ChildrenRange";
import {Skeleton} from "@/core/components/ui/skeleton";
import useLaboratoryBranding from "@/laboratory/composables/useLaboratoryBranding";

const props = defineProps<{
    laboratoryId: string;
    range?: ChildrenRange;
}>();

const emits = defineEmits(['on-range-selected']);

const {data: branding, isLoading} = useLaboratoryBranding(props.laboratoryId);
const selectedRange = ref<ChildrenRange | undefined>(props.range);

const childrenRanges = computed(() => {
    if (branding.value?.parentRanges) {
        return branding.value.parentRanges.flatMap((parent) => parent.childrenRanges);
    }

    return [];
});

/**
 * On range update, emit on range selected event.
 */
watch(() => selectedRange.value, () => {
    emits('on-range-selected', selectedRange.value);
});


</script>

<template>
  <div v-if="isLoading" class="flex flex-row gap-4">
    <Skeleton class="h-8 w-24 rounded-full" />
    <Skeleton class="h-8 w-24 rounded-full" />
    <Skeleton class="h-8 w-24 rounded-full" />
  </div>
  <div v-else-if="childrenRanges?.length">
    <h2 class="pb-2 text-black">
      Gammes
    </h2>
    <div class="flex flex-row flex-wrap gap-4">
      <div class="flex flex-row flex-wrap gap-4">
        <PatientFilterChip
          v-for="childrenRange in childrenRanges"
          :key="childrenRange.id"
          :label="childrenRange.name"
          :selected-color="selectedRange?.branding?.primaryColor"
          :selected="!!selectedRange && childrenRange.id === selectedRange.id"
          @click="selectedRange === childrenRange ? selectedRange = undefined : selectedRange = childrenRange"
        />
      </div>
    </div>
  </div>
</template>
