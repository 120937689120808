<template>
  <div class="flex flex-col flex-1">
    <div class="text-sm">
      {{ data.label }}
    </div>
    <div class="min-h-72">
      <BarChart
        :id="data.label"
        class="flex flex-1 h-96 "
        :data="chartData"
        :options="chartOptions"
      />
    </div>
    <div class="text-sm">
      {{ data.extra }}
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {Bar as BarChart} from 'vue-chartjs';
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Filler,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    ArcElement,
    ChartData
} from 'chart.js';
import ChartService from "@/laboratory/services/ChartService";

ChartJS.register(Title, Tooltip, Legend, Filler, BarElement, CategoryScale, LinearScale, ArcElement);

interface BarChartData {
    /** The label of bar */
    label: string;
    /** The value of the bar */
    value: number;
}

export default defineComponent({
    name: 'ProgramBarChartStatisticsCard',
    components: {BarChart},
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        chartData(): ChartData<"bar", (number | [number, number] | null)[]> {
            const colors = ChartService.colorPalette();
            const datasets = Object.entries(this.data.value as BarChartData[]) || [];
            const firstSet = Object.values(Object.values(this.data.value)[0] as BarChartData[]);

            return {
                labels: firstSet.map((item: BarChartData) => item.label),
                datasets: datasets.map(([key, dataset], index) => {
                    return {
                        label: key,
                        data: Object.values(dataset).map((item) => item.value),
                        backgroundColor: colors[index],
                    };
                }) as []
            };
        },
        chartOptions() {
            return {
                scales: {
                    y: {
                        ticks: {
                            callback: (tickValue: string | number) => {
                                return tickValue + ' ' + this.data.unit;
                            }
                        },
                    },
                    x: {
                        ticks: {
                            autoSkip: false,
                            font: {
                                size: 9,
                            },
                        },
                    },
                },
            };
        },
    },
});
</script>
