import {LegacySellinOffer} from "@/core/interfaces/SellinOffer";
import noProductImage from '@/assets/images/no-product-image.png';
import ImageFile from "@/core/interfaces/laboratory/ImageFile";

export default {
    /**
     * Get the image uri from imagePath (basically append imagePath to API_ROOTURL).
     *
     * @param  {string} imagePath
     *
     * @returns string | undefined
     */
    getURLFromPath(imagePath: string): string | undefined {
        const apiBase = import.meta.env.VITE_API_URL;

        if (apiBase !== undefined) {
            return `${apiBase}/${imagePath}`;
        }

        return undefined;
    },

    /**
     * Get the image uri from a calendar event.
     *
     * @param {Offer} offer
     *
     * @returns string | undefined
     */
    getOfferImage(offer: LegacySellinOffer): string | undefined {
        if (offer.imagePath) {
            return this.getURLFromPath("images/" + offer.imagePath);
        }
    },

    /**
     * Get the product image uri from a LaboratoryProduct or SellinOfferProduct object.
     *
     * @param  {LaboratoryProduct | SellinOfferProduct} product
     *
     * @returns string
     */
    getLaboratoryProductImage(product: { photoPath: string } | undefined): string {
        const path = product?.photoPath;
        const apiBase = import.meta.env.VITE_API_URL;

        if ((path !== undefined && path?.length !== 0) && apiBase !== undefined) {
            return `${apiBase}/images/${path}`;
        }

        return noProductImage;
    },
    /**
     * Get the image uri (with resizing) from a Background object.
     *
     * @param  {ImageFile} image
     * @param  {number | undefined} width
     * @param  {number | undefined} height
     * @param  {"crop" | "stretch" | "fill"} [fit]
     *
     * @returns string | undefined
     */
    getCropedUserImage(image: ImageFile, width: number | undefined, height: number | undefined, fit?: "crop" | "stretch" | "fill"): string | undefined {
        const logoPath = image.path;
        const logoToken = image.token;
        const apiBase = import.meta.env.VITE_API_URL;
        if (logoPath !== undefined && logoToken !== undefined && apiBase !== undefined) {
            let path = `${apiBase}/images/filemanager/${encodeURI(logoPath)}?token=${logoToken}`;
            if (fit !== undefined) {
                path += `&fit=${fit}`;
            }
            return path;
        }
        return undefined;
    }
};
