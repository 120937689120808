<script setup lang="ts">
import useLaboratory from "@/laboratory/composables/useLaboratory";
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import LaboratoryHomeStatisticCard from "@/laboratory/components/LaboratoryHomeStatisticCard.vue";
import {currencyze} from "@/core/filters/Currencyze";
import LaboratoryExtensions from "@/laboratory/components/LaboratoryExtensions.vue";
import {computed} from "vue";
import {useRouter} from "vue-router";
import dayjs from "dayjs";
import BaseButton from "@/core/components/base/BaseButton.vue";
import useLaboratoryStatistics from "@/laboratory/composables/useLaboratoryStatistics";
import LaboratoryHomePatientCard from "@/laboratory/components/LaboratoryHomePatientCard.vue";
import {ProgramType} from "@/program/interfaces/Program";
import KnowledgeableInputButton from "@/knowledge-box/components/KnowledgeableInputButton.vue";
import useLaboratoryColor from "@/laboratory/composables/useLaboratoryColor";

const props = defineProps<{
    laboratoryId: string;
}>();

const router = useRouter();

const {data: laboratory} = useLaboratory(props.laboratoryId);
const {primaryColor, secondaryColor} = useLaboratoryColor({
    primary: laboratory.value?.branding?.primary_color,
    secondary: laboratory.value?.branding?.secondary_color
});
const {data: statistics} = useLaboratoryStatistics(props.laboratoryId);


const redirectToAnimation = () => {
    router.push({
        name: 'laboratory.animation',
        params: {laboratoryId: props.laboratoryId}
    });
};

/**
 * Get all the extensions other than loyalty cards.
 */
const extensionsOtherThanLoyaltyCards = computed(() => {
    if (!laboratory.value?.extensions) {
        return [];
    }

    return laboratory.value.extensions.filter((extension) => {
        if (!('sub_type' in extension)) {
            return true;
        }

        return extension.sub_type !== ProgramType.LOYALTY_CARD;
    });
});
</script>

<template>
  <BaseContainer class="p-6 flex flex-col gap-6">
    <KnowledgeableInputButton
      v-if="laboratory?.knowledgeables?.length"
      :knowledgeable="laboratory.knowledgeables[0]"
      :colors="laboratory?.branding?.primary_color ? [primaryColor, '#FFFFFF'] : undefined"
      :placeholder="'Posez une question à l\'IA ' + laboratory?.name"
    />
    <div class="flex flex-col lg:flex-row gap-x-16 gap-y-6 items-start">
      <div class="w-full lg:w-auto flex flex-col md:flex-row lg:flex-col gap-6">
        <LaboratoryHomeStatisticCard
          class="flex-1 cursor-pointer"
          :value="(statistics?.consumer_count ?? 0).toString()"
          :label="`Patient${(statistics?.consumer_count ?? 0) > 1 ? 's' : ''}`"
          :sub-label="`${statistics?.today_consumer_count ?? 0} ont acheté aujourd'hui`"
          :color="primaryColor ?? ''"
          @click="router.push({name: 'laboratory.consumers'})"
        />
        <LaboratoryHomeStatisticCard
          class="flex-1 cursor-pointer"
          :value="currencyze(statistics?.total_revenue ?? 0, 0)"
          label="Cette année"
          :sub-label="`Meilleur vendeur : ${statistics?.top_operator?.name ?? statistics?.top_operator?.code ?? 'Aucun'}`"
          :color="primaryColor ?? ''"
          @click="router.push({
            name: 'laboratory.sales',
            query: {since: dayjs().subtract(1, 'year').format('YYYY-MM-DD')}
          })"
        />
      </div>
      <div class="flex-1 space-y-6">
        <div class="bg-white rounded-md p-4">
          <h1 class="text-black">
            Animez vos patients
          </h1>
          <div class="flex items-center gap-4">
            <p class="flex-1">
              Fidélisez-les en leur proposant l'app Apodis pour retrouver tout l'espace {{ laboratory?.name }} et plus
              encore&nbsp;! <br> {{ statistics?.today_consumer_count }} {{
                $filters.pluralize("patient", statistics?.today_consumer_count ?? 0)
              }} ont acheté un produit {{ laboratory?.name }} aujourd'hui.
            </p>
            <BaseButton
              append-icon="mdi-send" :color="primaryColor" :style="[
                'color: ' + (secondaryColor ?? '')
              ]" @click="redirectToAnimation"
            >
              Envoyer un SMS
            </BaseButton>
          </div>
        </div>
        <LaboratoryExtensions
          v-if="extensionsOtherThanLoyaltyCards.length"
          class="flex-1"
          :extensions="extensionsOtherThanLoyaltyCards"
        />
      </div>
    </div>
    <LaboratoryHomePatientCard :laboratory-id="laboratoryId" />
  </BaseContainer>
</template>

<style scoped>

</style>
