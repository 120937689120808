<script setup lang="ts">
import {LoyaltyCardMembership} from "@/loyalty-card/interfaces/hds/LoyaltyCard";
import Credit from '@/customer/components/customer-loyalty-membership/CustomerLoyaltyMembershipCredit.vue';
import Product from '@/customer/components/customer-loyalty-membership/CustomerLoyaltyMembershipProduct.vue';
import Discount from '@/customer/components/customer-loyalty-membership/CustomerLoyaltyMembershipDiscount.vue';

defineEmits(['refresh']);
defineProps<{
    membership: LoyaltyCardMembership;
    customerId: number;
}>();
</script>

<template>
  <div class="flex-shrink-0 flex space-x-2">
    <Discount
      v-if="membership.loyalty_card.point_rewards_count"
      :membership="membership"
      @attributed="$emit('refresh')"
    />

    <Product
      v-if="membership.loyalty_card.product_rewards_count"
      :membership="membership"
      :customer-id="customerId"
      @attributed="$emit('refresh')"
    />

    <Credit
      :membership="membership"
      :customer-id="customerId"
      :rate="membership.loyalty_card.point?.rate || 0"
      @credited="$emit('refresh')"
    />
  </div>
</template>
