<script setup lang="ts">
import {BackingStep} from "@/backing/interfaces/backing";
import Icon from "@/core/components/icon/Icon.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";

defineProps<{
    /** The step */
    step: BackingStep
}>();

</script>

<template>
  <div class="py-2 px-4 flex flex-row justify-between items-center h-min cursor-pointer hover:opacity-75">
    <div class="min-w-28">
      <icon :size="20" name="mdi-chat-outline" color="primary" class="mr-2" />
      <span class="">{{ step.name }}</span>
    </div>
    <BaseButton primary append-icon="mdi-chevron-right">Commencer</BaseButton>
  </div>
</template>
