<template>
  <div class="flex flex-col gap-5">
    <div class="gradient px-6 py-4">
      <div class="flex flex-row justify-space-between">
        <div class="flex flex-col text-white">
          <h2 class="text-white">
            Simplifiez la gestion des renouvellements d’ordonnance
          </h2>
          <p>
            Planifiez-les en quelques clics, envoyez des rappels automatiques par SMS et suivez facilement vos patients
          </p>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-between">
      <h1 class="ml-6 text-2xl">
        Gérez vos renouvellements
      </h1>
      <div class="flex flex-1 flex-row gap-x-2 mr-5 w-64 justify-end">
        <OngoingRenewalPlanButton :count="meta?.renewal_plan_consumer_count" :unique-consumers="true" />
      </div>
    </div>
    <PatientFilters v-if="meta" v-model="selectedTags" :tags="meta.tags" class="px-6 py-2" />
    <base-flex-spinner v-if="isLoading" />
    <div v-else class="px-6 flex flex-col gap-3">
      <div class="flex justify-between items-center">
        <h2>Ordonnances</h2>
        <BaseSelect
          v-model:modelValue="planFilter"
          default-value="to-renew"
          trigger-class="w-58"
          placeholder="Toutes les ordonnances"
          :options="[
            {value: 'all', label: 'Toutes les ordonnances'},
            {value: 'to-renew', label: 'Ordonnances à renouveler'},
            {value: 'with-plan', label: 'Ordonnances avec plan'},
            {value: 'non-renewable', label: 'Ordonnances non renouvelables'},
          ]"
        />
      </div>
      <prescription-list :prescriptions="prescriptions" />
      <div class="flex justify-center">
        <base-button
          v-if="!!meta?.after_key && !endReached"
          class="font-medium"
          :disabled="isLoadingMore"
          @click="loadMore"
        >
          <base-spinner v-if="isLoadingMore" size="button" />
          <span v-else>Voir plus</span>
        </base-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {prescriptionApi} from '@/container';
import PrescriptionList from '@/prescription/components/PrescriptionList.vue';
import pharmacistRenewal from '@/assets/images/pharmacist-renewal.png';
import {Prescription, PrescriptionIndexParams, Tag} from "@/prescription/interfaces/Prescription";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import BaseSpinner from "@/core/components/base/spinner/BaseSpinner.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import PatientFilters, {PatientFiltersProps} from "@/prescription/components/PatientFilters.vue";
import BaseSelect from "@/core/components/base/BaseSelect.vue";
import OngoingRenewalPlanButton from "@/prescription/components/OngoingRenewalPlanButton.vue";

export default defineComponent({
    name: 'PrescriptionRenewalScreen',
    components: {
        OngoingRenewalPlanButton,
        BaseSelect,
        PatientFilters: PatientFilters,
        BaseButton, BaseSpinner, PrescriptionList, BaseFlexSpinner
    },
    data: () => {
        return {
            page: 1,
            isLoading: false,
            selectedTags: {
                tags: [],
                certified: null,
                children: null,
                tags_operator: 'AND',
                purchase_type: null,
                purchase_id: null,
                gist: null,
            } as PatientFiltersProps,
            meta: null as {
                after_key: string | null,
                total: number, tags: Tag[],
                renewal_plan_consumer_count?: number
            } | null,
            prescriptions: [] as Prescription[],
            pharmacistRenewal,
            isLoadingMore: false,
            endReached: false,
            planFilter: 'to-renew' as 'without-plan' | 'with-plan' | 'non-renewable' | 'all' | 'to-renew',
            age_from: null as number | null,
            age_to: null as number | null
        };
    },
    watch: {
        selectedTags: {
            handler() {
                this.refresh();
            },
            deep: true
        },
        planFilter: {
            handler() {
                this.refresh();
            },
            deep: true
        }
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.isLoading = true;
            this.prescriptions = [];
            this.isLoadingMore = false;
            this.endReached = false;
            if (this.meta) {
                this.meta.after_key = null;
            }
            this.fetch().finally(() => this.isLoading = false);
        },
        async fetch() {
            const params: PrescriptionIndexParams = {
                with_renewal_plan_count: true,
                limit: 20,
                tags: this.selectedTags.tags,
                tags_operator: 'AND',
                after: this.meta?.after_key ? JSON.stringify(this.meta.after_key) : '',
                ...this.selectedTags.children && {age_to: '19 years'},
                ...this.selectedTags.certified && {certified: 1},
                ...this.selectedTags.purchase_type && {purchase_type: this.selectedTags.purchase_type},
                ...this.selectedTags.purchase_id && {purchase_id: this.selectedTags.purchase_id},
                ...this.selectedTags.gist && {gist: 1}
            };

            switch (this.planFilter) {
            case 'with-plan':
                params.renewal_plan = 'any';
                break;
            case 'without-plan':
                params.renewal_plan = 'none';
                break;
            case 'non-renewable':
                params.renewable = false;
                break;
            case 'to-renew':
                params.renewable = true;
                params.renewal_plan = 'none';
                break;
            }

            return await prescriptionApi()
                .index(params)
                .then(response => {
                    this.prescriptions = [...this.prescriptions, ...response.data];
                    this.endReached = this.meta?.after_key === response.meta.after_key;
                    this.meta = response.meta;
                });
        },
        loadMore() {
            this.isLoadingMore = true;
            this.fetch().finally(() => this.isLoadingMore = false);
        }
    }
});
</script>
