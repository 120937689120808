import {defineStore} from "pinia";
import Extension, {ExtensionIndexParams, ExtensionType, ProgramExtension} from "@/core/interfaces/extension/Extension";
import {extensionApi} from "@/container";

interface ExtensionStore {
    /** List of extensions */
    extensions: Extension[] | null;
    /** Loading state */
    loading: boolean;
    /** Refreshing state */
    refreshing: boolean;
}

export const useExtensionStore = defineStore('extension', {
    state: (): ExtensionStore => ({
        extensions: null,
        loading: false,
        refreshing: false
    }),

    actions: {
        /**
         * Load the extensions from the API.
         */
        async loadExtensions() {
            this.loading = true;
            try {
                this.extensions = await extensionApi()
                    .index({types: [ExtensionType.PROGRAM_BUNDLE, ExtensionType.PACK]});
            } finally {
                this.loading = false;
            }
        },
        /**
         * Refresh the extensions from the API.
         */
        async refreshExtensions(params: ExtensionIndexParams = {}) {
            this.refreshing = true;
            this.extensions = await extensionApi()
                .index({types: [ExtensionType.PROGRAM_BUNDLE, ExtensionType.PACK], ...params});
            this.refreshing = false;
        }
    },

    getters: {
        /** Returns the enabled extensions */
        enabledExtensions(state): Extension[] {
            return state.extensions?.filter(extension => extension.enabled) || [];
        },
        /** Returns the enabled program extensions */
        enabledProgramExtensions(): ProgramExtension[] {
            return this.enabledExtensions.filter(extension => {
                return extension.extendable_type === ExtensionType.PROGRAM_BUNDLE;
            }) as ProgramExtension[];
        },
        /**
         * Return the program extensions.
         */
        programExtensions(): ProgramExtension[] {
            return (this.extensions?.filter(extension => {
                return extension.extendable_type === ExtensionType.PROGRAM_BUNDLE;
            }) ?? []) as ProgramExtension[];
        },

        /** Returns the extension by slug. */
        extensionBySlug(state): (slug: string) => Extension | undefined {
            return (slug: string): Extension | undefined => {
                return state.extensions?.find(extension => extension.slug === slug);
            };
        },
        /**
         * Get the enabled extensions by subscription plan.
         */
        enabledExtensionsBySubscriptionPlan(): (subscriptionPlanId: number) => Extension[] | undefined {
            return (subscriptionPlanId: number): Extension[] | undefined => {
                return this.enabledExtensions.filter(extension => {
                    return extension.subscription_plan_id === subscriptionPlanId;
                });
            };
        }
    }
});
