<template>
  <div class="flex gap-4">
    <img
      v-if="loyaltyCard.file"
      :src="loyaltyCard.file?.url"
      class="self-start"
      alt="Loyalty card"
      style="aspect-ratio: 1.5; width: 45%; object-fit: cover; border-radius: 10px;"
    >
    <div class="flex flex-col justify-between w-full gap-2">
      <label class="text-sm line-clamp-2">{{ loyaltyCard.name }}</label>
      <base-button
        v-if="!membership"
        :primary="true"
        style="padding: 5px 8px; min-width: 100%"
        @click.stop="attach"
      >
        <p class="text-sm font-semibold">
          Activer
        </p>
      </base-button>
      <div v-else>
        <loyalty-card-membership-point-balance
          class="text-sm"
          :formatted-point-balance="membership.formatted.point_balance"
          :point-balance="membership.point_balance"
          :rate="loyaltyCard.point?.rate"
        />
        <loyalty-card-membership-product-balance
          v-if="LoyaltyCardService().hasProductRewards(loyaltyCard)"
          class="text-sm"
          :product-balance="membership.unit_balance"
          :formatted-product-balance="membership.formatted.unit_balance"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import BaseButton from '@/core/components/base/BaseButton.vue';
import LoyaltyCardMembershipPointBalance
    from '@/loyalty-card-membership/components/loyalty-card-membership-balances/LoyaltyCardMembershipPointBalance.vue';
import LoyaltyCardService from "@/loyalty-card/services/LoyaltyCardService";
import LoyaltyCardMembershipProductBalance
    from "@/loyalty-card-membership/components/loyalty-card-membership-balances/LoyaltyCardMembershipProductBalance.vue";
import {DetectionLoyaltyCard} from "@/notification/interfaces";

export default defineComponent({
    name: 'NotificationDetectionLoyaltyCardItem',
    components: {LoyaltyCardMembershipProductBalance, LoyaltyCardMembershipPointBalance, BaseButton},
    props: {
        /**
         * The loyalty card item
         *
         * @type {Object}
         */
        loyaltyCard: {
            type: Object as PropType<DetectionLoyaltyCard>,
            required: true
        }
    },
    emits: ['link-membership'],
    computed: {
        /**
         * Determine if the loyalty card has a membership.
         *
         * @return {Object|null}
         */
        membership() {
            return this.loyaltyCard.membership;
        }
    },
    methods: {
        LoyaltyCardService,
        /**
         * Forward the link membership event to parent component.
         *
         * @return {*}
         */
        attach() {
            return this.$emit('link-membership', this.loyaltyCard);
        }
    }
});
</script>
