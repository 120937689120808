<template>
  <div>
    <VAutocomplete
      class="shadow-none product-search-bar"
      :items="products"
      item-title="name"
      item-value="id"
      menu-icon=""
      :loading="isLoading"
      :disabled="disabled"
      :variant="variant"
      :density="density"
      color="primary"
      :placeholder="placeholder"
      no-data-text="Aucun produit trouvé"
      solo
      no-filter
      hide-no-data
      hide-details
      persistent-placeholder
      return-object
      @update:search="search = $event"
      @update:model-value="emitSelection($event)"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {productApi} from '@/container';
import {LegacyProduct} from "@/core/interfaces/Product";

export default defineComponent({
    name: 'BaseProductSearchBar',
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: "Rechercher un produit"
        },
        variant: {
            type: String as PropType<"solo" | "outlined" | "plain" | "underlined" | "filled" | "solo-inverted" | "solo-filled" | undefined>,
            default: "solo"
        },
        density: {
            type: String as PropType<'default' | 'comfortable' | 'compact' | undefined>,
            default: "comfortable"
        }
    },
    emits: {
        select: (product: LegacyProduct) => {
            return !!product;
        }
    },
    data() {
        return {
            search: undefined as string | undefined,
            select: null as null | LegacyProduct,
            products: [] as LegacyProduct[],
            localLoading: false
        };
    },
    computed: {
        isLoading(): boolean {
            return this.loading || this.localLoading;
        }
    },
    watch: {
    /**
     * Search for a product
     *
     * @param value - The search query value
     */
        search(value: string | undefined) {
            if (this.localLoading) {
                return;
            }

            if (!value || value === this.select?.name) {
                return;
            }

            this.localLoading = true;
            this.searchProduct(value)
                .then((products) => {
                    this.products = products;
                })
                .finally(() => {
                    this.localLoading = false;
                });
        }
    },
    methods: {
    /**
     * Search for a product
     *
     * @param {String} searchQuery
     */
        searchProduct(searchQuery: string) {
            return productApi()
                .search(searchQuery);
        },
        /**
     * Emit the selected product to parent component.
     */
        emitSelection(selection: LegacyProduct | null) {
            if (!selection) {
                return;
            }

            this.$emit('select', selection);
        }
    }
});
</script>

<style>
.product-search-bar.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(0);
}
</style>
