<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="28.89" stroke="currentColor" height="28" viewBox="0 0 28.89 28">
    <g id="Groupe_3590" transform="translate(-75 -492)">
      <text id="IA" transform="translate(75 513)" font-size="20" font-family="Poppins-Bold, Poppins" font-weight="700"><tspan x="0" y="0">IA</tspan></text>
      <g id="Groupe_3586" transform="translate(86.332 486.002)">
        <path id="Icon_awesome-magic" d="M19.22,10.675,21,7.117l3.558-1.779L21,3.558,19.22,0,17.441,3.558,13.883,5.337l3.558,1.779Z" transform="translate(-7 7)" />
      </g>
    </g>
  </svg>
</template>
