
<template>
  <button
    class="flex flex-row bg-primary text-white rounded-md gap-4 h-16 px-2 lg:px-4 items-center"
    @click="$emit('click')"
  >
    <img
      alt="download"
      class="w-8 h-8"
      :src="pictoDownload"
    >
    <div class="flex flex-col">
      <div class="text-sm font-bold">
        Télécharger
      </div>
      <div class="text-base font-extrabold">
        {{ title }}
      </div>
    </div>
    <img
      alt="download"
      class="w-10 h-10 self-center"
      :src="icon"
    >
  </button>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import pictoDownload from '@/assets/icons/picto-download.svg';

export default defineComponent({
    name: 'DownloadButton',

    props: {
        title: {
            type: String,
            default: null,
            required: false
        },
        icon: {
            type: String,
            default: null,
            required: false
        }
    },
    emits: ['click'],
    data() {
        return {
            pictoDownload
        };
    }
});
</script>
