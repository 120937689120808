<template>
  <div class="flex flex-1 flex-row flex-wrap gap-x-3 gap-y-3">
    <PatientFilterChip
      v-for="tag in props.tags"
      :key="tag.slug"
      :selected="isTagSelected(tag)"
      :label="tag.label"
      @click="toggleTags(tag.slug)"
    />
    <PatientFilterChip
      key="gist"
      :selected="!!selectedTags.gist"
      label="GIST"
      @click="toggleGist"
    />
    <PatientFilterChip
      key="certified"
      :selected="!!selectedTags.certified"
      label="Certifié"
      icon="mdi-check-circle"
      @click="toggleCertified"
    />
    <PatientFilterChip
      key="children"
      :selected="!!selectedTags.children"
      label="Enfants"
      icon="mdi-car-child-seat"
      @click="toggleChildren"
    />
    <PatientPurchaseFilter :selected="!!selectedTags.purchase_id" @selected="togglePurchase" />
  </div>
</template>

<script lang="ts" setup>
import {Tag} from "@/prescription/interfaces/Prescription";
import {PropType} from "vue";
import PatientFilterChip from "@/prescription/components/PatientFilterChip.vue";
import PatientPurchaseFilter from "@/prescription/components/PatientPurchaseFilter.vue";

const props = defineProps({
    tags: {
        type: Array as PropType<Tag[]>,
        required: true
    }
});

export interface PatientFiltersProps {
    tags: string[];
    certified: 1 | 0 | null;
    children: boolean | null;
    tags_operator: 'AND' | 'OR' | null;
    purchase_type: string | null,
    purchase_id: number | null,
    gist: 1 | 0 | null
}

/**
 * The bound model.
 */
const selectedTags = defineModel<PatientFiltersProps>({default: {meta: [], certified: false}});

/**
 * Check selected status.
 * @param tag  The tag to check.
 */
const isTagSelected = (tag: Tag) => {
    return selectedTags.value.tags.includes(tag.slug);
};

/**
 * Toggle Tags.
 * @param tag
 */
const toggleTags = (tag: string) => {
    const index = selectedTags.value.tags.indexOf(tag);

    if (index === -1) {
        selectedTags.value.tags.push(tag);
    } else {
        selectedTags.value.tags.splice(index, 1);
    }

    if (selectedTags.value.tags.length > 1) {
        selectedTags.value.tags_operator = 'AND';
    } else {
        selectedTags.value.tags_operator = null;
    }
};

/**
 * Toggle the certified tag.
 */
const toggleCertified = () => {
    selectedTags.value.certified = selectedTags.value.certified ? null : 1;
};

/**
 * Toggle the gist tag.
 */
const toggleGist = () => {
    selectedTags.value.gist = selectedTags.value.gist ? null : 1;
};

/**
 * Toggle the certified tag.
 */
const toggleChildren = () => {
    selectedTags.value.children = selectedTags.value.children ? null : true;
};

/**
 * On purchase selected.
 * @param type
 * @param id
 */
const togglePurchase = (type: 'product' | 'laboratory' | 'range' | null, id: number | null) => {
    if (selectedTags.value.purchase_type || selectedTags.value.purchase_id) {
        selectedTags.value.purchase_type = null;
        selectedTags.value.purchase_id = null;

        return;
    }

    selectedTags.value.purchase_type = type;
    selectedTags.value.purchase_id = id;
};

</script>
