import {createWebHashHistory, createRouter, RouteRecordRaw, createWebHistory} from 'vue-router';
import LoginView from "../login/views/LoginView.vue";
import LoginAsView from "../login/views/LoginAsView.vue";
import NotificationHistory from "../notification/views/NotificationHistory.vue";
import Tutorial from "../home/views/Tutorial.vue";
import PatchNotesScreen from "../home/views/PatchNotesScreen.vue";
import Support from "../home/views/Support.vue";
import HomeScreen from "../home/views/HomeScreen.vue";
import Customer from "../customer/views/Customer.vue";
import CustomerHome from "../customer/components/customer-home/CustomerHome.vue";
import BeneficiaryHome from "../customer/components/customer-beneficiary/BeneficiaryHome.vue";
import CustomerPrescriptions from "../customer/components/customer-prescriptions/CustomerPrescriptions.vue";
import CustomerLoyalty from "../customer/views/CustomerLoyalty.vue";
import LoyaltyCardMembershipScreen from "../loyalty-card-membership/views/LoyaltyCardMembershipScreen.vue";
import CustomerSurveys from "../customer/components/customer-surveys/CustomerSurveys.vue";
import CustomerCertificationOnboarding from "../customer-certification/views/CustomerCertificationOnboarding.vue";
import CustomerSupportProgram from "../customer/views/CustomerSupportProgram.vue";
import CustomerClickCollect from "../customer/components/customer-click-collect/CustomerClickCollect.vue";
import CustomerHospitalFiles from "../customer/components/customer-files/CustomerHospitalFiles.vue";
import OnboardingView from "@/onboarding/views/OnboardingView.vue";
import electronHelper from "@/core/services/electronHelper";
import Register from "@/register/views/Register.vue";
import ProgramDetails from "@/program/views/ProgramDetails.vue";
import ProgramDetailContent from "@/program/views/ProgramDetailContent.vue";
import ProgramConsumers from "@/program/components/program-consumers/ProgramConsumers.vue";
import SupportProgramPathwayDetail from "@/program/views/SupportProgramPathwayDetail.vue";
import LoyaltyCardAnimations from "@/loyalty-card/views/LoyaltyCardAnimations.vue";
import LoyaltyCardAnimationTransactions from "@/loyalty-card/views/LoyaltyCardAnimationTransactions.vue";
import ProgramDetailAttachments from "@/program/views/ProgramDetailAttachments.vue";
import SurveyBuilt from "@/survey/views/SurveyBuilt.vue";
import NotificationSettings from "@/settings/screens/NotificationSettings.vue";
import Notification from "@/notification/views/Notification.vue";
import CustomerInterviews from "@/interview/views/CustomerInterviews.vue";
import CustomerFeatureProgram from "@/customer/views/CustomerFeatureProgram.vue";
import ProgramBundleDetails from "@/program/views/ProgramBundleDetails.vue";
import ProgramBundleProgramCategoryDetails from "@/program/views/ProgramBundleProgramCategoryDetails.vue";
import ProgramBundleProgramCategoryResource from "@/program/views/ProgramBundleProgramCategoryResource.vue";
import ProgramBundleProgramCategoryPrograms from "@/program/views/ProgramBundleProgramCategoryPrograms.vue";
import ProgramBundleProgramCategoryConsumers from "@/program/views/ProgramBundleProgramCategoryConsumers.vue";
import disruptionLogisticsRouter from "@/disruption-logistics/disruptionLogisticsRouter";
import sponsorshipRouter from "@/sponsorship/sponsorshipRouter";
import LinkCPS from "@/onboarding/views/LinkCPS.vue";
import HealthcenterView from "@/healthcenter/views/HealthcenterView.vue";
import subscriptionPlanRouter from "@/subscription-plan/subscriptionPlanRouter";
import stripeRouter from "@/stripe/stripeRouter";
import interviewRouter from "@/interview/interviewRouter";
import extensionStoreRouter from "@/extension/extensionRouter";
import HospitalView from "@/hospital/views/HospitalView.vue";
import ApplicationLoadingView from "@/core/views/ApplicationLoadingView.vue";
import PrescriptionRenewalPlanEditScreen from "@/prescription/views/PrescriptionRenewalPlanEditScreen.vue";
import PrescriptionRenewalPlanShowScreen from "@/prescription/views/PrescriptionRenewalPlanShowScreen.vue";
import ChangeForgotPassword from "@/login/views/ChangeForgotPassword.vue";
import NotFoundView from "@/core/views/NotFoundView.vue";
import BillingView from "@/billing/views/BillingView.vue";
import RegisterGuest from "@/register/views/RegisterGuest.vue";
import laboratoryRouter from "@/laboratory/laboratoryRouter";
import knowledgeBoxRouter from "@/knowledge-box/knowledgeBoxRouter";
import PrescriptionAnalysisScreen from "@/prescription/views/PrescriptionAnalysisScreen.vue";
import ContractView from "@/contract/views/ContractView.vue";
import patientAdministrationRouter from "@/patient-administration/patientAdministrationRouter";

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'onboarding',
        component: OnboardingView,

        meta: {
            layout: 'TheWebsiteLayout'
        }
    },
    {
        path: '/home',
        name: 'home',
        component: HomeScreen,
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {
            layout: 'TheAuthenticationLayout'
        }
    },

    {
        path: '/login/as',
        name: 'login.as',
        component: LoginAsView
    },

    {
        path: '/register',
        name: 'register',
        component: Register,

        meta: {
            layout: 'TheAuthenticationLayout'
        }
    },
    {
        path: '/register/:hash',
        name: 'register.guest',
        component: RegisterGuest,

        meta: {
            layout: 'TheAuthenticationLayout'
        }
    },
    {
        path: '/linkcps',
        name: 'linkcps',
        component: LinkCPS,

        meta: {
            layout: 'TheAuthenticationLayout'
        }
    },

    {
        path: '/app-loading',
        name: 'app.loading',
        component: ApplicationLoadingView,
        meta: {
            layout: 'TheBlankLayout'
        }
    },

    {
        path: '/change-forgot-password/:code',
        name: 'change-forgot-password',
        component: ChangeForgotPassword,

        meta: {
            layout: 'TheAuthenticationLayout'
        }
    },

    {
        path: '/patch-notes',
        name: 'patch-notes',
        component: PatchNotesScreen
    },

    {
        path: '/notification',
        name: 'notification',
        component: Notification,

        meta: {
            title: 'Notifications',
            layout: 'TheNotificationLayout'
        }
    },
    {
        path: '/notification/preference',
        name: 'notification.preference',
        component: NotificationSettings,

        meta: {
            title: 'Paramètres de notification'
        }
    },
    {
        path: '/notification/history',
        name: 'notification.history',
        component: NotificationHistory,

        meta: {
            title: 'Notifications'
        }
    },
    {
        path: '/tutorial',
        name: 'tutorial',
        component: Tutorial,

        meta: {
            title: 'Tutoriels'
        }
    },
    {
        path: '/billing',
        name: 'billing',
        component: BillingView,

        meta: {
            title: 'Facturation'
        }
    },

    {
        path: '/healthcenter',
        name: 'healthcenter',
        component: HealthcenterView,

        meta: {
            title: 'Ma pharmacie'
        }
    },
    {
        path: '/hospital',
        name: 'hospital',
        component: HospitalView,

        meta: {
            title: 'Lien ville-hôpital'
        }
    },
    {
        path: '/support',
        name: 'support',
        component: Support,

        meta: {
            title: 'Support'
        }
    },
    {
        path: '/cgu',
        name: 'cgu',
        component: ContractView,

        meta: {
            title: 'CGU'
        }
    },
    {
        path: '/customer/:customerId',
        name: 'customer',
        component: Customer,

        children: [
            {
                path: 'home',
                name: 'customer.home',
                component: CustomerHome
            },
            {
                path: 'beneficiary/:id',
                name: 'customer.beneficiary',
                component: BeneficiaryHome
            },
            {
                name: 'customer.certification',
                path: 'certification',
                component: CustomerCertificationOnboarding
            },
            {
                path: 'prescriptions',
                name: 'customer.prescriptions',
                component: CustomerPrescriptions
            },
            {
                path: 'prescriptions/:prescriptionId',
                name: 'customer.prescriptions.show',
                children: [
                    {
                        path: 'control',
                        name: 'customer.prescriptions.control',
                        component: PrescriptionAnalysisScreen
                    },
                    {
                        path: 'renewal',
                        name: 'customer.prescriptions.renewal.show',
                        component: PrescriptionRenewalPlanShowScreen
                    },
                    {
                        path: 'renewals/:renewalId',
                        name: 'customer.prescriptions.renewal.edit',
                        component: PrescriptionRenewalPlanEditScreen
                    },
                ]
            },
            {
                path: 'loyalty',
                name: 'customer.loyalty',
                component: CustomerLoyalty
            },
            {
                path: 'click-collect',
                name: 'customer.click-collect',
                component: CustomerClickCollect
            },
            {
                path: 'files',
                name: 'customer.hospital-file.list',
                component: CustomerHospitalFiles
            },
            {
                path: 'loyalty/membership/:membershipId',
                name: 'customer.loyalty.membership',
                component: LoyaltyCardMembershipScreen
            },
            {
                path: 'survey-instances/:instanceId',
                name: 'customer.survey',
                component: SurveyBuilt
            },
            {
                path: 'survey-instances',
                name: 'customer.survey.list',
                component: CustomerSurveys
            },
            {
                path: 'consumer/:consumerKey/programs/:programId/support-program',
                name: 'consumer.support-program',
                component: CustomerSupportProgram
            },
            {
                path: 'feature-programs/:programId?/:consumerKey?',
                name: 'customer.feature-program',
                component: CustomerFeatureProgram
            },
            {
                path: 'interviews',
                name: 'customer.interviews',
                component: CustomerInterviews
            },
        ],
    },
    {
        path: '/program-bundle/:programBundleId',
        name: 'program-bundle.detail',
        component: ProgramBundleDetails
    },
    {
        path: '/program-bundle/:programBundleId/category/:programCategoryId',
        name: 'program-bundle.detail.category',
        component: ProgramBundleProgramCategoryDetails,
        children: [
            {
                path: 'attachments',
                name: 'program-bundle.detail.category.attachments',
                component: ProgramBundleProgramCategoryResource
            },
            {
                path: 'programs',
                name: 'program-bundle.detail.category.programs',
                component: ProgramBundleProgramCategoryPrograms
            },
            {
                path: 'consumers',
                name: 'program-bundle.detail.category.consumers',
                component: ProgramBundleProgramCategoryConsumers
            }
        ]
    },
    {
        path: '/program/:programId',
        name: 'program.detail',
        component: ProgramDetails,
        children: [
            {
                path: '/program/:programId/content',
                name: 'program.detail.content',
                component: ProgramDetailContent,
                props: true
            },
            {
                path: '/program/:programId/patients',
                name: 'program.detail.consumers',
                component: ProgramConsumers,
                props: true
            },
            {
                path: '/program/:programId/attachments',
                name: 'program.detail.attachments',
                component: ProgramDetailAttachments,
                props: true
            },
            {
                path: '/program/:programId/pathway',
                name: 'program.detail.pathway',
                component: SupportProgramPathwayDetail,
                props: true
            },
            {
                path: '/program/:programId/animations',
                name: 'program.detail.animations',
                component: LoyaltyCardAnimations,
                props: true
            },
            {
                path: '/program/:programId/animations/:animationId/transactions',
                name: 'program.detail.animations.transactions',
                component: LoyaltyCardAnimationTransactions,
                props: true
            }
        ]
    },
    ...disruptionLogisticsRouter,
    ...sponsorshipRouter,
    ...subscriptionPlanRouter,
    ...stripeRouter,
    ...extensionStoreRouter,
    ...interviewRouter,
    ...laboratoryRouter,
    ...knowledgeBoxRouter,
    ...patientAdministrationRouter,
];

const router = createRouter({
    history: electronHelper.isElectron() ? createWebHashHistory() : createWebHistory(),
    routes: [
        ...routes,
        {
            path: '/:pathMatch(.*)',
            component: NotFoundView,
            meta: {
                layout: electronHelper.isElectron() ? 'TheApplicationLayout' : 'TheBlankLayout'
            }
        }
    ]
});

export default router;
