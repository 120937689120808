<template>
  <div class="bg-white px-5 py-6 rounded-md">
    <div class="hidden lg:block float-right ml-4">
      <slot name="download" />
    </div>
    <StepperTitle>
      <p class="text-2xl xl:text-3xl font-extrabold text-primary text-shadow-md">
        <slot name="title" />
      </p>
    </StepperTitle>
    <div class="mt-2">
      <slot name="content" />
    </div>
    <div class="lg:hidden flex justify-center mx-auto mt-2">
      <slot name="download" />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {StepperTitle} from "@/core/components/ui/stepper";

export default defineComponent({
    name: 'InstallationStepLayout',
    components: {StepperTitle}
});
</script>
