import {AxiosInstance} from "axios";

/**
 * The sponsorship resource.
 */
export default function (client: AxiosInstance) {
    return {
        /**
         * Invite a user to the sponsorship.
         */
        invite(email: string): Promise<void> {
            return client.post('sponsorships/invite', {email});
        },

        /**
         * Check sponsorship code exists.
         */
        async check(code: string): Promise<{ success: boolean; sponsor: {name: string} }> {
            return client.get(`sponsorships/code/${code}`)
                .then(response => response.data);
        }
    };
}
