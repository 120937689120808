<script setup lang="ts">
import Fade from "@/core/transitions/Fade.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import MarkdownView from "@/core/components/markdown/Markdown.vue";
import SlideRightToLeft from "@/core/transitions/SlideRightToLeft.vue";
import SupportProgramDocumentGrid from "@/program/components/support-program/SupportProgramDocumentGrid.vue";
import {ProgramAttachment} from "@/program/interfaces/Program";
import {onMounted, ref, watchEffect} from "vue";
import {backingApi, interviewApi} from "@/container";
import MailizButton from "@/core/components/buttons/MailizButton.vue";
import DMPButton from "@/core/components/buttons/DMPButton.vue";

const props = defineProps<{
    summary?: string;
    attachments?: ProgramAttachment[];
    backingId: number;
    consumerKey: string;
    interviewIds?: number[];
    visible: boolean;
}>();

const emits = defineEmits(['on-close']);

const pdfSummary = ref(props.summary);
const loading = ref<boolean>(false);
const backingAttachments = ref<ProgramAttachment[]>(props.attachments || []);

/**
 * On mount, fetch attachments linked to the interview ids.
 */
onMounted(() => {
    if (props.interviewIds && props.interviewIds.length > 0) {
        props.interviewIds.map(id => {
            interviewApi().attachments(Number(id), props.consumerKey)
                .then(response => backingAttachments.value = [...response, ...backingAttachments.value]);
        });
    }
});

/**
 * Watcher, that update pdf summary when necessary.
 */
watchEffect(() => {
    pdfSummary.value = props.summary;
});

/**
 * Download backing summary.
 */
const downloadPdf = () => {
    if (pdfSummary.value) {
        loading.value = true;
        return backingApi().pdf(props.backingId, props.consumerKey, pdfSummary.value)
            .then(response => {
                const url = window.URL.createObjectURL(response);
                window.open(url);
            })
            .finally(() => loading.value = false);
    }
};

/**
 * On close action.
 */
const onClose = () => {
    emits('on-close');
};
</script>

<template>
  <div>
    <fade>
      <div
        v-if="visible"
        class="fixed inset-0 bg-gray-200 z-50 bg-opacity-60 cursor-pointer"
        @click="onClose"
      />
    </fade>
    <slide-right-to-left>
      <div
        v-if="visible"
        class="fixed right-0 top-0 h-full bg-white shadow-md cursor-default flex flex-col overflow-y-scroll"
        style="z-index: 55; transition: width 0.5s ease, transform 0.5s ease;"
        :style="{width: '100%'}"
      >
        <div
          class="flex justify-end p-3 items-center bg-gradient-to-r from-darkPurple-default to-darkBlue-default">
          <button
            class="font-bold text-white"
            @click="onClose"
          >
            Fermer
          </button>
        </div>
        <div
          v-if="visible"
          class="flex-1"
        >
          <div v-if="pdfSummary" class="p-6">
            <p>Vérifiez et complétez la conclusion, puis cliquez sur Télécharger le PDF en bas de page</p>
            <markdown-view
              class="my-3 p-6 border-2 border-primary border-opacity-75 bg-white rounded-lg"
              :editable="true"
              :content="pdfSummary"
              @on-edit="edit => pdfSummary = edit" />
            <support-program-document-grid
              v-if="attachments?.length"
              class="my-3"
              :attachments="attachments"
            />
            <div v-if="pdfSummary" class="flex justify-end my-4 gap-4 mr-20">
              <DMPButton />
              <MailizButton />
              <base-button primary @click="downloadPdf">
                Télécharger le pdf
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </slide-right-to-left>
  </div>
</template>

<style scoped>

</style>