<script setup lang="ts">
import useSwr from "@/core/composables/swr/useSwr";
import {laboratoryApi} from "@/container";
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardHeader from "@/core/components/base/BaseCardHeader.vue";
import {computed} from "vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import {Line as LineChartComponent} from "vue-chartjs";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    CategoryScale, ChartOptions, TooltipItem, PluginChartOptions
} from "chart.js";
import dayjs from "dayjs";
import ColorHelper from "@/core/helpers/colorHelper";
import backgroundHighlightPlugin from "@/core/components/charts/plugins/backgroundHighlightPlugin";

interface ExtendedChartPlugins extends PluginChartOptions<'line'> {
    backgroundHighlight?: {
        color?: string;
        period: {
            startIndex?: number;
            endIndex?: number;
        };
    };
}

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    CategoryScale,
    backgroundHighlightPlugin
);

const props = defineProps<{
    laboratoryId: string;
    offer?: {
        name: string;
    };
    offerId: string;
    color?: string;
}>();

const key = computed(() => `/laboratories/${props.laboratoryId}/offers/${props.offerId}/histogram`);

const {data} = useSwr(
    () => key.value,
    () => laboratoryApi().offerSalesGrowthHistogram(props.laboratoryId.toString(), props.offerId.toString())
);

const salesData = computed(() => data.value?.data || []);
const metadata = computed(() => data.value?.metadata || null);

const colors = computed(() => {
    return {
        transparent: ColorHelper.opacity(props.color || 'rgb(13, 110, 253)', 0.1),
        primary: props.color || 'rgb(13, 110, 253)',
        gray: 'rgb(108, 117, 125)'
    };
});

/**
 * Format the axis label based on the interval type.
 */
const formatAxisLabel = (keyAsString: string, intervalType: string) => {
    const date = dayjs(keyAsString);

    switch (intervalType) {
    case 'day':
        return date.format('DD/MM');
    case 'week':
        return date.format('DD/MM');
    case 'month':
        return date.format('MM/YYYY');
    case 'quarter':
        return date.format('MM/YYYY');
    default:
        return date.format('DD/MM');
    }
};

/**
 * Get the index from which the offer period starts and ends.
 */
const offerPeriodIndices = computed(() => {
    if (!salesData.value || salesData.value.length === 0) {
        return {startIndex: undefined, endIndex: undefined};
    }

    const sortedData = [...salesData.value].sort((a, b) => {
        return new Date(a.key_as_string).getTime() - new Date(b.key_as_string).getTime();
    });

    let startIndex = -1;
    let endIndex = -1;

    sortedData.forEach((item, index) => {
        if (item.period_type === 'during-offer') {
            if (startIndex === -1) {
                startIndex = index;
            }
            endIndex = index;
        }
    });

    return {
        startIndex: startIndex !== -1 ? startIndex : undefined,
        endIndex: endIndex !== -1 ? endIndex : undefined
    };
});

const chartData = computed(() => {
    if (!salesData.value || !salesData.value.length) {
        return {
            datasets: []
        };
    }

    const intervalType = metadata.value?.interval_type || "day";

    const sortedData = [...salesData.value].sort((a, b) => {
        return new Date(a.key_as_string).getTime() - new Date(b.key_as_string).getTime();
    });
    const labels = sortedData.map(item => formatAxisLabel(item.key_as_string, intervalType));
    const dataPoints = sortedData.map(item => item.revenue);

    const pointBackgroundColors = sortedData.map(item => {
        return item.period_type === 'during-offer' ? colors.value.primary : colors.value.gray;
    });

    const pointBorderColors = sortedData.map(item => {
        return item.period_type === 'during-offer' ? colors.value.primary : colors.value.gray;
    });

    const segmentColors: string[] = [];

    for (let i = 0; i < sortedData.length - 1; i++) {
        if (sortedData[i].period_type !== sortedData[i + 1].period_type) {
            const gradientColor = colors.value.gray;
            segmentColors.push(gradientColor);
        } else {
            segmentColors.push(sortedData[i].period_type === 'during-offer' ? colors.value.primary : colors.value.gray);
        }
    }

    const nonOfferPeriodDataset = {
        label: 'Hors promotion',
        data: [],
        borderColor: colors.value.gray,
        backgroundColor: 'white',
        pointRadius: 0,
        pointHoverRadius: 0
    };

    const offerPeriodDataset = {
        label: 'Période de promotion',
        data: [],
        borderColor: colors.value.primary,
        backgroundColor: colors.value.transparent,
        pointRadius: 0,
        pointHoverRadius: 0
    };

    nonOfferPeriodDataset.data = [null, null] as never[];
    offerPeriodDataset.data = [null, null] as never[];

    return {
        labels: labels,
        datasets: [
            {
                label: 'Chiffre d\'affaires',
                data: dataPoints,
                borderColor: segmentColors,
                pointBackgroundColor: pointBackgroundColors,
                pointBorderColor: pointBorderColors,
                tension: 0.4,
                borderWidth: 2,
                pointRadius: 2,
                pointHoverRadius: 4,
                spanGaps: false,
                segment: {
                    borderColor: ctx => {
                        const index = ctx.p0DataIndex;
                        if (index >= segmentColors.length) return colors.value.gray;
                        return segmentColors[index];
                    }
                }
            },
            nonOfferPeriodDataset,
            offerPeriodDataset
        ]
    };
});

const chartOptions = computed(() => {
    if (!metadata.value) {
        return {} as ChartOptions<'line'>;
    }

    let timeRangeText = "Période";
    if (metadata.value.analysis_period) {
        const start = dayjs(metadata.value.analysis_period.start).format('DD/MM/YYYY');
        const end = dayjs(metadata.value.analysis_period.end).format('DD/MM/YYYY');
        timeRangeText = `Période du ${start} au ${end}`;
    }

    const options: ChartOptions<'line'> = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: 1500,
            easing: 'easeOutQuart',
            delay: (context) => {
                return context.dataIndex * 50;
            }
        },
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    filter: (legendItem: { text: string }) => {
                        return legendItem.text === 'Hors promotion' ||
                            legendItem.text === 'Période de promotion';
                    }
                }
            },
            tooltip: {
                animation: {
                    duration: 300
                },
                callbacks: {
                    label: (context: TooltipItem<'line'>): string => {
                        let label = "Chiffre d'affaires: ";

                        if (context.parsed.y !== null) {
                            label += context.parsed.y.toLocaleString('fr-FR', {style: 'currency', currency: 'EUR'});
                        }

                        const index = context.dataIndex;
                        const periodType = salesData.value[index]?.period_type;

                        if (periodType === 'during-offer') {
                            label += ' (Période de promotion)';
                        } else {
                            label += ' (Hors promotion)';
                        }

                        return label;
                    }
                }
            }
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: timeRangeText
                }
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: "Chiffre d'affaires"
                },
                ticks: {
                    callback: (value: number | string) => {
                        return value.toLocaleString('fr-FR', {style: 'currency', currency: 'EUR'});
                    }
                }
            }
        }
    };

    (options.plugins as ExtendedChartPlugins).backgroundHighlight = {
        color: colors.value.transparent,
        period: offerPeriodIndices.value
    };

    return options;
});
</script>

<template>
  <BaseCard class="w-full">
    <BaseCardHeader>
      Promotion {{ offer?.name }} - Évolution des ventes
    </BaseCardHeader>
    <BaseCardBody>
      <div
        class="p-2"
        style="position: relative; height: 400px; width: 100%"
      >
        <LineChartComponent
          :data="chartData"
          :options="chartOptions"
          :height="400"
          style="width: 100%;"
        />
      </div>
    </BaseCardBody>
  </BaseCard>
</template>

<style scoped>

</style>
