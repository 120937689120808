<script setup lang="ts">
import useVideos from "@/video/composables/useVideos";
import {computed} from "vue";
import Icon from "@/core/components/icon/Icon.vue";
import {Skeleton} from "@/core/components/ui/skeleton";
import BaseButton from "@/core/components/base/BaseButton.vue";
import VideoItem from "@/video/components/VideoItem.vue";
import {useRouter} from "vue-router";

const router = useRouter();
const {data, isLoading} = useVideos({
    page: 1,
    limit: 2,
    tags: ['pinned']
});

const videos = computed(() => {
    return data.value?.data ?? [];
});

/**
 * Navigate to the tutorials page.
 */
const navigateToTutorials = () => {
    router.push({
        name: 'tutorial'
    });
};
</script>

<template>
  <div class="space-y-1">
    <h1>Visionnez nos tutoriels</h1>
    <div class="flex flex-col md:flex-row gap-2">
      <div>
        <div v-if="!isLoading" class="flex flex-col md:flex-row gap-3">
          <VideoItem v-for="video in videos" :key="video.id" :video="video" />
        </div>
        <div v-else class="flex gap-3">
          <div v-for="i in 2" :key="i" class="bg-white flex items-center gap-2 pr-2 rounded-lg">
            <Icon
              color="red"
              name="mdi-play"
              :size="35"
            />
            <Skeleton class="w-56 h-4" />
          </div>
        </div>
      </div>
      <BaseButton class="self-end" variant="text" density="compact" @click="navigateToTutorials">
        Voir plus
      </BaseButton>
    </div>
  </div>
</template>

<style scoped>

</style>
