<script setup lang="ts">
import {computed, onMounted, ref, watch} from "vue";
import {laboratoryApi, shopApi} from "@/container";
import LaboratoryProduct from "@/core/interfaces/laboratory/LaboratoryProduct";
import LaboratoryBrandingRanges from "@/laboratory/components/LaboratoryBrandingRanges.vue";
import ChildrenRange from "@/core/interfaces/laboratory/ChildrenRange";
import useLaboratoryNeeds from "@/laboratory/composables/useLaboratoryRanges";
import useLaboratory from "@/laboratory/composables/useLaboratory";
import LaboratoryNeedsCard from "@/laboratory/components/LaboratoryNeedsCard.vue";
import useEnvironment from "@/notification/composables/useEnvironment";
import useCart from "@/laboratory/composables/useCart";
import {useCartStore} from "@/laboratory/stores/cart.store";
import {storeToRefs} from "pinia";
import BaseButton from "@/core/components/base/BaseButton.vue";
import Icon from "@/core/components/icon/Icon.vue";
import {useToastStore} from "@/stores/toast.store";

const props = defineProps<{
    laboratoryId: string;
}>();

const {environment} = useEnvironment();
const {cart} = storeToRefs(useCartStore());
const toastStore = useToastStore();
const {data: laboratory} = useLaboratory(props.laboratoryId);
const {data: needs, isLoading, mutate: mutateNeeds} = useLaboratoryNeeds(props.laboratoryId);
const rangeNeeds = ref<LaboratoryProduct[]>();
const selectedRange = ref<ChildrenRange>();
const showSettings = ref<boolean>(false);
const cover = ref<number>();
const colisage = ref<number>();
const coverValues = Array.from({length: 21}, (_, i) => (i + 1) * 5);
const colisageValues = Array.from({length: 9}, (_, i) => parseFloat(((i + 1) * 0.1).toFixed(1)));


if (environment.value?.entityId) {
    const {data} = useCart(props.laboratoryId, environment.value?.entityId);
    if (data.value) {
        cart.value = data.value;
    }
}

/**
 * On mount, fetch laboratory settings
 */
onMounted(() => {
    if (environment.value?.entityId) {
        laboratoryApi().getLaboratorySettings(Number(props.laboratoryId), environment.value.entityId)
            .then(settings => {
                cover.value = settings && settings.daysCount ? settings.daysCount : 60;
                colisage.value = settings && settings.needsRounding ? settings.needsRounding : 0.5;
            });
    }
});

/**
 * On range update, get laboratory line products.
 */
watch(() => selectedRange.value, () => {
    laboratoryLine();
});

watch(() => [cover.value, colisage.value], () => {
    mutateNeeds();
    if (selectedRange.value) {
        laboratoryLine();
    }
});

/**
 * Get range laboratory line, if a range is selected.
 */
const laboratoryLine = () => {
    if (selectedRange.value) {
        laboratoryApi().line(Number(props.laboratoryId), selectedRange.value.id)
            .then(response => {
                rangeNeeds.value = response;
            });
    } else {
        rangeNeeds.value = undefined;
    }
};

/**
 * On quantity update, update cart.
 * @param products
 */
const onUpdate = (products: { [id: number]: number }) => {
    if (environment.value?.entityId && cart.value) {
        shopApi().manageCart(Number(props.laboratoryId), environment.value?.entityId, products)
            .then(response => {
                if (cart.value) {
                    cart.value = {...cart.value, lines: response.cartLines};
                }
            });
    }
};

/**
 * Update needs settings.
 */
const updateSettings = () => {
    if (environment.value?.entityId && cover.value && colisage.value) {
        laboratoryApi().setLaboratorySettings(Number(props.laboratoryId), environment.value?.entityId, cover.value, colisage.value)
            .then((settings) => {
                toastStore.showSuccess({
                    content: 'Les paramètres ont été mis à jour avec succès'
                });
                cover.value = settings && settings.daysCount ? settings.daysCount : 60;
                colisage.value = settings && settings.needsRounding ? settings.needsRounding : 0.5;
            });
    }
};

/**
 * Normalize the quantity needed to respect the packing quantity condition.
 * If quantity needed is less than packing quantity, round the value to the next packing quantity.
 *
 * @return {number}
 */
const getNormalizedQuantity = (product: LaboratoryProduct, quantity?: number) => {
    if (!quantity) {
        return 0;
    }

    const packingQuantity = product.laboratoryShopPrice?.packingQuantity ?? 1;
    const minQuantity = product.laboratoryShopPrice?.minimumQuantity ?? 0;
    let normalizedQuantity = quantity;

    if (normalizedQuantity === 0) {
        return 0;
    }

    if (normalizedQuantity < minQuantity) {
        normalizedQuantity = minQuantity;
    }

    if (normalizedQuantity % packingQuantity > 0) {
        const rest = normalizedQuantity % packingQuantity;

        return normalizedQuantity + rest;
    }

    return normalizedQuantity;
};

/**
 * Get the needs by product id.
 *
 * @param {LaboratoryProduct[]} products
 * @param {boolean} respectMinimumQuantity - Whether it should respect the packaging quantity and minimum quantity.
 * @return {{[p: number]: 0 | undefined | number}}
 */
const needByProductId = (products: LaboratoryProduct[], respectMinimumQuantity = false) => {
    return Object.assign({}, ...products.filter((product) => !product.has_order_quota)
        .map((product) => {
            const quantity = (product.needs?.quantityNeeded && Math.max(product.needs?.quantityNeeded, 0));

            return {[product.id]: respectMinimumQuantity ? getNormalizedQuantity(product, quantity) : quantity};
        }));
};


/**
 * Get the product quantity in cart.
 *
 * @param {number} productId - The product id.
 * @return {number}
 */
const getProductQuantityInCart = (productId: number): number => {
    return cart?.value?.lines?.find((line) => line.product.id === productId)?.quantity || 0;
};

/**
 * Get the quantity needed by product taken into account the product quantity in cart.
 *
 * @param {number} productId - The product id.
 * @return {number}
 */
const getQuantityNeededWithCart = (productId: number): number => {
    if (needs.value) {
        const quantityNeeded = needByProductId(needs.value)[productId];
        const quantityDifference = quantityNeeded - getProductQuantityInCart(productId);

        return quantityDifference >= 0 ? quantityDifference : 0;
    }

    return 0;
};

/**
 * Get total for needs.
 *
 * @returns {number} The total amount of the cart.
 */
const total = computed(() => {
    return needs.value?.reduce((agg, current) => {
        const count = getQuantityNeededWithCart(current.id);

        if (count > 0) {
            agg += current.laboratoryShopPrice.priceDiscountedWithoutVat * count;
        }
        return agg;
    }, 0) || 0;
});

/**
 * Add all quantity needed in cart.
 */
const addAllQuantityNeededToCart = () => {
    if (needs.value) {
        const copy = needByProductId(needs.value);

        Object.keys(copy).forEach((key: string) => {
            const productQuantityInCart = getProductQuantityInCart(parseInt(key));

            productQuantityInCart >= copy[parseInt(key)] && delete copy[parseInt(key)];
        });

        return onUpdate(copy);
    }
};
</script>

<template>
  <div v-if="isLoading" class="flex flex-col m-5 gap-5">
    <v-skeleton-loader
      type="list-item-avatar-three-line, text"
    />
    <v-skeleton-loader
      type="list-item-avatar-three-line, text"
    />
  </div>
  <div v-else class="flex flex-col pb-14">
    <div v-if="!showSettings">
      <div class="flex flew-row justify-between items-center bg-white border-t py-3 px-8">
        <div class="flex flex-col">
          <p>Vos besoins sont calculés pour couvrir <span class="font-semibold">{{ cover }}</span> jours de stock</p>
          <p>Arrondi de colisage : <span class="font-semibold">{{ colisage }}</span></p>
        </div>
        <BaseButton
          :color="laboratory?.branding?.primary_color"
          :style="{'color': laboratory?.branding?.secondary_color}"
          @click="showSettings=true"
        >
          <icon name="mdi-cog-outline" color="laboratory?.branding?.secondary_color" class="pr-2" />
          Paramètres
        </BaseButton>
      </div>
      <div v-if="!rangeNeeds" class="absolute bottom-0 bg-white shadow rounded-tr-lg flex items-center justify-end gap-3 py-4 px-6">
        <p>Total HT remisé : {{ total }} €</p>
        <BaseButton
          prepend-icon="mdi-cart"
          :color="laboratory?.branding?.primary_color"
          :style="{'color': laboratory?.branding?.secondary_color}"
          @click="addAllQuantityNeededToCart"
        >
          Tout ajouter
        </BaseButton>
      </div>
      <div class="flex flex-row items-center justify-between m-6">
        <LaboratoryBrandingRanges
          class="flex-1 bg-white p-4 rounded-lg"
          :laboratory-id="laboratoryId"
          :range="selectedRange"
          @on-range-selected="range => selectedRange = range"
        />
      </div>
      <LaboratoryNeedsCard
        v-for="need in rangeNeeds || needs"
        :key="need.id"
        class="mb-6"
        :need="need"
        :color="selectedRange?.branding.primaryColor || laboratory?.branding?.primary_color"
        :secondary-color="selectedRange?.branding.secondaryColor || laboratory?.branding?.secondary_color"
        :available="!!need.availability?.available"
        :quantity="getQuantityNeededWithCart(need.id) || 0"
        :quantity-needed="needs ? needByProductId(needs)[need.id] : 0"
        @on-update="quantity => onUpdate({[need.id]: quantity})"
      />
    </div>
    <div v-else class="bg-white p-6 pt-4 border-t">
      <div class="flex flex-row justify-end pr-3">
        <BaseButton @click="showSettings = false">
          Retour
        </BaseButton>
      </div>
      <h2 :style="{color: laboratory?.branding?.primary_color}" class="my-2">
        Jours de couverture
      </h2>
      <p class="pb-3">
        La période de couverture permet de calculer votre besoin pour cette période selon vos rotations et vos stocks
        présents dans votre officine.
      </p>
      <v-select
        v-model="cover"
        item-title="name"
        item-value="id"
        density="compact"
        variant="outlined"
        :items="coverValues"
        placeholder="Sélectionner une période de couverture"
        no-data-text="Veuillez d'abord sélectionner une période de couverture"
      />
      <h2 :style="{color: laboratory?.branding?.primary_color}" class="mt-6 mb-2">
        Arrondi de colisage
      </h2>
      <p class="pb-3">
        L'arrondi de colisage permet de calculer le seuil au dessus duquel la proposition de commande est arrondie
        au colisage supérieur. Par exemple pour un colisage de 6 et un arrondi à 0.4 le seuil est de 2.4 (6*0.4),
        si votre besoin sur la période est de 8 boîtes, la proposition de commande sera de 6 boîtes, si votre besoin
        sur la période est de 9 boîtes, la proposition de commande sera de 12 boîtes.
      </p>
      <v-select
        v-model="colisage"
        item-title="name"
        item-value="id"
        density="compact"
        variant="outlined"
        :items="colisageValues"
        placeholder="Sélectionner un arrondi de colisage"
        no-data-text="Veuillez d'abord sélectionner un un arrondi de colisage"
      />
      <div class="flex justify-center my-4">
        <BaseButton
          :color="laboratory?.branding?.primary_color"
          :style="{'color': laboratory?.branding?.secondary_color}"
          @click="updateSettings"
        >
          Valider
        </BaseButton>
      </div>
    </div>
  </div>
</template>
