<template>
  <div class="flex flex-col flex-1">
    <section v-show="programs?.length || customer.beneficiaries.find(beneficiary => !!beneficiary.programs.length)">
      <ConsumerPrograms
        :customer="customer"
        :consumer="customer"
        :consumers="consumers"
        :display-merged-programs="true"
      />
    </section>
    <div class="px-6 pt-3 space-y-3">
      <section
        v-show="customer && !customer.certification?.validated_at"
        class="mb-10"
      >
        <CustomerOnboardingCard :customer="customer" />
      </section>
      <div>
        <div class="flex flex-col md:flex-row w-full">
          <div class="flex flex-1 flex-col">
            <div
              v-if="partnership"
              class="my-3"
            >
              <div>
                <CustomerMedicineCabinetCard
                  :medicine-cabinet-id="customer.medicine_cabinet_id"
                  :program="partnership"
                  :customer="customer"
                />
              </div>
            </div>

            <div class="mb-3">
              <div
                class="flex cursor-pointer w-[50%] border-green"
                @click="navigateToSurveys"
              >
                <div
                  class="bg-white px-4 py-2 shadow rounded-md sm:rounded-lg sm:overflow-hidden min-w-max flex flex-1 flex-row items-center justify-between"
                >
                  <div class="flex flex-row items-center">
                    <img
                      width="25"
                      :src="surveyIcon"
                      class="cursor-pointer mr-5"
                      alt="Survey icon"
                    >
                    <h2 class="text-primary">
                      Voir les questionnaires
                    </h2>
                  </div>
                  <p class="text-2xl text-primary">
                    >
                  </p>
                </div>
              </div>
            </div>

            <div
              v-if="memberships"
              class="my-3"
            >
              <div>
                <CustomerMembershipCard
                  :memberships="memberships"
                  :customer-id="customer.id"
                />
              </div>
            </div>
            <div
              v-else
              class="flex flex-1 justify-center"
            >
              <BaseSpinner />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import CustomerMembershipCard from '@/customer/components/customer-home/CustomerMembershipCard.vue';
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import CustomerOnboardingCard from '@/customer/components/customer-home/CustomerOnboardingCard.vue';
import CustomerMedicineCabinetCard from '@/customer/components/customer-home/CustomerMedicineCabinetCard.vue';
import ConsumerPrograms from '@/consumer/components/consumer-programs/ConsumerPrograms.vue';
import {mapActions, mapState} from 'pinia';
import {usePatientStore} from '@/stores/patient.store';
import surveyIcon from '@/assets/icons/survey.svg';
import {Program} from "@/program/interfaces/Program";
import Customer from "@/customer/interfaces/Customer";

export default defineComponent({
    name: 'CustomerHome',
    components: {
        ConsumerPrograms,
        CustomerMedicineCabinetCard,
        CustomerOnboardingCard,
        BaseSpinner,
        CustomerMembershipCard
    },

    props: {
        customer: {
            type: Object as PropType<Customer>,
            required: true
        }
    },

    data() {
        return {
            qrcode: null,
            surveyIcon
        };
    },
    computed: {
        ...mapState(usePatientStore, {getMemberships: 'memberships'}),
        memberships() {
            return this.getMemberships;
        },
        partnership() {
            return this.customer.programs?.find((program: Program) => program.programmable_type === 'feature');
        },
        programs() {
            return this.customer.programs?.filter((program: Program) => program.programmable_type !== 'feature');
        },
        consumers() {
            return [
                this.customer,
                ...(this.customer.beneficiaries ?? [])
            ];
        }
    },
    watch: {
        customer: {
            immediate: true,

            handler(value) {
                if (this.memberships === null) {
                    this.loadMemberships((value.id as any))
                        .catch();
                }
            }
        }
    },
    methods: {
        ...mapActions(usePatientStore, {loadMemberships: 'loadMemberships'}),
        navigateToSurveys() {
            this.$router.push({name: 'customer.survey.list'});
        }
    }
});
</script>
