import {BLOCKS, INLINES} from '@contentful/rich-text-types';
import {h} from 'vue';

const customEmbeddedAsset = (node, key) => {
    if (node.data.target.fields.file.contentType.startsWith('image')) {
        return h(
            'img',
            {
                key,
                alt: node.data.target.fields.title,
                src: 'https:' + node.data.target.fields.file.url,
                style: 'max-width: 70%; max-height: 500px; margin-left:auto; margin-right:auto; margin-bottom: 20px; margin-top: 20px;'
            },
            null
        );
    } else if (node.data.target.fields.file.contentType.startsWith('video')) {
        return h(
            'video',
            {key, controls: true, style: 'max-width: 50%; max-height: 400px; margin-left:auto; margin-right:auto;'},
            h('source', {src: 'https:' + node.data.target.fields.file.url, type: 'video/mp4'}, null)
        );
    }
    return h(
        'iframe',
        {key, style: 'width: 0; min-width: 100% !important;', src: 'https:' + node.data.target.fields.file.url},
        null
    );
};

const customEmbeddedEntry = (node, key) => {
    if (node.data.target.fields.content.nodeType === 'document') {
        return h(
            'div',
            {key},
            h(
                'a',
                {
                    style: 'width: 100% !important;',
                    href: location.protocol + '//' + location.host + '/guide/' + node.data.target.sys.id
                },
                node.data.target.fields.title
            )
        );
    }
    return h('p', {key}, 'undefined asset');
};

const customHeading1 = (node, key, next) => {
    return h('h1', {key, class: 'py-2'}, next(node.content, key, next));
};

const customHeading2 = (node, key, next) => {
    return h('h2', {key, class: 'pb-1 pt-6'}, next(node.content, key, next));
};

const customHeading6 = (node, key, next) => {
    return h('span', {key, class: 'text-primary font-bold'}, next(node.content, key, next));
};

const customParagraph = (node, key, next) => {
    return h('p', {key, class: 'py-2'}, next(node.content, key, next));
};

const customUl = (node, key, next) => {
    return h('ul', {key, class: 'list-disc pl-4'}, next(node.content, key, next));
};

const customOl = (node, key, next) => {
    return h('ol', {key}, next(node.content, key, next));
};

const customLi = (node, key, next) => {
    return h('li', {key}, next(node.content, key, next));
};

const customHyperlink = (node, key, next) => {
    return h(
        'a',
        {
            class: 'text-primary underline cursor-pointer hover:opacity-75',
            href: node.data.uri,
            target: '_blank'
        },
        next(node.content, key, next)
    );
};

export default {
    renderNodes() {
        return {
            [BLOCKS.EMBEDDED_ASSET]: customEmbeddedAsset,
            [BLOCKS.EMBEDDED_ENTRY]: customEmbeddedEntry,
            [BLOCKS.HEADING_1]: customHeading1,
            [BLOCKS.HEADING_2]: customHeading2,
            [BLOCKS.HEADING_6]: customHeading6,
            [BLOCKS.PARAGRAPH]: customParagraph,
            [BLOCKS.UL_LIST]: customUl,
            [BLOCKS.OL_LIST]: customOl,
            [BLOCKS.LIST_ITEM]: customLi,
            [INLINES.HYPERLINK]: customHyperlink
        };
    }
};
