import {computed} from "vue";
import resolveConfig from "tailwindcss/resolveConfig";
import {Config} from "tailwindcss";
import tailwindConfig from "../../../tailwind.config";
import ColorHelper from "@/core/helpers/colorHelper";

const theme = resolveConfig(tailwindConfig as Config);

const useLaboratoryColor = ({primary, secondary}: { primary?: string, secondary?: string }) => {
    /**
     * Primary color of the laboratory theme.
     */
    const primaryColor = computed(() => {
        return primary ? primary : theme.theme.colors["primary"].DEFAULT;
    });

    /**
     * Secondary color of the laboratory theme.
     */
    const secondaryColor = computed(() => {
        return secondary ? secondary : undefined;
    });

    /**
     * Primary color with opacity.
     */
    const primaryWithOpacity = computed(() => {
        return ColorHelper.opacity(primaryColor.value, 0.1);
    });

    return {
        primaryColor,
        primaryWithOpacity,
        secondaryColor
    };
};

export default useLaboratoryColor;
