<script setup lang="ts">
import {computed, onMounted, nextTick, ref, onUnmounted} from "vue";
import {SupportProgramMeta, SupportProgramPatient} from "@/program/interfaces/Program";
import Extension from "@/core/interfaces/extension/Extension";
import SupportProgramConsumerTable from "@/program/components/program-consumers/SupportProgramConsumerTable.vue";
import BaseLogo from "@/core/components/logo/BaseLogo.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import StripePriceService from "@/stripe/services/stripePriceService";

defineEmits(["activate"]);

const props = defineProps<{
    meta: SupportProgramMeta;
    consumers: SupportProgramPatient[];
    extension: Extension;
}>();

const maximumRowToDisplay = 3;

const remainingConsumers = computed(() => {
    return props.meta.total - maximumRowToDisplay;
});

const lastRowToDisplayPosition = ref(0);
const tableHeight = ref(0);

const updatePositions = () => {
    nextTick(() => {
        const tableElement = document.querySelector('.degraded-consumer-table');
        const element = document.querySelectorAll('.v-data-table__tr');

        if (tableElement && element.length >= maximumRowToDisplay) {
            const thirdRow = element[maximumRowToDisplay - 1] as HTMLElement;
            const tableRect = tableElement.getBoundingClientRect();
            const rowRect = thirdRow.getBoundingClientRect();

            lastRowToDisplayPosition.value = rowRect.bottom - tableRect.top;
            tableHeight.value = tableRect.height;
        }
    });
};

onMounted(() => {
    updatePositions();
    window.addEventListener('resize', updatePositions);
});

onUnmounted(() => {
    window.removeEventListener('resize', updatePositions);
});

/**
 * Compute the overlay height.
 */
const overlayHeight = computed(() => {
    return tableHeight.value - lastRowToDisplayPosition.value;
});

/**
 * Get the price to display to the user.
 */
const price = computed(() => {
    return StripePriceService.featuredPrice(props.extension.subscription_plan?.prices)
        ?? StripePriceService.featuredPrice(props.extension.prices);
});
</script>

<template>
  <div class="relative">
    <SupportProgramConsumerTable
      class="degraded-consumer-table"
      :meta="meta"
      :consumers="consumers"
      degraded
    />
    <div class="absolute blur-overlay p-8 flex flex-col gap-3" :style="{height: overlayHeight + 'px'}">
      <div class="flex pr-2">
        <div class="flex-1">
          <h1>Vous souhaitez connaitre les {{ remainingConsumers }} autres patients ?</h1>
          <h3 class="text-primary">
            En achetant {{
              extension.subscription_plan ? `le pack ${extension.subscription_plan.name}` : `l'extension ${extension.name}`
            }},
            vous aurez accès à la liste complète des patients, et vous pourrez activer
            la notification au comptoir.
          </h3>
        </div>
        <BaseLogo
          :alt="extension.name"
          :logo="extension.subscription_plan?.logo ?? extension.logo"
        />
      </div>
      <BaseButton
        class="self-end text-primary" color="secondary" density="comfortable" variant="flat"
        @click="$emit('activate')"
      >
        {{
          price
            ? `Acheter pour ${StripePriceService.formatPrice(price)}`
            : 'Activer'
        }}
      </BaseButton>
    </div>
  </div>
</template>

<style scoped>
.blur-overlay {
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.5);
    bottom: 0;
    width: 100%;
    transition: all 0.3s;
    z-index: 10;
}
</style>
