<script setup lang="ts">

import Icon from "@/core/components/icon/Icon.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
</script>

<template>
  <div class="relative mt-24">
    <div class="absolute right-6 -top-24 z-10">
      <img src="@/assets/images/experience-tier-1.png" class="h-36 rotate-12 select-none" alt="experience tier 1">
    </div>
    <div class="bg-secondary-lightest p-2 text-primary space-y-2 relative w-full z-30 rounded-lg">
      <div class="flex flex-col lg:flex-row items-center gap-x-2 flex-wrap text-center">
        <div class="flex flex-col md:flex-row flex-1 gap-2 items-center">
          <div class="w-[32px]">
            <Icon name="mdi-crown" class="mx-auto" color="primary" :size="31" />
          </div>
          <p>
            Invitez vos amis pharmacien.nes sur Apodis et gagnez 1 mois offert (chacun !)
          </p>
        </div>
        <BaseButton variant="text" :to="{name: 'sponsorship'}">
          <span class="text-primary">Parrainer une pharmacie</span>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
