<template>
  <router-link
    v-if="globalPlan"
    :to="{name: 'subscription-plans'}"
    class="rounded-sm bg-secondary text-primary px-2 py-1 flex items-center gap-2"
  >
    <icon name="mdi-crown" color="primary" />
    <span v-if="!hasActiveSubscription" class="text-sm">Passer premium</span>
  </router-link>
</template>
<script setup lang="ts">
import Icon from "@/core/components/icon/Icon.vue";
import {storeToRefs} from "pinia";
import {useSubscriptionPlanStore} from "@/stores/subscription-plan.store";
import {computed} from "vue";
import SubscriptionPlanService from "@/subscription-plan/services/SubscriptionPlanService";

const {globalPlan} = storeToRefs(useSubscriptionPlanStore());

const hasActiveSubscription = computed(() => {
    if (!globalPlan.value) {
        return false;
    }

    return SubscriptionPlanService.hasActiveSubscription(globalPlan.value);
});
</script>
