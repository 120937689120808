<script lang="ts" setup>
import {onMounted, computed} from "vue";
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import CustomerSearch from "@/customer/components/customer-search/CustomerSearch.vue";
import HomeLiveActions from "@/home/components/HomeLiveActions.vue";
import SubscriptionPlanGrid from "@/subscription-plan/components/SubscriptionPlanGrid.vue";
import {useExperienceStore} from "@/stores/experience.store";
import {useRouter} from "vue-router";
import {useSubscriptionPlanStore} from "@/stores/subscription-plan.store";
import {storeToRefs} from "pinia";
import SubscriptionPlanExtensionGrid from "@/home/components/SubscriptionPlanExtensionGrid.vue";
import ProgramExtensionGrid from "@/home/components/ProgramExtensionGrid.vue";
import {useExtensionStore} from "@/stores/extension.store";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import {useCreditStore} from "@/stores/credit.store";
import Extension from "@/core/interfaces/extension/Extension";
import ExtensionNavigationService from "@/extension/services/ExtensionNavigationService";
import BaseSpinner from "@/core/components/base/spinner/BaseSpinner.vue";
import {SubscriptionPlan} from "@/subscription-plan/interfaces";
import TheHubspotChat from '@/onboarding/components/hubspot/TheHubspotChat.vue';
import TrialStatus from "@/trial/components/trial-status/TrialStatus.vue";
import HomeSponsorship from "@/home/components/HomeSponsorship.vue";
import HomeVideos from "@/video/components/HomeVideos.vue";
import AlertBanner from "@/alert/components/AlertBanner.vue";

const experienceStore = useExperienceStore();
const subscriptionPlanStore = useSubscriptionPlanStore();
const extensionStore = useExtensionStore();
const creditStore = useCreditStore();
const {individualPlans, loading: subscriptionPlansLoading} = storeToRefs(subscriptionPlanStore);
const {loading: extensionLoading, refreshing, enabledProgramExtensions} = storeToRefs(extensionStore);
const router = useRouter();

const loading = computed(() => {
    return subscriptionPlansLoading.value || extensionLoading.value;
});

onMounted(() => {
    experienceStore.fetchEvents();
    creditStore.refresh();

    if (!subscriptionPlanStore.loading) {
        subscriptionPlanStore.refreshSubscriptionPlans();
    }

    if (!extensionStore.loading && !refreshing.value) {
        extensionStore.refreshExtensions({enabled: true});
    }
});

/**
 * The program extensions without a subscription plan.
 */
const orphanProgramExtensions = computed(() => {
    return enabledProgramExtensions.value.filter(
        extension => !extension.subscription_plan && extension.organization.entity_type !== 'hospital'
    );
});

/**
 * The subscription plan that are linked to enabled extensions.
 */
const subscriptionPlanFromEnabledExtensions = computed(() => {
    const mappedPlans = enabledProgramExtensions.value.map(extension => {
        if (extension?.subscription_plan) {
            return extension.subscription_plan;
        }
    }).filter(plan => !!plan) as SubscriptionPlan[];

    return mappedPlans.filter(function (plan, index, self) {
        return index === self.findIndex(selfPlan => selfPlan.id === plan.id);
    });
});

/**
 * Navigate to the extension.
 *
 * @param extension
 */
const navigateToExtension = (extension: Extension) => {
    ExtensionNavigationService.navigate(extension, router);
};
</script>

<template>
  <TheHubspotChat />
  <div>
    <AlertBanner />
    <BaseContainer class="flex flex-col pb-6 px-6 pt-2 gap-6">
      <TrialStatus />
    <div class="relative">
        <CustomerSearch class="bg-white shadow-md py-5" placeholder="Rechercher, activer un patient …" />
        <HomeLiveActions />
      </div>
      <HomeVideos />
      <template v-if="!loading">
        <SubscriptionPlanGrid :plans="individualPlans ?? []" />
        <div class="flex flex-col gap-8">
          <div>
            <div class="flex items-center gap-2">
              <h1>Vos modules activés</h1>
              <div v-if="refreshing" class="pb-1">
                <BaseSpinner class="self-start" size="button" />
              </div>
            </div>
            <p>
              Activez des modules depuis le {{ " " }}
              <router-link :to="{name: 'extension-store'}" class="underline">
                Store
              </router-link>
            </p>
          </div>
          <template v-for="plan in subscriptionPlanFromEnabledExtensions ?? []" :key="plan.id">
            <SubscriptionPlanExtensionGrid
              :plan="plan" enabled
              @extension-click="(extension) => navigateToExtension(extension)"
            />
          </template>
          <ProgramExtensionGrid
            :program-extensions="orphanProgramExtensions"
            @extension-click="(extension) => navigateToExtension(extension)"
          />
        </div>
        <div class="flex flex-1 items-end">
          <HomeSponsorship class="w-full" />
        </div>
      </template>
      <base-flex-spinner v-else />
    </BaseContainer>
  </div>
</template>
