<template>
  <div class="flex flex-col items-end gap-2">
    <BaseButton
      v-if="!prescription || (!prescription.renewal_plan?.id && prescription.renewable)"
      primary
      class="flex flex-row justify-start w-44"
      @click="editRenewalPlan"
    >
      <icon name="mdi-calendar-arrow-right" color="white" class="mr-2" />
      <div class="flex-1 justify-center">
        Renouveler
      </div>
    </BaseButton>
    <button v-if="prescription?.renewable === false" class="flex flex-row items-center" @click="editRenewalPlan">
      <Icon name="mdi-cancel" color="red" class="mr-2" />
      Non renouvelable
    </button>
    <button v-if="prescription?.renewal_plan?.id" class="flex flex-row items-center" @click="showRenewalPlan">
      <Icon name="mdi-check" color="green" />Plan de renouvellement
      <Icon name="mdi-chevron-right" color="primary" class="ml-2" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import {PropType} from "vue";
import {Prescription} from "@/prescription/interfaces/Prescription";
import BaseButton from "@/core/components/base/BaseButton.vue";
import Icon from "@/core/components/icon/Icon.vue";
import {useRouter} from "vue-router";

const router = useRouter();

const props = defineProps({
    prescription: {
        type: Object as PropType<Prescription>,
        required: false,
        default: null
    }
});

/**
 * Redirect to the new renewal plan page
 */
const editRenewalPlan = () => {
    router.push({
        name: 'patient-administration.renewal.edit',
        params: {prescriptionId: props.prescription.id},
        query: {
            customerId: props.prescription.customer.id
        }
    });
};

/**
 * Redirect to the edit renewal plan page
 */
const showRenewalPlan = () => {
    router.push({
        name: 'patient-administration.renewal.show',
        params: {prescriptionId: props.prescription.id},
        query: {
            customerId: props.prescription.customer?.id
        }
    });
};
</script>
