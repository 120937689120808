<script setup lang="ts">
import {computed, ref} from "vue";
import Counter from "@/core/components/counter/Counter.vue";

const {packingQuantity, minQuantity, current} = defineProps<{
    packingQuantity: number | null,
    minQuantity: number,
    color?: string;
    secondaryColor?: string;
    current?: number;
}>();

const emits = defineEmits(['on-update']);

const count = ref<number>(current || 0);
const normalizedPackingQuantity = computed(() => {
    if (packingQuantity === 0 || packingQuantity === null) {
        return 1;
    }

    return packingQuantity;
});

/**
 * Handle add action.
 */
const handleAdd = () => {
    count.value += normalizedPackingQuantity.value;

    if (count.value < minQuantity) {
        count.value = minQuantity;
    }

    emits('on-update', count.value);
};

/**
 * Handle remove action.
 */
const handleRemove = () => {
    if (count.value <= minQuantity) {
        count.value = 0;
    } else {
        count.value -= normalizedPackingQuantity.value;
    }

    emits('on-update', count.value);
};

</script>

<template>
  <counter v-model="count" :color="color" :text-color="secondaryColor" @on-remove="handleRemove" @on-add="handleAdd" />
</template>
