<script lang="ts" setup>
import LegacyAccess from "@/core/interfaces/Access";
import {computed} from "vue";
import {LGO_NAMES} from "@/onboarding/services/onboardingService";
import {
    Stepper,
    StepperItem
} from "@/core/components/ui/stepper";
import useInstallationSteps from "@/onboarding/composables/useInstallationSteps";

const props = defineProps<{
    accessPoint?: LegacyAccess;
}>();

/**
 * Get the LGO name corresponding to the access point.
 */
const lgoName = computed(() => {
    const lgo = props.accessPoint?.entity.lgo;

    return lgo ? LGO_NAMES[lgo] : '';
});

/**
 * Determine the installation type of the LGO.
 */
const lgoInstall = computed(() => {
    const lgo = props.accessPoint?.entity.lgo;

    if (!lgo) return 'support';

    switch (lgo) {
    case 11: // LGPI
        return 'client';
    case 16: // Pharmavitale
    case 21: // Winpharma
        return 'server';
    case 15: // Pharmaland
    case 10: // LEO
        return 'server-special';
    case 2: // SmartRX
    default:
        return 'support';
    }
});

/**
 * Determine if Sante Secure is installed.
 */
const santeSecureInstalled = computed(() => {
    return !!props.accessPoint?.entity.lastLgoImport;
});

const santeSecureVersion = computed(() => {
    return props.accessPoint?.entity.santeSecureVersion;
});

const steps = useInstallationSteps(
    lgoName,
    lgoInstall,
    santeSecureInstalled,
    santeSecureVersion
);
</script>

<template>
  <Stepper orientation="vertical" class="flex w-full flex-col justify-start gap-0">
    <StepperItem
      v-for="step in steps"
      :key="step.step"
      class="flex w-full items-start gap-6"
      :step="step.step"
    >
      <div
        class="block h-[100%] w-0.5 shrink-0 rounded-full bg-primary"
      />

      <div class="flex flex-col w-full my-2">
        <component
          :is="step.component"
          v-bind="step.getProps?.()"
        />
      </div>
    </StepperItem>
  </Stepper>
</template>
