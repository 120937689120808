<script setup lang="ts">
import InterviewContainer from '@/interview/components/instance-modal/InterviewContainer.vue';
import {computed, ref} from "vue";
import InterviewRecording from "@/interview/components/instance-modal/InterviewRecording.vue";
import {useInterviewStore} from "@/stores/interview-store";
import {storeToRefs} from "pinia";
import {useRoute, useRouter} from "vue-router";
import BaseButton from "@/core/components/base/BaseButton.vue";
import InterviewSurveyBuilt from "@/interview/components/instance-modal/InterviewSurveyBuilt.vue";
import {interviewApi} from "@/container";
import InterviewAudioPanel from "@/interview/components/instance-modal/InterviewAudioPanel.vue";

const interviewStore = useInterviewStore();
const {interview, prefilledSurveyAnswers, interviewInstance} = storeToRefs(interviewStore);
const router = useRouter();
const route = useRoute();
const userAnswerValues = ref<Record<number, unknown>>({});
const loadingSkipRecord = ref(false);
const counterLoading = ref(false);
const audioStarted = ref(false);
const recordPlayerRef = ref();

/**
 * Compute the answers.
 * If the user has answered a question, use that answer.
 * Otherwise, use the prefilled answer.
 */
const answers = computed(() => {
    return Object.keys(userAnswerValues.value)
        .reduce((acc, key) => {
            acc[key] = userAnswerValues.value[key].length
                ? userAnswerValues.value[key]
                : prefilledAnswers.value[key] ?? [];

            return acc;
        }, {});
});

/**
 * Compute the prefilled answers.
 */
const prefilledAnswers = computed(() => {
    return prefilledSurveyAnswers.value?.reduce((acc, answer) => {
        acc[answer.question_id] = answer.answer;

        return acc;
    }, {});
});

/**
 * Create a new interview instance.
 */
const startInstance = async () => {
    await interviewStore.startInterviewInstance();
};

const startAudio = async () => {
    await startInstance();
    audioStarted.value = true;
};

/**
 * Skip the record, create the instance and navigate to next page.
 */
const recordSkipped = async () => {
    if (!interview.value) {
        return;
    }

    loadingSkipRecord.value = true;
    await startInstance();
    loadingSkipRecord.value = false;
    navigateToSurvey();
};

const navigateToSurvey = () => {
    router.replace({
        name: 'interview-instances.survey',
        query: {...route.query}
    });
};

/**
 * Navigate to the transcript loading page.
 */
const navigateToTranscriptLoading = () => {
    router.replace({
        name: 'interview-instances.transcript-loading',
        query: {...route.query}
    });
};

/**
 * When record is finished, set the audio to the store and navigate to the next page.
 */
const recordFinished = (audio: Blob) => {
    interviewStore.audio = audio;
    audioStarted.value = false;

    navigateToTranscriptLoading();
};

/**
 * When counter interview is finished post guidelines answers.
 */
const onCounterInterviewFinished = async () => {
    if (audioStarted.value) {
        recordPlayerRef.value.finish();
    } else {
        counterLoading.value = true;
        try {
            await startInstance();
            if (interviewInstance.value) {
                const formattedAnswers = Object.keys(answers.value)
                    .map(key => {
                        return {
                            question_id: Number(key),
                            answer: answers.value[key]
                        };
                    });
                await interviewApi().answerGuidelines(interviewInstance.value.id, formattedAnswers);
                navigateToTranscriptLoading();
            }
        } catch (e) {
            console.error(e);
        } finally {
            counterLoading.value = false;
        }
    }
};

/**
 * Set the uploaded audio file to the store and start the instance.
 *
 * @param file
 */
const handleImport = async (file: Blob) => {
    interviewStore.audio = file;
    await startInstance();
    navigateToTranscriptLoading();
};

/**
 * Handle the change event.
 *
 * @param {Object} value The new value.
 * @param {number} questionId The question ID.
 */
const handleChange = ({value, questionId}) => {
    userAnswerValues.value[questionId] = Array.isArray(value) ? value : [value];
};
</script>

<template>
  <interview-container class="ml-4">
    <InterviewRecording
      ref="recordPlayerRef"
      @start="startAudio"
      @skip="recordSkipped"
      @finish="recordFinished"
      @finish-upload="handleImport"
    />
    <InterviewAudioPanel />
    <div v-if="interview?.guideline_survey && interview.guideline_survey?.sections" class="flex flex-col gap-5 mt-3">
      <interview-survey-built
        density="compact"
        :survey="interview.guideline_survey"
        :answers="answers"
        @change="handleChange"
      />
      <div class="flex justify-end">
        <base-button primary class="my-4" :loading="counterLoading" @click="onCounterInterviewFinished">
          Terminer l'entretien
        </base-button>
      </div>
    </div>
    <div v-else class="self-end">
      <BaseButton :loading="loadingSkipRecord" primary @click="recordSkipped">
        Aller directement à l'entretien
      </BaseButton>
    </div>
  </interview-container>
</template>
