<script setup lang="ts">
import Icon from "@/core/components/icon/Icon.vue";

defineProps<{ title?: string; size?: number }>();
</script>

<template>
  <div class="flex flex-row items-center p-3">
    <button class="flex items-center" @click="$router.go(-1)">
      <div class="flex items-center bg-primary rounded-full p-1 justify-center mr-3">
        <Icon name="mdi-arrow-left" color="white" :size="size || 18" />
      </div>
      <div v-if="title" class="text-primary text-base font-semibold">
        {{ title }}
      </div>
    </button>
  </div>
</template>
