<script setup lang="ts">
import useHealthcenterAdvantages from "@/advantage/composables/useHealthcenterAdvantages";
import {DotLottieVue} from "@lottiefiles/dotlottie-vue";
import {computed} from "vue";
import MarkdownView from "@/core/components/markdown/Markdown.vue";

const {data} = useHealthcenterAdvantages();

const headline = computed(() => {
    if ((data.value?.length ?? 0) > 1) {
        return 'Vos partenaires ont un cadeau pour vous !';
    }

    return 'Votre partenaire a un cadeau pour vous !';
});

</script>

<template>
  <div v-if="data?.length" class="bg-secondary-lightest p-4 flex flex-col mt-6 gap-4">
    <div class="flex relative self-start">
      <h2 class="text-black">
        {{ headline }}
      </h2>
      <DotLottieVue
        src="animations/gift.lottie"
        class="h-28 w-28 absolute -right-1/3 -translate-y-1/2"
        autoplay loop
      />
    </div>
    <div v-for="advantage in data" :key="advantage.id" class="flex gap-4">
      <img v-if="advantage.logo" :src="advantage.logo" :alt="advantage.title" class="object-contain w-32 max-h-20">
      <div>
        <MarkdownView :content="advantage.sales_pitch" />
      </div>
    </div>
  </div>
</template>
