<template>
  <div class="relative min-w-64 rounded-lg z-20">
    <SearchInput v-bind="$attrs" :is-loading="isLoading" @input="search" />
    <div
      v-if="customers.length"
      class="absolute shadow-lg mt-1 bg-white border top-full z-20 w-full left-0 rounded max-h-72 overflow-y-auto"
    >
      <div
        v-for="(customer, index) in customers"
        :key="index"
        class="last:border-none border-b py-2 px-4 mx-auto text-sm hover:bg-purple-200 cursor-pointer"
        @click="onClick(customer, 'customer', customer.id, customer.key || `c_${customer.id}`)"
      >
        <div class="flex items-center justify-between flex-wrap">
          <div class="flex items-center gap-x-2 flex-wrap">
            <div class="text-purple-700">
              <Icon
                :size="12"
                class="mr-1"
                :name="customer.information.gender == 'male' ? 'mdi-face-man': 'mdi-face-woman' "
              />
              {{ customer.information.name }}
            </div>
            <div class="text-xs text-gray-700">
              {{ $filters.date(customer.information?.birth_date, "relative", true) }}
            </div>
          </div>
          <div v-if="customer.last_visit?.date" class="text-xs">
            Dernière visite le {{ dateHelper.mediumFormat(customer.last_visit.date) }}
          </div>
        </div>
        <div v-if="customer.beneficiaries?.length" class="flex flex-col gap-1 my-1">
          <div
            v-for="(beneficiary, indexBeneficiary) in customer.beneficiaries"
            :key="indexBeneficiary"
            class="flex justify-between"
          >
            <div class="flex gap-2 items-center pl-4">
              {{ beneficiary.information?.name }}
              <span class="text-xs text-gray-700">
                {{ $filters.date(beneficiary.information?.birth_date, "relative", true) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {ref} from "vue";
import {customerApi} from '@/container';
import Customer from "@/customer/interfaces/Customer";
import {useRouter} from "vue-router";
import Icon from "@/core/components/icon/Icon.vue";
import dateHelper from "@/core/helpers/dateHelper";
import SearchInput from "@/core/components/search-bar/SearchInput.vue";

defineOptions({
    inheritAttrs: false
});

const props = defineProps({
    input: {
        type: String,
        required: false,
        default: ''
    },
    callback: {
        type: Function,
        required: false,
        default: null
    }
});

const customers = ref<Customer[]>([]);
const isLoading = ref<boolean>(false);

const router = useRouter();

/**
 * Search for a customer.
 * @param keyword  The keyword to search
 */
const search = (keyword: string) => {
    if (keyword.length > 3) {
        isLoading.value = true;

        return customerApi()
            .search(keyword)
            .then(response => {
                customers.value = response;
                isLoading.value = false;
            });
    }

    if (!keyword) {
        customers.value = [];
    }
};

/**
 * On click event.
 * @param customer  The customer
 * @param type     The consumer type
 * @param id      The consumer id
 * @param key     The consumer key
 */
const onClick = (customer: Customer, type: string, id: number, key: string) => {
    customers.value = [];

    if (!props.callback) {
        router.push({
            name: 'customer.home',
            params: {
                customerId: customer.id
            }
        });

        return;
    }

    props.callback(type, id, key);
};
</script>
