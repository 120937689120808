import {FileManagerFile} from "../FileManagerFile";
import {ProgramBundle, ProgramType} from "@/program/interfaces/Program";
import {StripePrice} from "@/stripe/interfaces";
import {SubscriptionPlan} from "@/subscription-plan/interfaces";

export type Extension = PartnerExtension | ProgramExtension | PackExtension;

/**
 * BaseExtension interface.
 *
 * @extends LightweightExtension
 */
interface BaseExtension extends LightweightExtension {
    /** The extendable type. */
    extendable_type: ExtensionType;
    /** The extendable id. */
    extendable_id: number;
    /**
     * The extension short description.
     */
    short_description: string;
    /**
     * The extension description.
     */
    description: string;
    /** The extension policy information. */
    policy: string;
    /** The extension consent information. */
    consent: string;
    /** The extension video url. */
    video_url?: string;
    /** The extension file. */
    file: FileManagerFile;
    /** Whether the extension is a partnership. */
    is_partner: boolean;
    /** The extension logo. */
    logo: FileManagerFile;
    /** The extension previews image. */
    previews: FileManagerFile[];
    /** The extension start date. */
    start_at: string;
    /** The extension stripe id. */
    stripe_id: string;
    /** The extension prices. */
    prices: StripePrice[];
    /** The extension end date. */
    end_at: string;
    /** The extension state. (true when purchased and active, false when manually disabled, null when never purchased) */
    enabled: boolean | null;
    /**
     * Determine if the user is subscribed to the extension.
     * It is also true if the extension is free (stripe_id is null) or if the user has a subscription to the extension.
     */
    subscribed: boolean;
    /** The extension organization. */
    organization: ExtensionOrganization;
    /** The extension subscription plan id. */
    subscription_plan_id: number;
    /** The extension subscription plans. */
    subscription_plan?: SubscriptionPlan;
}

/**
 * PartnerExtension interface.
 *
 * @property {ExtensionType.PARTNER} extendable_type
 */
export interface PartnerExtension extends BaseExtension {
    extendable_type: ExtensionType.PARTNER;
}

/**
 * PackExtension interface.
 *
 * @property {ExtensionType.PACK} extendable_type
 */
export interface PackExtension extends BaseExtension {
    extendable_type: ExtensionType.PACK;
}

/**
 * ProgramExtension interface.
 *
 * @extends BaseExtension
 *
 * @property {ProgramType} sub_type - The programmable type.
 * @property {sub_id} sub_id - The programmable id.
 */
export interface ProgramExtension extends BaseExtension {
    extendable_type: ExtensionType.PROGRAM_BUNDLE;
    sub_type: ProgramType;
    sub_id: number;
    program_bundle: ProgramBundle;
}

/**
 * The extension types enum.
 */
export enum ExtensionType {
    PARTNER = "partners",
    PROGRAM_BUNDLE = "program-bundle",
    PACK = "pack"
}

export enum ExtensionSlug {
    CLICK_COLLECT = "click-and-collect",
    APODIS_LOYALTY_CARD = "loyalty-gifted-product",
    HEALTHCENTER_GROUP = "group",
    SMS_PREMIUM = "sms-premium",
    MEDIDESTOCK = "medidestock",
    VICTOR_CHALLENGES = "victor-challenges",
    DISRUPTION_LOGISTICS = "disruption-logistics",
    POSOS = "posos",
}

export interface ExtensionIndexParams {
    /** The extension types */
    types?: (ExtensionType.PARTNER | ExtensionType.PROGRAM_BUNDLE | ExtensionType.PACK)[];
    /** The enabled status */
    enabled?: boolean;
}

/**
 * ExtensionOrganization interface.
 *
 * @property {number} id - The extension organization id.
 * @property {number} entity_id - The extension organization entity id.
 * @property {string} entity_name - The extension organization entity name.
 * @property {string} entity_type - The extension organization entity type.
 * @property {string} file - The extension organization image file.
 */
export interface ExtensionOrganization {
    id: number;
    entity_id: number;
    entity_name: string;
    entity_type: string;
    file: string;
}

/**
 * LigthweightExtension interface.
 *
 *  @property {number} id - The extension id.
 *  @property {string} name - The extension name.
 *  @property {string} slug - The extension slug.
 */
export interface LightweightExtension {
    id: number;
    name: string;
    slug: ExtensionSlug | null;
}

export default Extension;
