import {RouteRecordRaw} from "vue-router";
import SubscriptionPlanView from "./views/SubscriptionPlanView.vue";
import ApodisPremiumView from "@/subscription-plan/views/ApodisPremiumView.vue";

const routes: RouteRecordRaw[] = [
    {
        path: '/subscription-plans',
        name: 'subscription-plans',
        component: ApodisPremiumView,
        meta: {
            title: 'Apodis Premium'
        }
    },
    {
        path: '/subscription-plans/:planId',
        name: 'subscription-plan',
        component: SubscriptionPlanView
    },
];

export default routes;
