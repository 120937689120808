<template>
  <installation-step-layout>
    <template #title>
      3. Installer Apodis Pro
    </template>
    <template #subtitle>
      Pilotez votre officine
    </template>
    <template #content>
      <p>Vous pouvez aussi télécharger Apodis Pro sur smartphone/tablette pour retrouver tout Apodis dans votre poche !</p>
    </template>
    <template #download>
      <div class="flex flex-row flex-wrap justify-center flew-wrap gap-6 my-4 mx-4">
        <button @click="$emit('openAppStore')">
          <img
            alt="download app store"
            :src="downloadAppStoreIcon"
          >
        </button>
        <button @click="$emit('openPlayStore')">
          <img
            alt="download playstore"
            :src="downloadPlayStoreIcon"
          >
        </button>
        <div>
          <img
            :src="proAppQrCode"
            class="w-24 object-contain"
            alt="app store qr code"
          >
          <label class="text-sm text-center text-primary font-bold">Scannez-moi</label>
        </div>
      </div>
    </template>
  </installation-step-layout>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import InstallationStepLayout from '@/onboarding/components/installation-process/InstallationStepLayout.vue';
import downloadPlayStoreIcon from '@/assets/icons/download-playstore.svg';
import downloadAppStoreIcon from '@/assets/icons/download-appstore.svg';
import proAppQrCode from '@/assets/images/pro-app-qr-code.png';
import gamificationLevel5 from '@/assets/images/gamification-level-5.png';

export default defineComponent({
    name: 'ApodisProStep',
    components: {InstallationStepLayout},
    emits: ['openGuide', 'openPlayStore', 'openAppStore'],
    data() {
        return {
            downloadPlayStoreIcon,
            downloadAppStoreIcon,
            proAppQrCode,
            gamificationLevel5
        };
    }
});
</script>
