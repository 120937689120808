import useSwr from "@/core/composables/swr/useSwr";
import {laboratoryApi} from "@/container";

/**
 * Composable to fetch the laboratory franco.
 *
 * @param laboratoryId
 * @param healthcenterId
 */
const useLaboratoryFranco = (laboratoryId: string, healthcenterId: number) => {
    const {
        data,
        isLoading
    } = useSwr(
        `/laboratories/${laboratoryId}/francos/healthcenters/${healthcenterId}`,
        () => laboratoryApi().franco(Number(laboratoryId), healthcenterId),
        {revalidateOnFocus: false}
    );
    return {
        data,
        isLoading
    };
};

export default useLaboratoryFranco;
