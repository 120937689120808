<script setup lang="ts">
import {NotificationSequenceDetails} from "@/notification-sequences/interfaces/NotificationSequence";
import NotificationPreview from "@/core/components/notification-preview/NotificationPreview.vue";
import useLaboratoryColor from "@/laboratory/composables/useLaboratoryColor";

const props = defineProps<{
    sequence?: NotificationSequenceDetails;
    color?: string;
}>();

const {primaryColor} = useLaboratoryColor({
    primary: props.color
});
</script>

<template>
  <div class="timeline">
    <div class="timeline-container">
      <div class="timeline-item flex flex-col md:flex-row justify-between">
        <div class="timeline-left">
          <div class="timeline-dot" :style="{ backgroundColor: primaryColor }" />
          <div class="timeline-content">
            <p class="font-medium">
              Déclencheur :
            </p>
            <p>{{ sequence?.name }}</p>
          </div>
        </div>
      </div>

      <div
        v-for="(message, index) in sequence?.messages" :key="index"
        class="timeline-item flex flex-col md:flex-row justify-between gap-4"
      >
        <div class="timeline-left">
          <div class="sms-bubble">
            <span>SMS</span>
          </div>
          <div class="timeline-content">
            <p>
              J{{ message.delay !== 0 ? '+' : '' }}{{ message.delay }}: {{ message.title || "Courage !" }}
            </p>
          </div>
        </div>
        <div class="timeline-right max-w-2xl pl-[50px] md:pl-0">
          <NotificationPreview
            :content="message.template.parsed_content ?? message.template.content"
            type="sms"
            :count="0"
            :when="message.delay === 0 ? 'Le jour de l\'achat' : `${message.delay} jours après l\'achat`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.timeline {
    @apply relative;
}

.timeline-container {
    @apply relative;
}

.timeline-container::before {
    content: '';
    @apply absolute top-0 bottom-0 left-[29px] w-[2px];
    background-color: v-bind(primaryColor);
}

.timeline-item {
    @apply relative mb-[50px];
}

.timeline-left {
    @apply flex items-start gap-5 z-10 min-w-[500px];
}

.timeline-item:not(:first-child) .timeline-left {
    @apply items-center;
}

.timeline-content {
    @apply flex flex-col;
}

.timeline-dot {
    @apply w-[20px] h-[20px] ml-[20px] z-10 bg-primary rounded-full shrink-0;
}

.sms-bubble {
    @apply w-[40px] h-[40px] bg-black rounded-full flex items-center justify-center text-white font-bold relative ml-[10px] z-10 shrink-0;
}

:deep(.notification-content) {
    @apply shadow-md rounded-lg;
}
</style>
