import {AxiosInstance} from "axios";
import {NotificationTemplate} from "@/core/interfaces/MassCertification";

export interface NotificationTemplateIndexParams {
    type?: "sms" | "notification";
    program_id?: number;
    customer_id?: number;
    laboratory_id?: number;
}

export interface FeatureNotificationTemplateParams {
    feature: string;
    customer_id: number;
}

export default function (client: AxiosInstance) {
    return {
        /**
         * Get the mass certification message template index.
         *
         * @return {Promise<Array>}
         */
        async index(params: NotificationTemplateIndexParams = {}): Promise<NotificationTemplate[]> {
            return client.get('mass-certification/notification-templates', {params})
                .then(response => response.data.data);
        },

        /**
         * Fetch the notification templates scoped with a given customer.
         * @param params
         */
        async feature(params: FeatureNotificationTemplateParams): Promise<NotificationTemplate[]> {
            return client.get('notification-templates/feature', {params})
                .then(response => response.data.data);
        }
    };
}
