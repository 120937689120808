import useSwr from "@/core/composables/swr/useSwr";
import {loyaltyCardApi} from "@/container";

const useLoyaltyCardAnalysis = (loyaltyCardId: number) => {
    const {data, isLoading} = useSwr(
        `/loyalty-cards/${loyaltyCardId}/healthcenter/analysis`,
        () => loyaltyCardApi().getLoyalcardStatistics(loyaltyCardId)
    );

    return {
        data, isLoading
    };
};

export default useLoyaltyCardAnalysis;
