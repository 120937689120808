import {filterEmptyKeys} from "@/lib/utils";

/**
 * Generate a cache key for swr.
 *
 * @param route  The route to generate the cache key for.
 * @param params The parameters to include in the cache key.
 */
export function cacheKey(route: string, params?: unknown): string {
    if (params) {
        const queryString: string = new URLSearchParams(filterEmptyKeys(params as GenericParams)).toString();

        return `${route}?${queryString}`;
    }

    return route;
}

export interface GenericParams {
    [key: string]: string | number | boolean | undefined | null | string[];
}
