import useSwr from "@/core/composables/swr/useSwr";
import {laboratoryApi} from "@/container";

const useLaboratoryOfferStatistics = (laboratoryId: string, offerId: string) => {
    const {data, isLoading} = useSwr(
        `/laboratories/${laboratoryId}/offers/${offerId}/statistics`,
        () => laboratoryApi().offerStatistics(laboratoryId, offerId),
        {
            revalidateOnFocus: false,
            ttl: 1000 * 60 * 5,
            errorRetryCount: 0
        }
    );

    return {
        data,
        isLoading
    };
};

export default useLaboratoryOfferStatistics;
