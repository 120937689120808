<script setup lang="ts">
import {useSubscriptionPlanStore} from "@/stores/subscription-plan.store";
import {storeToRefs} from "pinia";
import {computed, DefineComponent, markRaw, Raw} from "vue";
import NoTrialStatus from "@/trial/components/trial-status/NoTrialStatus.vue";
import {Trial} from "@/core/interfaces/trial";
import ActiveTrialStatus from "@/trial/components/trial-status/ActiveTrialStatus.vue";
import EndedTrialStatus from "@/trial/components/trial-status/EndedTrialStatus.vue";
import SubscriptionPlanService from "@/subscription-plan/services/SubscriptionPlanService";
import useHealthcenterAdvantages from "@/advantage/composables/useHealthcenterAdvantages";

const {globalPlan} = storeToRefs(useSubscriptionPlanStore());
useHealthcenterAdvantages();

const status = computed(() => {
    if (!globalPlan.value) {
        return null;
    }

    const latestTrial = globalPlan.value.latest_trial;

    if (!latestTrial) {
        return 'no-trial';
    }

    return latestTrial.status;
});

const componentMap: Record<keyof Trial['status'] & 'no-trial', Raw<DefineComponent>> = {
    'no-trial': markRaw(NoTrialStatus),
    'active': markRaw(ActiveTrialStatus),
    'ended': markRaw(EndedTrialStatus),
};

const component = computed(() => {
    if (!status.value) {
        return null;
    }

    return componentMap[status.value];
});

/**
 * Determine if the global plan has an active subscription
 */
const hasPreviouslySubscribed = computed(() => {
    if (!globalPlan.value) {
        return false;
    }

    return SubscriptionPlanService.hasPreviouslySubscribed(globalPlan.value);
});
</script>

<template>
  <component :is="component" v-if="!hasPreviouslySubscribed" :trial="globalPlan?.latest_trial" />
</template>

<style scoped>

</style>
