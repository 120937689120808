<script setup lang="ts">
import {Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious} from "@/core/components/ui/carousel";
import ProductImage from "@/product/components/ProductImage.vue";
import {KnowledgeableProduct} from "@/knowledge-box/interfaces";

defineProps<{
    products: KnowledgeableProduct[]
}>();
</script>

<template>
  <Carousel
    class="w-full max-w-md mx-auto" :opts="{
      align: 'start'
    }"
  >
    <CarouselContent class="-ml-1">
      <CarouselItem v-for="product in products" :key="product.id" class="pl-1 md:basis-1/2 lg:basis-1/3">
        <div class="p-1">
          <ProductImage :product="{name: product.name, photoPath: product.image_url ?? undefined}" />
          <div class="space-y-2">
            <p class="text-sm">
              {{ product.name }}
            </p>
            <div v-if="product.stock" class="text-sm">
              <p>Prix : <label>{{ $filters.currencyze(product.stock.price) }}</label></p>
              <p>Quantité en stock : <label>{{ product.stock.quantity }}</label></p>
            </div>
          </div>
        </div>
      </CarouselItem>
    </CarouselContent>
    <CarouselPrevious />
    <CarouselNext />
  </Carousel>
</template>

<style scoped>

</style>
