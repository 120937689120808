<script setup lang="ts">

import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardHeader from "@/core/components/base/BaseCardHeader.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import {computed, reactive} from "vue";
import {currencyze} from "@/core/filters/Currencyze";
import useLaboratoryOfferProducts, {
    LaboratoryOfferProductsAnalysisSearchParams
} from "@/laboratory/composables/useLaboratoryOfferProducts";

const props = defineProps<{
    laboratoryId: string;
    offerId: string;
}>();

let searchParams = reactive<LaboratoryOfferProductsAnalysisSearchParams>({
    page: 1,
    size: 10,
});

const {data, isLoading, isValidating} = useLaboratoryOfferProducts(props.laboratoryId, props.offerId, searchParams);

const headers = [
    {
        title: "Produit",
        key: "product",
        sortable: false
    },
    {
        title: "CA TTC",
        key: "revenue",
        sortable: false
    },
    {
        title: "Quantité",
        key: "quantity",
        sortable: false
    },
    {
        title: "Clients",
        key: "consumers",
        sortable: false
    }
];

const _data = computed(() => {
    return data.value?.products.map((item) => {
        return {
            product: item.product,
            revenue: currencyze(item.revenue, 2),
            quantity: item.quantity,
            consumers: item.consumer_count
        };
    });
});

/**
 * Handle item per page update.
 *
 * @param _itemsPerPage
 */
const handleItemPerPageUpdate = (_itemsPerPage: number) => {
    searchParams.size = _itemsPerPage;
    searchParams.page = 1;
};

/**
 * Handle page update.
 *
 * @param _page
 */
const handlePageUpdate = (_page) => {
    searchParams.page = _page;
};
</script>

<template>
  <BaseCard>
    <BaseCardHeader>
      Liste des ventes
    </BaseCardHeader>
    <BaseCardBody>
      <v-data-table-server
        :items="_data"
        :headers="headers"
        :hover="true"
        :items-length="data?.meta.pagination.total ?? 0"
        :loading="isLoading || isValidating"
        :items-per-page-options="[10, 20, 30]"
        :items-per-page="searchParams.size"
        :page="searchParams.page"
        @update:items-per-page="handleItemPerPageUpdate"
        @update:page="handlePageUpdate"
      >
        <template #[`item.product`]="{item}">
          <div>
            <div class="font-semibold">
              {{ item.product.name }}
            </div>
            <div class="text-xs text-gray-500">
              {{ item.product.code }}
            </div>
          </div>
        </template>
      </v-data-table-server>
    </BaseCardBody>
  </BaseCard>
</template>

<style scoped>

</style>
