import {SubscriptionPlan} from "@/subscription-plan/interfaces";

const SubscriptionPlanService = () => {
    /**
     * Determine if the plan has active subscription.
     *
     * @param plan
     */
    const hasActiveSubscription = (plan: SubscriptionPlan) => {
        return plan.subscriptions?.some((item) => item.status === 'active') ?? false;
    };

    /**
     * Determine if the plan has previously subscribed.
     *
     * @param plan
     */
    const hasPreviouslySubscribed = (plan: SubscriptionPlan) => {
        if (!plan.subscriptions) {
            return false;
        }

        return plan.subscriptions?.length > 0 ?? false;
    };

    return {
        hasActiveSubscription,
        hasPreviouslySubscribed
    };
};

export default SubscriptionPlanService();
