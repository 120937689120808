<script lang="ts" setup>
import BaseButton from '@/core/components/base/BaseButton.vue';
import Customer from "@/customer/interfaces/Customer";
import PrescriptionRenewalNotifySMS from "@/prescription/components/PrescriptionRenewalNotifySMS.vue";
import PrescriptionRenewalNotifyPush from "@/prescription/components/PrescriptionRenewalNotifyPush.vue";
import {NotificationTemplate} from "@/core/interfaces/MassCertification";
import {computed} from "vue";

defineProps<{
    customer: Customer;
    dates: Date[];
    notifications: NotificationTemplate[];
}>();

defineEmits(['next-step']);

const customerPhone = defineModel<string | null>("customerPhone", {required: true});
const notifyCreated = defineModel<boolean>("notifyCreated", {required: true});
const selectedNotificationChannel = defineModel<string>("selectedNotificationChannel", {required: true});
const isDisabled = computed(() =>
    selectedNotificationChannel.value === 'sms' && (customerPhone.value?.length || 0) < 8);
</script>

<template>
  <div class="flex flex-col gap-y-4">
    <div>
      <h1>Rappels automatiques</h1>
      <p>Le patient recevra un rappel de 3 jours avant chaque date de renouvellement</p>
    </div>
    <div v-if="selectedNotificationChannel === 'sms'">
      <PrescriptionRenewalNotifySMS
        v-model:customer-phone="customerPhone"
        v-model:notify-created="notifyCreated"
        :notifications="notifications"
        :customer="customer"
        :dates="dates"
      />
    </div>
    <div v-else>
      <PrescriptionRenewalNotifyPush
        v-model:customer-phone="customerPhone"
        v-model:notify-created="notifyCreated"
        :notifications="notifications"
        :customer="customer"
        :dates="dates"
      />
    </div>
    <div class="flex flex-row justify-end py-4">
      <base-button primary :disabled="isDisabled" @click="$emit('next-step')">
        Continuer
      </base-button>
    </div>
  </div>
</template>
