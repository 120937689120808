<script setup lang="ts">

import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardHeader from "@/core/components/base/BaseCardHeader.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import useLaboratoryOfferConsumerAnalysis, {
    LaboratoryOfferConsumerAnalysisSearchParams
} from "@/laboratory/composables/useLaboratoryOfferConsumers";
import {computed, reactive} from "vue";
import {currencyze} from "@/core/filters/Currencyze";

const props = defineProps<{
    laboratoryId: string;
    offerId: string;
}>();

let searchParams = reactive<LaboratoryOfferConsumerAnalysisSearchParams>({
    page: 1,
    size: 10,
});

const {data, isLoading, isValidating} = useLaboratoryOfferConsumerAnalysis(props.laboratoryId, props.offerId, searchParams);

const headers = [
    {
        title: "Patient",
        key: "name",
        sortable: false
    },
    {
        title: "CA TTC",
        key: "revenue",
        sortable: false
    },
    {
        title: "Quantité",
        key: "quantity",
        sortable: false
    },
    {
        title: "Ventes supplémentaires",
        key: "additional_sales",
        sortable: false
    }
];

const _data = computed(() => {
    return data.value?.consumers.map((item) => {
        return {
            name: item.consumer.name,
            revenue: currencyze(item.revenue, 2),
            quantity: item.quantity,
            additional_sales: item.additional,
            top_sales: item.top_sales
        };
    });
});

/**
 * Handle item per page update.
 *
 * @param _itemsPerPage
 */
const handleItemPerPageUpdate = (_itemsPerPage: number) => {
    searchParams.size = _itemsPerPage;
    searchParams.page = 1;
};

/**
 * Handle page update.
 *
 * @param _page
 */
const handlePageUpdate = (_page) => {
    searchParams.page = _page;
};
</script>

<template>
  <BaseCard>
    <BaseCardHeader>
      Patient ayant bénéficié de la promotion
    </BaseCardHeader>
    <BaseCardBody>
      <v-data-table-server
        :items="_data"
        :headers="headers"
        :hover="true"
        :items-length="data?.meta.pagination.total ?? 0"
        :loading="isLoading || isValidating"
        :items-per-page-options="[10, 20, 30]"
        :items-per-page="searchParams.size"
        :page="searchParams.page"
        @update:items-per-page="handleItemPerPageUpdate"
        @update:page="handlePageUpdate"
      >
        <template #[`item.quantity`]="{item}">
          <div v-if="item.top_sales?.items?.length > 0">
            <v-tooltip location="top">
              <template #activator="{ props }">
                <span v-bind="props" class="cursor-pointer">
                  {{ item.quantity }}
                  <v-icon size="x-small" class="ml-1">mdi-information-outline</v-icon>
                </span>
              </template>
              <div class="pa-2">
                <div class="text-subtitle-2 mb-2">
                  Top des ventes
                </div>
                <div v-for="(sale, index) in item.top_sales.items" :key="index" class="text-body-2">
                  {{ sale.quantity }}x {{ sale.product_name }} ({{ $filters.currencyze(sale.price) }})
                </div>
                <div v-if="item.top_sales.total > item.top_sales.items.length" class="text-body-2 mt-2 border-t pt-1">
                  + {{ item.top_sales.total - item.top_sales.items.length }} autres unités
                </div>
              </div>
            </v-tooltip>
          </div>
          <span v-else>{{ item.quantity }}</span>
        </template>

        <template #[`item.additional_sales`]="{item}">
          <span v-if="item.additional_sales">+ {{ item.additional_sales?.quantity }} {{
            $filters.pluralize("unité", item.additional_sales.quantity)
          }} / + {{ $filters.currencyze(item.additional_sales.revenue) }}</span>
        </template>
      </v-data-table-server>
    </BaseCardBody>
  </BaseCard>
</template>

<style scoped>

</style>
