import {AxiosInstance} from "axios";
import {Knowledgeable, KnowledgeableProduct} from "@/knowledge-box/interfaces";

interface KnowledgableIndexQueryParams {
    knowledgeable_types?: (string | null)[];
}

export default function (client: AxiosInstance) {
    return {

        async index(query: KnowledgableIndexQueryParams = {}): Promise<Knowledgeable[]> {
            return client.get('knowledgeables', {params: query})
                .then(response => response.data.data);
        },

        /**
         * Ask knowledge box and stream the response.
         */
        async streamKnowledgeBox(
            knowledgeableId: number,
            onChunkReceived: (text: string) => void, prompt?: string
        ) {
            const controller = new AbortController();

            const request = client({
                url: `knowledgeables/${knowledgeableId}`,
                method: 'post',
                responseType: 'stream',
                data: {prompt},
                signal: controller.signal,
                onDownloadProgress(progressEvent) {
                    onChunkReceived(progressEvent.event.target.response);
                },
            });

            return {
                request,
                abort: () => controller.abort()
            };
        },

        async extractProducts(knowledgableId: number, prompt: string): Promise<KnowledgeableProduct[]> {
            return client.post(`knowledgeables/${knowledgableId}/products`, {prompt})
                .then(response => response.data.data);
        }
    };
}
