<script setup lang="ts">
import {Knowledgeable} from "@/knowledge-box/interfaces";
import GlowingContainer from "@/core/components/glowing-container/GlowingContainer.vue";
import Icon from "@/core/components/icon/Icon.vue";
import KnowledgeableChatModal from "@/knowledge-box/components/KnowledgeableChatModal.vue";

defineProps<{
    knowledgeable: Knowledgeable;
    colors?: string[];
    placeholder?: string;
}>();
</script>

<template>
  <KnowledgeableChatModal :knowledgeable="knowledgeable">
    <template #trigger>
      <GlowingContainer class="cursor-pointer select-none" :colors="colors">
        <div class="bg-white p-3 rounded-full flex justify-between">
          <p class="text-gray-400">
            {{ placeholder ?? knowledgeable.default_prompt }}
          </p>
          <div>
            <span class="font-bold">Demander</span>
            <Icon name="mdi-creation" class="ml-1" color="primary" :size="20" />
          </div>
        </div>
      </GlowingContainer>
    </template>
  </KnowledgeableChatModal>
</template>

<style scoped>

</style>
