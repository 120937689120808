<script setup lang="ts">
import {onMounted, ref} from "vue";
import {contractApi} from "@/container";
import {Contract} from "@/core/interfaces/Contract";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {useToastStore} from "@/stores/toast.store";
import imagesHelper from "@/core/helpers/imagesHelper";
import HeaderBackButton from "@/core/components/header-back-button/HeaderBackButton.vue";

const contract = ref<Contract>();
const loading = ref<boolean>(true);
const toastStore = useToastStore();

/**
 * On mounted, get accepted contract.
 */
onMounted(() => {
    contractApi().readAccepted()
        .then(contracts => {
            const healthCenterContract = contracts.find(contract => contract.slug === "cgu-pharmacie");
            if (healthCenterContract && healthCenterContract.version) {
                contract.value = healthCenterContract;
            }
        })
        .finally(() => loading.value = false);
});

const openContract = () => {
    if (contract.value?.version?.id) {
        const pdfPath = imagesHelper.getURLFromPath(`contracts/versions/${contract.value.version?.id}/show`);

        window.open(pdfPath);
    } else {
        toastStore.showError({content: 'Une erreur est survenue lors de la lecture des cgus.'});
    }
};
</script>

<template>
  <div>
    <header-back-button title="CGU" />
    <div class="flex h-full items-center justify-center">
      <base-button :disabled="loading" primary @click="openContract">
        Visualiser les cgu
      </base-button>
    </div>
  </div>
</template>
