<template>
  <interview-short-cut-container title="">
    <TabsRoot class="flex flex-col w-full" default-value="survey">
      <TabsList class="relative shrink-0 flex bg-gray-300 border-primary border-2 rounded-lg">
        <TabsTrigger
          class="flex flex-1 justify-center p-2 px-4 rounded-lg hover:opacity-75 hover:cursor-pointer data-[state=active]:bg-white"
          value="survey"
        >
          <h2 class="text-lg">
            Questionnaires
          </h2>
        </TabsTrigger>
        <TabsTrigger
          class="flex flex-1 justify-center p-2 px-4 rounded-lg hover:opacity-75 hover:cursor-pointer data-[state=active]:bg-white"
          value="results"
        >
          <h2 class="text-lg">
            Résultats {{ meta?.total ? `(${meta.total})` : "" }}
          </h2>
        </TabsTrigger>
      </TabsList>
      <TabsContent value="survey">
        <p class="mt-3">
          Lancez un questionnaire rapide
        </p>
        <div class="flex flex-wrap gap-3 pt-3 pb-10">
          <div
            v-for="survey in surveys"
            :key="survey.id"
            class="bg-white rounded-md px-3 py-2 cursor-pointer"
            @click="startSurvey(survey)"
          >
            <span
              v-if="survey.emoji"
              class="mr-2"
            >{{ survey.emoji }}</span> <span>{{ survey.theme ?? survey.name }}</span>
          </div>
        </div>
      </TabsContent>
      <TabsContent value="results">
        <div
          v-if="loading"
          class="mx-auto"
        >
          <base-spinner size="medium-small" />
        </div>
        <div
          v-else
          class="flex flex-col gap-3 mt-3"
        >
          <p
            v-if="!instances?.length"
            class="text-center"
          >
            Aucun résultats de questionnaire
          </p>
          <div
            v-else
            class="flex flex-col gap-3"
          >
            <div
              v-for="instance in instances"
              :key="instance.id"
              class="cursor-pointer"
              @click="exportInstance(instance.id)"
            >
              <div class="bg-white rounded-lg px-3 py-2 shadow-md">
                <p class="line-clamp-2">
                  <span
                    v-if="instance.survey.emoji"
                    class="mr-2"
                  >{{ instance.survey.emoji }}</span>{{
                    instance.survey.name
                  }}
                </p>
                <p class="text-sm mt-1">
                  {{ $filters.date(instance.completed_at) }}
                </p>
                <div
                  v-if="instance.verdict"
                  class="flex mt-2"
                >
                  <div
                    v-if="instance.verdict.color"
                    class="w-1 mr-2 rounded-sm"
                    :style="{
                      backgroundColor: instance.verdict.color
                    }"
                  />
                  <p
                    v-if="instance.verdict"
                    class="text-sm flex flex-1 line-clamp-3"
                  >
                    {{ instance.verdict.title }}
                  </p>
                </div>
              </div>
            </div>
            <base-button
              v-if="meta?.current_page < meta?.last_page"
              class="font-medium"
              :disabled="loadingMore"
              @click="loadMore"
            >
              <base-spinner
                v-if="loadingMore"
                size="button"
              />
              <span v-else>Voir plus</span>
            </base-button>
          </div>
        </div>
      </TabsContent>
    </TabsRoot>
  </interview-short-cut-container>
</template>

<script setup lang="ts">
import {inject, onMounted, ref} from "vue";
import InterviewShortCutContainer
    from '@/interview/components/instance-modal/short-cuts/InterviewShortCutContainer.vue';
import {surveyAnswerInstanceApi} from '@/container';
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import SurveyAnswerInstanceService from '@/survey/services/SurveyAnswerInstanceService';
import BaseButton from '@/core/components/base/BaseButton.vue';
import {useInterviewStore} from "@/stores/interview-store";
import {TabsContent, TabsList, TabsTrigger, TabsRoot} from "radix-vue";

const openSurveyModal = inject<any>('openSurveyModal');

const {surveys, consumerKey} = useInterviewStore();
const instances = ref<any[] | null>(null);
const loading = ref<boolean>(false);
const loadingMore = ref<boolean>(false);
const exporting = ref<boolean>(false);
const meta = ref<any>(null);

onMounted(() => {
    loading.value = true;
    loadConsumerInstances()
        .finally(() => {
            loading.value = false;
        });
});

/**
 * Load the consumer instances.
 *
 * @returns {Promise<void>|*}
 */
const loadConsumerInstances = (page = 1) => {
    if (!consumerKey) {
        return Promise.resolve();
    }

    return surveyAnswerInstanceApi()
        .answeredInstance({consumer_key: consumerKey, page})
        .then((response) => {
            meta.value = response.meta;
            instances.value = [...(instances.value ?? []), ...response.data];
        });
};

/**
 * Start a new survey instance.
 *
 * @param {Object} survey The survey to start.
 */
const startSurvey = (survey) => {
    openSurveyModal(
        survey.id,
        consumerKey,
        null,
        null,
        null,
        {
            showVerdict: true,
            consent: survey.consent,
            onClose: () => {
                instances.value = null;
                loading.value = true;
                loadConsumerInstances()
                    .finally(() => {
                        loading.value = false;
                    });
            }
        }
    );
};
/**
 * Export the survey instance.
 *
 * @param {Number} instanceId The instance id.
 */
const exportInstance = (instanceId) => {
    if (exporting.value) {
        return;
    }

    exporting.value = true;
    SurveyAnswerInstanceService.exportAndOpen(instanceId)
        .finally(() => {
            exporting.value = false;
        });
};

/**
 * Load next page of consumer instances.
 */
const loadMore = () => {
    if (!meta.value) {
        return;
    }

    loadingMore.value = true;
    loadConsumerInstances(meta.value?.current_page + 1)
        .finally(() => {
            loadingMore.value = false;
        });
};

</script>
