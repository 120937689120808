<script setup lang="ts">

import TrialStatusContainer from "@/trial/components/trial-status/TrialStatusContainer.vue";
import Icon from "@/core/components/icon/Icon.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {ref} from "vue";
import {subscriptionPlanApi} from "@/container";
import {useSubscriptionPlanStore} from "@/stores/subscription-plan.store";
import {storeToRefs} from "pinia";
import {useExtensionStore} from "@/stores/extension.store";

const loading = ref(false);
const store = useSubscriptionPlanStore();
const extensionStore = useExtensionStore();
const {globalPlan} = storeToRefs(store);

/**
 * Launch the trial period for the global plan.
 */
const launchTrial = async () => {
    if (!globalPlan.value) {
        return;
    }

    try {
        loading.value = true;
        await subscriptionPlanApi().launchTrial(globalPlan.value.id);
        await Promise.all([
            store.refreshSubscriptionPlans(),
            extensionStore.refreshExtensions()
        ]);
    } finally {
        loading.value = false;
    }
};

</script>

<template>
  <TrialStatusContainer>
    <template #headline>
      <div class="space-y-2">
        <div class="flex items-center gap-2">
          <Icon name="mdi-crown" color="primary" :size="30" />
          <p>Activez votre essai gratuit pour utiliser les modules et débloquer la popup au comptoir</p>
        </div>
        <div class="flex text-primary justify-between gap-4 flex-wrap pl-1">
          <router-link :to="{name: 'subscription-plans'}">
            <p class="underline">
              Découvrir les avantages Premium<span class="no-underline inline-block">&nbsp;🚀</span>
            </p>
          </router-link>
          <BaseButton
            primary density="compact" class="font-content-bold" :loading="loading"
            @click="launchTrial"
          >
            <span class="tracking-normal">Commencer mon essai gratuit d'1 mois</span>
          </BaseButton>
        </div>
      </div>
    </template>
  </TrialStatusContainer>
</template>

<style scoped>

</style>
