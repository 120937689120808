<script setup lang="ts">
import {Knowledgeable} from "@/knowledge-box/interfaces";
import {Dialog, DialogContent, DialogHeader, DialogTrigger} from "@/core/components/ui/dialog";
import KnowledgeableChat from "@/knowledge-box/components/KnowledgeableChat.vue";
import Icon from "@/core/components/icon/Icon.vue";

const {knowledgeable} = defineProps<{
    knowledgeable: Knowledgeable;
}>();
</script>

<template>
  <Dialog>
    <DialogTrigger as-child>
      <slot name="trigger" />
    </DialogTrigger>
    <DialogContent
      class="max-w-lg md:max-w-xl lg:max-w-4xl" @pointer-down-outside="(event) => {
        event.preventDefault();
      }"
    >
      <DialogHeader class="text-start">
        <h1>
          Demandez conseil à l'IA
          <Icon color="primary" name="mdi-creation" class="ml-1" :size="20" />
        </h1>
        <p>
          Obtenez des réponses instantanées grâce à l'expertise <strong>{{ knowledgeable.knowledge_box.title }}
            {{ knowledgeable.knowledge_box.description ? `- ${knowledgeable.knowledge_box.description}` : '' }}</strong>
        </p>
      </DialogHeader>
      <KnowledgeableChat :knowledgeable="knowledgeable" />
    </DialogContent>
  </Dialog>
</template>
