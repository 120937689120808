<template>
  <div
    v-if="!!loyaltyCards?.length"
    class="m-3"
  >
    <h2
      class="text-sm mb-2"
    >
      Cartes de fidélité
    </h2>
    <div
      ref="loyalty"
      class="flex flex-col gap-4"
    >
      <a
        v-for="loyaltyCard in loyaltyCards.slice(0, maximumLoyaltyCardsToDisplay)"
        :key="`loyalty-${loyaltyCard.id}`"
        class="cursor-pointer"
        @click="$emit('show-membership', loyaltyCard)"
      >
        <notification-detection-loyalty-card-item
          :loyalty-card="loyaltyCard"
          @link-membership="handleLinkMembership"
        />
      </a>
      <div
        v-if="remainingLoyaltyCards > 0"
        class="flex justify-end w-full"
      >
        <span class="text-sm mx-3">
          + {{ remainingLoyaltyCards }} autre{{
            remainingLoyaltyCards > 1 ? "s" : ""
          }} carte{{ remainingLoyaltyCards > 1 ? "s" : "" }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import NotificationDetectionLoyaltyCardItem
    from '@/notification/components/notification-detection/loyalty-cards/NotificationDetectionLoyaltyCardItem.vue';
import {DetectionNotificationProps} from "@/notification/interfaces";

export default defineComponent({
    name: 'NotificationDetectionMemberships',
    components: {NotificationDetectionLoyaltyCardItem},
    props: {
    /**
     * The memberships to display.
     *
     * @type {Object}
     */
        item: {
            type: Object as PropType<DetectionNotificationProps>,
            required: true
        }
    },
    emits: ['show-membership', 'link-membership'],
    data() {
        return {
            maximumLoyaltyCardsToDisplay: 3
        };
    },
    computed: {
    /**
     * The consumer memberships.
     *
     * @return {Array}
     */
        loyaltyCards() {
            return this.item.loyalty_cards;
        },
        /**
     * The remaining loyalty cards to display.
     *
     * @return {Number}
     */
        remainingLoyaltyCards() {
            return this.loyaltyCards.length - this.maximumLoyaltyCardsToDisplay;
        }
    },
    methods: {
    /**
     * Trigger the attachment loyalty card event.
     *
     * @param {Object} loyaltyCard
     */
        handleLinkMembership(loyaltyCard) {
            return this.$emit('link-membership',loyaltyCard);
        }
    }
});
</script>
