<script lang="ts" setup>
import Customer from "@/customer/interfaces/Customer";
import Icon from "@/core/components/icon/Icon.vue";
import {useSystemStore} from "@/stores/system.store";
import NotificationPreview from "@/core/components/notification-preview/NotificationPreview.vue";
import {Checkbox} from "@/core/components/ui/checkbox";
import {Label} from "@/core/components/ui/label";
import {NotificationTemplate} from "@/core/interfaces/MassCertification";

const {environment} = useSystemStore();

defineProps<{
    customer: Customer;
    dates: Date[];
    notifications: NotificationTemplate[];
}>();

const notifyCreated = defineModel<boolean>("notifyCreated", {required: true});

/**
 * Handle notify created
 * @param value
 */
const handleNotifyCreated = (value: boolean) => {
    notifyCreated.value = value;
};
</script>

<template>
  <div class="flex flex-col gap-y-4">
    <div class="step-card">
      <div class="flex flex-col gap-y-2">
        <h3>
          {{ customer.information.first_name }} a l'application Apodis
          <Icon name="mdi-check" :size="15" class="text-white rounded-full bg-green" />
        </h3>
        <div class="flex gap-x-2 relative">
          <p>
            Les rappels seront envoyés par notifications directement sur l'application du patient.
          </p>
          <img class="w-16 absolute -top-10 -right-4" src="@/assets/images/iphone-mockup.png" alt="Phone">
        </div>
      </div>
    </div>
    <div>
      <h2 class="text-gray-dark mb-2">
        Le patient recevra :
      </h2>
      <div class="flex flex-col gap-y-6">
        <NotificationPreview
          type="push"
          :count="dates.length"
          when="3 jours avant chaque renouvellement"
          :content="`Message de la ${(environment as any)?.entity.name || 'pharmacie'}: n'oubliez pas de renouveler votre ordonnance. Retrouvez vos ordonnances sur https://www.apodis-sante.com/apodis/`"
        />
        <NotificationPreview
          v-if="notifyCreated"
          type="push"
          :count="1"
          when="À la création du plan"
          :content="`Message de la ${(environment as any)?.entity.name || 'pharmacie'}: n'oubliez pas de renouveler votre ordonnance. Retrouvez vos ordonnances sur https://www.apodis-sante.com/apodis/`"
        />
        <div class="flex items-center gap-x-2">
          <Checkbox
            id="notifyCreated"
            :checked="notifyCreated"
            class="data-[state=checked]:bg-primary"
            @update:checked="handleNotifyCreated"
          />
          <Label for="notifyCreated" class="text-gray-dark">Envoyer une notification à la création du plan</Label>
        </div>
      </div>
    </div>
  </div>
</template>
