import useSwr from "@/core/composables/swr/useSwr";
import {computed, Reactive} from "vue";
import {cacheKey} from "@/lib/cache";
import {videoApi} from "@/container";
import {VideoQueryParams} from "@/core/http/resources/video/VideoResource";

/**
 * Composable to fetch video index with swr.
 *
 * @param params
 */
const useVideos = (params: Reactive<VideoQueryParams>) => {
    const key = computed(() => {
        return cacheKey('videos', params);
    });

    const {data, isLoading} = useSwr(key, () => videoApi().index(params));

    return {
        data, isLoading
    };
};

export default useVideos;
