<template>
  <div class="flex justify-center">
    <DateTimePickerInput
      v-model="selectedDateValue[0]"
      :min-date="now"
      :display-time="false"
      :show-selected-date="true"
      :editable="editable"
      @on-confirm="emit('change', selectedDateValue)"
    />
  </div>
</template>

<script setup lang="ts">
import {useVModel} from "@vueuse/core";
import {ref} from "vue";
import dayjs from "dayjs";
import {SurveyQuestion} from "@/core/interfaces/survey/Survey";
import DateTimePickerInput from "@/core/components/date-picker/DateTimePickerInput.vue";

const props = withDefaults(defineProps<{
    question: SurveyQuestion,
    editable: boolean,
    density: string,
    value: any[]
}>(), {
    editable: false,
    density: 'normal'
});

const emit = defineEmits(['change', 'update:selectedDate']);

const selectedDateValue = useVModel(props, 'value', emit, {
    eventName: 'update:selectedDate'
});
const now = ref(dayjs().format('YYYY-MM-DD'));
</script>
