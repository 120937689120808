<script setup lang="ts">
import useEnvironment from "@/notification/composables/useEnvironment";
import useCart from "@/laboratory/composables/useCart";
import {computed, ref} from "vue";
import {LaboratoryCartLine} from "@/laboratory/interfaces";
import useLaboratory from "@/laboratory/composables/useLaboratory";
import LaboratoryCartCard from "@/laboratory/components/LaboratoryCartCard.vue";
import LaboratoryCartFooter from "@/laboratory/components/LaboratoryCartFooter.vue";
import ProgressBar from "@/core/components/ui/progress/ProgressBar.vue";
import useLaboratoryFranco from "@/laboratory/composables/useLaboratoryFranco";
import {LaboratoryFranco} from "@/core/interfaces/laboratory/LaboratoryFranco";
import Icon from "@/core/components/icon/Icon.vue";
import {shopApi} from "@/container";
import {storeToRefs} from "pinia";
import {useCartStore} from "@/laboratory/stores/cart.store";

const props = defineProps<{
    laboratoryId: string;
}>();

const {environment} = useEnvironment();
const {data: laboratory} = useLaboratory(props.laboratoryId);
const {cart} = storeToRefs(useCartStore());
const franco = ref<LaboratoryFranco>();


if (environment.value?.entityId) {
    if (!cart.value) {
        const {data} = useCart(props.laboratoryId, environment.value?.entityId);
        cart.value = data.value;
    }
    const {data: dataFranco} = useLaboratoryFranco(props.laboratoryId, environment.value?.entityId);
    franco.value = dataFranco.value;
}

/**
 * Total cart value.
 */
const cartTotal = computed(() => {
    if (cart.value && franco.value) {
        return cart.value?.lines.reduce((acc, line) => {
            return acc + (franco.value?.laboratoryFrancoTypeId === 1 ? line.quantity : line.totalDiscountedWithoutVat);
        }, 0);
    }

    return null;
});

/**
 * Progress value in percentage.
 */
const progress = computed(() => {
    if (cartTotal.value && franco.value) {
        return (cartTotal.value / franco.value.francoValue > 1 ? 1 : cartTotal.value / franco.value.francoValue) * 100;
    }

    return null;
});

/**
 * Clear cart content.
 */
const clearCart = () => {
    if (environment.value?.entityId) {
        shopApi().deleteCart(Number(props.laboratoryId), environment.value?.entityId)
            .then(() => cart.value = undefined);
    }
};

/**
 * Compute total discounted price without vat.
 *
 * @param {LaboratoryCartLine[]} cartLines - The cart lines.
 *
 * @return {number}
 */
const computeTotal = (cartLines: LaboratoryCartLine[]): number => {
    return cartLines.reduce((sum, element) => sum + element.totalDiscountedWithoutVat, 0);
};


/**
 * Determine if the user can fire the purchase event.
 *
 * @return {boolean}
 */
const canPurchase = (): boolean => {
    let francoReached = true;

    if (cart.value) {
        if (franco.value?.francoValue) {
            francoReached = computeTotal(cart.value.lines) > franco.value.francoValue;
        }

        return cart.value.lines.length !== 0 && francoReached;
    }
    return false;
};

/**
 * Delete a cart line.
 */
const deleteLine = (lineId: number) => {
    if (cart.value) {
        const lineToDelete = cart.value.lines.find(line => line.id === lineId);

        if (lineToDelete) {
            updateCart({[lineToDelete.productId]: 0});
        }
    }
};

/**
 * Update cart, if there is one.
 */
const updateCart = (products: { [id: number]: number }) => {
    if (environment.value?.entityId && cart.value) {
        shopApi().manageCart(Number(props.laboratoryId), environment.value?.entityId, products)
            .then(response => {
                if (cart.value) {
                    cart.value = {...cart.value, lines: response.cartLines};
                }
            });
    }
};

</script>

<template>
  <div class="flex flex-row justify-center bg-white p-4 border-t">
    <h2 class="text-center" :style="{color: laboratory?.branding?.primary_color}">
      Mon panier {{ laboratory?.name }}
    </h2>
  </div>
  <div class="p-4">
    <div v-if="franco" class="flex flex-row items-center px-6 gap-4">
      <p>Franco</p>
      <ProgressBar :value="progress || 0" :color="laboratory?.branding?.primary_color" class="my-4" />
      <p>{{ progress || 0 }}%</p>
    </div>
    <div v-if="cart?.lines" class="py-4">
      <div
        v-if="cart?.lines.length"
        class="flex flex-row px-4 gap-2 cursor-pointer hover:opacity-50"
        @click="clearCart"
      >
        <Icon name="mdi-trash-can" color="red" />
        <p class="text-red">
          Vider le panier
        </p>
      </div>
      <LaboratoryCartCard
        v-for="line in cart.lines"
        :key="line.id"
        class="m-4"
        :line="line"
        :color="laboratory?.branding?.primary_color"
        :secondary-color="laboratory?.branding?.secondary_color"
        :available="!!line.product.availability?.available"
        @on-delete="deleteLine"
        @on-update="quantity => updateCart({[line.productId]: quantity})"
      />
    </div>
    <LaboratoryCartFooter
      v-if="cart?.lines"
      :lines="cart?.lines"
      :can-purchase="canPurchase"
      :laboratory="laboratory"
    />
  </div>
</template>
