<script lang="ts" setup>
import {onMounted, ref} from "vue";
import {storeToRefs} from "pinia";
import {authenticationService, environmentApi} from "@/container";
import {useRouter} from "vue-router";
import {useSystemStore} from "@/stores/system.store";
import {useWebsiteStore} from "@/stores/website.store";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import TheHubspotChat from "@/onboarding/components/hubspot/TheHubspotChat.vue";
import TheHubspotAppointment from "@/onboarding/components/hubspot/TheHubspotAppointment.vue";

const systemStore = useSystemStore();
const websiteStore = useWebsiteStore();
const router = useRouter();
const {environment} = storeToRefs(systemStore);

const loading = ref(false);

onMounted(() => {
    if (!authenticationService().authenticated) {
        router.push({
            name: 'login'
        });
    } else {
        websiteStore.fetchData();

        loading.value = true;
        environmentApi()
            .read()
            .then((_environment) => {
                environment.value = _environment;
            })
            .finally(() => {
                loading.value = false;
            });
    }
});
</script>

<template>
  <div class="h-screen overflow-hidden bg-primary-lightest flex flex-row">
    <BaseFlexSpinner v-if="loading" />
    <div v-else class="min-h-0 flex-1 flex overflow-hidden">
      <main class="min-w-0 flex-1 lg:flex">
        <section
          aria-labelledby="primary-heading"
          class="min-w-0 flex-1 h-full flex flex-col overflow-auto lg:order-last"
        >
          <router-view />
        </section>
      </main>
    </div>
    <TheHubspotChat />
    <TheHubspotAppointment />
  </div>
</template>
