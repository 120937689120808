<script setup lang="ts">
import {ProgressIndicator, ProgressRoot} from 'radix-vue'
import {useVModel} from "@vueuse/core";

const props = defineProps<{ value: number; color?: string }>();

const progressValue = useVModel(props, 'value');
</script>

<template>
  <ProgressRoot v-model="progressValue"
                class="relative overflow-hidden bg-gray-200 rounded-full w-full h-4 sm:h-5"
                style="transform: translateZ(0)">
    <ProgressIndicator
      class="bg-white rounded-full w-full h-full transition-transform duration-[660ms] ease-[cubic-bezier(0.65, 0, 0.35, 1)]"
      :style="{transform: `translateX(-${100 - progressValue}%)`, 'background-color': `${color} !important`}" >
    </ProgressIndicator>
  </ProgressRoot>
</template>