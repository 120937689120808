<script setup lang="ts">
import {useRoute, useRouter} from "vue-router";
import {onMounted, onUnmounted, ref, watch} from "vue";
import {extensionApi, programApi} from "@/container";
import Extension from "@/core/interfaces/extension/Extension";
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import MarkdownView from "@/core/components/markdown/Markdown.vue";
import {
    SupportProgramMarketStatistic, SupportProgramMeta,
    SupportProgramPatient
} from "@/program/interfaces/Program";
import SupportProgramExtensionDetails
    from "@/extension/components/support-program-extension-details/SupportProgramExtensionDetails.vue";
import StripeCheckoutService from "@/stripe/services/stripeCheckoutService";
import StripePriceService from "@/stripe/services/stripePriceService";
import ExtensionDetailsHeader from "@/extension/components/extension-details-header/ExtensionDetailsHeader.vue";
import ExtensionService from "@/extension/services/ExtensionService";
import ExpandableTextOverflow from "@/core/components/expandable-text-overflow/ExpandableTextOverflow.vue";
import ExtensionSlider from "@/extension/components/extension-slider/ExtensionSlider.vue";

const route = useRoute();
const router = useRouter();
const extensionId = Number(route.params.extensionId);
const loading = ref(false);
const extension = ref<null | Extension>(null);
const statistics = ref<undefined | SupportProgramMarketStatistic[]>(undefined);
const consumers = ref<undefined | {
    data: SupportProgramPatient[],
    meta: SupportProgramMeta
}>(undefined);

/**
 * Read the extension.
 *
 * @param extensionId
 */
const readExtension = async (extensionId: number): Promise<Extension> => {
    return extensionApi()
        .read(extensionId);
};

/**
 * Read the market statistics of the support program.
 */
const readMarketStats = async (supportProgramId: number) => {
    return programApi()
        .statistics(supportProgramId)
        .then(_statistics => {
            statistics.value = _statistics;
        });
};

/**
 * Fetch the consumers of the support program.
 */
const supportProgramConsumers = async (supportProgramId: number) => {
    return programApi()
        .consumers(supportProgramId)
        .then(_consumers => {
            consumers.value = _consumers;
        });
};

/**
 * Load the extension data.
 */
const load = async (extensionId: number) => {
    loading.value = true;
    return readExtension(extensionId)
        .then(response => {
            extension.value = response;

            if (ExtensionService.isSupportProgramExtension(response)) {
                return Promise.all([
                    readMarketStats(response.sub_id),
                    supportProgramConsumers(response.sub_id)
                ]);
            }
        })
        .finally(() => {
            loading.value = false;
        });
};

watch(() => extensionId, async (id: number) => {
    await load(id);
}, {immediate: true});

onMounted(() => {
    window.ipcRenderer.on("stripe-checkout-success", () => {
        load(extensionId);
    });
});

onUnmounted(() => {
    window.ipcRenderer.removeAllListeners("stripe-checkout-success");
});

/**
 * Enable the extension.
 */
const enableExtension = async (extension: Extension) => {
    return extensionApi()
        .enable(extension.id)
        .then(() => {
            extension.enabled = true;
        });
};

/**
 * Disable the extension.
 */
const disableExtension = async (extension: Extension) => {
    return extensionApi()
        .disable(extension.id)
        .then(() => {
            extension.enabled = false;
        });
};

/**
 * Buy the extension.
 */
const buyExtension = async (extension: Extension) => {
    const price = StripePriceService.featuredPrice(extension.prices);

    if (price) {
        return StripeCheckoutService.checkout(price);
    }
};

/**
 * Toggle the activation of the extension.
 */
const toggleActivation = () => {
    if (!extension.value) {
        return;
    }

    const nextAction = ExtensionService.getNextAction(extension.value);

    if (nextAction.action === 'disable') {
        return disableExtension(extension.value)
            .then(() => load(extensionId));
    } else if (nextAction.action === 'enable') {
        return enableExtension(extension.value)
            .then(() => load(extensionId));
    } else if (nextAction.action === 'buy') {
        if (extension.value.subscription_plan) {
            router.push({
                name: 'subscription-plan',
                params: {
                    planId: extension.value.subscription_plan.id.toString()
                }
            });
        } else {
            buyExtension(extension.value);
        }
    }
};
</script>

<template>
  <BaseContainer>
    <BaseFlexSpinner v-if="loading" />
    <div v-else-if="extension">
      <ExtensionDetailsHeader :extension="extension" @button-click="toggleActivation" />
      <div class="p-6 bg-lightPurple-lightest flex flex-row space-between">
        <expandable-text-overflow class="basis-full">
          <MarkdownView :content="extension.description" />
        </expandable-text-overflow>
        <div v-if="extension.video_url || extension.previews?.length" class="w-64 pl-4">
          <extension-slider :extension="extension" class="w-24" />
        </div>
      </div>
      <SupportProgramExtensionDetails
        v-if="ExtensionService.isSupportProgramExtension(extension)"
        :extension="extension"
        :market-stats="statistics"
        :consumers="consumers"
        @activate="toggleActivation"
      />
    </div>
  </BaseContainer>
</template>

<style scoped>
</style>
