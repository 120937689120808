/**
 * ChartJS custom plugin to highlight a period of time on the x-axis
 *
 * To use this plugin :
 *
 * 1. Register the plugin in the chart options :
 *     Chart.register(backgroundHighlightPlugin);
 *
 * 2. Add the plugin options in the chart options :
 *    plugins: {
 *      backgroundHighlight: {
 *        color: 'rgba(0, 0, 0, 0.1)',
 *        period: {
 *            startIndex: 0,
 *            endIndex: 10
 *        }
 *      }
 *    }
 *
 */
const backgroundHighlightPlugin = {
    id: 'backgroundHighlight',
    beforeDraw: (chart) => {
        const {ctx, chartArea, scales} = chart;
        const pluginOptions = chart.options.plugins?.backgroundHighlight;

        if (!pluginOptions.period || !scales.x) {
            return;
        }

        const {startIndex, endIndex} = pluginOptions?.period;
        const color = pluginOptions?.color || 'rgba(0, 0, 0, 0.1)';

        if (startIndex === undefined || endIndex === undefined) {
            return;
        }

        const startX = scales.x.getPixelForValue(startIndex);
        const endX = scales.x.getPixelForValue(endIndex);

        if (startX >= chartArea.left && endX <= chartArea.right) {
            ctx.save();
            ctx.fillStyle = color;
            ctx.fillRect(startX, chartArea.top, endX - startX, chartArea.height);

            ctx.beginPath();
            ctx.lineWidth = 1;
            ctx.strokeStyle = color;

            ctx.moveTo(startX, chartArea.top);
            ctx.lineTo(startX, chartArea.bottom);

            ctx.moveTo(endX, chartArea.top);
            ctx.lineTo(endX, chartArea.bottom);

            ctx.stroke();
            ctx.restore();
        }
    }
};

export default backgroundHighlightPlugin;
