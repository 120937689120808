<template>
  <base-container>
    <div class="flex flex-1 flex-col">
      <div class="w-full fixed z-10 bg-slate-100">
        <nav
          class="flex space-x-2 bg-white"
          aria-label="Tabs"
        >
          <router-link
            :to="{ name: 'patient-administration.home' }"
            active-class="border-primary text-primary font-semibold border-b-2.5"
            class="nav-item"
          >
            Accueil
          </router-link>
          <router-link
            :to="{ name: 'patient-administration.dashboard' }"
            active-class="border-primary text-primary font-semibold border-b-2.5"
            class="nav-item"
          >
            Patients
          </router-link>
          <router-link
            :to="{ name: 'patient-administration.renewal.index' }"
            active-class="border-primary text-primary font-semibold border-b-2.5"
            class="nav-item"
          >
            Renouvellement
          </router-link>
          <router-link
            :to="{ name: 'patient-administration.communication' }"
            active-class="border-primary text-primary font-semibold border-b-2.5"
            class="nav-item"
          >
            Communication
          </router-link>
          <router-link
            :to="{ name: 'patient-administration.certified-patients' }"
            active-class="border-primary text-primary font-semibold border-b-2.5"
            class="nav-item"
          >
            Application mobile
          </router-link>
          <router-link
            :to="{ name: 'patient-administration.click-collect' }"
            active-class="border-primary text-primary font-semibold border-b-2.5"
            class="nav-item"
          >
            Click&Collect
          </router-link>
          <router-link
            :to="{ name: 'patient-administration.surveys' }"
            active-class="border-primary text-primary font-semibold border-b-2.5"
            class="nav-item"
          >
            Questionnaires
          </router-link>
        </nav>
      </div>
      <div class="pt-8">
        <router-view :key="$route.path" />
      </div>
    </div>
  </base-container>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseContainer from '@/core/components/base/BaseContainer.vue';

export default defineComponent({
    name: 'PatientAdministrationScreen',
    components: {
        BaseContainer
    }
});
</script>
