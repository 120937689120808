import {ContentfulClientApi, EntrySkeletonType} from "contentful/dist/types/types";
import {ApodisPremiumContentfulEntries} from "@/contentful/interfaces/contentful";

/**
 * The contentful service.
 * @author Alexis Perret <a.perret@apodispharma.com>
 * @returns {Object} The public object.
 */
export default function (contentfulCreateClient: any) {
    const client: ContentfulClientApi<undefined> = contentfulCreateClient({
        space: import.meta.env.VITE_CONTENTFUL_SPACE_ID,
        accessToken: import.meta.env.VITE_CONTENTFUL_API_KEY
    });

    return {
        /**
         * Get the Sante Secure guide ID.
         * @returns {string} The contentful guide ID.
         */
        getSanteSecureGuideId() {
            return '3aPzs5fKxkivUyGv4Tpyg7';
        },
        /**
         * Get the Connect guide ID.
         * @returns {string} The contentful guide ID.
         */
        getConnectGuideId() {
            return '1d3DYAqaWJudelZox9ibJb';
        },
        /**
         * Get the Apodis Pro guide ID.
         * @returns {string} The contentful guide ID.
         */
        getProGuideId() {
            return '11g4LkMes93BEwxt64yA31';
        },
        /**
         * Get guides.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        getGuides() {
            return client.getEntries({
                content_type: 'guide',
                order: ['fields.order'],
            }).then((response: any) => {
                return response.items;
            });
        },
        /**
         * Get specific guide information.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        getGuide(id: number) {
            return client.getEntries({
                'sys.id': id.toString(),
                include: 10
            })
                .then((response: any) => {
                    return response.items[0].fields;
                });
        },
        /**
         * Get specific guide information filtered by specific tag.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        getSpecificTagGuide(tag: string) {
            return client.getEntries({
                content_type: 'guide',
                order: ['fields.order'],
                'fields.tags': tag
            }).then((response: any) => {
                return response.items;
            });
        },
        /**
         * Get releases.
         *
         * @returns {Promise} Promise object represents the HTTP request.
         */
        releases() {
            return client.getEntries({
                content_type: 'release',
                order: ['-fields.releaseDate']
            }).then((response: any) => {
                return response.items;
            });
        },
        /**
         * Get latest release.
         *
         * @returns {Promise} Promise object represents the HTTP request.
         */
        latestRelease() {
            return client.getEntries({
                content_type: 'release',
                order: ['-fields.releaseDate'],
                limit: 1
            }).then((response: any) => {
                return response.items;
            });
        },

        /**
         * Get the apodis premium page entry.
         */
        async apodisPremium(): Promise<ApodisPremiumContentfulEntries> {
            return client.getEntries<EntrySkeletonType<ApodisPremiumContentfulEntries>>({
                content_type: 'apodis-premium'
            }).then((response) => {
                return response.items[0]?.fields ?? null;
            });
        }
    };
}
