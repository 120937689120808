<script setup lang="ts">
import {ref, watch} from "vue";
import {useRoute} from 'vue-router';
import routes from '../laboratoryRouter';
import {isLinkActive} from "@/core/helpers/routeHelper";

interface ClickOutsideElement extends HTMLElement {
    clickOutsideEvent?: (event: Event) => void
}

interface ClickOutsideBinding {
    value: (event: Event) => void
}

const route = useRoute();

defineProps<{
    activeColor?: string;
    activeTextColor?: string;
}>();

const links = [
    {
        label: "Accueil",
        to: {name: "laboratory.home"}
    },
    {
        label: "Patients",
        to: {name: "laboratory.consumers"}
    },
    {
        label: "Animation",
        links: [
            {
                label: "Animation",
                to: {name: "laboratory.animation"}
            },
            {
                label: "Routines",
                to: {name: "laboratory.routines"}
            },
            {
                label: "Promotions",
                to: {name: "laboratory.offers"}
            },
        ]
    },
    {
        label: "Statistiques",
        links: [
            {
                label: "Vendeurs",
                to: {name: "laboratory.operators"}
            },
            {
                label: "Produits",
                to: {name: "laboratory.products"}
            },
            {
                label: "Ventes",
                to: {name: "laboratory.sales"}
            },
        ]
    },
    {
        label: "Catalogue",
        to: {name: "laboratory.needs"}
    },
];

const dropdownOpen = ref(Array.from({length: links.length}, () => false));

watch(
    () => route.name,
    () => {
        closeDropdown();
    }
);

const isDropdownActive = (link: any) => {
    if (!link.links) {
        return false;
    }
    return link.links.some((subLink: any) => isLinkActive(subLink.to, true, routes));
};

const toggleDropdown = (index: number) => {
    if (dropdownOpen.value[index] === true) {
        closeDropdown();
    } else {
        closeDropdown();
        dropdownOpen.value[index] = !dropdownOpen.value[index];
    }
};

const closeDropdown = () => {
    dropdownOpen.value = Array.from({length: links.length}, () => false);
};

const vClickOutside = {
    mounted(el: ClickOutsideElement, binding: ClickOutsideBinding) {
        el.clickOutsideEvent = (event: Event) => {
            if (!(el === event.target || el.contains(event.target as Node))) {
                binding.value(event);
            }
        };
        document.addEventListener('click', el.clickOutsideEvent);
    },
    beforeUnmount(el: ClickOutsideElement) {
        if (el.clickOutsideEvent) {
            document.removeEventListener('click', el.clickOutsideEvent);
        }
    }
};
</script>

<template>
  <nav v-click-outside="() => closeDropdown()" class="flex pb-2 items-center gap-1">
    <template v-for="(link, index) in links" :key="link.label">
      <router-link
        v-if="!link.links"
        :to="link.to"
        :class="[
          'px-3 py-2 text-sm font-semibold rounded-lg transition-colors duration-200',
          isLinkActive(link.to, true, routes)
            ? 'link-active'
            : 'link-inactive'
        ]"
      >
        {{ link.label }}
      </router-link>

      <div
        v-else
        class="relative"
      >
        <button
          :class="[
            'flex flex-row items-center px-3 py-2 text-sm font-semibold rounded-lg transition-colors duration-200',
            (isDropdownActive(link) || dropdownOpen[index])
              ? 'link-active'
              : 'link-inactive'
          ]"
          @click="toggleDropdown(index)"
        >
          <span>{{ link.label }}</span>
          <svg
            fill="currentColor"
            viewBox="0 0 20 20"
            :class="[
              'inline w-4 h-4 ml-1 transition-transform duration-200',
              {'rotate-180': dropdownOpen[index]}
            ]"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <div
            v-if="dropdownOpen[index]"
            class="absolute right-0 mt-1 origin-top-right z-50 min-w-[200px]"
          >
            <div class="overflow-hidden bg-white rounded-md shadow-lg">
              <div class="divide-y divide-gray-100">
                <router-link
                  v-for="subLink in link.links"
                  :key="subLink.label"
                  :to="subLink.to"
                  :class="[
                    'block w-full text-left px-3 py-2.5 text-sm transition-colors duration-200 font-semibold',
                    isLinkActive(subLink.to, true, routes)
                      ? 'link-active'
                      : 'link-inactive'
                  ]"
                >
                  {{ subLink.label }}
                </router-link>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </template>
  </nav>
</template>

<style scoped>
.link-active {
    background-color: v-bind(activeColor);
    color: v-bind(activeTextColor);
}

.link-inactive {
    color: rgb(55, 65, 81);
}

.link-inactive:hover {
    background-color: v-bind(activeColor+ '1A');
    color: rgb(17, 24, 39); /* text-gray-900 */
}

.dropdown-enter-active,
.dropdown-leave-active {
    transition: all 0.2s ease;
}

.dropdown-enter-from,
.dropdown-leave-to {
    opacity: 0;
    transform: translateY(-10px);
}
</style>
