<script setup lang="ts">
import {storeToRefs} from "pinia";
import {useSubscriptionPlanStore} from "@/stores/subscription-plan.store";
import StripePriceService from "@/stripe/services/stripePriceService";
import {computed, ref} from "vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import StripeCheckoutService from "@/stripe/services/stripeCheckoutService";
import SubscriptionPlanService from "@/subscription-plan/services/SubscriptionPlanService";

defineProps<{
    horizontal?: boolean
}>();

const {globalPlan} = storeToRefs(useSubscriptionPlanStore());
const loadingCheckout = ref(false);

/**
 * Start the checkout stripe payment process.
 */
const checkout = () => {
    const lowestPrice = StripePriceService.featuredPrice(globalPlan.value?.prices);

    if (!lowestPrice) {
        return;
    }

    loadingCheckout.value = true;
    StripeCheckoutService.checkout(lowestPrice)
        .finally(() => {
            loadingCheckout.value = false;
        });
};

/**
 * The human readable price of the global plan.
 */
const price = computed(() => {
    const lowestPrice = StripePriceService.featuredPrice(globalPlan.value?.prices);

    if (!lowestPrice) {
        return null;
    }

    return StripePriceService.formatPrice(lowestPrice);
});

const hasActiveSubscription = computed(() => {
    if(!globalPlan.value) {
        return false;
    }

    return SubscriptionPlanService.hasActiveSubscription(globalPlan.value);
});
</script>

<template>
  <div
    v-if="!hasActiveSubscription"
    class="flex flex-col items-center gap-0.5"
    :class="{
      'flex-row gap-2': horizontal,
    }"
  >
    <p class="price">
      {{ price }}
    </p>
    <BaseButton class="bg-secondary text-primary" :loading="loadingCheckout" @click="checkout">
      Passer premium
    </BaseButton>
  </div>
</template>
