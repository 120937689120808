import useSwr from "@/core/composables/swr/useSwr";
import {shopApi} from "@/container";
import {mutate} from "swrv";

/**
 * Composable to fetch the laboratory cart.
 *
 * @param laboratoryId
 * @param healthcenterId
 */
const useCart = (laboratoryId: string, healthcenterId: number) => {
    const {
        data,
        isLoading
    } = useSwr(
        `/shop/laboratories/${laboratoryId}/healthcenters/${healthcenterId}/cart`,
        () => shopApi().laboratoryCart(Number(laboratoryId), healthcenterId), {revalidateOnFocus: false}
    );

    /**
     * Prefetch laboratory statistics data.
     */
    const prefetch = () => {
        return mutate(
            `/shop/laboratories/${laboratoryId}/healthcenters/${healthcenterId}/cart`,
            () => shopApi().laboratoryCart(Number(laboratoryId), healthcenterId)
        );
    };

    return {
        data,
        isLoading,
        prefetch
    };
};

export default useCart;
