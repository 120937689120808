import useSwr from "@/core/composables/swr/useSwr";
import {contentfulService} from "@/container";

const useApodisPremiumContentful = () => {
    const {data, isLoading} = useSwr(
        "contentful/apodis-premium",
        () => contentfulService().apodisPremium()
            .then(response => response),
        {
            revalidateOnFocus: true
        }
    );

    return {
        data,
        isLoading
    };
};

export default useApodisPremiumContentful;
