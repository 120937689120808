<script lang="ts" setup>
import apodisConnectIcon from '@/assets/icons/apodis-connect.svg';
import DownloadButton from "@/onboarding/components/download-button/DownloadButton.vue";
import InstallationStepLayout from "@/onboarding/components/installation-process/InstallationStepLayout.vue";
import {useWebsiteStore} from "@/stores/website.store";
import {storeToRefs} from "pinia";
import {
    Dialog,
    DialogHeader,
    DialogScrollContent,
    DialogTitle,
    DialogTrigger
} from "@/core/components/ui/dialog";

const websiteStore = useWebsiteStore();
const {hosts} = storeToRefs(websiteStore);

/**
 * Download apodis connect
 */
const openConnect = () => {
    window.open(import.meta.env.VITE_CONNECT_EXE_URL);
};
</script>

<template>
  <div class="flex flex-col md:flex-row gap-x-4">
    <installation-step-layout class="flex-1 basis-2/3">
      <template #title>
        2. Installer Apodis Connect
      </template>
      <template #subtitle>
        Communiquez avec vos patients
      </template>
      <template #content>
        <p>
          Téléchargez et installez Apodis Connect <strong class="uppercase underline">sur tous vos postes
            comptoir</strong>
        </p>
      </template>
      <template #download>
        <DownloadButton
          title="Apodis Connect"
          :icon="apodisConnectIcon"
          @click="openConnect"
        />
      </template>
    </installation-step-layout>
    <div
      v-if="hosts?.length"
      class="rounded-md lg:max-w-sm xl:max-w-md basis-1/3 bg-green-100 p-4"
    >
      <p class="font-bold">
        Installés sur {{ hosts.length }} {{ $filters.pluralize('appareil', hosts.length) }}
      </p>
      <div class="flex justify-center h-full items-center">
        <Dialog>
          <DialogTrigger as-child>
            <p class="text-primary font-bold">
              Voir les appareils
            </p>
          </DialogTrigger>
          <DialogScrollContent class="sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle>
                Apodis Connect connectés
              </DialogTitle>
            </DialogHeader>
            <div>
              <ul>
                <li
                  v-for="host in hosts"
                  :key="host.id"
                >
                  <div class="flex flex-row rounded-lg shadow-md border-gray-200 border p-2 my-2">
                    <div class="font-base text-base text-gray-800">
                      {{ host.name || 'Appareil sans nom' }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </DialogScrollContent>
        </Dialog>
      </div>
    </div>
  </div>
</template>
