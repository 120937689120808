<script setup lang="ts">
import Icon from "@/core/components/icon/Icon.vue";
import useLaboratoryColor from "@/laboratory/composables/useLaboratoryColor";

const props = defineProps<{
    value: string;
    label: string;
    subLabel: string;
    color: string;
}>();

const {primaryWithOpacity} = useLaboratoryColor({primary: props.color});
</script>

<template>
  <div class="bg-white rounded-md overflow-hidden flex flex-col">
    <div class="flex items-center justify-between p-3 flex-1">
      <div class="flex font-bold items-center gap-2 pl-3">
        <p class="text-4xl font-content-bold">
          {{ value }}
        </p>
        <p class="text-xl font-bold">
          {{ label }}
        </p>
      </div>
      <Icon name="mdi-chevron-right" :size="50" />
    </div>
    <div
      class="p-2"
      :style="{
        backgroundColor: primaryWithOpacity,
      }"
    >
      <p class="text-sm">
        {{ subLabel }}
      </p>
    </div>
  </div>
</template>

<style scoped>

</style>
