import {RouteRecordRaw} from "vue-router";
import PatientTopNavigation from "@/patient-administration/views/PatientTopNavigation.vue";
import PatientAdministrationHome from "@/patient-administration/views/PatientAdministrationHome.vue";
import PatientDashboardScreen from "@/patient-administration/views/PatientDashboardScreen.vue";
import OrdersScreen from "@/order/views/OrdersScreen.vue";
import PrescriptionRenewalIndexScreen from "@/prescription/views/PrescriptionRenewalIndexScreen.vue";
import PrescriptionRenewalPlanIndexScreen from "@/prescription/views/PrescriptionRenewalPlanIndexScreen.vue";
import PrescriptionRenewalPlanShowScreen from "@/prescription/views/PrescriptionRenewalPlanShowScreen.vue";
import PrescriptionRenewalPlanEditScreen from "@/prescription/views/PrescriptionRenewalPlanEditScreen.vue";
import CustomersListScreen from "@/customer/views/CustomersListScreen.vue";
import SurveysView from "@/survey/views/SurveysView.vue";
import OffersView from "@/offer/views/OffersView.vue";
import OfferDetailsView from "@/offer/views/OfferDetailsView.vue";
import communicationRouter from "@/communication/communicationRouter";
import PatientAdministrationClickCollect from "@/patient-administration/views/PatientAdministrationClickCollect.vue";

const routes: RouteRecordRaw[] = [
    {
        path: '/patient-administration',
        name: 'patient-administration',
        component: PatientTopNavigation,
        children: [
            {
                path: 'home',
                name: 'patient-administration.home',
                component: PatientAdministrationHome
            },
            {
                path: 'dashboard',
                name: 'patient-administration.dashboard',
                component: PatientDashboardScreen
            },
            {
                path: 'orders',
                name: 'patient-administration.click-collect',
                component: PatientAdministrationClickCollect
            },
            {
                path: 'orders',
                name: 'patient-administration.orders',
                component: OrdersScreen
            },
            {
                path: 'renewals',
                name: 'patient-administration.renewal.index',
                component: PrescriptionRenewalIndexScreen
            },
            {
                path: 'renewal-plans',
                name: 'patient-administration.renewal-plans.index',
                component: PrescriptionRenewalPlanIndexScreen
            },
            {
                path: 'renewals/:prescriptionId',
                name: 'patient-administration.renewal.show',
                component: PrescriptionRenewalPlanShowScreen
            },
            {
                path: 'renewals/:prescriptionId/edit',
                name: 'patient-administration.renewal.edit',
                component: PrescriptionRenewalPlanEditScreen
            },
            {
                path: 'certified-patients',
                name: 'patient-administration.certified-patients',
                component: CustomersListScreen
            },
            {
                path: 'surveys',
                name: 'patient-administration.surveys',
                component: SurveysView
            },
            {
                path: 'offers',
                name: 'patient-administration.offers',
                component: OffersView
            },
            {
                path: 'detail/:offerId',
                name: 'patient-administration.offers.detail',
                component: OfferDetailsView
            },
            ...communicationRouter
        ]
    },
];

export default routes;
