import useSWRV, {IConfig} from "swrv";
import useSwrState from "@/core/composables/swr/useSwrState";
import {IKey} from "swrv/esm/types";

const defaultConfig: IConfig = {
    revalidateOnFocus: false,
    ttl: 1000 * 60 * 60 * 24, // 24 hours
};

/**
 * Composable to make a request and cache the result.
 *
 * @param {string} key The key to cache the request.
 * @param {Function} fetcher The function to fetch the data.
 * @param {IConfig} options The options for the request.
 */
const useSwr = <T>(key: IKey, fetcher: () => Promise<T>, options: IConfig<T> = {}) => {
    const swr = useSWRV(key, fetcher, {
        ...defaultConfig,
        ...options
    });
    const state = useSwrState(swr.data, swr.error, swr.isValidating);

    return {
        ...swr,
        ...state
    };
};

export default useSwr;
