<script setup lang="ts">
import { ref, computed, onUnmounted, watch, nextTick } from 'vue';
import { useElementHover } from '@vueuse/core';
import { TIMING } from '@/alert/composables/useScrolling';
import AlertContent from '@/alert/components/AlertContent.vue';
import { useAlertBanner } from '@/alert/composables/useAlertBanner';
import useAlerts from "@/alert/composables/useAlerts";

enum BannerState {
    IDLE = 'idle',
    ACTIVE = 'active',
    TRANSITIONING = 'transitioning'
}

const { data: alertsData } = useAlerts();

const currentAlertIndex = ref(0);
const alertContentRef = ref<InstanceType<typeof AlertContent> | null>(null);
const bannerRef = ref<HTMLElement | null>(null);
const isHovered = useElementHover(bannerRef);

const bannerState = ref<BannerState>(BannerState.IDLE);

const primaryTimeout = ref<NodeJS.Timeout | null>(null);

const alerts = computed(() => alertsData.value || []);
const hasMultipleAlerts = computed(() => alerts.value.length > 1);
const currentAlert = computed(() => {
    if (!alerts.value.length) return undefined;
    return alerts.value[currentAlertIndex.value % alerts.value.length];
});

const { bannerStyle, handleBannerClick } = useAlertBanner(currentAlert);

/**
 * Clear the primary timeout
 */
const clearPrimaryTimeout = () => {
    if (primaryTimeout.value !== null) {
        clearTimeout(primaryTimeout.value);
        primaryTimeout.value = null;
    }
};

/**
 * Schedules the next action based on current state
 */
const scheduleNextAction = () => {
    if (isHovered.value || bannerState.value === BannerState.TRANSITIONING) {
        return;
    }

    clearPrimaryTimeout();

    const delay = alertContentRef.value?.isOverflowing
        ? TIMING.SINGLE_ALERT_LOOP_DELAY
        : TIMING.DEFAULT_ROTATION_DELAY;

    primaryTimeout.value = setTimeout(() => {
        if (hasMultipleAlerts.value) {
            nextAlert();
        } else if (alertContentRef.value?.isOverflowing) {
            restartCurrentAlert();
        }
    }, delay);
};

/**
 * Advances to the next alert in rotation
 */
const nextAlert = async () => {
    if (!hasMultipleAlerts.value || bannerState.value === BannerState.TRANSITIONING) {
        return;
    }

    if (isHovered.value) {
        scheduleNextAction();
        return;
    }

    bannerState.value = BannerState.TRANSITIONING;
    clearPrimaryTimeout();

    if (alertContentRef.value) {
        alertContentRef.value.resetScrollStates();
    }

    currentAlertIndex.value = (currentAlertIndex.value + 1) % alerts.value.length;

    await nextTick();

    primaryTimeout.value = setTimeout(() => {
        bannerState.value = BannerState.ACTIVE;

        if (alertContentRef.value?.isOverflowing) {
            alertContentRef.value.startScrolling();
        } else {
            scheduleNextAction();
        }
    }, 300); // Transition delay
};

/**
 * Restarts scrolling for the current alert
 */
const restartCurrentAlert = () => {
    if (bannerState.value === BannerState.TRANSITIONING || !alertContentRef.value) {
        return;
    }

    clearPrimaryTimeout();
    alertContentRef.value.resetScrollStates();

    if (isHovered.value) return;

    bannerState.value = BannerState.ACTIVE;
    alertContentRef.value.startScrolling();
};

/**
 * Handle selection of a specific alert via dots
 */
const selectAlert = async (index: number) => {
    if (index === currentAlertIndex.value ||
        bannerState.value === BannerState.TRANSITIONING) {
        return;
    }

    bannerState.value = BannerState.TRANSITIONING;
    clearPrimaryTimeout();

    if (alertContentRef.value) {
        alertContentRef.value.resetScrollStates();
    }

    currentAlertIndex.value = index;

    await nextTick();

    primaryTimeout.value = setTimeout(() => {
        bannerState.value = BannerState.ACTIVE;

        if (alertContentRef.value?.isOverflowing) {
            alertContentRef.value.startScrolling();
        } else {
            scheduleNextAction();
        }
    }, 300);
};

/**
 * Handle scrolling completion
 */
const onScrollComplete = () => {
    if (bannerState.value !== BannerState.TRANSITIONING) {
        scheduleNextAction();
    }
};

/**
 * Initialize banner with current alert
 */
const initializeBanner = () => {
    clearPrimaryTimeout();

    if (alerts.value.length > 0 && alertContentRef.value) {
        bannerState.value = BannerState.ACTIVE;
        alertContentRef.value.calculateScrollDuration();

        if (alertContentRef.value.isOverflowing) {
            alertContentRef.value.startScrolling();
        } else {
            scheduleNextAction();
        }
    } else {
        bannerState.value = BannerState.IDLE;
    }
};

watch(isHovered, (newValue) => {
    if (newValue) {
        clearPrimaryTimeout();
    } else if (
        bannerState.value === BannerState.ACTIVE &&
        alertContentRef.value &&
        !alertContentRef.value.isScrolling
    ) {
        scheduleNextAction();
    }
});

watch(alerts, async () => {
    currentAlertIndex.value = 0;
    bannerState.value = BannerState.IDLE;
    clearPrimaryTimeout();

    if (alerts.value.length > 0) {
        await nextTick();
        primaryTimeout.value = setTimeout(() => initializeBanner(), 300);
    }
}, { immediate: true });

onUnmounted(() => {
    bannerState.value = BannerState.IDLE;
    if (alertContentRef.value) {
        alertContentRef.value.resetScrollStates();
    }
    clearPrimaryTimeout();
});
</script>

<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="currentAlert"
      ref="bannerRef"
      class="alert-banner"
      :style="bannerStyle"
      @click="handleBannerClick"
    >
      <AlertContent
        ref="alertContentRef"
        :alert="currentAlert"
        :is-hovered="isHovered"
        @scroll-complete="onScrollComplete"
      />

      <div v-if="hasMultipleAlerts" class="alert-nav">
        <span
          v-for="(_, index) in alerts"
          :key="index"
          class="dot"
          :class="{ active: index === currentAlertIndex }"
          @click.stop="selectAlert(index)"
        />
      </div>
    </div>
  </transition>
</template>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-enter-from {
    opacity: 0;
    transform: translateY(-20px);
}

.fade-leave-to {
    opacity: 0;
    transform: translateY(-20px);
}
</style>
