import {AxiosInstance} from "axios";
import {Advantage} from "@/advantage/interfaces/Advantages";

export interface AdvantageIndexRequest {
    type?: 'grouping';
}


export default function (client: AxiosInstance) {
    return {
        /**
         * Get all the advantages.
         */
        async index(query: AdvantageIndexRequest = {}): Promise<Advantage[]> {
            const response = await client.get("advantages", {params: query});

            return response.data.data;
        },

        /**
         * Get the advantages for the current healthcenter.
         *
         * @return {*}
         */
        async healthcenterAdvantages(): Promise<Advantage[]> {
            const response = await client.get("healthcenter/advantages");

            return response.data.data;
        },
    };
}
