<script setup lang="ts">
import {ref, computed, onMounted, onUnmounted, watch, nextTick} from 'vue';
import {Alert} from "@/alert/interfaces";
import {useScrolling, AlertState, TIMING} from "@/alert/composables/useScrolling";
import "@/alert/styles/alertStyles.css";

const props = defineProps<{
    alert: Alert;
    isHovered: boolean;
}>();

const emit = defineEmits<{
    (e: 'scrollComplete'): void;
}>();

const textWrapper = ref<HTMLElement | null>(null);
const textContent = ref<HTMLElement | null>(null);

const {
    isOverflowing,
    scrollState,
    isScrolling,
    isReadyToScroll,
    isScrollComplete,
    scrollDuration,
    scrollAmount,
    calculateScrollDuration,
    resetScrollState
} = useScrolling(textWrapper, textContent);

const animationTimeout = ref<NodeJS.Timeout | null>(null);
const remainingTime = ref<number | null>(null);
const startTime = ref<number | null>(null);

/**
 * Computes text content styling including CSS variables for animation
 */
const textContentStyle = computed(() => ({
    '--scroll-duration': `${scrollDuration.value}ms`,
    '--play-state': props.isHovered ? 'paused' : 'running',
    '--scroll-amount': scrollAmount.value
}));

/**
 * Clears the current animation timeout
 */
const clearAnimationTimeout = () => {
    if (animationTimeout.value !== null) {
        clearTimeout(animationTimeout.value);
        animationTimeout.value = null;
    }
};

/**
 * Advances the scroll state machine to the next state
 */
const advanceScrollState = () => {
    clearAnimationTimeout();

    switch (scrollState.value) {
    case AlertState.IDLE:
        if (isOverflowing.value) {
            scrollState.value = AlertState.READY;
            animationTimeout.value = setTimeout(() => {
                advanceScrollState();
            }, TIMING.PRE_SCROLL_DELAY);
        } else {
            emit('scrollComplete');
        }
        break;

    case AlertState.READY:
        scrollState.value = AlertState.SCROLLING;
        startTime.value = Date.now();
        remainingTime.value = scrollDuration.value;

        animationTimeout.value = setTimeout(() => {
            startTime.value = null;
            remainingTime.value = null;
            advanceScrollState();
        }, scrollDuration.value);
        break;

    case AlertState.SCROLLING:
        scrollState.value = AlertState.COMPLETED;

        if (!props.isHovered) {
            animationTimeout.value = setTimeout(() => {
                emit('scrollComplete');
            }, TIMING.POST_SCROLL_DELAY);
        }
        break;

    case AlertState.COMPLETED:
        break;
    }
};

/**
 * Starts the scrolling animation sequence
 */
const startScrolling = () => {
    if (scrollState.value !== AlertState.IDLE) {
        resetScrollStates();
    }

    calculateScrollDuration();
    advanceScrollState();
};

/**
 * Pauses the scrolling animation
 */
const pauseScrolling = () => {
    if (scrollState.value === AlertState.SCROLLING && startTime.value) {
        clearAnimationTimeout();

        const elapsedTime = Date.now() - startTime.value;
        remainingTime.value = Math.max(0, scrollDuration.value - elapsedTime);
        startTime.value = null;
    } else if (scrollState.value === AlertState.COMPLETED) {
        clearAnimationTimeout();
    }
};

/**
 * Resumes the scrolling animation
 */
const resumeScrolling = () => {
    if (
        scrollState.value === AlertState.SCROLLING &&
        remainingTime.value !== null &&
        !animationTimeout.value
    ) {

        startTime.value = Date.now();

        animationTimeout.value = setTimeout(() => {
            startTime.value = null;
            remainingTime.value = null;
            advanceScrollState();
        }, remainingTime.value);
    } else if (scrollState.value === AlertState.COMPLETED && !animationTimeout.value) {
        animationTimeout.value = setTimeout(() => {
            emit('scrollComplete');
        }, TIMING.POST_SCROLL_DELAY);
    }
};

/**
 * Resets all scroll states
 */
const resetScrollStates = () => {
    clearAnimationTimeout();
    resetScrollState();
    startTime.value = null;
    remainingTime.value = null;
};

watch(() => props.isHovered, (newValue) => {
    if (newValue) {
        pauseScrolling();
    } else {
        resumeScrolling();
    }
});

watch(() => props.alert, () => {
    nextTick(() => {
        resetScrollStates();
        calculateScrollDuration();
    });
}, {immediate: true});

defineExpose({
    startScrolling,
    resetScrollStates,
    isOverflowing,
    calculateScrollDuration,
    isScrolling,
    isReadyToScroll,
    isScrollComplete
});

onMounted(async () => {
    await nextTick();
    calculateScrollDuration();
    startScrolling();
});

onUnmounted(() => {
    clearAnimationTimeout();
});
</script>

<template>
  <div ref="textWrapper" class="text-wrapper">
    <div
      ref="textContent"
      class="text-content"
      :class="{
        'scrolling': isScrolling && isOverflowing,
        'ready-to-scroll': isReadyToScroll && isOverflowing,
        'scroll-complete': isScrollComplete && isOverflowing
      }"
      :style="textContentStyle"
    >
      {{ alert.content }}
    </div>
  </div>
</template>
