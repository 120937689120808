import useSwr from "@/core/composables/swr/useSwr";
import {notificationSequenceApi} from "@/container";

/**
 * Composable to read a notification sequence.
 *
 * @param sequenceId
 */
const useNotificationSequence = (sequenceId: string | number) => {
    const {data, isLoading} = useSwr(
        `/notification-sequences/${sequenceId}`,
        () => notificationSequenceApi().read(sequenceId),
    );

    return {
        data,
        isLoading
    };
};

export default useNotificationSequence;
