import useSwr from "@/core/composables/swr/useSwr";
import {
    NotificationSequenceIndexQueryParams
} from "@/core/http/resources/notification-sequence/NotificationSequenceResource";
import {computed} from "vue";
import {filterEmptyKeys} from "@/lib/utils";
import {notificationSequenceApi} from "@/container";

/**
 * Composable to get the notification sequences.
 *
 * @param params
 */
const useNotificationSequenceIndex = (params: NotificationSequenceIndexQueryParams = {}) => {
    const key = computed(() => {
        const query = new URLSearchParams(filterEmptyKeys(params));

        return `notification-sequences?${query.toString()}`;
    });

    const {data, isLoading} = useSwr(
        key,
        () => notificationSequenceApi().index(params)
    );

    return {
        data,
        isLoading
    };
};

export default useNotificationSequenceIndex;
