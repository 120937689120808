import {AxiosInstance} from "axios";
import {Alert} from "@/alert/interfaces";

const AlertResource = (client: AxiosInstance) => {
    return {
        /**
         * Get the active alerts.
         */
        async index(): Promise<Alert[]> {
            return client.get("alerts")
                .then((response) => response.data.data);
        }
    };
};

export default AlertResource;
