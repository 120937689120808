<script setup lang="ts">
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardHeader from "@/core/components/base/BaseCardHeader.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import Icon from "@/core/components/icon/Icon.vue";
import {Skeleton} from "@/core/components/ui/skeleton";

defineProps<{
    label: string;
    value: string | number;
    periodLabel?: string;
    isLoadingData?: boolean;
}>();
</script>

<template>
  <BaseCard>
    <BaseCardHeader>
      <div class="flex gap-2">
        <label class="flex-1 line-clamp-1">{{ label }}</label>
        <v-tooltip v-if="periodLabel">
          <template #activator="{ props }">
            <Icon name="mdi-information-slab-circle" color="gray-700" v-bind="props" />
          </template>
          <div v-if="!$slots.periodLabel" class="text-sm">
            {{ periodLabel }}
          </div>
          <slot name="periodLabel" />
        </v-tooltip>
      </div>
    </BaseCardHeader>
    <BaseCardBody>
      <Skeleton v-if="isLoadingData" class="w-full h-8" />
      <span v-else class="font-bold text-xl">{{ value }}</span>
    </BaseCardBody>
  </BaseCard>
</template>

<style scoped>

</style>
