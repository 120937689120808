import {AxiosInstance} from "axios";
import {Prescription, PrescriptionIndexMeta} from "@/prescription/interfaces/Prescription";

export default function (hdsClient: AxiosInstance) {
    return {
        /**
         * Analyze prescription.
         * @param documentKey
         * @returns {Promise}
         */
        async analyze(documentKey: string): Promise<{ data: Prescription[], meta: PrescriptionIndexMeta }> {
            return await hdsClient.post('posos/analyze', {document_id: parseInt(documentKey)})
                .then(response => response.data.data);
        }
    };
}
