<template>
  <div
    class="cursor-pointer relative"
    :class="[{
      'detection-header': !groupingLogo,
      'detection-grouping-header': groupingLogo
    }]"
    :style="{ height: headerHeight + 'px'}"
    @click="$emit('show-customer', item.customer ?? null)"
  >
    <div class="mr-3 flex-shrink-0">
      <img
        v-if="groupingLogo"
        class="h-10 w-10 object-contain"
        :src="groupingLogo"
        alt="Logo Apodis"
      >
      <img
        v-else
        class="h-10 w-10"
        src="@/assets/images/logos/apodis-white.svg"
        alt="Logo Apodis"
      >
    </div>
    <div
      class="flex-1 text-lg font-black break-normal line-clamp-2"
      :class="[{'text-secondary': !groupingLogo, 'text-primary': groupingLogo}]"
    >
      <div
        v-if="item.patient"
        class="flex-1"
      >
        {{ item.patient.firstName }} {{ item.patient.lastName }}
      </div>
      <div v-else>
        Patient inconnu
      </div>
    </div>
    <div class="flex flex-row gap-x-5 justify-around absolute -bottom-5 right-5 text-primary">
      <div
        class="w-10 h-10 flex items-center justify-center bg-white rounded-full shadow-custom font-black"
        @click.stop="$emit('navigate-surveys', item.customer ?? null)"
      >
        <survey-icon class="w-6 h-6" />
      </div>
      <div
        v-if="!item.certified"
        class="w-10 h-10 flex items-center justify-center bg-white rounded-full shadow-custom"
        @click.stop="$emit('navigate-qr-code', item.customer ?? null)"
      >
        <print :size="15" />
      </div>
      <div
        v-if="!item.certified"
        class="w-10 h-10 flex items-center justify-center bg-white rounded-full shadow-custom font-black"
        @click.stop="$emit('navigate-sms', item.customer ?? null)"
      >
        SMS
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import SurveyIcon from '@/core/components/icons/SurveyIcon.vue';
import Print from '@/core/components/icons/Print.vue';
import {useSystemStore} from "@/stores/system.store";
import {mapState} from "pinia";
import {DetectionNotificationProps} from "@/notification/interfaces";

export const DETECTION_HEADER_HEIGHT = 80;

export default defineComponent({
    name: 'NotificationDetectionCustomerHeader',
    components: {Print, SurveyIcon},
    props: {
        /**
         * The detection item.
         */
        item: {
            type: Object as PropType<DetectionNotificationProps>,
            required: true
        }
    },
    emits: ['navigate-sms', 'navigate-qr-code', 'navigate-surveys', 'show-customer', 'navigate-knowledgeables'],
    data() {
        return {
            headerHeight: DETECTION_HEADER_HEIGHT,
        };
    },
    computed: {
        ...mapState(useSystemStore, ['environment', 'groupingLogo'])
    },
});
</script>
