import {AxiosInstance, AxiosResponse} from "axios";
import {Contract} from "@/core/interfaces/Contract";

/**
 * API resource for contract.
 *
 * @param client
 */
export const ContractResource = (client: AxiosInstance) => {
    return {
        /**
         * Get the accepted contract.
         */
        async readAccepted(): Promise<Contract[]> {
            const params = {
                include: "version",
                wrappers: {response: null}
            };

            return client.get("/contracts/accepted", {params})
                .then((response: AxiosResponse) => response.data.contracts);
        }
    };
};

export default ContractResource;
