<script lang="ts" setup>
import useLoyaltyCardAnalysis from "@/loyalty-card/composables/useLoyaltyCardAnalysis";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import LoyaltyCardStatisticsCard from "@/program/components/loyalty-card-statistics/LoyaltyCardStatisticsCard.vue";
import UserSolid from "@/assets/images/icons-vue/user-solid.vue";
import {computed} from "vue";

const props = defineProps<{
    programmableId: number;
}>();

const {data: loyaltyCard, isLoading} = useLoyaltyCardAnalysis(props.programmableId);

/**
 * Get the male percentage.
 */
const malePercentage = computed(() => {
    if (loyaltyCard.value?.analysis) {
        return ((loyaltyCard.value.analysis.count.male / loyaltyCard.value.analysis.count.membership) * 100).toFixed(2);
    }

    return '';
});

/**
 * Get the female percentage.
 */
const femalePercentage = computed(() => {
    if (loyaltyCard.value?.analysis) {
        return ((loyaltyCard.value.analysis.count.female / loyaltyCard.value.analysis.count.membership) * 100).toFixed(2);
    }

    return "";
});

/**
 * Get the total balance label.
 */
const totalPointBalanceLabel = computed(() => {
    if (!loyaltyCard.value?.analysis) {
        return '';
    }

    const balance = loyaltyCard.value.analysis.total.point_balance;
    const euroBalance = balance * (loyaltyCard.value.point?.rate || 1);

    return `${balance} (${euroBalance.toFixed(2)}€)`;
});

const totalUnitBalanceLabel = computed(() => {
    if (!loyaltyCard.value?.analysis) {
        return '';
    }

    return loyaltyCard.value.analysis.total.unit_balance;
});
</script>

<template>
  <div v-if="isLoading">
    <base-flex-spinner />
  </div>
  <div
    v-else-if="loyaltyCard"
    class="flex flex-row justify-between flex-wrap gap-x-5 gap-y-6 my-2 mx-10 p-2"
    style="min-width: 400px;"
  >
    <div
      class="card-w-details bg-pink-200 rounded-md"
    >
      <LoyaltyCardStatisticsCard
        label="Nombre de patients"
        :value="loyaltyCard.analysis?.count?.membership"
        card-type="number"
        icon="user"
        fill-color="white"
        bg-color="pink"
        :shadow="true"
      />

      <div class="flex flex-col my-4 gap-5">
        <div class="flex justify-evenly items-center ml-4">
          <UserSolid
            class="icon-pink bg-white mr-4 rounded-full p-2"
            width="48"
          />
          <div class="w-full">
            <div>Moyenne d'âge</div>
            <div class="font-bold">
              {{ loyaltyCard.analysis?.average?.age }}
            </div>
          </div>
        </div>
        <div class="flex justify-evenly items-center ml-4">
          <UserSolid
            class="icon-pink bg-white mr-4 rounded-full p-2"
            width="48"
          />
          <div class="w-full">
            <div>Répartition</div>
            <div class="font-bold">
              {{ `Hommes : ${malePercentage}%` }}
            </div>
            <div class="font-bold">
              {{ `Femmes : ${femalePercentage}%` }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <LoyaltyCardStatisticsCard
        v-if="!!loyaltyCard.point_rewards_count"
        label="Solde total de points"
        :value="totalPointBalanceLabel"
        card-type="text"
        icon="star"
        fill-color="yellow"
        bg-color="white"
        :shadow="true"
      />
      <LoyaltyCardStatisticsCard
        v-if="!!loyaltyCard.product_rewards_count"
        label="Solde total d'unités"
        :value="totalUnitBalanceLabel"
        card-type="text"
        icon="star"
        fill-color="yellow"
        bg-color="white"
        :shadow="true"
      />
      <LoyaltyCardStatisticsCard
        label="Nombre d'achat par patient"
        :value="loyaltyCard.analysis?.average?.cart"
        card-type="number"
        icon="shopping-bag"
        fill-color="white"
        bg-color="blue"
        :shadow="true"
        :fraction-digit="2"
      />
    </div>

    <div>
      <LoyaltyCardStatisticsCard
        label="Panier moyen"
        :value="loyaltyCard.analysis?.average?.cart_price?.current"
        card-type="currency"
        icon="shopping-bag"
        fill-color="white"
        bg-color="yellow"
        :shadow="true"
      />
    </div>
  </div>
  <div
    v-else
    class="flex justify-center my-10"
  >
    <p class="font-bold">
      Une erreur s'est produite, réessayez plus tard
    </p>
  </div>
</template>
