<script setup lang="ts">
import {NotificationSequenceMessage} from "@/notification-sequences/interfaces/NotificationSequence";

defineProps<{
    messages: NotificationSequenceMessage[],
    color?: string
}>();
</script>

<template>
  <div class="timeline">
    <div v-for="(item, index) in messages" :key="index" class="timeline-item">
      <div class="timeline-item-content">
        <div class="sms-bubble">
          <span>SMS</span>
        </div>
        <div class="timeline-label">
          <p>J{{ item.delay !== 0 ? '+' : '' }}{{ item.delay }}: {{ item.title }}</p>
        </div>
      </div>
      <div v-if="index < messages.length - 1" class="timeline-line" />
    </div>
  </div>
</template>

<style scoped>
.timeline {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.timeline-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex: 1;
}

.timeline-item-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.sms-bubble {
    width: 40px;
    height: 40px;
    background-color: v-bind(color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
    position: relative;
    z-index: 2;
}

.sms-bubble:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: v-bind(color);
    transform: rotate(45deg);
    bottom: -3px;
    left: 15px;
}

.timeline-label {
    text-align: center;
    font-size: 14px;
    color: v-bind(color);
    font-weight: bold;
    width: 100%;
    margin: 0;
    word-wrap: break-word;
}

.timeline-label p {
    margin: 0;
    padding: 0;
}

.timeline-line {
    position: absolute;
    top: 20px;
    right: calc(-50% + 20px);
    height: 2px;
    background-color: v-bind(color);
    width: calc(100% - 40px);
    z-index: 1;
}
</style>
