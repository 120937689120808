<template>
  <v-chip
    :variant="variant"
    :color="color"
  >
    <Icon v-if="icon" :icon="icon" start :color="iconColor" />
    {{ label }}
  </v-chip>
</template>

<script lang="ts" setup>
import {computed} from "vue";
import Icon from "@/core/components/icon/Icon.vue";

const {selected, label, selectedColor} = defineProps<{
    selected: boolean;
    label: string;
    icon?: string;
    selectedColor?: string;
}>();

/**
 * Variant dynamic property of the filter.
 */
const variant = computed(() => {
    return selected ? 'flat' : 'tonal';
});

/**
 * Color dynamic property of the filter.
 */
const color = computed(() => {
    if (selectedColor && selected) {
        return selectedColor;
    }

    return selected ? 'green' : 'default';
});

/**
 * Icon color dynamic property of the filter.
 */
const iconColor = computed(() => {
    return selected ? 'white' : 'default';
});

</script>
