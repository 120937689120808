import {init, setUser} from "@sentry/electron/renderer";
import {
    init as vueInit,
    browserTracingIntegration,
    replayIntegration,
    dedupeIntegration,
    attachErrorHandler,
    setUser as vueSetUser
} from "@sentry/vue";
import {App} from "vue";
import {Router} from "vue-router";
import electronHelper from "@/core/services/electronHelper";

const SentryService = () => {
    return {
        /**
         * Initialize sentry.
         *
         * @param app
         * @param router
         */
        initialize(app: App, router: Router) {
            if (electronHelper.isElectron()) {
                this.initializeForElectron(app, router);
            } else {
                this.initializeForWeb(app, router);
            }

            attachErrorHandler(app, {
                logErrors: true,
                attachProps: true,
                trackComponents: true,
                timeout: 2000,
                hooks: ['activate', 'mount', 'update']
            });
        },
        /**
         * Set the user in the sentry context.
         */
        setUser(email: string) {
            if (electronHelper.isElectron()) {
                setUser({email});
            } else {
                vueSetUser({email});
            }
        },

        /**
         * Initialize sentry for electron.
         *
         * @param app
         * @param router
         */
        initializeForElectron(app: App, router: Router) {
            init(this.commonConfig(app, router), vueInit);
        },

        /**
         * Initialize sentry for web.
         *
         * @param app
         * @param router
         */
        initializeForWeb(app: App, router: Router) {
            vueInit({
                dsn: 'https://aee5f05309edb377077d2227736230f9@o4508160012124160.ingest.de.sentry.io/4508160144769104',
                ...this.commonConfig(app, router)
            });
        },

        /**
         * Get the common config for electron sentry and web sentry.
         * @param app
         * @param router
         */
        commonConfig(app: App, router: Router) {
            return {
                app,
                integrations: (integrations) => {
                    const _integrations = integrations.filter((integration => integration.name !== dedupeIntegration().name));
                    _integrations.push(browserTracingIntegration({router}));
                    _integrations.push(replayIntegration);

                    return _integrations;
                },
                tracesSampleRate: 1.0,
                replaysSessionSampleRate: 1,
                replaysOnErrorSampleRate: 1.0,
                trackComponents: true,
                tracePropagationTargets: [/^\/api\//],
                enabled: import.meta.env.PROD,
            };
        }
    };
};

export default SentryService();
