<script setup lang="ts">

</script>

<template>
  <div class="flex flex-col rounded-md overflow-hidden bg-white">
    <div v-if="$slots['headline']" class="bg-secondary-lightest py-2 px-4">
      <slot name="headline" />
    </div>
    <div v-if="$slots['default']" class="px-4 py-2">
      <slot />
    </div>
  </div>
</template>

<style scoped>

</style>
