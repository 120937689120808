<script setup lang="ts">
import {onMounted, onUnmounted, ref} from "vue";
import {Dialog, DialogScrollContent, DialogTrigger} from "@/core/components/ui/dialog";
import BaseButton from "@/core/components/base/BaseButton.vue";
import DateTimePicker from "@/core/components/date-picker/DateTimePicker.vue";
import {Popover, PopoverContent, PopoverTrigger} from "@/core/components/ui/popover";
import Icon from "@/core/components/icon/Icon.vue";
import dateHelper from "@/core/helpers/dateHelper";
import {useVModel} from '@vueuse/core';

const props = withDefaults(defineProps<{
    modelValue: string | null;
    minDate?: string;
    showSelectedDate?: boolean;
    displayTime?: boolean;
    editable?: boolean;
}>(), {minDate: undefined, editable: true, displayTime: true});

const emit = defineEmits(['update:modelValue', 'onConfirm']);

const dateValue = useVModel(props, 'modelValue', emit);
const isMobile = ref(false);
const isSmallScreen = ref(false);
const isOpen = ref(false);

const checkIfMobile = () => {
    isMobile.value = window.innerWidth <= 768;
    isSmallScreen.value = window.innerHeight < 800;
};

onMounted(() => {
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
});

onUnmounted(() => {
    window.removeEventListener('resize', checkIfMobile);
});

/**
 * Handle date confirmation.
 *
 * @param value
 */
const handleConfirm = (value: string) => {
    isOpen.value = false;
    dateValue.value = value;
    emit('onConfirm', value);
};
</script>

<template>
  <div>
    <div v-if="isMobile || isSmallScreen">
      <Dialog v-model:open="isOpen">
        <DialogTrigger as-child>
          <div v-if="showSelectedDate && modelValue">
            <span class="text-primary text-base mr-1 cursor-pointer inline-flex items-center">
              {{ dateHelper.shortFormat(modelValue, 'DD MMMM à HH:mm') }}
              <Icon name="mdi-calendar-month" class="ml-1" />
            </span>
          </div>
          <BaseButton v-else append-icon="mdi-calendar-month">
            Ajouter
          </BaseButton>
        </DialogTrigger>
        <DialogScrollContent class="p-0">
          <DateTimePicker
            v-model="dateValue"
            :min-date="minDate"
            :display-time="displayTime"
            @on-confirm="handleConfirm"
          />
        </DialogScrollContent>
      </Dialog>
    </div>
    <div v-else>
      <Popover :open="isOpen" @update:open="editable ? isOpen = $event : undefined">
        <PopoverTrigger as-child>
          <div>
            <div v-if="showSelectedDate && modelValue">
              <span class="text-primary text-base mr-1 cursor-pointer inline-flex items-center">
                {{ dateHelper.shortFormat(modelValue, 'DD MMMM à HH:mm') }}
                <Icon name="mdi-calendar-month" class="ml-1" />
              </span>
            </div>
            <BaseButton v-else append-icon="mdi-calendar-month">
              Ajouter
            </BaseButton>
          </div>
        </PopoverTrigger>
        <PopoverContent class="p-0 w-auto" align="center" side="bottom">
          <DateTimePicker
            v-model="dateValue"
            :min-date="minDate"
            :display-time="displayTime"
            @on-confirm="handleConfirm"
          />
        </PopoverContent>
      </Popover>
    </div>
  </div>
</template>
