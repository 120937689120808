<script setup lang="ts">
import {Laboratory} from "@/core/interfaces/laboratory/Laboratory";
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import useLaboratoryOfferStatistics from "@/laboratory/composables/useLaboratoryOfferStatistics";
import LaboratoryStatisticCard from "@/laboratory/components/LaboratoryStatisticCard.vue";
import LaboratoryOfferLoyalConsumersCard from "@/laboratory/components/offer/LaboratoryOfferLoyalConsumersCard.vue";
import useSwr from "@/core/composables/swr/useSwr";
import {offerApi} from "@/container";
import LaboratoryOfferSalesGrowthHistogram from "@/laboratory/components/offer/LaboratoryOfferSalesGrowthHistogram.vue";
import LaboratoryOfferConsumerDatatable from "@/laboratory/components/offer/LaboratoryOfferConsumerDatatable.vue";
import LaboratoryOfferProductDatatable from "@/laboratory/components/offer/LaboratoryOfferProductDatatable.vue";
import {computed} from "vue";
import {useRoute} from "vue-router";
import Icon from "@/core/components/icon/Icon.vue";

const props = defineProps<{
    laboratoryId: string;
    offerId: string;
    laboratory: Laboratory;
}>();

const {data: offer} = useSwr(
    `offer/${props.offerId}`,
    () => offerApi().read(Number(props.offerId))
);
const {data: statistics, isLoading} = useLaboratoryOfferStatistics(props.laboratoryId, props.offerId);
const route = useRoute();
const params = route.query as { offerName: string; offerStartAt: string; offerEndAt: string };

const growthPercentageSign = computed(() => {
    if (!statistics.value?.sales_impact.growth_percentage) {
        return '';
    }

    return statistics.value?.sales_impact.growth_percentage > 0 ? '+ ' : '';
});
</script>

<template>
  <BaseContainer class="h-full p-6">
    <div class="flex flex-col gap-4">
      <div class="flex items-center gap-2">
        <button class="p-2" @click="$router.back()">
          <Icon name="mdi-arrow-left" :size="20" color="primary" />
        </button>
        <h1>
          {{ params.offerName }} du {{ $filters.date(params.offerStartAt, "short") }} au
          {{ $filters.date(params.offerEndAt, "short") }}
        </h1>
      </div>
      <div class="flex gap-4 flex-wrap">
        <LaboratoryStatisticCard
          class="flex-1 min-w-[240px] sm:min-w-[200px]"
          label="Effet sur les ventes"
          :value="growthPercentageSign + (statistics?.sales_impact.growth_percentage ?? 0).toString() + '%'"
          :is-loading-data="isLoading"
          period-label="Impact de la promotion par rapport aux ventes de la même période de l'année précédente : 30 jours avant la promotion : ${statistics?.sales_impact?.before_rate} ventes/jour pendant la promotion : ${statistics?.sales_impact?.during_rate} ventes/jour`"
        >
          <template #periodLabel>
            <div>
              Impact de la promotion par rapport aux ventes de la même période de l'année précédente :
              <p>30 jours avant la promotion : {{ statistics?.sales_impact?.before_rate.toFixed(2) }} ventes/jour</p>
              <p>Pendant la promotion : {{ statistics?.sales_impact?.during_rate.toFixed(2) }} ventes/jour</p>
            </div>
          </template>
        </LaboratoryStatisticCard>
        <LaboratoryStatisticCard
          class="flex-1 min-w-[240px] sm:min-w-[200px]"
          label="Découverte marque"
          :value="`${(statistics?.brand_discovery ?? 0)} patient${(statistics?.brand_discovery ?? 0) > 1 ? 's' : ''}`"
          :is-loading-data="isLoading"
          period-label="Nombre de patient ayant acheté pour la première un produit du laboratoire fois pendant la promotion"
        />
        <LaboratoryStatisticCard
          class="flex-1 min-w-[240px] sm:min-w-[200px]"
          label="Découverte produit"
          :value="`${statistics?.product_discovery ?? 0} patient${(statistics?.product_discovery ?? 0) > 1 ? 's' : ''}`"
          :is-loading-data="isLoading"
          period-label="Nombre de patient ayant acheté pour la première fois un produit de la promotion"
        />
      </div>

      <LaboratoryOfferLoyalConsumersCard
        v-if="statistics?.new_loyal_customers.data.length"
        :laboratory="laboratory"
        :total="statistics?.new_loyal_customers.total ?? 0"
        :consumers="statistics?.new_loyal_customers.data.map(_data => _data.consumer)"
        :color="laboratory.branding?.primary_color"
      />

      <LaboratoryOfferSalesGrowthHistogram
        :laboratory-id="laboratoryId"
        :offer-id="offerId"
        :offer="offer"
        :color="laboratory.branding?.primary_color"
      />
      <LaboratoryOfferConsumerDatatable :laboratory-id="laboratoryId" :offer-id="offerId" />
      <LaboratoryOfferProductDatatable :laboratory-id="laboratoryId" :offer-id="offerId" />
    </div>
  </BaseContainer>
</template>
