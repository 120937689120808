<script setup lang="ts">
import BaseButton from "@/core/components/base/BaseButton.vue";

/**
 * Handle the click on the shared medical record button.
 */
const handleSharedMedicalRecordClick = () => {
    window.ipcRenderer.openInBrowser('https://www.dmp.fr/web/dmp/ps');
};
</script>

<template>
  <BaseButton @click="handleSharedMedicalRecordClick">
    <img src="@/assets/images/dmp.jpg" class="h-8" alt="DMP">
    Accéder au DMP
  </BaseButton>
</template>
