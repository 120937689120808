import ApodisSecureStep from "@/onboarding/components/installation-process/ApodisSecureStep.vue";
import ApodisProStep from "@/onboarding/components/installation-process/ApodisProStep.vue";
import ApodisConnectStep from "@/onboarding/components/installation-process/ApodisConnectStep.vue";
import {ComputedRef} from "vue";

interface StepConfig {
    step: number;
    component: any;
    getProps?: () => Record<string, any>;
}

const useInstallationSteps = (
    lgoName: ComputedRef<string>,
    lgoInstall: ComputedRef<string>,
    santeSecurePing: ComputedRef<boolean>,
    santeSecureVersion: ComputedRef<string | undefined>,
) => {
    const steps: StepConfig[] = [
        {
            step: 1,
            component: ApodisSecureStep,
            getProps: (): InstanceType<typeof ApodisSecureStep>["$props"] => ({
                lgoName: lgoName.value,
                lgoInstall: lgoInstall.value,
                santeSecurePing: santeSecurePing.value,
                santeSecureVersion: santeSecureVersion.value,
            }),
        },
        {
            step: 2,
            component: ApodisConnectStep,
        },
        {
            step: 3,
            component: ApodisProStep,
        }
    ];

    return steps;
};

export default useInstallationSteps;
