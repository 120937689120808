<script setup lang="ts">
import {InterviewInstance} from "@/interview/interfaces/interview";
import {BackingInterviewStep} from "@/backing/interfaces/backing";
import Icon from "@/core/components/icon/Icon.vue";
import {useRouter} from "vue-router";

const props = defineProps<{
    data: {
        instance: Omit<InterviewInstance, "interview">,
        step: Omit<BackingInterviewStep, "interview_instances">
    },
    backingId: number,
    consumerKey: string,
    consumerName: string
}>();

const router = useRouter();

/**
 * Redirect to the interview instance view.
 * The interview instance layout is responsible of navigating to the correct view depending the instance state.
 */
const handleClick = () => {
    router.push({
        name: 'interview-instances',
        query: {
            backingId: props.backingId,
            consumerKey: props.consumerKey,
            interviewId: props.data.step.resource_id,
            interviewInstanceId: props.data.instance.id,
            interviewName: props.data.step.name
        }
    });
};
</script>

<template>
  <div class="hover:opacity-75 cursor-pointer" @click="handleClick">
    <div class="min-w-48 flex flex-row justify-between">
      <div>
        <icon
          :size="20"
          :name="data.instance.ended_at ? 'mdi-check-bold' : 'mdi-check-outline'"
          class="mr-1"
          :color="data.instance.ended_at ? 'secondary': 'gray'"
        />
        <span>{{ data.step.name }}</span>
      </div>
      <div v-if="!data.instance.ended_at" class="flex justify-end">
        <span class="text-sm font-semibold text-primary">Finaliser l'entretien</span>
      </div>
      <div v-else class="flex flex-row justify-end" @click="handleClick">
        <icon
          :size="20"
          :name="'mdi-file'"
          class="mr-1"
        />
        <span class="text-sm font-semibold text-primary">Accéder au compte rendu</span>
      </div>
    </div>
    <span class="text-sm  pl-6">
          Fait le {{ $filters.date(data.instance?.ended_at || data.instance.started_at, 'medium') }}
        </span>
  </div>
</template>
