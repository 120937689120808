<script setup lang="ts">

import TrialStatusContainer from "@/trial/components/trial-status/TrialStatusContainer.vue";
import Icon from "@/core/components/icon/Icon.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {useRouter} from "vue-router";
import MarkdownView from "@/core/components/markdown/Markdown.vue";
import {storeToRefs} from "pinia";
import {useSubscriptionPlanStore} from "@/stores/subscription-plan.store";
import SupportService from "@/core/services/SupportService";

const router = useRouter();

const {globalPlan} = storeToRefs(useSubscriptionPlanStore());

const navigateToGlobalPlan = () => {
    router.push({name: 'subscription-plans'});
};
</script>

<template>
  <TrialStatusContainer>
    <template #headline>
      <div class="space-y-2">
        <div class="flex items-center gap-2 border-b pb-2">
          <Icon name="mdi-crown" color="primary" :size="30" />
          <p class="flex-1">
            Votre essai gratuit est terminé 😢
          </p>
          <div class="flex items-baseline relative gap-1">
            <Icon name="mdi-phone" :size="25" />
            <p
              class="underline cursor-pointer font-content-bold text-primary bottom-0.5 relative"
              @click="SupportService.openCEOSupportWindow()"
            >
              Contactez un conseiller
            </p>
          </div>
        </div>
        <div class="py-2">
          <MarkdownView
            v-if="globalPlan?.sales_pitch"
            class="sales-pitch"
            inherit-styles
            :content="globalPlan.sales_pitch"
          />
        </div>
        <div class="flex items-center justify-between">
          <p class="text-primary font-content-bold">
            N'attendez plus, libérez le potentiel de votre pharmacie 🚀
          </p>
          <BaseButton density="comfortable" primary @click="navigateToGlobalPlan">
            Passer premium
          </BaseButton>
        </div>
      </div>
    </template>
  </TrialStatusContainer>
</template>

<style scoped>
.sales-pitch {
    @apply text-primary;
}

:deep(.sales-pitch strong) {
    @apply text-primary font-content-bold;
}
</style>
