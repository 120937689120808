<script setup lang="ts">
import ProductImage from "@/product/components/ProductImage.vue";
import BaseCard from "@/core/components/base/BaseCard.vue";
import LaboratoryProductQuantityStepper from "@/laboratory/components/LaboratoryProductQuantityStepper.vue";
import ProductPriceLine from "@/sellin-offer/components/ProductPriceLine.vue";
import LaboratoryProduct from "@/core/interfaces/laboratory/LaboratoryProduct";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../tailwind.config";
import {Config} from "tailwindcss";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {useCartStore} from "@/laboratory/stores/cart.store";
import {computed, ref} from "vue";
import {storeToRefs} from "pinia";

const {need, quantity} = defineProps<{
    need: LaboratoryProduct;
    color?: string;
    quantityNeeded: number;
    secondaryColor?: string;
    available: boolean;
    quantity?: number;
}>();

defineEmits(['on-update']);

const {theme} = resolveConfig(tailwindConfig as Config);
const {cart, franco} = storeToRefs(useCartStore());
const localQuantity = ref(quantity);
const quantityInCart = computed(() => {
    return cart.value?.lines?.find((line) => line.product.id === need.id)?.quantity || 0;
});

/**
 * Get the label to display depending on the availability.
 *
 * @return {string}
 */
const getAvailabilityLabel = (available?: boolean): string => {
    switch (available) {
        case true:
            return "Disponible";
        case false:
            return "Indisponible";
        default:
            return "Non renseigné";
    }
};

/**
 * Get availability chip color.
 */
const availabilityChipColor = (available?: boolean): string => {
    return available === undefined
        ? 'transparent'
        : available
            ? (theme.colors as any).secondary.DEFAULT
            : (theme.colors as any).red.default;
};

/**
 * Get availability chip border color.
 */
const availabilityBorderColor = (available?: boolean): string => {
    return available === undefined
        ? (theme.colors as any).gray.default
        : available
            ? (theme.colors as any).secondary.DEFAULT
            : (theme.colors as any).red.default;
};

</script>

<template>
  <BaseCard
    class="mx-5"
  >
    <div class="flex flex-row p-4 gap-2">
      <ProductImage :product="need" :size="28" />
      <div class="flex flex-1 flex-col">
        <div class="flex flex-row justify-between">
          <div class="flex flex-col items-start">
            <div class="flex flex-row justify-between">
              <p class="font-semibold">
                {{ need.name }}
              </p>
            </div>
            <p class="text-gray-500">
              CIP {{ need.cip13 }}
            </p>
            <div>
              <p v-if="need?.order_comment" class="text-gray-700">{{ need.order_comment }}</p>
              <p v-else-if="need?.has_order_quota" class="text-gray-700">
                Produit sous quota
              </p>
            </div>
            <div class="mt-2">
              <ProductPriceLine :product="need" unit="u" color="black" />
            </div>
          </div>
          <div v-if="franco" class="flex flex-col items-center justify-end">
            <base-button
              v-if="localQuantity"
              :color="color"
              :style="{'color': secondaryColor}"
              @click="$emit('on-update', localQuantity)"
            >
              Ajouter {{ localQuantity }}
            </base-button>
            <p v-if="quantityInCart < quantityNeeded" class="text-red pt-1">
              {{ quantityInCart }} dans le panier
            </p>
            <LaboratoryProductQuantityStepper
              :min-quantity="need.laboratoryShopPrice.minimumQuantity"
              :color="color"
              :secondary-color="secondaryColor"
              :packing-quantity="need.laboratoryShopPrice.packingQuantity"
              :current="quantity"
              @on-update="newQuantity => localQuantity = newQuantity"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-between bg-gray-200 p-2 px-4">
      <div>
        <p>
          Stock <span class="font-semibold mr-4">{{ need.needs?.quantityInStock || 0 }}</span>
          <span v-if="!!need.needs?.monthlySales">
            Ventes/mois <span class="font-semibold mr-4">{{ need.needs?.monthlySales || 0 }}</span>
          </span>
          Besoin <span class="font-semibold mr-4">{{ need.needs?.quantityNeeded || 0 }}</span>
        </p>
      </div>
      <div class="flex flex-row items-center gap-1">
        <div
          class="w-3 h-3 rounded-full border-2 border-gray-400"
          :style="{
            'background-color': availabilityChipColor(need.availability?.available),
            'border-color': availabilityBorderColor(need.availability?.available)
          }"
        />
        <p>{{ getAvailabilityLabel(need.availability?.available) }}</p>
      </div>
    </div>
  </BaseCard>
</template>

<style scoped>

</style>