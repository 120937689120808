import {acceptHMRUpdate, defineStore} from "pinia";
import dayjs from "dayjs";
import {PatientSearchParams} from "@/customer/interfaces/Patient";

export const usePatientDashboardStore = defineStore('patient-dashboard', {
    state: (): { searchParams: PatientSearchParams } => ({
        searchParams: {
            limit: 20,
            page: 1,
            from: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
            sort_by: null,
            sort_order: null,
            tags: [],
            certified: null,
            children: null,
            purchase_type: null,
            purchase_id: null,
            age_to: null,
            consumer_key: null,
            tags_operator: null,
            visits_from: null,
            visits_to: null,
            gist: null,
        }
    }),
    actions: {}
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(usePatientDashboardStore, import.meta.hot));
}
