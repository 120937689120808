import {AxiosInstance} from "axios";
import {Laboratory} from "@/core/interfaces/laboratory/Laboratory";
import Customer from "@/customer/interfaces/Customer";
import Consumer from "@/core/interfaces/consumers/Consumer";
import {LaboratoryConsumerAnalysisSearchParams} from "@/laboratory/composables/useConsumerAnalysis";
import {Routine} from "@/laboratory/interfaces";
import Offer, {
    OfferConsumerResponse,
    OfferGrowthHistogram,
    OfferProductResponse,
    OfferStatistics
} from "@/core/interfaces/Offer";
import LaboratoryProduct from "@/core/interfaces/laboratory/LaboratoryProduct";
import Branding from "@/core/interfaces/laboratory/Branding";
import {LaboratoryFranco} from "@/core/interfaces/laboratory/LaboratoryFranco";
import LaboratoryPartnership from "@/core/interfaces/laboratory/LaboratoryPartnership";

export interface CustomerAnalysisResponse {
    data: {
        customer?: Customer,
        consumer?: Consumer,
        consumer_key?: string,
        units: number,
        revenue: number,
        cart_count: number,
        last_visit: string,
        average_cart_quantity: number,
        average_cart_revenue: number,
        top_sale?: {
            datetime: string;
            product: {
                name: string;
                code: string;
            }
        }
    }[],
    meta: {
        top_consumers: {
            customer?: Customer,
            consumer?: Consumer,
            consumer_key?: string,
            units: number,
            revenue: number,
            cart_count: number,
            last_visit: string
        }[],
        total_consumer_count: number,
        today_consumers: number,
        average_quantity: number,
        average_price: number,
        faithful_consumer_count: number,
        new_consumers_count: number,
    }
}

export interface LaboratoryOperatorAnalysis {
    /** The name of the operator or the operator code if it could not be found */
    operator: string;
    operator_code: string;
    revenue: number;
    average_cart_price: number;
    total_quantity: number;
    average_cart_quantity: number;
    sale_histogram: {
        key_as_string: string;
        key: number;
        revenue: number;
    }[]
}

export interface LaboratorySale {
    consumer?: Consumer;
    consumer_key?: string;
    product: {
        name: string;
        code: string;
    },
    datetime: string;
    quantity: number;
    revenue: number;
    operator: string;
}

export interface LaboratoryProductsStatistics {
    product: {
        id: string;
        name: string;
        code: string;
    },
    consumer_count: number;
    revenue: number;
    quantity: number;
    last_sale: string;
}

export default function (client: AxiosInstance, hdsClient: AxiosInstance) {
    return {
        /**
         * Get laboratories that has patient partnership.
         */
        async patientPartnerLaboratories(): Promise<Laboratory[]> {
            return await client.get('/partnership/patient/laboratories')
                .then((response) => {
                    return response.data.data;
                });
        },

        /**
         * Read a laboratory that has patient partnership.
         */
        async patientPartnerLaboratory(laboratoryId: number): Promise<Laboratory> {
            return await client.get(`/partnership/patient/laboratories/${laboratoryId}`)
                .then((response) => {
                    return response.data.data;
                });
        },

        async patientPartnerStatistics(laboratoryId: number): Promise<{
            total_revenue: number,
            consumer_count: number,
            today_consumer_count: number,
            top_operator: { name?: string, code?: string },
        }> {
            return await client.get(`/partnership/patient/laboratories/${laboratoryId}/statistics`)
                .then((response) => {
                    return response.data.data;
                });
        },

        /**
         * Get the analysis of customers for a laboratory.
         */
        async customers(laboratoryId: number, params?: LaboratoryConsumerAnalysisSearchParams): Promise<CustomerAnalysisResponse> {
            return await hdsClient.get(`/laboratories/${laboratoryId}/customers/analysis`, {
                params
            })
                .then((response) => {
                    return response.data;
                });
        },
        /**
         * Get the analysis of operators for a laboratory.
         *
         * @param {number} laboratoryId
         * @param {object} params
         */
        async operatorAnalysis(laboratoryId: number, params: {
            since?: string
        } = {}): Promise<LaboratoryOperatorAnalysis[]> {
            return await client.get(`laboratories/${laboratoryId}/operators/analysis`, {params})
                .then((response) => {
                    return response.data.data;
                });
        },

        /**
         * Get the sales of a laboratory.
         */
        async sales(laboratoryId: number, params: {
            operator_code?: string;
            consumer_key?: string,
            page?: number,
            size?: number,
            sortBy?: string,
            order?: string,
            since?: string,
            product_id?: string
        } = {}): Promise<{
            data: LaboratorySale[],
            meta: { total: number }
        }> {
            return await hdsClient.get(`laboratories/${laboratoryId}/sales`, {params})
                .then((response) => {
                    return response.data;
                });
        },

        /**
         * Get the products statistics for a laboratory.
         */
        async products(
            laboratoryId: number,
            params: {
                page?: number,
                size?: number,
                sort_by?: string,
                order?: string,
                since?: string,
            } = {}
        ): Promise<{ data: LaboratoryProductsStatistics[]; meta: { total: number } }> {
            return await client.get(`laboratories/${laboratoryId}/products/statistics`, {params})
                .then((response) => {
                    return response.data;
                });
        },

        /**
         * Get the routines of a laboratory.
         *
         * @param laboratoryId
         */
        async routines(laboratoryId: number): Promise<Routine[]> {
            return await client.get(`routines`, {
                params: {
                    laboratory_id: laboratoryId
                }
            })
                .then((response) => {
                    return response.data.data;
                });
        },

        /**
         * Update the partnership status of a laboratory for the current healthcenter.
         *
         * @param organizationId
         * @param enabled
         */
        async updatePartnershipStatus(organizationId: number, enabled: boolean) {
            return await client.patch(`/healthcenter/partnerships/${organizationId}`, {
                enabled
            })
                .then((response) => {
                    return response.data.data;
                });
        },

        /**
         * Get offers for a laboratory.
         */
        async offers(params): Promise<Offer[]> {
            return await client.get('imported-offers', {
                params
            })
                .then((response) => {
                    return response.data.data;
                });
        },

        /**
         * Get the statistics of an offer for a laboratory.
         *
         * @param laboratoryId
         * @param offerId
         */
        async offerStatistics(laboratoryId: string, offerId: string): Promise<OfferStatistics> {
            return await hdsClient.get(`/laboratories/${laboratoryId}/offers/${offerId}/statistics`)
                .then((response) => {
                    return response.data.data;
                });
        },

        /**
         * Get the sales growth histogram of an offer for a laboratory.
         *
         * @param laboratoryId
         * @param offerId
         */
        async offerSalesGrowthHistogram(laboratoryId: string, offerId: string): Promise<OfferGrowthHistogram> {
            return await hdsClient.get(`/laboratories/${laboratoryId}/offers/${offerId}/sales-growth-histogram`)
                .then((response) => {
                    return response.data.data;
                });
        },

        /**
         * Get offer consumers analysis for laboratory.
         *
         * @param laboratoryId
         * @param offerId
         * @param params
         */
        async offerConsumers(laboratoryId: string, offerId: string, params): Promise<OfferConsumerResponse> {
            return await hdsClient.get(`/laboratories/${laboratoryId}/offers/${offerId}/consumers`, {
                params
            })
                .then((response) => {
                    return response.data.data;
                });
        },

        /**
         * Get offer products analysis for laboratory.
         *
         * @param laboratoryId
         * @param offerId
         * @param params
         */
        async offerProducts(laboratoryId: string, offerId: string, params): Promise<OfferProductResponse> {
            return await hdsClient.get(`/laboratories/${laboratoryId}/offers/${offerId}/products`, {
                params
            })
                .then((response) => {
                    return response.data.data;
                });
        },

        /**
         * Get laboratory needs paginated.
         *
         * @param {number} laboratoryId - The laboratory id.
         * @param {number} page - The results page number.
         * @param {number} limit - The elements limit on each page.
         *
         * @returns {Promise<LaboratoryProduct[]>}
         */
        async needs(laboratoryId: number, page = 1, limit = 15): Promise<LaboratoryProduct[]> {
            return await client.get(`/laboratories/${laboratoryId}/ranges/products/paginatedWithNeeds`, {
                params: {
                    page,
                    limit,
                    include: "laboratoryPriceRanges.laboratoryPrice,availability"
                }
            })
                .then(response => {
                    return response.data.data;
                });
        },

        /**
         * Get laboratory branding
         *
         * @param {number} laboratoryId - The laboratory id.
         *
         * @returns {Promise<Branding>}
         */
        async branding(laboratoryId: number): Promise<Branding> {
            return client.get(`/laboratories/${laboratoryId}/branding`, {
                params: {
                    include: "laboratory.organization,logo,background,sliders,parentRanges,parentRanges.branding," +
                        "parentRanges.branding.logo,parentRanges.branding.background,parentRanges.childrenRanges," +
                        "parentRanges.childrenRanges.branding,parentRanges.childrenRanges.branding.background"
                }
            })
                .then(response => {
                    const {data} = response;
                    return data.laboratoryBranding;
                });
        },

        /**
         * Get laboratory line.
         *
         * @param {number} laboratoryId - The laboratory id.
         * @param {number} rangeId - The results page number.
         *
         * @returns {Promise<LaboratoryProduct[]>}
         */
        async line(laboratoryId: number, rangeId: number): Promise<LaboratoryProduct[]> {
            return client.get(`/laboratories/${laboratoryId}/ranges/${rangeId}/products/needs`, {
                params: {
                    include: "laboratoryPriceRanges.laboratoryPrice,availability"
                }
            })
                .then(response => {
                    return response.data.data;
                });
        },

        /**
         * Get laboratory franco.
         *
         * @param {number} laboratoryId - The laboratory id.
         * @param {number} healthcenterId - The healthcenter id.
         *
         * @returns {Promise<LaboratoryFranco>}
         */
        async franco(laboratoryId: number, healthcenterId: number): Promise<LaboratoryFranco> {
            return client.post(`/laboratories/${laboratoryId}/francos/healthcenters/${healthcenterId}`)
                .then(response => {
                    const {data} = response;
                    return data.data;
                });
        },
        /**
         * Get laboratory partnership.
         *
         * @param {number} laboratoryId - The laboratory id.
         *
         * @returns {Promise<LaboratoryPartnership>}
         */
        async read(laboratoryId: number): Promise<LaboratoryPartnership> {
            return client.get(`/laboratories/${laboratoryId}`)
                .then(response => {
                    const {data} = response;
                    return data.data;
                });
        },

        /**
         * Get laboratory settings.
         *
         * @param {number} laboratoryId - Laboratory id.
         * @param {number} healthcenter - Healthcenter id.
         *
         * @returns {Promise<any>}
         */
        async getLaboratorySettings(laboratoryId: number, healthcenter: number): Promise<any> {
            return client.get(`/healthcenters/${healthcenter}/reassortConfig/${laboratoryId}`)
                .then(response => {
                    const {data} = response;
                    return data.data;
                });
        },

        /**
         * Set laboratory settings.
         *
         * @param {number} laboratoryId - Laboratory id.
         * @param {number} healthcenter - HealthCenter id.
         * @param {number} daysCount
         * @param {number} needsRounding
         *
         * @returns {Promise<any>}
         */
        async setLaboratorySettings(
            laboratoryId: number,
            healthcenter: number,
            daysCount: number,
            needsRounding: number
        ): Promise<any> {
            return client.post(`/healthcenters/${healthcenter}/reassortConfig/${laboratoryId}`, {
                daysCount,
                needsRounding
            })
                .then(response => {
                    const {data} = response;
                    return data.data;
                });
        }
    };
}
