<script setup lang="ts">
import useApodisPremiumContentful from "@/subscription-plan/composables/useApodisPremiumContentful";
import {onMounted, onUnmounted} from "vue";
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import SupportService from "@/core/services/SupportService";
import ContentfulTestimonial from "@/contentful/components/ContentfulTestimonial.vue";
import {Accordion} from "@/core/components/ui/accordion";
import ContentfulFAQItem from "@/contentful/components/ContentfulFAQItem.vue";
import HomeSponsorship from "@/home/components/HomeSponsorship.vue";
import ApodisPremiumHeader from "@/subscription-plan/components/apodis-premium/ApodisPremiumHeader.vue";
import ApodisPremiumCheckoutButton from "@/subscription-plan/components/apodis-premium/ApodisPremiumCheckoutButton.vue";
import ApodisPremiumSalesPitch from "@/subscription-plan/components/apodis-premium/ApodisPremiumSalesPitch.vue";
import ApodisPremiumHeroFooter from "@/subscription-plan/components/apodis-premium/ApodisPremiumHeroFooter.vue";
import {useRouter} from "vue-router";
import {useSubscriptionPlanStore} from "@/stores/subscription-plan.store";

const {data, isLoading} = useApodisPremiumContentful();
const router = useRouter();
const subscriptionPlansStore = useSubscriptionPlanStore();

onMounted(() => {
    window.ipcRenderer.on("stripe-checkout-success", () => {
        subscriptionPlansStore.loadSubscriptionPlans();

        router.replace({name: 'home'});
    });
});

/**
 * Remove the stripe checkout success event listener when the component is unmounted.
 */
onUnmounted(() => {
    window.ipcRenderer.removeAllListeners("stripe-checkout-success");
});
</script>

<template>
  <BaseContainer>
    <div v-if="!isLoading && data" class="space-y-8">
      <div class="gradient pt-4 pb-8 px-6 text-white space-y-4">
        <div class="flex justify-between gap-6 items-center">
          <ApodisPremiumHeader :title="data.title" :social-proof="data.socialProof" />
          <ApodisPremiumCheckoutButton />
        </div>
        <ApodisPremiumSalesPitch :sales-pitch="data.salesPitch" :sales-pitch-footer="data.salesPitchFooter" />
      </div>
      <div class="px-6 space-y-8">
        <div class="flex justify-between items-center">
          <div class="px-6">
            <h1 class="text-black">
              Vous souhaitez en discuter ?
            </h1>
            <p class="text-primary">
              Nous sommes à l'écoute, contactez-nous !
            </p>
          </div>
          <div>
            <BaseButton
              prepend-icon="mdi-phone" class="text-primary"
              @click="SupportService.openApodisPremiumSupportWindow()"
            >
              Contacter un conseiller
            </BaseButton>
          </div>
        </div>
        <div v-if="data.testimonials?.length" class="space-y-8">
          <div class="bg-white p-4 space-y-4 rounded-md">
            <h1 v-if="data.testimonialHeader" class="text-black">
              {{ data.testimonialHeader }}
            </h1>
            <div class="space-y-8">
              <div
                v-for="(testimonial, index) in data.testimonials ?? []" :key="index"
                class="border-2 rounded-lg p-4 border-primary"
              >
                <ContentfulTestimonial :testimonial="testimonial.fields" />
              </div>
            </div>
          </div>
          <div class="flex px-6 justify-between">
            <div>
              <h2>Toujours pas convaincu ? Vous êtes dur en affaire !</h2>
              <p>Appelez-nous et discutons-en 😉</p>
            </div>
            <BaseButton
              class="text-primary" prepend-icon="mdi-phone"
              @click="SupportService.openApodisPremiumSupportWindow()"
            >
              Contacter un conseiller
            </BaseButton>
          </div>
        </div>
      </div>
      <ApodisPremiumHeroFooter :social-proof="data.socialProof" :sales-pitch-footer="data.salesPitchFooter" />
      <div v-if="data.faq?.length" class="px-6">
        <Accordion type="single" collapsible>
          <ContentfulFAQItem v-for="(item, index) in data.faq" :key="index" :item="item.fields" />
        </Accordion>
      </div>
    </div>
    <div class="pt-8">
      <HomeSponsorship class="w-full" />
    </div>
  </BaseContainer>
</template>
