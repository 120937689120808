import {QuickFilter, QuickFilterCategory, SearchItem} from "@/core/interfaces/search-bar";
import SearchBarAutoCompleteService from "@/core/services/SearchBarAutoCompleteService";
import customerCategories from "@/core/factories/search-bar/CustomerQuickFilters";
import productCategories from "@/core/factories/search-bar//ProductQuickFilters";
import {SearchBarAutoCompleteItem} from "@/core/interfaces/search-bar/ISearchBarAutoComplete";
import {CUSTOMER_SEARCH_QUERY_PARAM_KEYS} from "@/core/interfaces/search-bar/ICustomerSearchBar";

/**
 * The quick filter haystack.
 *
 * @type {QuickFilterCategory[]}
 */
const quickFilterHaystack: QuickFilterCategory[] = [
    ...productCategories,
    ...customerCategories
];

const SearchBarService = () => {
    /**
     * Determine if the given item is part of the given quick filter categories haystack.
     *
     * @param {*} item - The item to search for.
     * @param {QuickFilterCategory[]} haystack - The haystack to search in.
     * Default to customer and product quick filters.
     *
     * @return {item is QuickFilter<any>}
     */
    const isQuickFilter = (item: any, haystack: QuickFilterCategory[] = quickFilterHaystack): item is QuickFilter<any> => {
        if (item.queryParam === undefined || item.value === undefined) {
            return false;
        }

        return haystack.some((category: QuickFilterCategory) => {
            const filters = category.filters;

            return !!filters.find((filter) => {
                if (Array.isArray(filter.value) && Array.isArray(item.value)) {
                    return item.value.some((value: any) => filter.value.includes(value)) && filter.queryParam === item.queryParam;
                }

                if (Array.isArray(item.value)) {
                    return item.value.includes(filter.value) && filter.queryParam === item.queryParam;
                }

                if (filter.queryParam === 'age' || filter.queryParam === CUSTOMER_SEARCH_QUERY_PARAM_KEYS.LAST_VISIT_RANGE) {
                    return (filter.value.from === item.value.from) &&
                        (filter.value.to === item.value.to) &&
                        (filter.queryParam === item.queryParam);
                }
                return filter.value === item.value && filter.queryParam === item.queryParam;
            });
        });
    };

    /**
     * Get the index of the given item in the given haystack.
     *
     * @param {SearchItem} item - The item to search for.
     * @param {SearchItem[]} haystack - The haystack to search in.
     * Default to product and customer quick filters.
     *
     * @return {number}
     */
    const getItemIndex = (item: SearchItem, haystack: SearchItem[]): number => {
        return haystack.findIndex((element) => {
            if (
                SearchBarAutoCompleteService.isAutoCompleteItem(item) &&
                SearchBarAutoCompleteService.isAutoCompleteItem(element)
            ) {
                return (element.id === item.id) && (element.type === item.type);
            }

            if (
                isQuickFilter(item, quickFilterHaystack) &&
                isQuickFilter(element, quickFilterHaystack)
            ) {
                if (element.queryParam === 'age' || element.queryParam === CUSTOMER_SEARCH_QUERY_PARAM_KEYS.LAST_VISIT_RANGE) {
                    return (element.value.from === item.value.from) &&
                        (element.value.to === item.value.to) &&
                        (element.queryParam === item.queryParam);
                }
                return (element.value === item.value) && (element.queryParam === item.queryParam);
            }

            return false;
        });
    };

    /**
     * Transform quick filter items to query params.
     *
     * @param {QuickFilter<any>[]} items
     * @return {Record<string, any[]>}
     */
    const transformQuickFilterToQueryParams = (items: QuickFilter<any>[]): Record<string, any[]> => {
        return items.reduce((params: Record<string, any[]>, item: QuickFilter<any>) => {
            if (params[item.queryParam] === undefined) {
                params[item.queryParam] = Array.isArray(item.value) ? item.value : [item.value];
            } else {
                if (Array.isArray(item.value)) {
                    params[item.queryParam] = [...params[item.queryParam], ...item.value];
                } else {
                    params[item.queryParam].push(item.value);
                }
            }

            return params;
        }, {});
    };

    /**
     * Transform search item to query params.
     *
     * @param {SearchItem[]} items - Th search items to transform.
     *
     * @return {Record<string, any[]>}
     */
    const transformToQueryParams = (items: SearchItem[]): Record<string, any[]> => {
        const autoCompleteItems = items.filter(SearchBarAutoCompleteService.isAutoCompleteItem);

        const isQuickFilterWrapper = (searchItem: SearchItem): searchItem is QuickFilter<any> => {
            return isQuickFilter(searchItem);
        };

        const quickFilterItems = items.filter(isQuickFilterWrapper);

        const autoCompleteParams = SearchBarAutoCompleteService.transformToQueryParams(autoCompleteItems);
        const quickFilterParams = transformQuickFilterToQueryParams(quickFilterItems);

        return {
            ...autoCompleteParams,
            ...quickFilterParams
        };
    };

    /**
     * Transform query params to search items.
     *
     * @param {Record<string, any[]>} queryParams The query params.
     * @param {QuickFilterCategory[]} quickFiltersFactory The quick filters factory haystack.
     *
     * @return {SearchItem[]}
     */
    const transformToSearchItems = (
        queryParams: Record<string, any[]>,
        quickFiltersFactory: QuickFilterCategory[] = quickFilterHaystack
    ): SearchItem[] => {
        const items: SearchItem[] = [];

        Object.keys(queryParams)
            .forEach((key) => {
                const quickFilter = quickFiltersFactory
                    .flatMap((category: QuickFilterCategory) => category.filters)
                    .find((filter: QuickFilter<any>) => {
                        return filter.queryParam === key && queryParams[key].includes(filter.value);
                    });

                if (quickFilter) {
                    items.push(quickFilter);
                }
            });

        return items;
    };

    /**
     * Remove the given item from the selection.
     *
     * @param {SearchItem} item - The item to remove.
     * @param {SearchItem[]} selection - The selection to remove the item from.
     *
     * @return {SearchItem[]} - The new selection.
     */
    const removeFromSelection = (item: SearchBarAutoCompleteItem, selection: SearchBarAutoCompleteItem[]): SearchItem[] => {
        const elementIndex = getItemIndex(item, selection);
        const copy = [...selection];

        if (elementIndex !== -1) {
            copy.splice(elementIndex, 1);
        }

        return copy;
    };

    return {
        isQuickFilter,
        getItemIndex,
        transformToQueryParams,
        removeFromSelection,
        transformToSearchItems
    };
};

export default SearchBarService();
