<script setup lang="ts">
import {useVModel} from "@vueuse/core";

const props = defineProps<{ modelValue: number; color?: string; textColor?: string }>();
const emits = defineEmits(['on-add', 'on-remove']);

const count = useVModel(props, "modelValue", emits);

/**
 * Handle add action.
 */
const handleAdd = () => {
    emits('on-add');
};

/**
 * Handle remove action.
 */
const handleRemove = () => {
    if (count.value > 0) {
        emits('on-remove');
    }
};
</script>

<template>
  <div class="flex flex-row items-center gap-4 py-2">
    <v-btn
      density="compact"
      icon="mdi-minus"
      :color="color"
      :style="{'color': textColor}"
      :disabled="count <= 0"
      @click="handleRemove"
    />
    <p class="w-4 text-center">
      {{ count }}
    </p>
    <v-btn density="compact" icon="mdi-plus" :color="color" :style="{'color': textColor}" @click="handleAdd" />
  </div>
</template>

<style scoped>

</style>