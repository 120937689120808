<script setup lang="ts">
import BaseButton from "@/core/components/base/BaseButton.vue";

const openMSSante = () => {
    window.open('https://mailiz.mssante.fr/');
};
</script>

<template>
  <base-button class="max-w-72 font-bold" @click="openMSSante()">
    <img
      class="block h-10 w-auto"
      src="@/assets/images/logos/logo-ms-sante.png"
      alt="Apodis"
    >
  </base-button>
</template>
