<script setup lang="ts">
defineProps<{
    title: string;
    socialProof?: string;
}>();
</script>

<template>
  <div class="flex justify-between gap-6 items-center">
    <div>
      <p v-if="socialProof" class="text-secondary text-sm font-bold">
        {{ socialProof }}
      </p>
      <h1 class="text-white font-content-bold">
        {{ title }}
      </h1>
    </div>
  </div>
</template>

<style scoped>

</style>
