<script setup lang="ts">
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardHeader from "@/core/components/base/BaseCardHeader.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import ColorHelper from "@/core/helpers/colorHelper";
import {computed, onMounted, ref} from "vue";
import {Laboratory} from "@/core/interfaces/laboratory/Laboratory";

const props = defineProps<{
    laboratory: Laboratory;
    total: number;
    consumers: {
        name: string;
        key: string;
        id: number | null;
    }[];
    color?: string;
}>();

/**
 * Add opacity to the prop color.
 */
const backgroundColor = computed(() => {
    if (!props.color) {
        return undefined;
    }

    return ColorHelper.opacity(props.color, 0.1);
});

const isExpanded = ref(false);
const containerRef = ref<HTMLElement | null>(null);
const showExpandButton = ref(false);
const maxHeight = ref("5rem");

const checkOverflow = () => {
    if (containerRef.value) {
        setTimeout(() => {
            const container = containerRef.value;
            if (container) {
                showExpandButton.value = container.scrollHeight > container.clientHeight;
            }
        }, 200);
    }
};

const toggleExpand = () => {
    isExpanded.value = !isExpanded.value;
};

onMounted(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
});
</script>

<template>
  <BaseCard>
    <BaseCardHeader class="consumer-card">
      <p class="text-xl mb-1">
        {{ total }} {{ $filters.pluralize("Patient", total) }} {{ $filters.pluralize("fidèle", total) }} {{ $filters.pluralize("gagné", total) }} suite à la promotion 🎊
      </p>
      <p class="font-content text-md">
        Ces patients ont découvert la marque pendant la promotion et ont racheté des produits par la suite
      </p>
    </BaseCardHeader>
    <BaseCardBody>
      <div class="overflow-container">
        <div
          ref="containerRef"
          class="flex flex-wrap gap-x-6 gap-y-3 transition-all duration-300"
          :style="{ maxHeight: isExpanded ? '1000px' : maxHeight, overflow: 'hidden' }"
        >
          <div
            v-for="consumer in consumers" :key="consumer.key"
            class="rounded-full bg-white border-[1px] border-gray-300 py-1 px-3 mb-2"
          >
            <p class="consumer-name text-primary">
              {{ consumer.name }}
            </p>
          </div>
        </div>
        <div v-if="!isExpanded && showExpandButton" class="blur-gradient" />
      </div>

      <div v-if="showExpandButton" class="text-center mt-2">
        <button
          class="text-primary font-medium flex items-center justify-center mx-auto transition-all duration-300"
          @click="toggleExpand"
        >
          <span>{{ isExpanded ? 'Voir moins' : 'Voir plus' }}</span>
          <span class="ml-1 transform transition-transform duration-300" :class="{ 'rotate-180': isExpanded }">
            ↓
          </span>
        </button>
      </div>
    </BaseCardBody>
  </BaseCard>
</template>

<style scoped>
.consumer-card {
    background-color: v-bind(backgroundColor) !important;
}

.consumer-name {
    text-transform: lowercase;
}

.consumer-name::first-letter {
    text-transform: uppercase;
}

.overflow-container {
    position: relative;
}

/* Ensure the consumers have consistent height */
.rounded-full {
    height: 2rem;
    display: inline-flex;
    align-items: center;
}

/* Blur gradient at the bottom */
.blur-gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2rem;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    pointer-events: none; /* Allow clicks to pass through */
    z-index: 1;
}
</style>
