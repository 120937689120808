<script setup lang="ts">
import {ref, watch} from "vue";
import BaseInput from "@/core/components/base/BaseInput.vue";

const emits = defineEmits<{
    (e: 'update:modelValue', payload: number): void
}>();

const props = defineProps<{
    rate: number;
    modelValue: number;
}>();

const point = ref(props.modelValue);
const euro = ref(0);

watch(point, (newPoint) => {
    euro.value = newPoint * props.rate;
    emits('update:modelValue', point.value);
});

watch(euro, (newEuro) => {
    point.value = newEuro / props.rate;
});
</script>

<template>
  <div class="flex gap-4">
    <BaseInput
      v-model="point" class="flex-1" :value="point" type="number" label="Nombre de points"
      @input="point = $event"
    />
    <BaseInput v-model="euro" class="flex-1" :value="euro" type="number" label="€" @input="euro = $event" />
  </div>
</template>
