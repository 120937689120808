<script setup lang="ts">
import Icon from "@/core/components/icon/Icon.vue";

defineProps<{ title: string; subtitle: string; iconName?: string }>();
</script>

<template>
  <div class="flex flex-row items-center justify-between bg-white px-4 py-2 rounded-lg hover:opacity-75 cursor-pointer">
    <div class="flex flex-col items-start">
      <p class="font-semibold">
        {{ title }}
      </p>
      <p class="text-sm">
        {{ subtitle }}
      </p>
    </div>
    <div class="flex">
      <icon v-if="iconName" :name="iconName" color="#FF80A2" :size="32" />
    </div>
  </div>
</template>
