<script setup lang="ts">
import RichTextRenderer from 'contentful-rich-text-vue-renderer';
import contentfulHelper from "@/core/helpers/contentfulHelper";
import ApodisPremiumAdvantages from "@/subscription-plan/components/apodis-premium/ApodisPremiumAdvantages.vue";
import ApodisPremiumCheckoutButton from "@/subscription-plan/components/apodis-premium/ApodisPremiumCheckoutButton.vue";

defineProps<{
    salesPitch: any;
    salesPitchFooter?: string;
}>();
</script>

<template>
  <div class="bg-white rounded-lg p-6 space-y-8 shadow-[5px_5px_0_0_#65efca]">
    <div class="sales-pitch">
      <RichTextRenderer :document="salesPitch" :node-renderers="contentfulHelper.renderNodes()" />
    </div>
    <ApodisPremiumAdvantages />
    <div class="flex items-center">
      <div class="flex-1">
        <h2 v-if="salesPitchFooter">
          {{ salesPitchFooter }}
        </h2>
      </div>
      <ApodisPremiumCheckoutButton horizontal />
    </div>
  </div>
</template>

<style scoped>
:deep(.sales-pitch ul) {
    @apply !pl-0;
}

:deep(.sales-pitch li) {
    @apply flex items-start;
    list-style: none;
}

:deep(.sales-pitch li) {
    @apply mb-2 !important;
}

:deep(.sales-pitch li::before) {
    content: "✓";
    @apply text-secondary text-3xl top-2 relative;
    margin-right: 10px;
}
</style>
