<template>
  <div>
    <TheBackButton title="Ordonnances" class="bg-white" />
    <div class="flex flex-col gap-y-6 page-padding">
      <div class="flex justify-between">
        <h1 class="flex-1 text-2xl">
          <span v-if="! route.query.consumer_key">Suivez les renouvellements de vos patients</span>
          <span v-else>Suivez les renouvellements du patient</span>
          <div class="text-sm leading-5 text-gray-dark">
            Visualisez les rappels à partir du calendrier
          </div>
        </h1>
        <div class="flex flex-row gap-x-2 w-56 justify-end">
          <BaseSelect
            v-model:modelValue="state.planFilter"
            :options="[
              {value: 'ongoing', label: 'Renouvellements à venir'},
              {value: 'completed', label: 'Renouvellements passés'},
            ]"
          />
        </div>
      </div>
      <PatientFilters v-if="state.meta" v-model="state.selectedTags" :tags="state.meta.tags" />
      <base-flex-spinner v-if="state.isLoading" />
      <div v-else class="flex flex-col gap-y-4">
        <h3 v-if="!route.query.consumer_key">
          {{ state.meta?.renewal_plan_consumer_count }}
          {{ pluralize('patient accompagné', state.meta?.renewal_plan_consumer_count || 0) }}
        </h3>
        <Accordion type="single" collapsible class="flex flex-col gap-3">
          <RenewalPlanAccordionItem
            v-for="consumer in consumerBlocks"
            :key="consumer.customer.id + '_' + consumer.beneficiary?.id || '0'"
            :prescriptions="consumer.prescriptions"
            :customer="consumer.customer"
            :beneficiary="consumer.beneficiary"
          />
        </Accordion>
        <div class="flex justify-center">
          <base-button
            v-if="!!state.meta?.after_key && !state.endReached"
            class="font-medium"
            :disabled="state.isLoadingMore"
            @click="loadMore"
          >
            <base-spinner v-if="state.isLoadingMore" size="button" />
            <span v-else>Voir plus</span>
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, ComputedRef, onMounted, reactive, watch} from "vue";
import {prescriptionApi} from '@/container';
import {Prescription, PrescriptionIndexMeta, PrescriptionIndexParams} from "@/prescription/interfaces/Prescription";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import BaseSpinner from "@/core/components/base/spinner/BaseSpinner.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import PatientFilters, {PatientFiltersProps} from "@/prescription/components/PatientFilters.vue";
import BaseSelect from "@/core/components/base/BaseSelect.vue";
import {pluralize} from "@/core/filters/Pluralize";
import RenewalPlanAccordionItem from "@/prescription/components/RenewalPlanAccordionItem.vue";
import Customer from "@/customer/interfaces/Customer";
import Beneficiary from "@/customer/interfaces/Beneficiary";
import TheBackButton from "@/core/components/navigation-bar/TheBackButton.vue";
import {Accordion} from "@/core/components/ui/accordion";
import {useRoute} from "vue-router";

const route = useRoute();

const state = reactive({
    page: 1,
    isLoading: false,
    isLoadingMore: false,
    endReached: false,
    selectedTags: {
        tags: [],
        certified: null,
        children: null,
        tags_operator: 'AND',
        purchase_type: null,
        purchase_id: null,
        gist: null,
    } as PatientFiltersProps,
    meta: null as PrescriptionIndexMeta | null,
    prescriptions: [] as Prescription[],
    planFilter: 'ongoing' as 'ongoing' | 'any' | 'none' | 'completed',
});

onMounted(() => refresh());

/**
 * Refresh the renewal plans.
 */
const refresh = async () => {
    state.isLoading = true;
    state.prescriptions = [];
    state.isLoadingMore = false;
    state.endReached = false;
    if (state.meta) {
        state.meta.after_key = null;
    }
    await fetch();

    state.isLoading = false;
};

/**
 * Fetch the renewal plans.
 */
const fetch = async () => {
    const params: PrescriptionIndexParams = {
        with_renewal_plan_count: true,
        renewal_plan: state.planFilter,
        limit: 100,
        tags: state.selectedTags.tags,
        tags_operator: 'AND',
        after: state.meta?.after_key ? JSON.stringify(state.meta.after_key) : '',
        ...state.selectedTags.children && {age_to: '19 years'},
        ...state.selectedTags.certified && {certified: 1},
        ...state.selectedTags.purchase_type && {purchase_type: state.selectedTags.purchase_type},
        ...state.selectedTags.purchase_id && {purchase_id: state.selectedTags.purchase_id},
        ...state.selectedTags.gist && {gist: 1},
        ...route.query.consumer_key && {consumer_key: route.query.consumer_key},
    };

    return await prescriptionApi()
        .index(params)
        .then(response => {
            state.prescriptions = [...state.prescriptions, ...response.data];
            state.endReached = state.meta?.after_key === response.meta.after_key;
            state.meta = response.meta;
        });
};

/**
 * Group renewal plan by consumers.
 */
const consumerBlocks: ComputedRef<{
    prescriptions: Prescription[],
    customer: Customer,
    beneficiary: Beneficiary
}[]> = computed(() => {
    return Object.values(state.prescriptions
        .reduce((accumulator, prescription) => {
            if (!accumulator[prescription.renewal_plan.consumer_key]) {
                accumulator[prescription.renewal_plan.consumer_key] = {
                    "customer": prescription.customer,
                    "beneficiary": prescription.beneficiary,
                    "prescriptions": [],
                };
            }

            accumulator[prescription.renewal_plan.consumer_key].prescriptions.push(prescription);

            return accumulator;
        }, {}));
});

/**
 * Load more renewal plans.
 */
const loadMore = async () => {
    state.isLoadingMore = true;
    await fetch();
    state.isLoadingMore = false;
};

/** Watch for filter changes */
watch(() => state.planFilter, refresh);
watch(() => state.selectedTags, refresh, {deep: true});

</script>
