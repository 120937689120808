<script setup lang="ts">
import BaseButton from "@/core/components/base/BaseButton.vue";
import {storeToRefs} from "pinia";
import {useSubscriptionPlanStore} from "@/stores/subscription-plan.store";
import {computed, ref} from "vue";
import StripePriceService from "@/stripe/services/stripePriceService";
import StripeCheckoutService from "@/stripe/services/stripeCheckoutService";
import SubscriptionPlanService from "@/subscription-plan/services/SubscriptionPlanService";

defineProps<{
    socialProof?: string;
    salesPitchFooter?: string;
}>();

const {globalPlan} = storeToRefs(useSubscriptionPlanStore());
const loadingCheckout = ref(false);

/**
 * Start the checkout stripe payment process.
 */
const checkout = () => {
    const lowestPrice = StripePriceService.featuredPrice(globalPlan.value?.prices);

    if (!lowestPrice) {
        return;
    }

    loadingCheckout.value = true;
    StripeCheckoutService.checkout(lowestPrice)
        .finally(() => {
            loadingCheckout.value = false;
        });
};

/**
 * The human readable price of the global plan.
 */
const price = computed(() => {
    const lowestPrice = StripePriceService.featuredPrice(globalPlan.value?.prices);

    if (!lowestPrice) {
        return null;
    }

    return StripePriceService.formatPrice(lowestPrice);
});

const hasActiveSubscription = computed(() => {
    if(!globalPlan.value) {
        return false;
    }

    return SubscriptionPlanService.hasActiveSubscription(globalPlan.value);
});
</script>

<template>
  <div class="bg-white text-center py-10 space-y-4">
    <h1 class="font-content-bold text-3xl">
      {{ socialProof }}
    </h1>
    <div class="flex justify-center items-center gap-4">
      <p v-if="salesPitchFooter">
        {{ salesPitchFooter }}
      </p>
      <BaseButton v-if="!hasActiveSubscription" class="bg-secondary text-primary" :loading="loadingCheckout" @click="checkout">
        Acheter pour {{ price }}
      </BaseButton>
    </div>
  </div>
</template>
