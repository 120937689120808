<script lang="ts" setup>

import {LoyaltyCardMembership} from "@/loyalty-card/interfaces/hds/LoyaltyCard";
import {ref} from "vue";
import {loyaltyCardMembershipCreditApi} from "@/container";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogFooter, DialogTrigger, DialogClose
} from "@/core/components/ui/dialog";
import BaseButton from "@/core/components/base/BaseButton.vue";
import LoyaltyCardMembershipPointCredit
    from "@/customer/components/customer-loyalty-membership/LoyaltyCardMembershipPointCredit.vue";
import LoyaltyCardService from "@/loyalty-card/services/LoyaltyCardService";
import LoyaltyCardMembershipProductCredit
    from "@/customer/components/customer-loyalty-membership/LoyaltyCardMembershipProductCredit.vue";
import {useToastStore} from "@/stores/toast.store";

const emit = defineEmits(['credited']);
const loading = ref(false);
const props = defineProps<{
    rate: number;
    membership: LoyaltyCardMembership;
    customerId: number;
}>();

const credited = ref(false);
const credit = ref<{ type: 'point' | 'unit', value: number }>({
    type: LoyaltyCardService().hasPointRewards(props.membership.loyalty_card) ? 'point' : 'unit',
    value: 0
});
const toast = useToastStore();

/**
 * Submit the credit
 */
const submit = async () => {
    loading.value = true;
    return loyaltyCardMembershipCreditApi()
        .create(props.customerId, props.membership.id, credit.value)
        .then(() => {
            credited.value = true;
            toast.showSuccess({
                content: 'Le crédit a été ajouté avec succès'
            });
            emit('credited');
        })
        .catch(error => {
            toast.showError({
                content: 'Une erreur est survenue lors de l\'ajout du crédit'
            });
            error.value = error.response.data;
        })
        .finally(() => {
            loading.value = false;
        });
};

/**
 * Handle credit value change.
 *
 * @param type
 * @param value
 */
const handleCreditChange = (type: 'unit' | 'point', value: number) => {
    credit.value = {type, value};
};
</script>

<template>
  <div>
    <Dialog>
      <DialogTrigger as-child>
        <div>
          <button
            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Créditer
          </button>
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader class="text-start">
          <DialogTitle>
            Créditer
          </DialogTitle>
        </DialogHeader>
        <DialogDescription class="space-y-4">
          <LoyaltyCardMembershipPointCredit
            v-if="LoyaltyCardService().hasPointRewards(membership.loyalty_card)"
            :rate="membership.loyalty_card.point?.rate || 0"
            :model-value="credit.value"
            @update:model-value="(value) => handleCreditChange('point', value)"
          />
          <LoyaltyCardMembershipProductCredit
            v-else-if="LoyaltyCardService().hasProductRewards(membership.loyalty_card)"
            :model-value="credit.value"
            @update:model-value="(value) => handleCreditChange('unit', value)"
          />
        </DialogDescription>
        <DialogFooter>
          <DialogClose as-child>
            <BaseButton>
              Fermer
            </BaseButton>
          </DialogClose>
          <DialogClose as-child>
            <BaseButton
              :loading="loading"
              primary
              type="submit"
              @click="submit"
            >
              Valider
            </BaseButton>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  </div>
</template>
