import useSwr from "@/core/composables/swr/useSwr";
import {knowledgeBoxApi} from "@/container";

/**
 * Composable to get knowledgeables.
 */
const useKnowledgeables = () => {
    const {data, isLoading} = useSwr(
        "knowledgeables",
        () => knowledgeBoxApi().index({
            knowledgeable_types: ['laboratory', 'null']
        }),
        {
            revalidateOnFocus: false
        }
    );

    return {
        data,
        isLoading
    };
};

export default useKnowledgeables;
