<script setup lang="ts">
import {LaboratoryCartLine} from "@/laboratory/interfaces";
import Icon from "@/core/components/icon/Icon.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {laboratoryApi, shopApi} from "@/container";
import {onMounted, ref} from "vue";
import LaboratoryPartnership from "@/core/interfaces/laboratory/LaboratoryPartnership";
import useEnvironment from "@/notification/composables/useEnvironment";
import {useRouter} from "vue-router";
import {Laboratory} from "@/core/interfaces/laboratory/Laboratory";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from "@/core/components/ui/dialog";
import imagesHelper from "@/core/helpers/imagesHelper";

const {lines, laboratory} = defineProps<{
    lines: LaboratoryCartLine[];
    laboratory?: Laboratory;
    canPurchase: boolean
}>();

const {environment} = useEnvironment();
const laboratoryPartnership = ref<LaboratoryPartnership>();
const open = ref(false);
const loading = ref(false);
const router = useRouter();

onMounted(() => {
    if (laboratory) {
        laboratoryApi().read(laboratory.id)
            .then(response => laboratoryPartnership.value = response);
    }
});

const totalHT = lines.reduce((sum, element) => sum + element.totalDiscountedWithoutVat, 0) || 0;
const totalTTC = lines.reduce((sum, element) => sum + element.totalDiscountedWithVat, 0) || 0;
const totalWithoutDiscountHT = lines.reduce((sum, element) => sum + (element.priceWithoutVat * element.quantity), 0) || 0;
const totalRemisesHT = totalWithoutDiscountHT - totalHT;
const discounted = totalWithoutDiscountHT > totalHT;

/**
 * Validate cart.
 */
const validateCart = () => {
    if (laboratory?.id && environment.value) {
        loading.value = true;
        return shopApi().validateCart(laboratory.id, environment.value.id)
            .then(() => router.back())
            .finally(() => loading.value = false);
    }
};

/**
 * Open cgv.
 */
const openCGV = () => {
    if (laboratoryPartnership.value?.cgvPath) {
        const pdfPath = imagesHelper.getURLFromPath("files/" + laboratoryPartnership.value.cgvPath);

        window.open(pdfPath);
    }
};
</script>

<template>
  <div class="flex flex-col justify-center items-center">
    <div v-if="discounted" class="flex flex-row items-center gap-2 pb-4">
      <icon name="mdi-sale-outline" :size="25" />
      <p>
        Vous bénéficiez de{{ " " }}
        <span class="font-semibold">
          {{ ((totalRemisesHT / totalHT) * 100).toFixed(1) }}% ({{ $filters.currencyze(totalRemisesHT) }})
          de remise globale
        </span>
        sur cette commande
      </p>
    </div>
    <div class="flex flex-row w-full justify-around items-center">
      <div v-if="lines?.length" class="pb-4">
        <p>Total HT remisé <span class="font-semibold">{{ $filters.currencyze(totalHT) }}</span></p>
        <p>Total TVA <span class="font-semibold">{{ $filters.currencyze(totalTTC - totalHT) }}</span></p>
        <p>Total TTC <span class="font-semibold">{{ $filters.currencyze(totalTTC) }}</span></p>
      </div>
      <BaseButton
        :color="laboratory?.branding?.primary_color"
        :style="{'color': laboratory?.branding?.secondary_color}"
        @click="open=true"
      >
        Valider le panier
      </BaseButton>
    </div>
  </div>
  <Dialog
    :open="open"
    @update:open="(value) => open = value"
  >
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Validation du panier</DialogTitle>
      </DialogHeader>
      <DialogDescription class="text-base">
        {{ laboratoryPartnership?.markdown.cgvValidationText ?? '' }}
      </DialogDescription>
      <DialogFooter>
        <div class="flex gap-5 self-center">
          <BaseButton
            v-if="laboratoryPartnership?.cgvPath"
            variant="text"
            :disabled="loading"
            class="font-bold"
            @click="openCGV"
          >
            Voir les CGV
          </BaseButton>
          <BaseButton
            variant="text"
            :disabled="loading"
            class="font-bold"
            @click="open=false"
          >
            Annuler
          </BaseButton>
          <BaseButton
            primary
            :disabled="loading"
            :loading="loading"
            @click="validateCart"
          >
            <span class="font-bold">Confirmer</span>
          </BaseButton>
        </div>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
