<template>
  <header class="flex-shrink-0 relative h-12 bg-white flex items-center border-b-2 border-primary-lightest">
    <div class="absolute inset-y-0 left-0">
      <div
        class="hidden md:block h-28 w-24 rounded-b-3xl"
        :class="[{
          'bg-gradient-to-r from-primary-dark to-primary': !groupingLogo,
          'bg-white shadow': groupingLogo
        }]"
      >
        <div class="flex items-center justify-center h-28">
          <img
            v-if="!detecting"
            class="h-12 w-auto"
            :src="groupingLogo ? groupingLogo : apodisLogoWhite"
            alt="Apodis"
          >
          <BaseSpinner
            v-else
            :color="groupingLogo ? 'primary' : 'white'"
            size="medium-small"
          />
        </div>
      </div>
    </div>

    <div class="text-primary font-semibold ml-20 md:ml-28">
      {{ title }}
    </div>

    <div class="hidden md:min-w-0 md:flex-1 md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1" />

      <div class="ml-10 pr-4 flex-shrink-0 flex items-center space-x-10">
        <div class="flex items-center space-x-8">
          <ApodisPremiumButton />
          <VersionButton />
          <div class="relative flex flex-row space-x-3 align-center text-left">
            <router-link :to="{ name: 'tutorial' }" class="button" active-class="button-active">
              <span class="sr-only">Tutoriels</span>
              <Icon name="mdi-movie-play" :size="25" class="text-primary" />
            </router-link>
            <router-link :to="{ name: 'notification.history' }" class="button" active-class="button-active">
              <span class="sr-only">Notifications</span>
              <span class="relative inline-block">
                <Icon name="mdi-bell" :size="25" class="text-primary" />
                <span
                  v-if="notificationCountLabel !== null"
                  class="absolute top-2 right-2.5 h-4 w-4 inline-flex items-center justify-center px-2 py-1 text-sm font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full"
                >{{ notificationCountLabel }}</span>
              </span>
            </router-link>
            <TheCalendar>
              <Icon name="mdi-calendar-month" :size="25" class="text-primary" />
            </TheCalendar>
            <TheUserMenu />
          </div>
        </div>
      </div>
    </div>

    <TheMobileMenu />
  </header>
</template>

<script setup lang="ts">
import {onMounted, computed, ref} from "vue";
import TheUserMenu from '@/core/components/navigation-bar/TheUserMenu.vue';
import TheMobileMenu from '@/core/components/navigation-bar/TheMobileMenu.vue';
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import VersionButton from '@/core/components/version-button/VersionButton.vue';
import {useSystemStore} from '@/stores/system.store';
import ApodisPremiumButton from "@/core/components/apodis-premium/ApodisPremiumButton.vue";
import {useRoute, useRouter} from "vue-router";
import {storeToRefs} from "pinia";
import apodisLogoWhite from '@/assets/images/logos/apodis-white.svg';
import {usePatientStore} from "@/stores/patient.store";
import {NotificationEventParams} from "@/core/interfaces/Event";
import TheCalendar from "@/core/components/navigation-bar/TheCalendar.vue";
import Icon from "@/core/components/icon/Icon.vue";

const {notificationCount, environment, groupingLogo} = storeToRefs(useSystemStore());
const patientStore = usePatientStore();

const router = useRouter();
const route = useRoute();
const detecting = ref(false);
const title = computed(() => {
    if (groupingLogo.value) {
        return environment.value?.entity?.grouping?.name;
    }

    return route.meta.title || 'Apodis Connect';
});

const notificationCountLabel = computed(() => {
    const count = notificationCount.value;

    if (!count) {
        return null;
    }

    return count > 9 ? '9+' : count;
});

/**
 * Show a support program.
 */
const showSupportProgram = (event: never, params: NotificationEventParams) => {
    patientStore
        .load(params.customerId)
        .then(() => router.push({
            name: 'consumer.support-program',
            params: {customerId: params.customerId, programId: params.programId, consumerKey: params.consumerKey}
        }));
};

/**
 * Show a customer.
 */
const showCustomer = (event: never, customerId: number) => {
    patientStore
        .load(customerId)
        .then(() => router.push({
            name: 'customer.home',
            params: {customerId}
        }));
};

onMounted(() => {
    window.ipcRenderer.on('detection-start', () => {
        detecting.value = true;
    });

    window.ipcRenderer.on('detection-completed', () => {
        detecting.value = false;
    });


    window.ipcRenderer.off('show-support-program', showSupportProgram);
    window.ipcRenderer.on('show-support-program', showSupportProgram);

    window.ipcRenderer.off('show-customer', showCustomer);
    window.ipcRenderer.on('show-customer', showCustomer);
});
</script>

<style scoped>
.button {
    @apply flex-shrink-0 inline-flex items-center justify-center h-8 w-8;
}

.button:hover,
.button-active span > svg {
    @apply stroke-current text-secondary;
}
</style>
