<script setup lang="ts">
import {FAQContentfulEntries} from "@/contentful/interfaces/contentful";
import {AccordionContent, AccordionItem, AccordionTrigger} from "@/core/components/ui/accordion";
import contentfulHelper from "@/core/helpers/contentfulHelper";
import RichTextRenderer from 'contentful-rich-text-vue-renderer';

defineProps<{
    item: FAQContentfulEntries
}>();
</script>

<template>
  <AccordionItem :value="item.question">
    <AccordionTrigger class="text-xl">
      {{ item.question }}
    </AccordionTrigger>
    <AccordionContent class="text-base">
      <RichTextRenderer :document="item.answer" :node-renderers="contentfulHelper.renderNodes()" />
    </AccordionContent>
  </AccordionItem>
</template>
