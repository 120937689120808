<template>
  <BaseCard>
    <BaseCardBody>
      <h1>Achats</h1>
      <div>
        <div class="text-gray-500 mt-1 mb-5 text-sm">
          Filtres
        </div>
        <ul
          :id="'filters'"
          class="flex flex-row font-content flex-wrap gap-3"
        >
          <li
            class="rounded-3xl p-3 text-sm"
            :class="{'active': !filter, 'not-active': !!filter}"
          >
            <button @click="setFilter(null)">
              Tous
            </button>
          </li>
          <li
            v-for="slug in groups()"
            :key="slug"
          >
            <button
              class="bg-gray-100 rounded-3xl p-3 text-sm"
              :class="{'active': filter === slug, 'not-active': !filter}"
              @click="setFilter(slug)"
            >
              {{ label(slug) }}
            </button>
          </li>
        </ul>
      </div>
      <ul class="mt-8">
        <li
          v-for="(purchase, index) in orderedPurchases()"
          :key="index"
          class="flex flex-row"
        >
          <div class="purchase-label text-sm font-content">
            {{ purchase.label }}
          </div>
          <div class="flex flex-col justify-center">
            <div class="purchase-details flex flex-row">
              <div
                class="text-primary rounded-full bg-gray-100 w-9 h-9 flex items-center justify-center purchase-icon"
              >
                <CartIcon :size="5" />
              </div>
              <div class="ml-3">
                <div class="text-sm">
                  <strong>Achat</strong> {{ purchaseDate(purchase) }}
                </div>
                <div class="text-xs font-bold font-content">
                  {{ purchase.quantity }} x {{ purchase.name }}
                </div>
                <div class="text-xs text-gray-500 font-content">
                  {{ purchase.cip }}
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </BaseCardBody>
  </BaseCard>
</template>
<script lang="ts">
import {defineComponent, PropType} from "vue";
import BaseCardBody from '@/core/components/base/BaseCardBody.vue';
import BaseCard from '@/core/components/base/BaseCard.vue';
import CartIcon from '@/core/components/icons/CartIcon.vue';
import dateHelper from '@/core/helpers/dateHelper';
import domHelper from '@/core/helpers/domHelper';
import {SupportProgramMeta, SupportProgramPatient} from "@/program/interfaces/Program";

export default defineComponent({
    components: {CartIcon, BaseCard, BaseCardBody},
    props: {
        history: {
            type: Object as PropType<{data: SupportProgramPatient, meta: SupportProgramMeta}>,
            required: true
        }
    },
    data: () => ({
        filter: null as null | string
    }),
    methods: {
        groups() {
            return Object.keys({...this.history.meta.included, ...this.history.meta.excluded});
        },
        label(slug: string) {
            return this.history.meta.included[slug] || this.history.meta.excluded[slug];
        },
        orderedPurchases() {
            const purchases: any[] = [];

            for (const group of [...this.history.data.groups.included, ...this.history.data.groups.excluded]) {
                if (!group.sales) {
                    group.sales = [{name: 'Achat qualifiant', date: group.last_visit}];
                }

                if (group.sales) {
                    for (const sale of group.sales) {
                        if (!this.filter || this.filter === group.slug) {
                            purchases.push({...sale, label: this.label(group.slug)});
                        }
                    }
                }
            }

            return purchases;
        },
        purchaseDate(purchase: { date: string }) {
            const date = purchase.date;

            return `le ${dateHelper.mediumFormat(date)}`;
        },
        setFilter(slug: string | null) {
            domHelper.scrollIntoView('filters');

            this.filter = slug;
        }
    }

});
</script>

<style scoped>

.purchase-label {
    @apply border-r-4 py-8 pr-10 flex flex-col justify-center;
    width: 100px;
    border-right-color: rgba(101, 239, 202, 1);
}

.purchase-details {
    @apply rounded-2xl shadow p-5 py-5 bg-white;
    position: relative;
    left: -10px;
}

.purchase-icon {
    background-color: rgba(226, 196, 255, 1);
}

.active {
    @apply bg-primary text-white
}

.not-active {
    @apply bg-gray-100 text-black
}
</style>
