<script setup lang="ts">
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import useLaboratoryOffers from "@/laboratory/composables/useLaboratoryOffers";
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardHeader from "@/core/components/base/BaseCardHeader.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import {Laboratory} from "@/core/interfaces/laboratory/Laboratory";
import LaboratoryOfferCard from "@/laboratory/components/offer/LaboratoryOfferCard.vue";
import LaboratoryOfferCardSkeleton from "@/laboratory/components/offer/LaboratoryOfferCardSkeleton.vue";
import Offer from "@/core/interfaces/Offer";

const props = defineProps<{
    laboratoryId: string;
    laboratory?: Laboratory;
}>();

const {data: currentOffers, isLoading: currentIsLoading} = useLaboratoryOffers(props.laboratoryId, {status: 'active'});
const {data: pastOffers, isLoading: pastIsLoading} = useLaboratoryOffers(props.laboratoryId, {status: 'past'});

const redirectParams = (offer: Offer) => {
    return {
        name: 'laboratory.offers.details',
        params: {laboratoryId: props.laboratoryId, offerId: offer.id},
        query: {offerName: offer.name, offerStartAt: offer.start_at, offerEndAt: offer.end_at}
    };
};
</script>

<template>
  <BaseContainer class="p-6">
    <div class="space-y-6">
      <BaseCard>
        <BaseCardHeader>
          <div>
            <h2 class="text-black">
              Promotions en cours
            </h2>
            <p v-if="currentOffers?.length" class="text-base font-content">
              Publiez vos promotions sur l'application Apodis et mesurez la performance de vos promotions
            </p>
          </div>
          <BaseCardBody class="text-base font-content">
            <div v-if="currentIsLoading" class="grid grid-cols-4 gap-6">
              <LaboratoryOfferCardSkeleton />
              <LaboratoryOfferCardSkeleton />
              <LaboratoryOfferCardSkeleton />
            </div>
            <div v-else-if="currentOffers?.length === 0">
              <p>
                Ajoutez des promotions sur les produits {{ laboratory?.name }} depuis votre LGO pour mesurer leur
                performance ici, et les publier sur l'app patient
              </p>
            </div>
            <div v-else class="grid grid-cols-4 gap-6">
              <router-link
                v-for="offer in currentOffers" :key="offer.id" :to="redirectParams(offer)"
              >
                <LaboratoryOfferCard :offer="offer" />
              </router-link>
            </div>
          </BaseCardBody>
        </BaseCardHeader>
      </BaseCard>
      <BaseCard>
        <BaseCardHeader>
          <div>
            <h2 class="text-black">
              Promotions passées
            </h2>
          </div>
          <BaseCardBody class="text-base font-content">
            <div v-if="pastIsLoading" class="grid grid-cols-4 gap-6">
              <LaboratoryOfferCardSkeleton />
              <LaboratoryOfferCardSkeleton />
              <LaboratoryOfferCardSkeleton />
            </div>
            <div v-else-if="pastOffers?.length === 0">
              <p>
                Aucune promotion
              </p>
            </div>
            <div v-else class="grid grid-cols-4 gap-6">
              <router-link
                v-for="offer in pastOffers" :key="offer.id" :to="redirectParams(offer)"
              >
                <LaboratoryOfferCard :offer="offer" />
              </router-link>
            </div>
          </BaseCardBody>
        </BaseCardHeader>
      </BaseCard>
    </div>
  </BaseContainer>
</template>

<style scoped>

</style>
