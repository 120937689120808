<script setup lang="ts">
import {PrescriptionControlProduct} from "@/prescription/interfaces/Prescription";

defineProps<{
    product: PrescriptionControlProduct
}>();

</script>

<template>
  <div class="flex flex-col">
    <div class="font-bold">
      {{ product.dispensed.name || product.prescribed.name }}
    </div>
    <span class="text-sm text-gray">{{ product.dispensed.code || product.prescribed.code }}</span>
    <div v-if="product.dispensed.name && product.prescribed.name">
      <div class="border-l-4 border-l-green pl-3 text-sm">
        {{ product.label }}
      </div>
    </div>
    <div v-else>
      <div class="border-l-4 border-l-orange pl-3 text-sm">
        {{ product.label }}
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
