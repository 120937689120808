<template>
  <div class="flex h-screen w-full">
    <GoogleMap
      ref="mapRef"
      :center="center"
      :zoom="16"
      style="position: relative; width: 100%; height: inherit;"
      @click="selectedHealthcenter=null"
    >
      <GMapMarker
        v-for="(m, index) in healthcenters"
        :key="index"
        :options="{
          position: { lat: m.geo.location.lat, lng: m.geo.location.lon },
          clickable: true,
          draggable: false,
          icon: { url: (selectedHealthcenter && (selectedHealthcenter.cip === m.cip)) ? healthcenterPlaceMarkSelected : healthcenterPlaceMark}}"
        @click="selectedHealthcenter=m"
      />
      <div class="absolute top-10 left-1/2 transform -translate-x-1/2 z-10">
        <div class="px-4 py-2 bg-white rounded-lg text-black">
          Recherche par code postal :
          <input
            v-model="postalCodeMutable"
            type="text"
            class="border-transparent rounded-lg text-primary w-24 border-primary"
            @keyup.enter="updatePostalCode"
            @blur="formatZipcode"
            @input="() => debounceFormatZipCode(10000)"
          >
          <button
            class="shadow p-2 rounded"
            @click="updatePostalCode"
          >
            Rechercher
          </button>
        </div>
      </div>
      <div class="absolute bottom-10 right-20 z-10">
        <RegisterHealthcenterView
          v-if="selectedHealthcenter"
          :healthcenter="selectedHealthcenter"
          :register-is-loading="loading"
          @validate="validateHealthcenter"
        />
      </div>
    </GoogleMap>
    <div v-if="healthcenters?.length" class="flex flex-col overflow-y-auto w-[30%] bg-white">
      <div
        v-for="healthcenter in healthcenters"
        :key="healthcenter.id"
        class="gap-4 p-4 cursor-pointer hover:opacity-75 hover:bg-gray-100 border-b"
        @click="selectedHealthcenter=healthcenter"
      >
        <p class="text-primary font-semibold">
          {{ healthcenter.name }}
        </p>
        <div class="text-sm">
          {{ healthcenter.geo.city.address1 }}<br>
          {{ healthcenter.geo.city.postal_code }}, {{ healthcenter.geo.city.name }}
        </div>
        <div class="text-sm text-gray-500">
          CIP: {{ healthcenter.cip }}
        </div>
        <div class="flex justify-end">
          <base-button density="compact" primary>
            Sélectionner
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {healthcenterSearchApi} from '@/container';
import {Marker as GMapMarker} from 'vue3-google-map';
import GoogleMap from "@/core/components/google-map/GoogleMap.vue";
import RegisterHealthcenterView from '@/register/views/RegisterHealthcenterView.vue';
import formatHelper from '@/core/helpers/formatHelper';
import healthcenterPlaceMarkSelected from '@/assets/icons/healthcenter_placemark_selected.svg';
import healthcenterPlaceMark from '@/assets/icons/healthcenter_placemark.svg';
import BaseButton from "@/core/components/base/BaseButton.vue";

export default {
    name: 'RegisterMapView',

    components: {
        BaseButton,
        RegisterHealthcenterView,
        GoogleMap,
        GMapMarker
    },

    props: {
        postalCode: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    emits: ['validate'],

    data() {
        return {
            postalCodeMutable: this.postalCode as string,
            timer: null as any,
            google: null as any,
            center: {lat: 48.856613, lng: 2.352222},
            healthcenters: [] as any[],
            selectedHealthcenter: null as any | null,
            healthcenterPlaceMarkSelected,
            healthcenterPlaceMark
        };
    },

    mounted() {
        this.updatePostalCode();
        this.centerMapOnPostalCode();
    },

    methods: {
        updatePostalCode() {
            healthcenterSearchApi()
                .read({postalCode: [this.postalCodeMutable]})
                .then(healthcenters => {
                    this.healthcenters = healthcenters.hits.hits.map(h => h._source);
                    this.centerMapOnPostalCode();
                });
        },

        centerMapOnPostalCode() {
            const mapRef = this.$refs.mapRef as InstanceType<typeof GoogleMap>;
            if (mapRef) {
                let bounds = this.healthcenters.map(hc => {
                    return ({
                        lat: hc.geo.location.lat,
                        lng: hc.geo.location.lon
                    });
                });
                mapRef.fitBounds(bounds);
            } else {
                setTimeout(this.centerMapOnPostalCode, 1000);
            }
        },

        validateHealthcenter(healthcenter) {
            this.$emit('validate', healthcenter);
        },

        debounceFormatZipCode(timeout) {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            this.timer = setTimeout(() => {
                this.formatZipcode();
            }, timeout);
        },
        formatZipcode() {
            let code = formatHelper.formatZipCode(this.postalCodeMutable);
            if (code) {
                this.postalCodeMutable = code;
            }
        }
    }
};
</script>
