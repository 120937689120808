<template>
  <BaseCard v-if="!supportProgram.meta.options.hideGroups">
    <BaseCardBody>
      <h3 class="text-xl leading-6 font-medium text-gray-900 mb-5">
        Statistiques
      </h3>
      <ProgramDigestCards
        v-if="hasExcluded"
        :statistics-included="supportProgram.statistics.included"
        :statistics-excluded="supportProgram.statistics.excluded"
      />
      <ProgramDigestGroups :data="supportProgram" :has-excluded="hasExcluded" />
      <ProgramKeyIndicators
        v-if="marketStatistics?.length"
        :stats="marketStatistics"
      />
    </BaseCardBody>
  </BaseCard>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import BaseCard from '@/core/components/base/BaseCard.vue';
import BaseCardBody from '@/core/components/base/BaseCardBody.vue';
import ProgramDigestCards from '@/program/components/program-statistics/ProgramDigestCards.vue';
import ProgramDigestGroups from '@/program/components/program-statistics/ProgramDigestGroups.vue';
import ProgramKeyIndicators from '@/program/components/program-statistics/ProgramKeyIndicators.vue';
import {SupportProgramMarketStatistic} from "@/program/interfaces/Program";

export default defineComponent({
    name: 'SupportProgramStatistics',
    components: {
        BaseCardBody,
        BaseCard,
        ProgramDigestCards,
        ProgramDigestGroups,
        ProgramKeyIndicators: ProgramKeyIndicators
    },
    props: {
        supportProgram: {
            type: Object,
            required: true
        },
        marketStatistics: {
            type: Array as PropType<SupportProgramMarketStatistic[]>,
            required: true
        }
    },
    computed: {
        hasExcluded() {
            const excludedGroups = this.supportProgram.meta.excluded;

            return (Array.isArray(excludedGroups) && excludedGroups.length > 0)
                || (typeof excludedGroups === 'object' && Object.keys(excludedGroups).length > 0);
        }
    }
});
</script>
