<script setup lang="ts">

import TrialStatusContainer from "@/trial/components/trial-status/TrialStatusContainer.vue";
import Icon from "@/core/components/icon/Icon.vue";
import {Trial} from "@/core/interfaces/trial";
import {computed} from "vue";
import dateHelper from "../../../core/helpers/dateHelper";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {useRouter} from "vue-router";
import HealthcenterAdvantages from "@/advantage/components/HealthcenterAdvantages.vue";

const router = useRouter();
const props = defineProps<{
    trial: Trial;
}>();

/**
 * Compute the remaining days of the trial
 */
const remainingDays = computed(() => {
    const endDate = new Date(props.trial.end_at);
    const now = new Date();
    const diffTime = Math.abs(endDate.getTime() - now.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
});

const navigateToGlobalPlan = () => {
    router.push({name: 'subscription-plans'});
};
</script>

<template>
  <div>
    <TrialStatusContainer>
      <template #headline>
        <div class="flex items-center gap-2">
          <Icon name="mdi-crown" color="primary" :size="30" />
          <p class="flex-1">
            Votre essai gratuit d'Apodis PREMIUM se termine dans {{ remainingDays }}
            {{ $filters.pluralize("jour", remainingDays) }} (le {{
              dateHelper.shortFormat(trial.end_at, "DD MMMM")
            }})
          </p>
          <BaseButton primary @click="navigateToGlobalPlan">
            Passer premium
          </BaseButton>
        </div>
      </template>
      <HealthcenterAdvantages />
    </TrialStatusContainer>
  </div>
</template>

<style scoped>

</style>
