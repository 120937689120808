<script setup lang="ts">
import {SubscriptionPlan} from "@/subscription-plan/interfaces";
import BaseButton from "@/core/components/base/BaseButton.vue";
import StripePriceService from "@/stripe/services/stripePriceService";
import {computed, ref} from "vue";
import SubscriptionPlanLogo from "@/subscription-plan/components/SubscriptionPlanLogo.vue";
import Icon from "@/core/components/icon/Icon.vue";
import StripeCheckoutService from "@/stripe/services/stripeCheckoutService";
import SupportService from "@/core/services/SupportService";

const props = defineProps<{
    plan: SubscriptionPlan
}>();
const stripeLoading = ref(false);

const price = computed(() => {
    const lowestPrice = StripePriceService.featuredPrice(props.plan.prices);

    if (!lowestPrice) {
        return null;
    }

    return StripePriceService.formatPrice(lowestPrice);
});

/**
 * Start the checkout stripe payment process.
 */
const handleButtonPress = () => {
    const lowestPrice = StripePriceService.featuredPrice(props.plan.prices);

    if (!lowestPrice) {
        return;
    }

    stripeLoading.value = true;
    return StripeCheckoutService.checkout(lowestPrice)
        .finally(() => {
            stripeLoading.value = false;
        });
};

/**
 * Redirect to hubspot.
 */
const handleSupportRequest = () => {
    SupportService.openCEOSupportWindow();
};
</script>

<template>
  <div class="flex gap-4 items-end">
    <SubscriptionPlanLogo class="!h-14 self-center" :plan="plan" />
    <div class="flex-1 py-2">
      <h1 class="text-white">
        {{ plan.name }}
      </h1>
      <p class="text-white">
        {{ plan.teaser }}
      </p>
    </div>
    <div v-if="plan.subscribed" class="flex gap-2 text-white items-baseline self-center">
      Pack acheté
      <icon name="mdi-check-bold" color="secondary" :size="30" />
    </div>
    <div v-else class="text-center flex flex-col gap-1">
      <div class="flex flex-row gap-3">
        <BaseButton @click="handleSupportRequest">
          Contacter un conseiller
        </BaseButton>
        <div class="flex flex-col">
          <BaseButton
            color="secondary"
            class="text-primary"
            :disabled="stripeLoading"
            :loading="stripeLoading"
            @click="handleButtonPress"
          >
            {{ price ? 'Acheter le pack' : 'Activer le pack' }}
          </BaseButton>
          <p v-if="price" class="font-semibold text-white">
            {{ price }} HT
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
