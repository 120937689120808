import {computed} from "vue";
import {filterEmptyKeys} from "@/lib/utils";
import useSwr from "@/core/composables/swr/useSwr";
import {laboratoryApi} from "@/container";

export interface LaboratoryOfferConsumerAnalysisSearchParams {
    size?: number;
    page?: number;
}

/**
 * Composable to get the analysis of customers for a laboratory.
 */
const useLaboratoryOfferConsumerAnalysis = (
    laboratoryId: string,
    offerId: string,
    searchParams: LaboratoryOfferConsumerAnalysisSearchParams
) => {
    const swrKey = computed(() => {
        const query = new URLSearchParams(filterEmptyKeys(searchParams));

        return `/laboratories/${laboratoryId}/offers/${offerId}/consumers?${query.toString()}`;
    });

    const {data, error, isLoading, isValidating, mutate} = useSwr(
        swrKey,
        () => laboratoryApi().offerConsumers(
            laboratoryId,
            offerId,
            searchParams
        ),
        {
            revalidateOnFocus: false,
            ttl: 1000 * 60 * 5,
            errorRetryCount: 0
        }
    );

    return {
        data,
        error,
        isLoading,
        isValidating,
        mutate
    };
};

export default useLaboratoryOfferConsumerAnalysis;
