<script setup lang="ts">
import {TestimonialContentfulEntries} from "@/contentful/interfaces/contentful";
import RichTextRenderer from 'contentful-rich-text-vue-renderer';
import contentfulHelper from "@/core/helpers/contentfulHelper";
import contentfulUtil from "@/contentful/services/contentfulUtil";
import {Dialog, DialogContent, DialogTrigger} from "@/core/components/ui/dialog";
import BaseButton from "@/core/components/base/BaseButton.vue";

defineProps<{
    testimonial: TestimonialContentfulEntries
}>();
</script>

<template>
  <div class="flex gap-4">
    <div>
      <img
        v-if="testimonial.leftImage?.fields" class="object-contain h-28" alt="testimonial-image"
        :src="contentfulUtil.fullImagePath(testimonial.leftImage.fields.file.url)"
      >
    </div>
    <div class="flex flex-col justify-between gap-3 w-full">
      <div class="testimonial-content">
        <RichTextRenderer :document="testimonial.content" :node-renderers="contentfulHelper.renderNodes()" />
      </div>
      <div v-if="testimonial.videoUrl" class="self-end">
        <Dialog>
          <DialogTrigger>
            <BaseButton variant="text" class="text-primary" density="compact">
              {{ testimonial.callToAction }}
            </BaseButton>
          </DialogTrigger>
          <DialogContent class="aspect-video max-w-4xl">
            <iframe
              class="w-full h-[400px]"
              :src="testimonial.videoUrl"
              allowfullscreen
            />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.testimonial-content p) {
    @apply italic !important;
}
</style>
