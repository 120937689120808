<template>
  <BaseFlexSpinner v-if="loading" />
  <p v-else-if="!plan">
    Une erreur s'est produite
  </p>
  <BaseContainer v-else class="flex flex-col">
    <div class="gradient p-4">
      <SubscriptionPlanHeader :plan="plan" />
    </div>
    <div class="p-4 flex flex-col gap-8">
      <SubscriptionPlanDescription v-if="plan.description" :plan="plan" />
      <subscription-plan-sales-pitch v-if="plan?.sales_pitch" :content="plan.sales_pitch" />
      <div class="space-y-3">
        <p>Ce pack comprend les modules suivants :</p>
        <div class="flex flex-col gap-6">
          <div
            v-for="extension in plan.extensions" :key="extension.id"
          >
            <SubscriptionPlanExtensionCard
              :extension="extension"
            />
          </div>
        </div>
      </div>
    </div>
  </BaseContainer>
</template>

<script setup lang="ts">
import {subscriptionPlanApi} from "@/container";
import {SubscriptionPlan} from "@/subscription-plan/interfaces";
import {ref, watch, onMounted, onUnmounted} from "vue";
import {useRoute} from "vue-router";
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import SubscriptionPlanExtensionCard from "@/subscription-plan/components/SubscriptionPlanExtensionCard.vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import SubscriptionPlanHeader from "@/subscription-plan/components/SubscriptionPlanHeader.vue";
import SubscriptionPlanDescription from "@/subscription-plan/components/SubscriptionPlanDescription.vue";
import SubscriptionPlanSalesPitch from "@/subscription-plan/components/SubscriptionPlanSalesPitch.vue";

const route = useRoute();
const planId = route.params.planId as string;

const loading = ref(false);
const plan = ref<SubscriptionPlan | null>(null);

onMounted(() => {
    window.ipcRenderer.on("stripe-checkout-success", () => {
        fetchPlan(planId);
    });
});

onUnmounted(() => {
    window.ipcRenderer.removeAllListeners("stripe-checkout-success");
});

watch(() => planId, fetchPlan, {immediate: true});

/**
 * Fetches the subscription plan from the API
 */
async function fetchPlan(id: string) {
    loading.value = true;
    try {
        plan.value = await subscriptionPlanApi().read(Number(id));
    } finally {
        loading.value = false;
    }
}
</script>
