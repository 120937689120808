<script setup lang="ts">
import {Knowledgeable} from "@/knowledge-box/interfaces";
import KnowledgeableChatModal from "@/knowledge-box/components/KnowledgeableChatModal.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import Icon from "@/core/components/icon/Icon.vue";

defineProps<{
    knowledgeable: Knowledgeable
}>();
</script>

<template>
  <div class="bg-white rounded-md overflow-hidden shadow-md text-center">
    <div class="py-2">
      <img
        v-if="knowledgeable.knowledge_box.logo?.url"
        class="w-32 mx-auto object-contain"
        :alt="knowledgeable.knowledge_box.title"
        :src="knowledgeable.knowledge_box.logo.url"
      >
    </div>
    <div class="py-2">
      <p>{{ knowledgeable.knowledge_box.title }}</p>
      <KnowledgeableChatModal :knowledgeable="knowledgeable">
        <template #trigger>
          <BaseButton variant="text">
            <span class="text-primary font-heading font-bold">Poser une question</span>
            <Icon name="mdi-creation" class="ml-1" color="primary" :size="20" />
          </BaseButton>
        </template>
      </KnowledgeableChatModal>
    </div>
  </div>
</template>

<style scoped>

</style>
