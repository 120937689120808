const SupportService = () => {
    /**
     * Open the CEOSupport window.
     */
    const openCEOSupportWindow = () => {
        const supportUrl = 'https://meetings.hubspot.com/thomas-brunet?uuid=a0df9fb4-f957-4b00-856d-8f8d7c1c4823';

        window.open(supportUrl);
    };

    /**
     * Open the Apodis Premium Support window.
     */
    const openApodisPremiumSupportWindow = () => {
        const supportUrl = "https://meetings.hubspot.com/alexis-perret/apodis-premium";

        window.open(supportUrl);
    };

    return {
        openCEOSupportWindow,
        openApodisPremiumSupportWindow,
    };
};

export default SupportService();
