import dayjs, {Dayjs} from 'dayjs';
import 'dayjs/locale/fr';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import Timezone from 'dayjs/plugin/timezone';
import UTC from 'dayjs/plugin/utc';
import RelativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(LocalizedFormat);
dayjs.extend(Timezone);
dayjs.extend(UTC);
dayjs.extend(RelativeTime);
dayjs.extend(customParseFormat);

export default {
    /**
     * Returns a string representation of the date (short format).
     *
     * @param  date - Date to convert.
     * @param format - Override format.
     *
     * @return string
     */
    shortFormat(date: string | Date, format = 'DD/MM/YYYY') {
        return dayjs(date).tz('Europe/Paris').locale('fr').format(format);
    },

    /**
     * Returns a string representation of the date (medium format).
     *
     * @param  date - Date to convert.
     *
     * @return string
     */
    mediumFormat(date: string | Date) {
        return dayjs(date).tz('Europe/Paris').locale('fr').format('DD/MM/YY à LT');
    },

    /**
     * Returns a string representation of the date (long format).
     *
     * @param  {Date|string|undefined} date - Date to convert.
     *
     * @return string
     */
    longFormat(date: string | Date) {
        return dayjs(date).tz('Europe/Paris').locale('fr').format('LL à LT');
    },

    /**
     * Returns a string representation of the date (day only, long format).
     *
     * @param  {Date|string|undefined} date - Date to convert.
     *
     * @return string
     */
    dayOnlyLongFormat(date: string | Date) {
        return dayjs(date).tz('Europe/Paris').locale('fr').format('LL');
    },

    /**
     * Returns a string representation of the date (day only, long format).
     *
     * @param  {Date|string|undefined} date - Date to convert.
     *
     * @return string
     */
    abbreviatedMonthFormat(date: string | Date) {
        return dayjs(date).tz('Europe/Paris').locale('fr').format('DD MMM YYYY');
    },

    /**
     * Returns a string representation of relative date.
     *
     * @param  {Date|string|undefined} date - Date to convert.
     *
     * @param withoutSuffix
     * @return string
     */
    relativeFormat(date?: string | Date, withoutSuffix = false) {
        return dayjs(date).tz('Europe/Paris').locale('fr').fromNow(withoutSuffix);
    },
    /**
     * Compute the age of the patient.
     */
    age(birthdate: string | Date | Dayjs | null | undefined) {
        if (!birthdate) {
            return 'age inconnu';
        }

        const age = dayjs().diff(birthdate, 'year');

        return `${age} ans`;
    }
};
